import { gql } from "@apollo/client";

export const fetchIncidents = gql`
    query fetchIncidents {
        incidents (where: {enabled: {_eq: true}}){
            id
            description
        }
    }
`;

export const fetchIncidentManagement = gql`
    query fetchIncidentManagement {
        incidentManagement {
            id
            description
            incident {
                id
                description
            }
            emailNotification
            emailMessage
            appNotification
            appMessage
            enabled
        }
    }
`;

export const insertIncidentManagement = gql`
    mutation insertIncidentManagement($description: String!, $incidentId: uuid!, $emailNotification: Boolean, $emailMessage: String, $appNotification: Boolean, $appMessage: String, $enabled: Boolean!) {
        insert_incidentManagement(objects: {description: $description, incidentId: $incidentId, emailNotification: $emailNotification, emailMessage: $emailMessage, appNotification: $appNotification, appMessage: $appMessage, enabled: $enabled}) {
            affected_rows
            returning {
                id
            }
        }
    }
`;

export const updateIncidentManagement = gql`
    mutation updateIncidentManagement($id: uuid!, $description: String!, $incidentId: uuid!, $emailNotification: Boolean, $emailMessage: String, $appNotification: Boolean, $appMessage: String, $enabled: Boolean!) {
        update_incidentManagement(_set: {description: $description, incidentId: $incidentId, , emailNotification: $emailNotification, emailMessage: $emailMessage, appNotification: $appNotification, appMessage: $appMessage, enabled: $enabled}, where: {id: {_eq: $id}}) {
            affected_rows
        }
    }
`;

export const deleteIncidentManagement = gql`
    mutation deleteIncidentManagement($id: uuid!) {
        delete_incidentManagement(where: {id: {_eq: $id}}) {
            affected_rows
        }
    }
`;