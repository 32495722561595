import '../css/BasicKPI.css';
import { Row, Col } from 'antd';
import { AreaChart, Area, ResponsiveContainer } from "recharts";

export const BasicKPI = (props) => {
    
    return (
        <div>
            <Row gutter={[25, 25]}>
                <Col span={24} >
                    <h3 className="basic-kpi-value">{props.unitPosition === "left" ? props.unit : ""}{props.data ? props.data[props.data.length - 1].pv : 0}{props.unitPosition === "right" ? props.unit : ""}</h3>
                </Col>
            </Row>
            <Row gutter={[25, 25]}>
                <Col span={24} >
                    <p className="basic-kpi-label">{props.label}</p>
                </Col>
            </Row>
            <Row gutter={[25, 25]}>
                <Col span={24} >
                 

                    <ResponsiveContainer width="100%" height="88%">
                        <AreaChart
                        className="basic-kpi-graph"
                          data={props.data}
                          width={355} 
                          height={75}
                        >
                          <Area type="monotone" dataKey="pv" stroke={props.color} fill={props.fill} strokeWidth={4}/>
                        </AreaChart>
                      </ResponsiveContainer>
                </Col>
            </Row>
        </div>
    );
}
