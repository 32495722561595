import React, { useState } from 'react';
import { Table, Button, Drawer, Space, Form, Switch, Tooltip, Card, Descriptions, Input, Select, TimePicker } from 'antd';
import { useMutation } from '@apollo/client';
import { DeleteFilled, EditFilled } from '@ant-design/icons';
import Swal from 'sweetalert2';
import moment from 'moment';

import { insertTimetables, updateTimetables, deleteTimetables } from '../../queries/transportCompaniesQueries';

export const Timetables = (props) => {
    const { setShowDrawer, showDrawer, timetables, setTimetables, companyName, transportCompanyId } = props;

    const [insTimetables] = useMutation(insertTimetables, { fetchPolicy: 'no-cache' });
    const [updTimetables] = useMutation(updateTimetables, { fetchPolicy: 'no-cache' });
    const [delTimetables] = useMutation(deleteTimetables, { fetchPolicy: 'no-cache' });

    const [isEditing, setIsEditing] = useState(false);
    const [clickedRow, setClickedRow] = useState("");

    const [form] = Form.useForm();


    const handleSave = async (values) => {
        const { id, day, startTime, endTime, enabled } = values;

        try {
            if(!isEditing) {
                const res = await insTimetables({
                    variables: {
                        day, 
                        startTime: startTime.format('HH:mm'),
                        endTime: endTime.format('HH:mm'), 
                        enabled,
                        transportCompanyId
                    },
                });
        
                if(res.data.insert_timetables.affected_rows > 0) {
                    const { id } = res.data.insert_timetables.returning[0];
    
                    setTimetables((current) => [...current, {
                        id,
                        day, 
                        startTime: startTime.format('HH:mm'),
                        endTime: endTime.format('HH:mm'), 
                        enabled,
                        transportCompanyId
                    }]);
                }
            } else {
                const res = await updTimetables({
                    variables: {
                        id,
                        day, 
                        startTime: startTime.format('HH:mm'),
                        endTime: endTime.format('HH:mm'), 
                        enabled
                    }
                });

                if(res.data.update_timetables.affected_rows > 0) {
                    const records = timetables;
                    const indexToReplace = timetables.findIndex((item) => item.id === id);

                    records.splice(indexToReplace, 1, {
                        id,
                        day, 
                        startTime: startTime.format('HH:mm'),
                        endTime: endTime.format('HH:mm'), 
                        enabled,
                        transportCompanyId
                    });
                    setTimetables([...records]);
                }
            }
        } catch (error) {
            
            if(error.message.includes('constraint')) {
                Swal.fire({
                    title: 'Error',
                    text: 'El día ya fue registrado. No puede ingresar un día repetido.',
                    icon: 'error',
                    confirmButtonText: 'Cerrar'
                });
            } else {
                Swal.fire({
                    title: 'Error',
                    text: error.message,
                    icon: 'error',
                    confirmButtonText: 'Cerrar'
                });
            }
        }

        setIsEditing(false);
        setClickedRow("");

        form.setFieldsValue({
            id: '',
            day: undefined, 
            startTime: undefined, 
            endTime: undefined, 
            enabled: true,
        });
    };

    const handleDelete = (record) => {
        const { id } = record;

        Swal.fire({
            title: '¿Seguro desea eliminar?',
            text: "Este proceso es irreversible!",
            icon: 'question',
            showCancelButton: true,
            confirmButtonColor: 'var(--primaryColor)',
            cancelButtonColor: '#B6B6B6',
            confirmButtonText: 'Sí, eliminar',
            cancelButtonText: 'No, cancelar',
        }).then( async (result) => {
            if(result.isConfirmed) {
                try {
                    const res = await delTimetables({
                        variables: {
                            id
                        },
                        fetchPolicy: 'no-cache'
                    });
        
                    if(res.data.delete_timetables.affected_rows > 0) {
                        setTimetables(timetables.filter((item) => item.id !== id));

                        Swal.fire({
                            title: 'Registro eliminado',
                            icon: 'success',
                            showConfirmButton: false,
                            timer: 1000
                        });
                    }
                } catch (error) {
                    console.error(error)
                }
            }
        });
    };

    const handleEdit = (record) => {
        const { id, day, startTime, endTime, enabled } = record;
        
        setIsEditing(true);

        form.setFieldsValue({
            id,
            day,
            startTime: moment(startTime, 'HH:mm'),
            endTime: moment(endTime, 'HH:mm'),
            enabled
        });
    };

    const handleClear = () => {
        setIsEditing(false);
        setClickedRow("");

        form.setFieldsValue({
            id: '',
            day: undefined, 
            startTime: undefined, 
            endTime: undefined, 
            enabled: true,
        });
    };

    const columns = [
        {
            key: 'day',
            dataIndex: 'day',
            title: 'Día'
        },
        {
            key: 'startTime',
            dataIndex: 'startTime',
            title: 'Inicio',
            align: 'center',
        },
        {
            key: 'endTime',
            dataIndex: 'endTime',
            title: 'Fin',
            align: 'center',
        },
        {
            key: 'enabled',
            dataIndex: 'enabled',
            title: 'Habilitado',
            align: 'center',
            render: (value) => <Switch checked={value} size='small' disabled />
        },
        {
            key: 'actions',
            dataIndex: 'actions',
            title: 'Acciones',
            align: 'center',
            render: (value, record) => {
                return (
                    <Space>
                        <Tooltip title='Eliminar'>
                            <Button
                                type='primary'
                                shape='round'
                                icon={<DeleteFilled />}
                                onClick={() => {
                                    handleDelete(record);
                                }}
                            />
                        </Tooltip>
                        <Tooltip title='Modificar'>
                            <Button
                                type='primary'
                                shape='round'
                                icon={<EditFilled />}
                                onClick={() => {
                                    handleEdit(record);
                                }}
                            />
                        </Tooltip>
                    </Space>
                )
            }
        }
    ];

    return (
        <div
            style={{
                margin: '10px'
            }}
        >
            <Drawer
                title={`${companyName} - Horario laboral`}
                width={600}
                onClose={() => {
                    handleClear();
                    setShowDrawer(false);
                }}
                visible={showDrawer}
                bodyStyle={{ paddingBottom: 80 }}
                footer={
                    <div style={{ textAlign: 'right' }}>
                        <Button
                            type='ghost'
                            shape='round'
                            onClick={() => {
                                handleClear();
                                setShowDrawer(false);
                            }}
                        >
                            Cerrar
                        </Button>
                    </div>
                }
            >
                <Card
                    title={`Horarios - ${isEditing ? 'Modificar' : 'Agregar'}`}
                    style={{ marginBottom: '10px', minHeight: '0%'}}
                    size='small'
                >
                    <Form
                        form={form}
                        name='configuration-timetables'
                        onFinish={handleSave}
                    >
                        <Form.Item name='id' hidden>
                            <Input />
                        </Form.Item>
                        <Descriptions
                            style={{ marginBottom: '10px' }}
                            bordered 
                            size='small'
                            column={{ xxl: 2, xl: 2, lg: 2, md: 2, sm: 2, xs: 1 }}
                        >
                            <Descriptions.Item label='Día'>
                                <Form.Item 
                                    name='day'
                                    rules={[{ required: true, message: ''}]}
                                >
                                    <Select 
                                        placeholder='Seleccione...'
                                        allowClear
                                        onChange={(value) => form.setFieldsValue({ day: value })}
                                    >
                                        <Select.Option value='Lunes'>Lunes</Select.Option>
                                        <Select.Option value='Martes'>Martes</Select.Option>
                                        <Select.Option value='Miércoles'>Miércoles</Select.Option>
                                        <Select.Option value='Jueves'>Jueves</Select.Option>
                                        <Select.Option value='Viernes'>Viernes</Select.Option>
                                        <Select.Option value='Sábado'>Sábado</Select.Option>
                                        <Select.Option value='Domingo'>Domingo</Select.Option>
                                    </Select>
                                </Form.Item>
                            </Descriptions.Item>
                            <Descriptions.Item label='Habilitado'>
                                <Form.Item name='enabled' valuePropName='checked'>
                                    <Switch />
                                </Form.Item>
                            </Descriptions.Item>
                            <Descriptions.Item label='Inicio'>
                                <Form.Item
                                    name='startTime'
                                    rules={[{ required: true, message: ''}]}
                                >
                                    <TimePicker minuteStep={15} format='HH:mm' showNow={false} placeholder='Hora' />
                                </Form.Item>
                            </Descriptions.Item>
                            <Descriptions.Item label='Fin'>
                                <Form.Item
                                    name='endTime'
                                    rules={[{ required: true, message: ''}]}
                                >
                                    <TimePicker minuteStep={15} format='HH:mm' showNow={false} placeholder='Hora' />
                                </Form.Item>
                            </Descriptions.Item>
                        </Descriptions>
                        <Form.Item>
                            <Space>
                                <Button 
                                    type='primary' 
                                    shape='round'
                                    htmlType='submit'
                                >
                                    {isEditing ? 'Modificar' : 'Agregar'}
                                </Button>
                                <Button
                                    type='ghost'
                                    shape='round'
                                    onClick={handleClear}
                                >
                                    Cancelar
                                </Button>
                            </Space>
                        </Form.Item>
                    </Form>
                </Card>
                <div>
                    <Table
                        columns={columns}
                        dataSource={timetables}
                        size='small'
                        rowKey='id'
                        pagination={{ pageSize: 10 }}
                        scroll={{
                            x: 100
                        }}
                        onRow={(record) => {
                            return {
                                onClick: () => {
                                    setClickedRow(record.id);
                                    handleEdit(record);
                                },
                            }
                        }}
                        rowClassName={(record) => ['mouse-row-enter', record.id === clickedRow ? 'table-row-color' : ""]}
                    />
                </div>
            </Drawer>
        </div>
    )
}
