import React, { useState } from 'react';
import { Route, Redirect } from "react-router-dom";

import { useUser } from '../context/UserContext';

import { LayoutScreen } from '../components/layout/ui/screens/LayoutScreen';

export const PrivateRoute = ({ component: Component, ...rest }) => {
    const { isAuthenticated, user } = useUser();
    const [flag, setFlag] = useState(0);

    return (
        <Route 
            {...rest}
            render={(routeProps) => (
                (isAuthenticated)
                ? (<LayoutScreen user={user} title={rest.title} setFlag={setFlag}><Component flag={flag} {...routeProps}/></LayoutScreen>)
                : (<Redirect to="/login" />)
            )}
        />
    )
}
