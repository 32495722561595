import React, { useEffect, useState } from 'react';
import { Card, Button, Row, Col, Progress, message } from 'antd';
import { useLazyQuery } from '@apollo/client';

import { fetchUsersVehicles } from '../../queries/schedulingQueries';

import { useScheduling } from '../../context/SchedulingContext';
import { UploadOutlined, CheckCircleOutlined } from '@ant-design/icons';
import localStorageService from '../../../../services/localStorageService';

const columns = [
    {
        title: 'Placa',
        dataIndex: 'licencePlate',
        key: 'licencePlate',
        align: 'center',
        width: 84,
        render: (text) => <small>{text}</small>
    },
    {
        title: 'Nombre',
        dataIndex: 'displayName',
        key: 'displayName',
        width: 170,
        render: (text) => <small>{text}</small>
    }
];

export const UsersVehiclesList = (props) => {
    const [selectedWarehouser, setSelectedWarehouser] = useState(JSON.parse(localStorageService.get("selectedWarehouses"))?.warehouses);
    const {
        usersVehicles,
        setUsersVehicles,
        setSelectedUserVehicle,
        dataOrdersInVehicleTemp,
        onInsertRoute,
        ordersInVehicleTemp,
    } = useScheduling();
    const [getUsersVehicles, dataUserVehicles] = useLazyQuery(fetchUsersVehicles, { fetchPolicy: 'no-cache' });
    const [itemSelected, setItemSelected] = useState([]);

    const [internalFlag, setInternalFlag] = useState(0);

    const [measurementUnit, setMeasurementUnit] = useState(false);

    useEffect(() => {
        const warehouses = JSON.parse(localStorageService.get("selectedWarehouses"))?.warehouses;
        setSelectedWarehouser(warehouses);
    }, [props.Flag]);
    
    useEffect(() => {
        getUsersVehicles({ variables: { distributionCenterId: selectedWarehouser?.length > 0 ? selectedWarehouser : ["00000000-0000-0000-0000-000000000000"] }, fetchPolicy: 'no-cache' });
    }, [selectedWarehouser]);


    useEffect(() => {
        if (!dataUserVehicles.loading && dataUserVehicles.data && dataUserVehicles.data.userVehicle.length > 0) {
            let records = [];

            dataUserVehicles?.data?.userVehicle.map((item) => {
                return records.push({
                    key: item?.id,
                    licencePlate: item?.vehicle?.licencePlate,
                    displayName: item?.user?.displayName,
                    capacity: item?.vehicle?.weight,
                    userId: item?.userId,
                    vehicleId: item?.vehicleId,
                    weight: item?.vehicle?.weight,
                    cubicMeters: item?.vehicle?.cubicMeters,
                    userVehicleId: item?.id,
                    schedulingStartLocation: item?.vehicle?.schedulingStartLocation,
                    lastLocation: item?.lastLocation
                });
            });
            setUsersVehicles(records);
        } else {
            setUsersVehicles([]);
        }
    }, [dataUserVehicles.data, dataUserVehicles.loading, setUsersVehicles, dataOrdersInVehicleTemp.data]);

    const format_number = str => {
        str += '';
        let x = str.split('.');
        let x1 = x[0];
        let x2 = x.length > 1 ? '.' + x[1] : '';
        let rgx = /(\d+)(\d{3})/;
        while (rgx.test(x1)) {
            x1 = x1.replace(rgx, '$1' + ',' + '$2');
        }
        return x1 + x2;
    };

    return (
        <>
            {itemSelected.length === 0 ? <p className="scheduling-screen-list-container11-title">Vehículos ({((itemSelected.length > 0 ? itemSelected : props.filter.length > 0 && props.activeFilter1 ? usersVehicles.filter(item => {
                if ((item.licencePlate)?.toUpperCase().includes((props.filter).toUpperCase())) {
                    return true;
                }

                if ((item.displayName)?.toUpperCase().includes((props.filter).toUpperCase())) {
                    return true;
                }

                return false;
            }) : usersVehicles).filter(item => {
   
                let flagVehicle = true;

                const usr = JSON.parse(sessionStorage.getItem('userData'));

                if (dataOrdersInVehicleTemp.data && dataOrdersInVehicleTemp.data.ordersInVehicleTemp) {
                    (dataOrdersInVehicleTemp.data.ordersInVehicleTemp.filter(item4 => item4.userId === item.userId)).map(element => {

                        if (element.createByUserId !== usr.id) {
                            flagVehicle = false;
                        }
                    });
                }

                return flagVehicle;
            })).length})</p> : null}
            <Col span={22} className={itemSelected.length === 0 ? `scheduling-screen-list-container11` : `scheduling-screen-list-container11-selected`}>

                {
                    ((itemSelected.length > 0 ? itemSelected : props.filter.length > 0 && props.activeFilter1 ? usersVehicles.filter(item => {

                        if ((item.licencePlate)?.toUpperCase().includes((props.filter).toUpperCase())) {
                            return true;
                        }

                        if ((item.displayName)?.toUpperCase().includes((props.filter).toUpperCase())) {
                            return true;
                        }

                        return false;
                    }) : usersVehicles).filter(item => {

                        let flagVehicle = true;

                        const usr = JSON.parse(sessionStorage.getItem('userData'));

                        if (dataOrdersInVehicleTemp.data && dataOrdersInVehicleTemp.data.ordersInVehicleTemp) {
                            (dataOrdersInVehicleTemp.data.ordersInVehicleTemp.filter(item4 => item4.userId === item.userId)).map(element => {

                                if (element.createByUserId !== usr.id) {
                                    flagVehicle = false;
                                }
                            });
                        }

                        if (!flagVehicle && itemSelected.length > 0 && itemSelected[0].userId === item.userId) {
                            setSelectedUserVehicle({});
                            setItemSelected([]);
                            props.onItemClickOff();
                            props.clearOIV();
                        }

                        return flagVehicle;
                    })).map((item, index) => {

                        let orderWeight = 0;
                        let orderTotalCubicMeters = 0;
                        let sumOrders = 0;

                        if (dataOrdersInVehicleTemp.data && dataOrdersInVehicleTemp.data.ordersInVehicleTemp) {
                            dataOrdersInVehicleTemp.data.ordersInVehicleTemp.filter(element => element.userId === item.userId).map((item2) => {
                                orderWeight += item2?.order?.weight ?? 0;
                                orderTotalCubicMeters += item2?.order?.totalCubicMeters ?? 0;

                                sumOrders++;
                            });
                        }

                        return <Row key={index} gutter={[0, 0]} className={`scheduling-screen-list-item-row-container ${itemSelected.length > 0 ? 'scheduling-screen-list-item-row-container-selected' : ''}`} >
                            <Col span={24}>
                                <Row gutter={[0, 0]} >
                                    <Col span={11}>
                                        <Row className="scheduling-screen-list-item-label-1">{item.displayName}</Row>
                                        <Row className="scheduling-screen-list-item-label-2">{(item.licencePlate || 'n/a').toUpperCase()}</Row>
                                        <Row className="scheduling-screen-list-item-label-1"><Col className={!measurementUnit ? "orders-list-screen-list-item-row-container-selected3" : ""} span={6} onClick={() => { setMeasurementUnit(false) }}>KG</Col> <Col className={measurementUnit ? "orders-list-screen-list-item-row-container-selected3" : ""} span={6} onClick={() => setMeasurementUnit(true)}>M3</Col></Row>
                                    </Col>
                                    <Col span={11}>
                                        <Row className="scheduling-screen-list-item-label-3">Cantidad Ordenes</Row>
                                        <Row className="scheduling-screen-list-item-label-4">{sumOrders}</Row>
                                        <Row className="scheduling-screen-list-item-label-5">{format_number(orderWeight)} Kg/{format_number(item.weight)} Kg</Row>
                                        <Row className="scheduling-screen-list-item-label-5">{format_number(Math.round(orderTotalCubicMeters * 10000) / 10000)} M3/{format_number(Math.round(item.cubicMeters * 10000) / 10000)} M3</Row>
                                    </Col>
                                    <Col span={2}>
                                        <Row className="orders-list-screen-list-item-label-6"><CheckCircleOutlined onClick={() => {
                                            if (itemSelected.length > 0) {
                                                setSelectedUserVehicle({});
                                                setItemSelected([]);
                                                props.onItemClickOff();
                                                props.clearOIV();
                                            } else {
                                                setSelectedUserVehicle(item);
                                                let mItemSelected = itemSelected;
                                                mItemSelected.push(item);
                                                setItemSelected(mItemSelected);
                                                props.onItemClickOn();
                                            }
                                        }} /></Row>
                                    </Col>
                                </Row>
                                <Row gutter={[0, 0]}><Progress className="scheduling-screen-list-item-progress" strokeColor={(measurementUnit ? orderTotalCubicMeters > item.cubicMeters : orderWeight > item.weight) ? 'red' : '#87d068'} percent={measurementUnit ? (((((orderTotalCubicMeters) / (item.cubicMeters === undefined ? 1 : item.cubicMeters)) * 100)).toFixed(0)) : ((((orderWeight) / (item.weight === undefined ? 1 : item.weight)) * 100)).toFixed(0)} showInfo={false} /></Row>
                            </Col>
                        </Row>
                    }
                    )
                }
            </Col>
            <Col span={2} className="scheduling-screen-list-button-container">
                <Button className="scheduling-screen-list-button" disabled={ordersInVehicleTemp.length === 0 || itemSelected.length === 0} type="primary" icon={<UploadOutlined />} onClick={
                    () => {
                        onInsertRoute((mSelectedUserVehicle) => {
                            setItemSelected([]);
                            props.onItemClickOff();
                            props.clearOIV();
                            props.clearOrders();
                            setInternalFlag(current => current + 1);
                            if (usersVehicles.filter(item => item.id === mSelectedUserVehicle.id).length === 1) {
                                setUsersVehicles(usersVehicles.filter(item => item.id !== mSelectedUserVehicle.id));
                            }
                        });
                    }
                } />
            </Col>
        </>
    )
}