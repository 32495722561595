import React, { useState, useEffect } from 'react';
import { Form, Table, Switch, Descriptions, Input, Card, Button, Space, Skeleton, Col, Row, Modal } from 'antd';
import { DeleteFilled, EditFilled } from '@ant-design/icons';
import Swal from 'sweetalert2';
import { useLazyQuery, useMutation } from '@apollo/client';
import { CarOutlined, CheckOutlined, ClockCircleOutlined, EditOutlined } from '@ant-design/icons';
import {
    fetchVehicleTypes,
    insertVehicleType,
    updateVehicleType,
    deleteVehicleType
} from '../../queries/vehicleTypesQueries';

import { VehicleTypesIndicator } from './VehicleTypesIndicator';

import { getColumnSearchPropsText } from '../../../../completed-routes/ui/screens/UseCases';
import { ConfigurationMoreSettings } from '../../../ConfigurationMoreSettings';

import localStorageService from '../../../../../services/localStorageService';
const companyId = localStorageService.get('companyId');

export const VehicleTypesScreen = () => {
    const [getVehicleTypes, dataVehicleTypes] = useLazyQuery(fetchVehicleTypes, { variables: {companyId}, fetchPolicy: 'no-cache' });
    const [insVehicleTypes] = useMutation(insertVehicleType, { variables: {companyId}, fetchPolicy: 'no-cache' });
    const [delVehicleTypes] = useMutation(deleteVehicleType, { fetchPolicy: 'no-cache' });
    const [updVehicleTypes] = useMutation(updateVehicleType, { fetchPolicy: 'no-cache' });

    const [vehicleTypes, setVehicleTypes] = useState([]);
    const [isEditing, setIsEditing] = useState(false);
    const [clickedRow, setClickedRow] = useState("");

    const [form] = Form.useForm();

    const [isModalVisible, setIsModalVisible] = useState(false);

    const showModal = () => {
        setIsModalVisible(true);
    };

    const handleOk = () => {
        setIsModalVisible(false);
    };

    const handleCancel = () => {
        setIsModalVisible(false);
    };

    useEffect(() => {
        getVehicleTypes();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        setVehicleTypes([]);

        if (!dataVehicleTypes.loading && dataVehicleTypes.data && dataVehicleTypes.data.vehicleTypes.length > 0) {
            dataVehicleTypes.data.vehicleTypes.map((item) => {
                const deleteable = item.vehicles_aggregate.aggregate.count === 0;
                const { id, name } = item;

                return setVehicleTypes((current) => [...current, {
                    id,
                    name,
                    deleteable
                }]);
            });
        }
    }, [dataVehicleTypes.loading, dataVehicleTypes.data])


    const columns = [
        {
            title: 'Nombre',
            key: 'name',
            dataIndex: 'name',
            ...getColumnSearchPropsText("Nombre", "name")
        },
    ];

    const handleSave = async (values) => {
        const { id, name, deleteable } = values;

        try {
            if (!isEditing) {
                const res = await insVehicleTypes({
                    variables: {
                        name,
                    },
                });

                if (res.data.insert_vehicleTypes.affected_rows > 0) {
                    const { id } = res.data.insert_vehicleTypes.returning[0];

                    setVehicleTypes((current) => [...current, {
                        id,
                        name,
                        deleteable: true
                    }]);
                }
            } else {
                const res = await updVehicleTypes({
                    variables: {
                        id,
                        name,
                    }
                });

                if (res.data.update_vehicleTypes.affected_rows > 0) {
                    const records = vehicleTypes;
                    const indexToReplace = vehicleTypes.findIndex((item) => item.id === id);

                    records.splice(indexToReplace, 1, {
                        id,
                        name,
                        deleteable
                    });
                    setVehicleTypes([...records]);
                }
            }
        } catch (error) {
            console.error(error);
        }

        setIsEditing(false);

        form.setFieldsValue({
            id: '',
            name: '',
            deleteable: true
        });
    };

    const handleDelete = (record) => {
        const  id  = record;

        Swal.fire({
            title: '¿Seguro desea eliminar?',
            text: "Este proceso es irreversible!",
            icon: 'question',
            showCancelButton: true,
            confirmButtonColor: 'var(--primaryColor)',
            cancelButtonColor: '#B6B6B6',
            confirmButtonText: 'Sí, eliminar',
            cancelButtonText: 'No, cancelar',
        }).then(async (result) => {
            if (result.isConfirmed) {
                try {
                    const res = await delVehicleTypes({
                        variables: {
                            id
                        },
                        fetchPolicy: 'no-cache'
                    });

                    if (res.data.delete_vehicleTypes.affected_rows > 0) {
                        setVehicleTypes(vehicleTypes.filter((item) => item.id !== id));
                        setClickedRow("");
                        
                        Swal.fire({
                            title: 'Registro eliminado',
                            icon: 'success',
                            showConfirmButton: false,
                            timer: 1000
                        });

                        setIsEditing(false);

                        form.setFieldsValue({
                            id: '',
                            name: '',
                            deleteable: true
                        });
                    }
                } catch (error) {
                    console.error(error)
                }
            }
        });
    };

    const handleEdit = (record) => {
        const { id, name, deleteable } = record;

        setIsEditing(true);

        form.setFieldsValue({
            id,
            name,
            deleteable
        });
    };

    const handleClear = () => {
        setIsEditing(false);
        setClickedRow("");
        handleCancel();

        form.setFieldsValue({
            id: '',
            name: '',
            deleteable: true
        });
    };

    const formItemLayout = {
        labelCol: {
          lg: { span:5 },
        },
        wrapperCol: {
          lg: { span: 15 },
        },
      };
    return (
        <Col className='children-inner-container-with-bg'>
            <ConfigurationMoreSettings />
            <Row className='children-inner-row'>
                <Col span={5} className='children-inner-col'>
                    <VehicleTypesIndicator value={vehicleTypes.length} label={"Total Tipos de Vehículos"}><CarOutlined style={{backgroundColor: 'var(--primaryColor)'}} className="report-indicator-icon"/></VehicleTypesIndicator>
                </Col>
                <Col span={5} className='children-inner-col'>
                    <VehicleTypesIndicator value={(vehicleTypes.filter(item => !item.enabled)).length} label={"Tipos de Vehículos Activos"}><CheckOutlined style={{backgroundColor: '#9ccc65'}} className="report-indicator-icon"/></VehicleTypesIndicator>
                </Col>
                <Col span={5} className='children-inner-col'>
                    <VehicleTypesIndicator value={(vehicleTypes.filter(item => item.enabled)).length} label={"Tipos de Vehículos Inactivos"}><ClockCircleOutlined style={{backgroundColor: '#ffba57'}} className="report-indicator-icon"/></VehicleTypesIndicator>
                </Col>
                <Col span={3} offset={3} className='children-inner-col'><Button type="primary" size={"large"} className='children-inner-button' icon={<EditOutlined />} onClick={showModal}>{clickedRow.length === 0 ? 'Crear' : 'Editar'}</Button></Col>
                <Col span={3} className='children-inner-col'><Button type="primary" size={"large"} className='children-inner-button' icon={<DeleteFilled />} onClick={()=> handleDelete(clickedRow)} disabled={clickedRow.length === 0 || (vehicleTypes.length > 0 && vehicleTypes.filter(item => item.id === clickedRow)[0] && !vehicleTypes.filter(item => item.id === clickedRow)[0].deleteable)}>Eliminar</Button></Col>
            </Row>
            <Row>
                <Skeleton loading={dataVehicleTypes.loading} active>
                    <Table
                        className='children-table'
                        columns={columns}
                        dataSource={vehicleTypes}
                        size='small'
                        rowKey='id'
                        pagination={{ pageSize: 13 }}
                        onRow={(record) => {
                            return {
                                onClick: () => {
                                    if(clickedRow.length === 0 || clickedRow !== record.id){
                                        setClickedRow(record.id);
                                        handleEdit(record, setIsEditing, form);
                                    }else{
                                        handleClear(setIsEditing, form);
                                        setClickedRow("");
                                    }
                                },
                            }
                        }}
                        rowClassName={(record) => ['mouse-row-enter', record.id === clickedRow ? 'table-row-color' : ""]}
                    />
                </Skeleton>
            </Row>
            <Modal className='children-modal' visible={isModalVisible} onOk={handleCancel} onCancel={handleCancel} footer={[]} title={clickedRow.length === 0 ? 'Crear Tipo de Vehículo' : 'Editar Tipo de Vehículo'} width={500}>
                <Form
                    {...formItemLayout}
                    form={form}
                    name='configuration'
                    onFinish={(values) => {
                        handleSave(values);
                        setClickedRow("");
                    }}
                    initialValues={{
                        id: '',
                        name: '',
                        deleteable: true
                    }}
                >
                    <Form.Item name='id' hidden>
                        <Input />
                    </Form.Item>
                
                            <Form.Item
                            label='Nombre'
                            labelAlign='left'
                                name='name'
                                rules={[{ required: true, message: 'Por favor ingrese un nombre' }]}
                            >
                                <Input />
                            </Form.Item>

                    <Form.Item>
                        <Space>
                            <Button
                                type='primary'
                                shape='round'
                                htmlType='submit'
                                onClick={handleCancel}
                            >
                                {isEditing ? 'Modificar' : 'Agregar'}
                            </Button>
                            <Button
                                type='ghost'
                                shape='round'
                                onClick={handleClear}
                            >
                                Cancelar
                            </Button>
                        </Space>
                    </Form.Item>
                    <Form.Item name='deleteable' hidden valuePropName='checked'>
                        <Switch />
                    </Form.Item>
                </Form>
            </Modal>
        </Col>
    );
}
