import { gql } from "@apollo/client";

export const fetchIncidentManagement = gql`
    query fetchIncidentManagement {
        incidentManagement (where: {enabled: {_eq: true}}){
            id
            description
            incidentId
        }
    }
`;

export const fetchIncidentReport = gql`
query fetchIncidentReport($distributionCenterId: [uuid!]) {
    incidentReport(order_by: {enabled: asc, incidentDatetime: desc},where: {user:{distributionCenterId: { _in: $distributionCenterId }}}) {
        id
        vehicleId
        userId
        incidentId
        incidentCauseId
        observations
        incidentManagementId
        enabled
        incidentDatetime
        updated_at
        user {
            displayName
          distributionCenterId
        }
        vehicle {
            licencePlate
        }
        incident {
            description
        }
        incidentCause {
            description
        }
        incidentManagement {
            description
        }
    }
}
`;

export const updateIncidentReport = gql`
    mutation updateIncidentReport($id: uuid!, $incidentManagementId: uuid!, $enabled: Boolean!) {
        update_incidentReport(_set: {incidentManagementId: $incidentManagementId, enabled: $enabled}, where: {id: {_eq: $id}}) {
            affected_rows
        }
    }
`;