import React, { useState, useEffect, useRef, useContext } from "react";
import { Form, Table, Input, Button } from "antd";
import { DeleteFilled } from "@ant-design/icons";
import { InsertRowBelowOutlined } from "@ant-design/icons";
import { v4 as uuidv4 } from "uuid";

const EditableContext = React.createContext(null);

const LinesUploadScreen = (props) => {
  const { hasOrder, ordersLine, setOrdersLine, form, formType, isUpdate} = props;

  useEffect(() => {
    //setOrdersLine([]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasOrder]);

  const handleInsertRow = () => {
    setOrdersLine((current) => [
      ...current,
      {
        key: uuidv4(),
        productNumber: "",
        productName: "",
        orderedQuantity: 1,
      },
    ]);
  };

  const handleDelete = (id, isUpdate) => {
    const data = isUpdate === true ? ordersLine.filter((item) => item.id !== id) : ordersLine.filter((item) => item.key !== id);
    setOrdersLine(data);
  };

  const handleSave = (row) => {
    const newData = [...ordersLine];
    const index = newData.findIndex((item) => row.key === item.key);
    const item = newData[index];
    newData.splice(index, 1, { ...item, ...row });
    setOrdersLine(newData);
  };

  const columns = [
    {
      title: "Cód. Producto *",
      key: "productNumber",
      dataIndex: "productNumber",
      editable: !isUpdate,
      
    },
    {
      title: "Nombre del producto *",
      key: "productName",
      dataIndex: "productName",
      editable: !isUpdate,
    },
    {
      title: "Cant. Ordenada *",
      key: "orderedQuantity",
      dataIndex: "orderedQuantity",
      editable: !isUpdate,
      align: "right",
    },
    {
      title: "Acciones",
      key: "operation",
      dataIndex: "operation",
      align: "center",
      render: (_, record) => {
        return (
          <Button
            type="primary"
            danger
            shape="round"
            disabled={isUpdate ? true : false}
            icon={<DeleteFilled />}
            onClick={() => {handleDelete(isUpdate === true ? record.id : record.key, isUpdate)}}
          />
        );
      },
    },
  ];

  const components = {
    body: {
      row: EditableRow,
      cell: EditableCell,
    },
  };

  const cols = columns.map((col) => {
    if (!col.editable) {
      return col;
    }

    return {
      ...col,
      onCell: (record) => ({
        record,
        editable: col.editable,
        dataIndex: col.dataIndex,
        title: col.title,
        handleSave: handleSave,
      }),
    };
  });

  return (
    <div>
      <Button
        icon={<InsertRowBelowOutlined />}
        disabled={!hasOrder || ((formType === "pickup" || formType === "delivery") && form.getFieldValue("consecutiveSaleOrder")==="" && form.getFieldValue("consecutiveRemission")==="" && form.getFieldValue("consecutiveBill")==="") || (formType === "warehousesTransfer" && form.getFieldValue("consecutiveBurden")==="" && form.getFieldValue("consecutiveShipping")==="" && form.getFieldValue("consecutiveTRM")==="")}
        type="primary"
        shape="round"
        style={{ marginTop: 10, marginBottom: 5 }}
        onClick={handleInsertRow}
      >
        Agregar línea
      </Button>
      <Table
        bordered
        components={components}
        columns={cols}
        dataSource={ordersLine}
        rowKey="key"
        rowClassName={() => "editable-row"}
        pagination={{ pageSize: 5 }}
      />
    </div>
  );
};

const EditableRow = ({ index, ...props }) => {
  const [form] = Form.useForm();
  return (
    <Form form={form} component={false}>
      <EditableContext.Provider value={form}>
        <tr {...props} />
      </EditableContext.Provider>
    </Form>
  );
};

const EditableCell = ({
  title,
  editable,
  children,
  dataIndex,
  record,
  handleSave,
  ...restProps
}) => {
  const [editing, setEditing] = useState(false);
  const inputRef = useRef(null);
  const form = useContext(EditableContext);
  useEffect(() => {
    if (editing) {
      inputRef.current.focus();
    }
  }, [editing]);

  const toggleEdit = () => {
    setEditing(!editing);
    form.setFieldsValue({
      [dataIndex]: record[dataIndex],
    });
  };

  const save = async () => {
    try {
      const values = await form.validateFields();

      toggleEdit();
      handleSave({ ...record, ...values });
    } catch (errInfo) {
      console.error("Save failed:", errInfo);
    }
  };

  let childNode = children;

  if (editable) {
    childNode = editing ? (
      <Form.Item
        style={{
          margin: 0,
        }}
        name={dataIndex}
        rules={[
          ({ getFieldValue, getFieldInstance }) => ({
            validator(obj, value) {
              if (!value) {
                return Promise.reject("Este campo es requerido.");
              }

              if (obj.field === "orderedQuantity") {
                const numeric = new RegExp("^\\d+$");

                if (numeric.test(getFieldValue("orderedQuantity"))) {
                  if (getFieldValue("orderedQuantity") < 1) {
                    return Promise.reject("Debe ser mayor a 0");
                  }
                  return Promise.resolve();
                } else {
                  return Promise.reject("De ingresar un valor numérico.");
                }
              } else {
                return Promise.resolve();
              }
            },
          }),
        ]}
      >
        <Input ref={inputRef} onPressEnter={save} onBlur={save} />
      </Form.Item>
    ) : (
      <div
        className="editable-cell-value-wrap"
        style={{
          paddingRight: 24,
        }}
        onClick={toggleEdit}
      >
        {children}
      </div>
    );
  }

  return <td {...restProps}>{childNode}</td>;
};

export default LinesUploadScreen;
