export const getStrokeColor = (second) => {
    const s = 50;
    const l = 100;
    const h = second * 6;

    const a = s * Math.min(l, 1 - l) / 100;
    const f = n => {
        const k = (n + h / 30) % 12;
        const color = l - a * Math.max(Math.min(k - 3, 9 - k, 1), -1);
        return Math.round(255 * color).toString(16).padStart(2, '0');   // convert to Hex and prefix "0" if needed
    };
    return `#${f(0)}${f(8)}${f(4)}`;
  }
  
  export const isValidLatLng = (latlng) => {
    const latlngArray = latlng.split(",");
    let ret = true;

    if(latlngArray.length !== 2) ret = false;

    if(latlngArray.length === 2) {
      if(latlngArray[0].toString().trim() === '') ret = false;
      if(latlngArray[1].toString().trim() === '')  ret = false;
      if(latlngArray[0].toString().includes(' ')) ret = false;
      if(latlngArray[1].toString().includes(' ')) ret = false;
    }

    for(let i = 0; i < latlngArray.length; i++) {
      if(isNaN(latlngArray[i]) || latlngArray[i] < -127 || latlngArray[i] > 75){
        ret = false;
      }
    }

    return ret;
  }

  export const getKeyByValue = (obj, value) => {
    return Object.keys(obj).find(key => obj[key] === value);
  }

  export const getValueByKey = (obj, key) => {
    return obj[key];
  }