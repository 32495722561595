import React from 'react';
import { useHistory } from "react-router-dom";
import { Button } from 'antd';
import {  ArrowLeftOutlined } from '@ant-design/icons';

export const ConfigurationMoreSettings = (props) => {

    const history = useHistory();
    const MoreSettings = () => {
        history.replace("/configuration");
    };

    return (
        <Button
            type="default"
            style={{color: 'var(--primaryColor)', border: 'none'}}
            icon={<ArrowLeftOutlined />}
            shape='round'
            onClick={MoreSettings}
        >
            Configuración
        </Button>
    );
}