import { gql } from "@apollo/client";

export const fetchHistoricalData = gql`
    query historicalData($rangeDate1: timestamptz, $rangeDate2: timestamptz){
        historicalData (order_by: {order: asc}, where: {created_at: {_gte: $rangeDate1, _lte: $rangeDate2}}){
            id
            sumOfOrdersDeliveredOnTime
            actualCapacityUsed
            totalLoadCapacity
            plannedMileage
            executedMileage
            routesQuantity
            loadsQuantity
            vehicleHoursBusy
            vehicleAvailableHours
            mobilizedTons
            totalVehicleCapacity
            numberOfTrips
            estimatedDeliveryTime
            realDeliveryTime
            totalOrdersDelivered
            totalOrdersDispatched
            totalOrdersPartiallyDelivered
            totalOrdersUndelivered
            order
            created_at
            internalVehicleHoursBusy
            totalOrdersDispatchedCumulative
            totalOrdersDeliveredCumulative
            totalOrdersPartiallyDeliveredCumulative
            totalOrdersUndeliveredCumulative
            internalVehicleAvailableHours
            incidentReportCount
        },
        incidentReport(order_by: {incidentDatetime: desc, enabled: asc}, where: {incidentDatetime: {_gte: $rangeDate1, _lte: $rangeDate2}}) {
            id
            vehicleId
            userId
            incidentId
            incidentCauseId
            observations
            incidentManagementId
            enabled
            incidentDatetime
            user {
                displayName
            }
            vehicle {
                licencePlate
            }
            incident {
                description
            }
            incidentCause {
                description
            }
            incidentManagement {
                description
            }
        },
        ordersTable(where: {createdAt: {_gte: $rangeDate1, _lte: $rangeDate2}}) {
            orderNumber
            address
            addressComplement
            custFullName
            custIdentificationNumber
            custPhoneNumber
            destination
            weight,
            createdAt
            route {
                id
                routeOrder
                status
            }
        }
    }
`;