import '../css/StandardKPI.css';
import { Row, Col } from 'antd';
import { AreaChart, Area, ResponsiveContainer } from "recharts";

export const StandardKPI = (props) => {
    
    return (
        <div>
            <Row gutter={[25, 25]}>
                <Col span={24} >
                    {props.condition ? 
                    <h3 className={`standard-kpi-value ${(props.data ? props.data[props.data.length - 1].pv : 0) >= 100 ?  'color-green' : 'color-red'}`}>{props.unitPosition === "left" ? props.unit : ""}{props.data ? props.data[props.data.length - 1].pv : 0}{props.unitPosition === "right" ? props.unit : ""}</h3>
                    :
                    <h3 className="standard-kpi-value">{props.unitPosition === "left" ? props.unit : ""}{props.data ? props.data[props.data.length - 1].pv : 0}{props.unitPosition === "right" ? props.unit : ""}</h3>
                }
                
                    </Col>
            </Row>
            <Row gutter={[25, 25]}>
                <Col span={24} >
                    <p className="standard-kpi-label">{props.label}</p>
                </Col>
            </Row>
            <Row gutter={[25, 25]}>
                <Col span={24} >
                  <ResponsiveContainer width="100%" height="90%">
                    <AreaChart
                      width={490}
                      height={80}
                      data={props.data}
                      className="standard-kpi-graph"
                    >
                      <Area type="monotone" dataKey="pv" stroke={props.color} fill={props.fill} strokeWidth={4}/>
                    </AreaChart>
                  </ResponsiveContainer>
                </Col>
            </Row>
            <Row gutter={[25, 25]}>
                <Col span={12} >
                    <h3 className="standard-kpi-value2">{props.unit2Position === "left" ? props.unit2 : ""}{props.data ? props.value1 : 0}{props.unit2Position === "right" ? props.unit2 : ""}</h3>
                </Col>
                <Col span={12} >
                    <h3 className="standard-kpi-value2">{props.unit2Position === "left" ? props.unit2 : ""}{props.data ? props.value2 : 0}{props.unit2Position === "right" ? props.unit2 : ""}</h3>
                </Col>
            </Row>
            <Row gutter={[25, 25]}>
                <Col span={12} >
                    <h3 className="standard-kpi-label2">{props.label1}</h3>
                </Col>
                <Col span={12} >
                    <h3 className="standard-kpi-label2">{props.label2}</h3>
                </Col>
            </Row>
        </div>
    );
}