import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { Row, Col } from 'antd';
import { FullscreenOutlined, MergeCellsOutlined, DashboardOutlined, CodeSandboxOutlined } from '@ant-design/icons';

import 'moment/locale/es';

export const VehicleDetails = (props) => {
    const { selectedUserVehicle } = props;
    const [userData, setUserData] = useState({});

    const [vehicleWeight, setVehicleWeight] = useState('-');
    const [availablePercent, setAvailablePercent] = useState(0);
    const [orderDelivered, setOrderDelivered] = useState(0);
    const [mOrderWeight, setMOrderWeight] = useState(0);

    useEffect(() => {
        setUserData({});
        setVehicleWeight('-');
        setAvailablePercent(0);
        setOrderDelivered(0);

        if (selectedUserVehicle.length === 1) {
            let orderWeight = 0;
            let toSum = 0;
            let ordersDelivered = 0;

            selectedUserVehicle[0].assignedRoutes.map((item) => {
                if (item.status === 'none' || item.status === 'start' || item.status === 'started' || item.status === 'undelivered') {
                    toSum = toSum + item.orderTable.weight;
                }

                if (item.status === 'partial_delivered' || item.status === 'delivered' || item.status === 'undelivered') {
                    ordersDelivered += 1;
                }

                return orderWeight = toSum;
            });

            setOrderDelivered(ordersDelivered);
            setUserData(selectedUserVehicle[0]);
            setVehicleWeight(`${selectedUserVehicle[0].vehicle === undefined ? 0 : (selectedUserVehicle[0].vehicle && selectedUserVehicle[0].vehicle.weight ? selectedUserVehicle[0].vehicle.weight : 0).toLocaleString('es')}`);
            setAvailablePercent(((((orderWeight) / (selectedUserVehicle[0].vehicle === undefined ? 1 : (selectedUserVehicle[0].vehicle && selectedUserVehicle[0].vehicle.weight ? selectedUserVehicle[0].vehicle.weight : 0))) * 100)).toFixed(0));
            setMOrderWeight(orderWeight);
        }

    }, [selectedUserVehicle]);

    return (
        <Col span={24} className="vehicle-details-container">
            <Row gutter={[0, 0]} className="vehicle-details1-container">
                <Col className='vehicle-indicator-container1' span={13} >
                    <Row gutter={[0, 0]}>
                        <Col span={24} >
                            <h6 className='vehicle-indicator-label'>Utilización</h6>
                        </Col>
                    </Row>
                    <Row gutter={[0, 0]}>
                        <Col span={6}>
                            <Row gutter={[0, 0]}>
                                <Col span={20} >
                                    <h2 className='vehicle-indicator-cumulative'>{availablePercent === "NaN" || availablePercent === "Infinity" ? 0 :availablePercent}%</h2>
                                </Col>
                            </Row>
                            <Row gutter={[0, 0]}>
                                <Col span={4}>
                                    <p className='vehicle-indicator-value'>{`${mOrderWeight}Kg/${vehicleWeight}Kg`}</p>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Col>

                <Col className='vehicle-indicator-container2' span={8} >
                    <FullscreenOutlined className='vehicle-indicator-icon' />
                </Col>
            </Row>

            <Row gutter={[0, 0]} className="vehicle-details2-container">
            <Col className='vehicle-indicator-container1' span={13} >
                    
                    
                    <Row gutter={[0, 0]}>
                        <Col span={24}>
                            <p className='vehicle-indicator-value2'>{`Ordenes Entregadas`}</p>
                            <p className='vehicle-indicator-value3'>{`${orderDelivered}/${userData.orderQty}`}</p>
                            <p className='vehicle-indicator-value2'>{`Total Kilometros`}</p>
                            <p className='vehicle-indicator-value3'>{`${selectedUserVehicle[0] && selectedUserVehicle[0].assignedRoutes.length > 0 ? `${selectedUserVehicle[0].assignedRoutes.map(item => item.distanceValue)[0] === undefined ? 0:Math.round(((selectedUserVehicle[0].assignedRoutes.map(item => item.distanceValue)).reduce((accumulator, currentValue) => accumulator + currentValue))/1000)}Km`: '-'}`}</p>
                            <p className='vehicle-indicator-value2'>{`Tiempo Total`}</p>
                            <p className='vehicle-indicator-value3'>{`${selectedUserVehicle[0] && selectedUserVehicle[0].assignedRoutes.length > 0 ? `${selectedUserVehicle[0].assignedRoutes.map(item => item.distanceValue)[0] === undefined ? 0:Math.round(((selectedUserVehicle[0].assignedRoutes.map(item => item.distanceValue)).reduce((accumulator, currentValue) => accumulator + currentValue))/60)}mins`: '-'}`}</p>
                        </Col>
                    </Row>
                    
                </Col>

                <Col className='vehicle-indicator-container2' span={8} >
                    <CodeSandboxOutlined className='vehicle-indicator-icon' />
                </Col>
            </Row>
        </Col>
    );
}