import { gql } from "@apollo/client";

export const fetchIncidents = gql`
    query fetchIncidents ($companyId: uuid!) {
        incidents (where: {enabled: {_eq: true}, companyId:{_eq: $companyId}}){
            id
            description
        }
    }
`;

export const fetchIncidentsCauses = gql`
    query fetchIncidentsCauses ($companyId: uuid!) {
        incidentCauses (where: {companyId: {_eq: $companyId}}) {
            id
            description
            incident {
                id
                description
            }
            enabled
        }
    }
`;

export const insertIncidentsCause = gql`
    mutation insertIncidentsCause($description: String!, $incidentId: uuid!, $enabled: Boolean!, $companyId: uuid!) {
        insert_incidentCauses(objects: {description: $description, incidentId: $incidentId, enabled: $enabled, companyId: $companyId}) {
            affected_rows
            returning {
                id
            }
        }
    }
`;

export const updateIncidentsCause = gql`
    mutation updateIncidentsCause($id: uuid!, $description: String!, $incidentId: uuid!, $enabled: Boolean!) {
        update_incidentCauses(_set: {description: $description, incidentId: $incidentId, enabled: $enabled}, where: {id: {_eq: $id}}) {
            affected_rows
        }
    }
`;

export const deleteIncidentsCause = gql`
    mutation deleteIncidentsCause($id: uuid!) {
        delete_incidentCauses(where: {id: {_eq: $id}}) {
            affected_rows
        }
    }
`;
