import { gql } from "@apollo/client";

export const fetchVehicleTypes = gql`
    query fetchVehicleTypes ($companyId: uuid!) {
        vehicleTypes (where: {companyId: {_eq: $companyId}}) {
            id
            name
            vehicles_aggregate {
                aggregate {
                    count(columns: vehicleTypeId)
                }
            }
        }
    }
`;

export const insertVehicleType = gql`
    mutation insertVehicleType($name: String!, $companyId: uuid!) {
        insert_vehicleTypes(objects: {name: $name, companyId: $companyId}) {
            affected_rows
            returning {
                id
            }
        }
    }  
`;

export const updateVehicleType = gql`
    mutation udateVehicleType($id: uuid!, $name: String!) {
        update_vehicleTypes(_set: {name: $name}, where: {id: {_eq: $id}}) {
            affected_rows
            returning {
                id
            }
        }
    }
`;

export const deleteVehicleType = gql`
    mutation deleteVehicleType($id: uuid!) {
        delete_vehicleTypes(where: {id: {_eq: $id}}) {
            affected_rows
        }
    }
`;
