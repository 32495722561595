import React, { useState, useEffect } from 'react';
import { Form, Table, Switch, Descriptions, Input, Card, Button, Select, Space, Skeleton, Col, Row, Modal } from 'antd';
import { DeleteFilled, EditFilled } from '@ant-design/icons';
import { useLazyQuery, useMutation } from '@apollo/client';
import { CarOutlined, CheckOutlined, ClockCircleOutlined, EditOutlined } from '@ant-design/icons';
import { handleSave, handleDelete, handleEdit, handleClear, getColumnSearchPropsText } from './UseCases';

import { 
    fetchReasons,
    insertReason,
    updateReason,
    deleteReason
} from '../../queries/reasonsQueries';
import localStorageService from '../../../../../services/localStorageService';
import { ConfigurationMoreSettings } from '../../../ConfigurationMoreSettings';

import { ReasonsIndicator } from './ReasonsIndicator';

const companyId = localStorageService.get('companyId');

export const ReasonsScreen = () => {
    const [getReasons, dataReasons] = useLazyQuery(fetchReasons, { variables: {companyId}, fetchPolicy: 'no-cache' });
    const [insReason] = useMutation(insertReason, { variables: {companyId}, fetchPolicy: 'no-cache' });
    const [delReason] = useMutation(deleteReason, { fetchPolicy: 'no-cache' });
    const [updReason] = useMutation(updateReason, { fetchPolicy: 'no-cache' });
    
    const [reasons, setReasons] = useState([]);
    const [isEditing, setIsEditing] = useState(false);
    const [clickedRow, setClickedRow] = useState("");

    const [form] = Form.useForm();

    const [isModalVisible, setIsModalVisible] = useState(false);

    const showModal = () => {
        setIsModalVisible(true);
    };

    const handleOk = () => {
        setIsModalVisible(false);
    };

    const handleCancel = () => {
        setIsModalVisible(false);
    };

    useEffect(() => {
        getReasons();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        setReasons([]);

        if(!dataReasons.loading && dataReasons.data && dataReasons.data.reasons.length > 0) {
            dataReasons.data.reasons.map((item) => {
                const deleteable = item.routes_aggregate.aggregate.count === 0;
                const { id, description, enabled, deliveryType: deliveryTyp } = item;
                
                return setReasons((current) => [...current, {
                    id,
                    description,
                    deliveryType: deliveryTyp,
                    deliveryTypeText: deliveryTyp === 'partial_delivered' ? 'Con Novedad' : deliveryTyp === 'rescheduled_delivery' ? 'Reprogramado' : 'Devuelto',
                    enabled,
                    deleteable
                }]);
            });
        }
    }, [dataReasons.loading, dataReasons.data]);

    const columns = [
        {
            title: 'Descripción',
            key: 'description',
            dataIndex: 'description',
            sorter: (a, b) => a.description.localeCompare(b.description),
            ...getColumnSearchPropsText("Descripción", "description")
        },
        {
            title: 'Tipo',
            key: 'deliveryTypeText',
            dataIndex: 'deliveryTypeText',
            sorter: (a, b) => a.deliveryTypeText.localeCompare(b.deliveryTypeText),
            ...getColumnSearchPropsText("Tipo", "deliveryTypeText")
        },
        {
            title: 'Habilitado',
            key: 'enabled',
            dataIndex: 'enabled',
            align: 'center',
            sorter: {
                compare: (a, b) => a.enabled - b.enabled,
            },
            render: (value) => <Switch checked={value} disabled />
        },
    ];
    const formItemLayout = {
        labelCol: {
          lg: { span:5 },
        },
        wrapperCol: {
          lg: { span: 15 },
        },
      };
    return (
        <Col className='children-inner-container-with-bg'>
            <ConfigurationMoreSettings />
            <Row className='children-inner-row'>
                <Col span={5} className='children-inner-col'>
                    <ReasonsIndicator value={reasons.length} label={"Total Motivos"}><CarOutlined style={{backgroundColor: 'var(--primaryColor)'}} className="report-indicator-icon"/></ReasonsIndicator>
                </Col>
                <Col span={5} className='children-inner-col'>
                    <ReasonsIndicator value={(reasons.filter(item => item.enabled)).length} label={"Motivos Activos"}><CheckOutlined style={{backgroundColor: '#9ccc65'}} className="report-indicator-icon"/></ReasonsIndicator>
                </Col>
                <Col span={5} className='children-inner-col'>
                    <ReasonsIndicator value={(reasons.filter(item => !item.enabled)).length} label={"Motivos Inactivos"}><ClockCircleOutlined style={{backgroundColor: '#ffba57'}} className="report-indicator-icon"/></ReasonsIndicator>
                </Col>
                <Col span={3} offset={3} className='children-inner-col'><Button type="primary" size={"large"} className='children-inner-button' icon={<EditOutlined />} onClick={showModal}>{clickedRow.length === 0 ? 'Crear' : 'Editar'}</Button></Col>
                <Col span={3} className='children-inner-col'><Button type="primary" size={"large"} className='children-inner-button' icon={<DeleteFilled />} onClick={()=> handleDelete(clickedRow, delReason, reasons, setReasons, setClickedRow)} disabled={clickedRow.length === 0}>Eliminar</Button></Col>
            </Row>
            <Row>
                <Skeleton loading={dataReasons.loading} active>
                    <Table
                        className='children-table'
                        columns={columns}
                        dataSource={reasons}
                        size='small'
                        rowKey='id'
                        pagination={{ pageSize: 13 }}
                        onRow={(record) => {
                            return {
                                onClick: () => {
                                    if(clickedRow.length === 0 || clickedRow !== record.id){
                                        setClickedRow(record.id);
                                        handleEdit(record, setIsEditing, form);
                                    }else{
                                        handleClear(setIsEditing, form);
                                        setClickedRow("");
                                    }
                                },
                            }
                        }}
                        rowClassName={(record) => ['mouse-row-enter', record.id === clickedRow ? 'table-row-color' : ""]}
                    />
                </Skeleton>
            </Row>
            <Modal className='children-modal' visible={isModalVisible} onOk={handleCancel} onCancel={handleCancel} footer={[]} title={clickedRow.length === 0 ? 'Crear Motivo' : 'Editar Motivo'} width={500}>
                <Form
                    {...formItemLayout}
                    form={form}
                    name='configuration'
                    onFinish={(values) => {
                        handleSave(values, insReason, updReason, reasons, setReasons, isEditing, setIsEditing, form);
                        setClickedRow("");
                    }}
                    initialValues={{
                        id: '',
                        description: '',
                        enabled: true,
                        deleteable: true
                    }}
                >
                    <Form.Item name='id' hidden>
                        <Input />
                    </Form.Item>
                   
                            <Form.Item
                            label='Descripción'
                            labelAlign='left'
                                name='description'
                                rules={[{ required: true, message: 'Por favor ingrese una descripción'}]}
                            >
                                <Input />
                            </Form.Item>
                            <Form.Item 
                                label='Tipo'
                                labelAlign='left'
                                name='deliveryType'
                                rules={[{ required: true, message: 'Por favor seleccione una opción'}]}
                            >
                                <Select 
                                    placeholder='Seleccione una opción'
                                    allowClear
                                    onChange={(value) => form.setFieldsValue({ deliveryType: value })}
                                >
                                    <Select.Option value='undelivered'>No entregado</Select.Option>
                                    <Select.Option value='partial_delivered'>Entrega parcial</Select.Option>
                                    <Select.Option value='rescheduled_delivery'>Reprogramado</Select.Option>
                                </Select>
                            </Form.Item>
                            <Form.Item labelAlign='left' label='Habilitado' name='enabled' valuePropName='checked'>
                                <Switch />
                            </Form.Item>

                    <Form.Item>
                        <Space>
                            <Button 
                                type='primary' 
                                shape='round'
                                htmlType='submit'
                                onClick={handleCancel}
                            >
                                {isEditing ? 'Modificar' : 'Agregar'}
                            </Button>
                            <Button
                                type='ghost'
                                shape='round'
                                onClick={() => {
                                    handleClear(setIsEditing, form);
                                    setClickedRow("");
                                    handleCancel();
                                }}
                            >
                                Cancelar
                            </Button>
                        </Space>
                    </Form.Item>
                    <Form.Item name='deleteable' hidden valuePropName='checked'>
                        <Switch />
                    </Form.Item>
                </Form>
            </Modal>
        </Col>
    );
}
