import React, { useEffect } from 'react';
import { Result, Button, Form, Input } from 'antd';
import  {  createBrowserHistory  }  from  "history" ;
import { useLazyQuery, useMutation } from '@apollo/client';
import Swal from 'sweetalert2';
import axios from 'axios';

import { getUserById, changePassword } from '../../queries/userQuery';
import { useUser } from '../../../../context/UserContext';

export const ChangePasswordScreen = () => {
    const { user } = useUser();
    const [form] = Form.useForm();
    const history = createBrowserHistory();

    const [getUser, dataUser] = useLazyQuery(getUserById, { fetchPolicy: 'no-cache' });
    const [updPassword] = useMutation(changePassword, { fetchPolicy: 'no-cache' });

    useEffect(() => {
        getUser({ variables: { id: user.id } });

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const formItemLayout = {
        labelCol: {
            xs: { span: 24 },
            sm: { span: 24 },
            md: { span: 24 },
            lg: { span: 24 },
            xl: { span: 24 },
            xxl: { span: 24 }
        },
        wrapperCol: {
            xs: { span: 24 },
            sm: { span: 24 },
            md: { span: 24 },
            lg: { span: 24 },
            xl: { span: 24 },
            xxl: { span: 24 },
        },

    };

    const handleSubmit = (values) => {
        const { id, displayName } = user;
        const { currentPassword, newPassword } = values;

        try {
            axios({
                method: 'POST',
                url: process.env.REACT_APP_FUNCTION_CHANGE_PASSWORD,
                data: {
                    id,
                    currentPassword,
                    newPassword
                }
            }).then((res) => {
                if (res.status === 200) {
                    Swal.fire({
                        title: 'Contraseña cambiada',
                        text: `${displayName} has cambiado tu contraseña correctamente.`,
                        icon: 'success',
                        confirmButtonText: 'Cerrar',
                        timer: 3000,
                        timerProgressBar: true,
                        willClose: () => {
                            history.replace('/');
                        }
                    });
                } else {
                    Swal.fire({
                        title: 'Error',
                        text: `Error: ${res.data.statusText}`,
                        icon: 'error',
                        confirmButtonText: 'Cerrar',
                        timer: 5000,
                        timerProgressBar: true
                    });
                }
            }).catch((err) => {
                console.error(err)
                Swal.fire({
                    title: 'Error',
                    text: `${err.toString()}`,
                    icon: 'error',
                    confirmButtonText: 'Cerrar',
                    timer: 5000,
                    timerProgressBar: true
                });
            })
        } catch (error) {
            Swal.fire({
                title: 'Error',
                text: `Ocurrió un error al intentar procesar los datos. Muestre la siguiente información al administrador: ${error.toString()}`,
                icon: 'error',
                confirmButtonText: 'Cerrar'
            });
        }
    }

    return (
        <div
            style={{
                height: '91vh',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
            }}
        >
            <Result
                status="info"
                title={`Usuario ${user.displayName}`}
                subTitle="Cambio de contraseña"
                extra={[
                    <Form
                        key='form'
                        form={form}
                        name='verification'
                        onFinish={handleSubmit}
                        {...formItemLayout}
                    >
                        <Form.Item
                            label='Contraseña actual'
                            name='currentPassword'
                            hasFeedback
                            rules={[
                                {
                                    required: true,
                                    message: 'Ingrese la contraseña actual',
                                },
                                ({ _ }) => ({
                                    validator(_, value) {
                                        if (value && value.length < 6) {
                                            return Promise.reject('Mínimo 6 caracteres')
                                        }
                                        return Promise.resolve();
                                    },
                                }),
                            ]}
                        >
                            <Input.Password />
                        </Form.Item>
                        <Form.Item
                            label="Nueva contraseña"
                            name="newPassword"
                            hasFeedback
                            rules={[
                                {
                                    required: true,
                                    message: 'Ingrese su nueva contraseña',
                                },
                                ({ _ }) => ({
                                    validator(_, value) {
                                        if (value && value.length < 6) {
                                            return Promise.reject('Mínimo 6 caracteres')
                                        }
                                        return Promise.resolve();
                                    },
                                }),
                            ]}
                        >
                            <Input.Password />
                        </Form.Item>
                        <Form.Item
                            label="Confirmar contraseña"
                            name="confirm"
                            dependencies={['newPassword']}
                            hasFeedback
                            rules={[
                                {
                                    required: true,
                                    message: 'Confirme su contraseña nueva',
                                },
                                ({ getFieldValue }) => ({
                                    validator(_, value) {
                                        if (!value || getFieldValue('newPassword') === value) {
                                            return Promise.resolve();
                                        }
                                        if (value.length < 6) {
                                            return Promise.reject('Mínimo 6 caracteres')
                                        }
                                        return Promise.reject('Las contraseñas ingresadas no coinciden!');
                                    },
                                }),
                            ]}
                        >
                            <Input.Password />
                        </Form.Item>
                        <Form.Item>
                            <Button type="primary" htmlType="submit" shape='round' size='large'>
                                Cambiar contraseña
                            </Button>
                        </Form.Item>
                    </Form>
                ]}
            />
        </div>
    );
};
