import { gql } from "@apollo/client";

export const fetchVehicleBrands = gql`
    query fetchVehicleBrands ($companyId: uuid!){
        vehicleBrands (where: {companyId: {_eq: $companyId}}) {
            id
            name
            vehicles_aggregate {
                aggregate {
                    count(columns: vehicleBrandId)
                }
            }
        }
    }
`;

export const insertVehicleBrand = gql`
    mutation insertVehicleBrand($name: String!, $companyId: uuid!) {
        insert_vehicleBrands(objects: {name: $name, companyId: $companyId}) {
            affected_rows
            returning {
                id
            }
        }
    }  
`;

export const updateVehicleBrand = gql`
    mutation udateVehicleBrand($id: uuid!, $name: String!) {
        update_vehicleBrands(_set: {name: $name}, where: {id: {_eq: $id}}) {
            affected_rows
            returning {
                id
            }
        }
    }
`;

export const deleteVehicleBrand = gql`
    mutation deleteVehicleBrand($id: uuid!) {
        delete_vehicleBrands(where: {id: {_eq: $id}}) {
            affected_rows
        }
    }
`;
