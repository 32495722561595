import { gql } from '@apollo/client';

export const fetchUserLogin = gql`
    query fetchUserLogin($email: String!) {
        users(where: {email: {_eq: $email}, _and: {enabled: {_eq: true}, _and: {role: {name: {_neq: "driver"}}}}}) {
            id
            email
            password
            displayName
            identificationNumber
            phoneNumber
            photoURL
            emailVerified
            roleId
            role {
                name
            }
        }
    }
`;

export const fetchUserByEmail = gql`
    query fetchUserByEmail($email: String!) {
        users(where: {email: {_eq: $email}, _and: {enabled: {_eq: true}}}) {
            id
            displayName
            email
        }
    }
`;

export const changePassword = gql`
    mutation updateUserPassword($id: uuid!, $password: String!) {
        update_users(_set: {password: $password, emailVerified: true}, where: {id: {_eq: $id}}) {
            affected_rows
        }
    }
`;

export const fetchUserIsVerified = gql`
    query fetchUserIsVerified($id: uuid!) {
        users (where: {id: {_eq: $id}}) {
            emailVerified
        }
    }
`;

export const getUserById = gql`
    query getUserById($id: uuid!) {
        users (where: {id: {_eq: $id}}) {
            password
        }
    }
`;