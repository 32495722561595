import { gql } from "@apollo/client";

export const fetchSequencesNumbers = gql`
    query fetchSequencesNumbers ($companyId: uuid!) {
        sequenceNumbers (where: {companyId: {_eq: $companyId}}) {
            id
            name
            from
            to
            currentNumber
            prefix
            enabled
            sequenceTypeId
            sequenceType{
                name
            }
        }
        sequenceNumbers_aggregate {
            aggregate {
                count(columns: id)
            }
        }
    }
`;

export const fetchSequenceTypes = gql`
    query fetchSequenceTypes{
        sequenceTypes {
            id
            name
        }
    }
`;

export const insertSequenceNumber = gql`
    mutation insertSequenceNumber($name: String!, $sequenceTypeId: String!, $from: Int!, $to: Int!, $currentNumber: Int!, $prefix: String!, $enabled: Boolean!, $companyId: uuid!) {
        insert_sequenceNumbers(objects: {name: $name, sequenceTypeId: $sequenceTypeId, from: $from, to: $to, currentNumber: $currentNumber, prefix: $prefix, enabled: $enabled, companyId: $companyId}) {
            affected_rows
            returning {
                id
            }
        }
    }
`;

export const updateSequenceNumber = gql`
    mutation updateSequenceNumber($id: uuid!, $sequenceTypeId: String!, $name: String!, $from: Int!, $to: Int!, $currentNumber: Int!, $prefix: String!, $enabled: Boolean!) {
        update_sequenceNumbers(_set: {name: $name, sequenceTypeId: $sequenceTypeId, from: $from, to: $to, currentNumber: $currentNumber, prefix: $prefix, enabled: $enabled}, where: {id: {_eq: $id}}) {
            affected_rows
        }
    }
`;

export const deleteSequenceNumber = gql`
    mutation deleteSequenceNumber($id: uuid!) {
        delete_sequenceNumbers(where: {id: {_eq: $id}}) {
            affected_rows
        }
    }
`;
