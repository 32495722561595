import React, { useState, useEffect } from 'react';
import { Form, Table, Switch, Descriptions, Input, Card, Button, Select, Space, Skeleton, Col, Row, Modal } from 'antd';
import { DeleteFilled, EditFilled } from '@ant-design/icons';
import { useLazyQuery, useMutation } from '@apollo/client';
import { CarOutlined, CheckOutlined, ClockCircleOutlined, EditOutlined } from '@ant-design/icons';
import { handleSave, handleDelete, handleEdit, handleClear, getColumnSearchPropsText } from './UseCases';
import localStorageService from '../../../../../services/localStorageService';

import {
    fetchIncidentsCauses,
    insertIncidentsCause,
    updateIncidentsCause,
    deleteIncidentsCause,
    fetchIncidents
} from '../../queries/incidentsCausesQueries';

import { IncidentsCausesIndicator } from './IncidentsCausesIndicator';
import { ConfigurationMoreSettings } from '../../../ConfigurationMoreSettings';

const companyId = localStorageService.get('companyId');

export const IncidentsCausesScreen = () => {
    const [getIncidentsCauses, dataIncidentsCauses] = useLazyQuery(fetchIncidentsCauses, { variables: {companyId}, fetchPolicy: 'no-cache' });
    const [getIncidents, dataIncidents] = useLazyQuery(fetchIncidents, {variables: {companyId}, fetchPolicy: 'no-cache' });
    const [insIncidentsCause] = useMutation(insertIncidentsCause, { variables: {companyId}, fetchPolicy: 'no-cache' });
    const [delIncidentsCause] = useMutation(deleteIncidentsCause, { fetchPolicy: 'no-cache' });
    const [updIncidentsCause] = useMutation(updateIncidentsCause, { fetchPolicy: 'no-cache' });
    const [, setIncident] = useState('');
    const [incidentsCauses, setIncidentsCauses] = useState([]);
    const [isEditing, setIsEditing] = useState(false);
    const [clickedRow, setClickedRow] = useState("");

    const [form] = Form.useForm();

    const [isModalVisible, setIsModalVisible] = useState(false);

    const showModal = () => {
        setIsModalVisible(true);
    };

    const handleOk = () => {
        setIsModalVisible(false);
    };

    const handleCancel = () => {
        setIsModalVisible(false);
    };

    useEffect(() => {
        getIncidentsCauses();
        getIncidents();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        setIncidentsCauses([]);

        if (!dataIncidentsCauses.loading && dataIncidentsCauses.data && dataIncidentsCauses.data.incidentCauses.length > 0) {
            dataIncidentsCauses.data.incidentCauses.map((item) => {
                const deleteable = true;
                const { id, description, enabled, incident } = item;

                return setIncidentsCauses((current) => [...current, {
                    id,
                    description,
                    incidentId: incident === null ? "none" : incident.id,
                    incidentName: incident === null ? "none" : incident.description,
                    enabled,
                    deleteable
                }]);
            });
           
        }
    }, [dataIncidentsCauses.loading, dataIncidentsCauses.data]);

    const columns = [
        {
            title: 'Causa',
            key: 'description',
            dataIndex: 'description',
            sorter: (a, b) => a.description.localeCompare(b.description),
            ...getColumnSearchPropsText("Causa", "description")
        },
        {
            title: 'Tipo de Incidente',
            key: 'incidentName',
            dataIndex: 'incidentName',
            sorter: (a, b) => a.incidentName.localeCompare(b.incidentName),
            ...getColumnSearchPropsText("Tipo", "incidentName")
        },
        {
            title: 'Habilitado',
            key: 'enabled',
            dataIndex: 'enabled',
            align: 'center',
            sorter: {
                compare: (a, b) => a.enabled - b.enabled,
            },
            render: (value) => <Switch checked={value} disabled />
        }
    ];
    const formItemLayout = {
        labelCol: {
          lg: { span:8 },
        },
        wrapperCol: {
          lg: { span: 15 },
        },
      };
    return (
        <Col className='children-inner-container-with-bg'>
            <ConfigurationMoreSettings />
            <Row className='children-inner-row'>
                <Col span={5} className='children-inner-col'>
                    <IncidentsCausesIndicator value={incidentsCauses.length} label={"Total Causas"}><CarOutlined style={{backgroundColor: 'var(--primaryColor)'}} className="report-indicator-icon"/></IncidentsCausesIndicator>
                </Col>
                <Col span={5} className='children-inner-col'>
                    <IncidentsCausesIndicator value={(incidentsCauses.filter(item => item.enabled)).length} label={"Causas Activas"}><CheckOutlined style={{backgroundColor: '#9ccc65'}} className="report-indicator-icon"/></IncidentsCausesIndicator>
                </Col>
                <Col span={5} className='children-inner-col'>
                    <IncidentsCausesIndicator value={(incidentsCauses.filter(item => !item.enabled)).length} label={"Causas Inactivas"}><ClockCircleOutlined style={{backgroundColor: '#ffba57'}} className="report-indicator-icon"/></IncidentsCausesIndicator>
                </Col>
                <Col span={3} offset={3} className='children-inner-col'><Button type="primary" size={"large"} className='children-inner-button' icon={<EditOutlined />} onClick={showModal}>{clickedRow.length === 0 ? 'Crear' : 'Editar'}</Button></Col>
                <Col span={3}  className='children-inner-col'><Button type="primary" size={"large"} className='children-inner-button' icon={<DeleteFilled />} onClick={()=> handleDelete(clickedRow, delIncidentsCause, incidentsCauses, setIncidentsCauses, setClickedRow)} disabled={clickedRow.length === 0}>Eliminar</Button></Col>
            </Row>
            <Row>
                <Skeleton loading={dataIncidents.loading} active>
                    <Table
                        className='children-table'
                        columns={columns}
                        dataSource={incidentsCauses}
                        size='small'
                        rowKey='id'
                        pagination={{ pageSize: 13 }}
                        onRow={(record) => {
                            return {
                                onClick: () => {
                                    if(clickedRow.length === 0 || clickedRow !== record.id){
                                        setClickedRow(record.id);
                                        handleEdit(record, setIsEditing, form);
                                    }else{
                                        handleClear(setIsEditing, form);
                                        setClickedRow("");
                                    }
                                    
                                },
                            }
                        }}
                        rowClassName={(record) => ['mouse-row-enter', record.id === clickedRow ? 'table-row-color' : ""]}
                    />
                </Skeleton>
            </Row>
            <Modal className='children-modal' visible={isModalVisible} onOk={handleCancel} onCancel={handleCancel} footer={[]} title={clickedRow.length === 0 ? 'Crear Causa' : 'Editar Causa'} width={500}>
                <Form
                    {...formItemLayout}
                    form={form}
                    name='configuration'
                    onFinish={(values) => {
                        handleSave({ ...values }, insIncidentsCause, updIncidentsCause, incidentsCauses, setIncidentsCauses, isEditing, setIsEditing, form);
                        setClickedRow("");
                    }}
                    initialValues={{
                        id: '',
                        description: '',
                        incidentId: undefined,
                        enabled: true,
                        deleteable: true
                    }}
                >
                    <Form.Item name='id' hidden>
                        <Input />
                    </Form.Item>
                    <Form.Item labelAlign='left' name='incidentName' hidden>
                        <Input />
                    </Form.Item>
                            <Form.Item
                                label='Causa'
                                labelAlign='left'
                                name='description'
                                rules={[{ required: true, message: 'Por favor ingrese una causa' }]}
                            >
                                <Input />
                            </Form.Item>
                            <Form.Item
                            label='Tipo de incidente'
                            labelAlign='left'
                                name='incidentId'
                                rules={[{ required: true, message: 'Por favor seleccione una opción' }]}
                            >
                                <Select
                                    placeholder='Seleccione...'
                                    allowClear
                                    onChange={(value, object) => {
                                        if (value !== undefined) {
                                            setIncident(object.children);
                                            return form.setFieldsValue({ incidentId: value, incidentName: object.children })
                                        }
                                    }}
                                >
                                    {
                                        dataIncidents && dataIncidents.data && dataIncidents.data.incidents.map((item) => {
                                            return <Select.Option key={item.id} value={item.id}>{item.description}</Select.Option>
                                        })
                                    }

                                </Select>
                            </Form.Item>
                            <Form.Item labelAlign='left' label='Habilitado' name='enabled' valuePropName='checked'>
                                <Switch />
                            </Form.Item>
                    <Form.Item>
                        <Space>
                            <Button
                                type='primary'
                                shape='round'
                                htmlType='submit'
                                onClick={handleCancel}
                            >
                                {isEditing ? 'Modificar' : 'Agregar'}
                            </Button>
                            <Button
                                type='ghost'
                                shape='round'
                                onClick={() => {
                                    handleClear(setIsEditing, form);
                                    setClickedRow("");
                                }}
                            >
                                Cancelar
                            </Button>
                        </Space>
                    </Form.Item>
                    <Form.Item name='deleteable' hidden valuePropName='checked'>
                        <Switch />
                    </Form.Item>
                </Form>
            </Modal>
        </Col>
    );
}
