import React, { useState, useEffect } from "react";
import { Table, Row, Col, Menu, Timeline, Button, Form, Select } from "antd";
import { SwapOutlined } from "@ant-design/icons";
import { useLazyQuery, useMutation } from "@apollo/client";
import ReactExport from "react-data-export";
import moment from "moment";
import Swal from "sweetalert2";
import "moment/locale/es";
import {
  hasAssignedRouteFalse,
  hasAssignedRouteTrue,
  updateMoveRoute,
  updateRoutes,
  updateUserVehicle,
  updateRoutesBatch,
  update_UserVehicle,
  updateHasAssignedRoute,
  queryHasAssignedRoute,
  updateUserIdRoute
} from "../../queries/homeQueries";
import { Button as ButtonMU } from "@mui/material";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import loggerCooltrack from "../../../../services/logger-cooltrack";
import localStorageService from '../../../../services/localStorageService';
import {HomeScreen} from './HomeScreen';
const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;

//const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;
const userDistributionCenterId = localStorageService.get(
  "userDistributionCenterId"
);
const role = localStorageService.get("role");
export const OrdersDetail = ({ selectedUserVehicle, CenterSelected }) => {
  const [setSelectedUserVehicle] = useState([]);
  const [orders, setOrders] = useState([]);
  const [isUpdate, setIsUpdate] = useState(false);
  const [selectedCenterId, setSelectedCenterId] = useState("");
  const [open, setOpen] = React.useState(false);
  const [isUpdateCenter, setIsUpdateCenter] = useState(false);
  const [routeForUpdate, setRouteForUpdate] = useState({});
  const [currentUserVehicleForUpdate, setCurrentUserVehicleForUpdate] =
    useState({});
    
  
  const handleClickOpen = (order) => {
    setRouteForUpdate(order);
    getCurrentRoutes({ variables: { userId: selectedUserVehicle[0].userId, distributionCenterId: CenterSelected} });
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleChange = async (ev) => {
    setCurrentUserVehicleForUpdate(
      dataCurrentRoutes.data?.userVehicle.filter(
        (item) => item.id === ev.target.value
      )[0] ?? {}
    );
  };
  //Aqui se guarda la data, codigo importante log
  const handleMove = async () => {

    await uptUserIdRoute({
      variables: {
        id: routeForUpdate.routeBatchId,
        userId: currentUserVehicleForUpdate.userId,
        licencePlate: currentUserVehicleForUpdate.vehicle.licencePlate
      },
    });
    await uptMoveRoute({
      variables: {
        id: routeForUpdate.key,
        userId: currentUserVehicleForUpdate.userId,
        batch: currentUserVehicleForUpdate.assignedRoutes[0]?.routeBatch?.batch,
        routeOrder:
          currentUserVehicleForUpdate.assignedRoutes_aggregate?.aggregate.count +
          1,
      },
    });
      getHasAssignedRoute({
        variables: {
          userId: selectedUserVehicle[0].userId
        },
      }).then((data)=>{
        AsignedRoute(data);
      });
    handleClose();
    Swal.fire({
      title: "Ruta Editado",
      text: "La orden ha sido movida correctamente!",
      icon: "success",
      confirmButtonText: "Cerrar",
    });
    loggerCooltrack({
      module: "Tracking",
      operation: "ChangeOrder",
      target: routeForUpdate.key,
    });
  };
  const AsignedRoute = async (dataHasAssignedRoute) =>{
    if(dataHasAssignedRoute?.data?.userVehicle[0]?.assignedRoutes.length === 0){
      await uptHasAssignedRoute({
        variables: {
          userId: dataHasAssignedRoute?.data?.userVehicle[0]?.userId,
          hasAssignedRoute: false
        },
      }).then(()=>{
        Swal.fire({
          title: "Vehiculo Liberado",
          text: `El Vehiculo del Usuario ${dataHasAssignedRoute?.data?.userVehicle[0]?.user?.displayName} esta disponible para ser nuevamente planificado!`,
          icon: "success",
          confirmButtonText: "Cerrar",
        });
      })
    } 
  }
  // const [viewMode, setViewMode] = useState(1);
  const cargarDataOrder = (dataRouteRunningCurrent) => {
    setSelectedRoute({
      userNew: dataRouteRunningCurrent,
      userIdCurrent: selectedUserVehicle[0].userId,
    });
  };
  const [selectedRoute, setSelectedRoute] = useState({});
  const [getRouteRunning, dataRouteRunning] = useLazyQuery(
    hasAssignedRouteFalse,
    {
      fetchPolicy: "no-cache",
    }
  );
  const [getCurrentRoutes, dataCurrentRoutes] = useLazyQuery(
    hasAssignedRouteTrue,
    {
      fetchPolicy: "no-cache",
    }
  );
  const [getHasAssignedRoute, dataHasAssignedRoute] = useLazyQuery(
    queryHasAssignedRoute,
    {
      fetchPolicy: "no-cache",
    }
  );
  const [updateRoutesDriver] = useMutation(updateRoutes, {
    fetchPolicy: "no-cache",
  });
  const [uptUserVehicle] = useMutation(updateUserVehicle, {
    fetchPolicy: "no-cache",
  });
  const [uptRoutesBatch] = useMutation(updateRoutesBatch, {
    fetchPolicy: "no-cache",
  });
  const [upt_UserVehicle] = useMutation(update_UserVehicle, {
    fetchPolicy: "no-cache",
  });
  const [uptMoveRoute] = useMutation(updateMoveRoute, {
    fetchPolicy: "no-cache",
  });
  const [uptUserIdRoute] = useMutation(updateUserIdRoute, {
    fetchPolicy: "no-cache",
  });
  const [uptHasAssignedRoute] = useMutation(updateHasAssignedRoute, {
    fetchPolicy: "no-cache",
  });
  

  // const toogleView = () => {
  //     setViewMode(current => current > 3 ? 1 : current+=1);
  // };

  //aquí es dónde ser realiza el verdadero cambio de datos xd
  const handleSave = async () => {
    const { userIdCurrent, userNew } = selectedRoute;

    const newUser = {
      displayName: userNew.user.displayName,
      key: userNew.id,
      licencePlate: userNew.vehicle.licencePlate,
      orderQty: userNew.assignedRoutes_aggregate.aggregate.count,
      schedulingStartLocation: undefined,
      userId: userNew.userId,
      vehicleId: userNew.vehicleId,
    };

    try {
      await updateRoutesDriver({
        variables: {
          userIdCurrent,
          userIdNew: userNew.userId,
        },
      });
      await uptRoutesBatch({
        variables: {
          userIdCurrent,
          userIdNew: userNew.userId,
        },
      });
      await upt_UserVehicle({
        variables: {
          userIdCurrent,
        },
      });
      await uptUserVehicle({
        variables: {
          userIdNew: userNew.userId,
        },
      });
      setSelectedUserVehicle([newUser]);
    } catch (error) {
      console.error(error);
    }
    Swal.fire({
      title: "Conductor Editado",
      text: "Conductor Editado correctamente!",
      icon: "success",
      confirmButtonText: "Cerrar",
    });
    setIsUpdate(!isUpdate);
    setSelectedUserVehicle([]);
  };

  useEffect(() => {
    getRouteRunning();
    setOrders([]);
    if (selectedUserVehicle.length === 1) {
      let records = [];
      let status = "";
      let color = "#ffba57";
      selectedUserVehicle[0].assignedRoutes.map((item) => {
        switch (item.status) {
          case "none":
          case "start":
            status = "Pendiente";
            color = "#ffba57";
            break;
          case "started":
            status = "En reparto";
            color = "orange";
            break;
          case "delivered":
            status = "Completamente Entregado";
            color = "#9ccc65";
            break;
          case "rescheduled_delivery":
            status = "Reprogramado";
            color = "rgb(70, 128, 255)";
            break;
          case "undelivered":
            status = "Devuelto";
            color = "rgb(150, 150, 150)";
            break;
          case "partial_delivered":
            status = "Con Novedad";
            color = "rgb(70, 128, 255)";
            break;
          default:
            status = "Desconocido";
            color = "#ffba57";
            break;
        }
        
        return records.push({
          key: item.id,
          orderNumber: item.orderTable.orderNumber,
          updatedAt: item.updatedAt,
          address: item.orderTable.address,
          custFullName: item.orderTable.custFullName,
          custPhoneNumber: item.orderTable.custPhoneNumber,
          addressComplement: item.orderTable.addressComplement,
          status,
          color,
          durationText: item.durationText,
          durationValue: item.durationValue,
          estimatedEndTimeAllWaypoints: item.routeBatch.estimatedEndTime,
          durationValueAllWaypoints: item.routeBatch.durationValue,
          realDeliveredTime: item.endDateTime,
          consecutiveBill: item.orderTable.consecutiveBill,
          consecutiveRemission: item.orderTable.consecutiveRemission,
          consecutiveSaleOrder: item.orderTable.consecutiveSaleOrder,
          distributionCenterId: item.orderTable.distributionCenterId,
          consecutiveShipping: item.orderTable.consecutiveShipping,
          consecutiveBurden: item.orderTable.consecutiveBurden,
          createdAtRouteBatch: item.routeBatch.createdAt,
          routeBatchId: item.routeBatch.id,
          licencePlate: selectedUserVehicle[0].licencePlate
        });
      });

      setOrders(records);
    }
  }, [selectedUserVehicle]);

  const columns = [
    {
      title: "Cliente",
      dataIndex: "custFullName",
      key: "custFullName",
      render: (value, record) => {
        return <small>{value}</small>;
      },
    },
    {
      title: "Entrega (aprox)",
      dataIndex: "durationText",
      key: "durationText",
      align: "center",
      render: (value, record) => {
        let totalDistanceToSubstract = 0;

        for (let i = 0; i < orders.length; i++) {
          const item = orders[i];

          totalDistanceToSubstract =
            totalDistanceToSubstract + item.durationValue;

          if (item.key === record.key) break;
        }

        const date = moment.utc(record.estimatedEndTimeAllWaypoints);
        const localTime = moment(date).subtract(
          record.durationValueAllWaypoints,
          "seconds"
        );
        const estimatedDeliveryTime = moment(localTime)
          .add(totalDistanceToSubstract, "seconds")
          .format("DD/MM/YYYY H:mm");

        return <small>{estimatedDeliveryTime}</small>;
      },
    },
    {
      title: "Estado",
      dataIndex: "status",
      key: "status",
      render: (value) => <small>{value}</small>,
    },
  ];

  const expandedRowRender = (record) => {
    const data = [
      {
        key: record.key,
        orderNumber: record.orderNumber,
        address: record.address,
        addressComplement: record.addressComplement,
        realDeliveredTime: record.realDeliveredTime,
      },
    ];

    const columns = [
      {
        title: "# Orden",
        dataIndex: "orderNumber",
        key: "orderNumber",
        render: (value) => {
          return <small>{value}</small>;
        },
      },
      {
        title: "Dirección",
        dataIndex: "address",
        key: "address",
        render: (value) => (
          <small>
            {value}. {record.addressComplement}
          </small>
        ),
      },
      {
        title: "Entrega real",
        dataIndex: "realDeliveredTime",
        key: "realDeliveredTime",
        render: (value) => {
          let dateToShow = "";

          if (value !== null) {
            const date = moment.utc(value);
            const localTime = moment(date);

            dateToShow = moment(localTime).format("DD/MM/YYYY H:mm");
          }
          return <small>{dateToShow}</small>;
        },
      },
    ];

    return <Table columns={columns} dataSource={data} pagination={false} />;
  };

  const multiDataSet = [
    {
      columns: [
        { title: "Headings", width: { wpx: 80 } }, //pixels width
        { title: "Text Style", width: { wch: 40 } }, //char width
        { title: "Colors", width: { wpx: 90 } },
      ],
      data: [
        [
          { value: "H1", style: { font: { sz: "24", bold: true } } },
          { value: "Bold", style: { font: { bold: true } } },
          {
            value: "Red",
            style: {
              fill: { patternType: "solid", fgColor: { rgb: "FFFF0000" } },
            },
          },
        ],
        [
          { value: "H2", style: { font: { sz: "18", bold: true } } },
          { value: "underline", style: { font: { underline: true } } },
          {
            value: "Blue",
            style: {
              fill: { patternType: "solid", fgColor: { rgb: "FF0000FF" } },
            },
          },
        ],
        [
          { value: "H3", style: { font: { sz: "14", bold: true } } },
          { value: "italic", style: { font: { italic: true } } },
          {
            value: "Green",
            style: {
              fill: { patternType: "solid", fgColor: { rgb: "FF00FF00" } },
            },
          },
        ],
        [
          { value: "H4", style: { font: { sz: "12", bold: true } } },
          { value: "strike", style: { font: { strike: true } } },
          {
            value: "Orange",
            style: {
              fill: { patternType: "solid", fgColor: { rgb: "FFF86B00" } },
            },
          },
        ],
        [
          { value: "H5", style: { font: { sz: "10.5", bold: true } } },
          { value: "outline", style: { font: { outline: true } } },
          {
            value: "Yellow",
            style: {
              fill: { patternType: "solid", fgColor: { rgb: "FFFFFF00" } },
            },
          },
        ],
        [
          { value: "H6", style: { font: { sz: "7.5", bold: true } } },
          { value: "shadow", style: { font: { shadow: true } } },
          {
            value: "Light Blue",
            style: {
              fill: { patternType: "solid", fgColor: { rgb: "FFCCEEFF" } },
            },
          },
        ],
      ],
    },
  ];

  const menu = (
    <Menu>
      <Menu.Item>
        <ExcelFile element={<a href="/">Excel</a>}>
          <ExcelSheet dataSet={multiDataSet} name="Organization" />
        </ExcelFile>
      </Menu.Item>
      <Menu.Item>PDF</Menu.Item>
    </Menu>
  );

  const ordersCaculated = (tOrders) => {
    let lastTimeValue = null;

    let mOrders = tOrders.map((order, index) => {
      let timeValue = null;

      if (index === 0) {
        if (
          order.status === "Entregada" ||
          order.status === "Reprogramado" ||
          order.status === "Entrega parcial" ||
          order.status === "No entregada"
        ) {
          timeValue = moment(order.realDeliveredTime)
            .add(18000, "s")
            .format("DD/MM/YYYY hh:mm A");
        } else if (order.status === "En reparto") {
          timeValue = moment(order.updatedAt)
            .add(order.durationValue, "s")
            .format("DD/MM/YYYY hh:mm A");
        } else {
          timeValue = moment()
            .add(order.durationValue, "s")
            .format("DD/MM/YYYY hh:mm A");
        }
      } else {
        if (
          order.status === "Entregada" ||
          order.status === "Reprogramado" ||
          order.status === "Entrega parcial" ||
          order.status === "No entregada"
        ) {
          timeValue = moment(order.realDeliveredTime)
            .add(18000, "s")
            .format("DD/MM/YYYY hh:mm A");
        } else if (order.status === "En reparto") {
          timeValue = moment(order.updatedAt)
            .add(order.durationValue, "s")
            .format("DD/MM/YYYY hh:mm A");
        } else {
          if (
            orders[index - 1].status === "Entregada" ||
            orders[index - 1].status === "Reprogramado" ||
            orders[index - 1].status === "Entrega parcial" ||
            orders[index - 1].status === "No entregada"
          ) {
            timeValue = moment(orders[index - 1].realDeliveredTime)
              .add(order.durationValue + 18000, "s")
              .format("DD/MM/YYYY hh:mm A");
          } else if (orders[index - 1].status === "En reparto") {
            timeValue = moment(orders[index - 1].updatedAt)
              .add(orders[index - 1].durationValue + order.durationValue, "s")
              .format("DD/MM/YYYY hh:mm A");
          } else {
            timeValue = moment(lastTimeValue)
              .add(order.durationValue, "s")
              .format("DD/MM/YYYY hh:mm A");
          }
        }
      }

      lastTimeValue = moment(timeValue, "DD/MM/YYYY hh:mm A");

      return { ...order, timeValue };
    });

    return mOrders;
  };

  return (
    <Col span={24} className="driver-details-container2">
      <Row gutter={[0, 0]} className="driver-details-item2">
        <Col className="driver-details-item-label2" span={24}>
        <p className='form-element3'>Ruta</p>
        </Col>

        <Col className="driver-details-item-value2" span={24} key="list-container">
          <Timeline className="driver-details-timeline">
            {ordersCaculated(orders).map((order, index) => {
              return (
                <Timeline.Item
                  className="driver-details-timeline-item"
                  color={order.color}
                  key={index}
                  onClick={() => handleClickOpen(order)}
                >
                  <Row gutter={[0, 0]}>
                    <Col
                      span={24}
                      className="driver-details-timeline-item-container"
                    >
                      <Row gutter={[0, 0]}>
                        <Col span={12}>
                          <p
                            style={{ color: order.color }}
                            className="driver-details-timeline-item-label1"
                          >
                            {order.status}
                          </p>
                        </Col>
                        <Col span={12}>
                          {!isUpdate ? (
                            <Button
                              className="Editar_Orden_HeaderUploadScreenBotton2"
                              type="primary"
                              ghost={!isUpdate}
                              shape="round"
                              size="small"
                              icon={<SwapOutlined/>}
                              onClick={() => handleClickOpen(order)}
                            ></Button>
                          ) : null}
                          <p className="driver-details-timeline-item-label5">
                            Nº {index + 1}
                          </p>
                        </Col>
                      </Row>
                      <Row gutter={[0, 0]}>
                        <Col span={12}>
                          <p className="driver-details-timeline-item-label3">
                            {order.orderNumber}
                          </p>
                        </Col>
                        <Col span={12}>
                          <p className="driver-details-timeline-item-label4">
                            {order.custFullName}
                          </p>
                          <p className="driver-details-timeline-item-label6">
                            Tel/Cel: {order.custPhoneNumber !== "" ? order.custPhoneNumber : "N/D"}
                          </p>
                        </Col>
                        
                      </Row>
                      <Row gutter={[0, 0]}>
                        <Col span={12}>
                          <p className="driver-details-timeline-item-label3">
                            {order.address}
                          </p>
                        </Col>
                        <Col span={12}>
                          <p className="driver-details-timeline-item-label4">
                            {moment(order.createdAtRouteBatch).format("DD/MM/YYYY hh:mm A")}
                          </p>
                        </Col>
                      </Row>
                      {order.consecutiveBill === null || order.consecutiveBill ==="" ? (
                        ""
                      ) : (
                        <Col span={24}>
                          <p className="driver-details-timeline-item-label20">
                            Factura: {order.consecutiveBill}
                          </p>
                        </Col>
                      )}
                      {order.consecutiveRemission === null || order.consecutiveRemission ==="" ? (
                        ""
                      ) : (
                        <Col span={24}>
                          <p className="driver-details-timeline-item-label21">
                            Remisión: {order.consecutiveRemission}
                          </p>
                        </Col>
                      )}
                      {order.consecutiveSaleOrder === null || order.consecutiveSaleOrder === "" ? (
                        ""
                      ) : (
                        <Col span={24}>
                          <p className="driver-details-timeline-item-label22">
                            Orden: {order.consecutiveSaleOrder}
                          </p>
                        </Col>
                      )}
                      {order.consecutiveShipping === null || order.consecutiveShipping === ""? (
                        ""
                      ) : (
                        <Col span={24}>
                          <p className="driver-details-timeline-item-label22">
                            Envio: {order.consecutiveShipping}
                          </p>
                        </Col>
                      )}
                       {order.consecutiveBurden === null || order.consecutiveBurden === "" ? (
                        ""
                      ) : (
                        <Col span={24}>
                          <p className="driver-details-timeline-item-label22">
                            Carga: {order.consecutiveBurden}
                          </p>
                        </Col>
                      )}
                    </Col>
                  </Row>
                </Timeline.Item>
              );
            })}
          </Timeline>
        </Col>
        <Col className="driver-details-item-live-icon-container" span={24}>
          <img
            src="https://res.cloudinary.com/srf/image/upload/v1626234268/ezgif.com-gif-maker_1_xedjrx.gif"
            width="30"
          />
        </Col>
      </Row>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Mover orden</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Seleccione un conductor con una ruta en ejecución para realizar el
            cambio
          </DialogContentText>
          <TextField
            id="outlined-select-currency"
            select
            label="Ruta"
            onChange={handleChange}
            style={{ width: "100%", marginTop: "16px" }}
          >
            {(dataCurrentRoutes?.data?.userVehicle ?? []).map((item) => (
              <MenuItem key={item.id} value={item.id}>
                {item.vehicle.licencePlate} - {item.user.displayName}
              </MenuItem>
            ))}
          </TextField>
        </DialogContent>
        <DialogActions>
          <ButtonMU sx={{ color: "red" }} onClick={handleClose}>
            Cerrar
          </ButtonMU>
          <ButtonMU sx={{ color: "red" }} onClick={handleMove}>
            Mover
          </ButtonMU>
        </DialogActions>
      </Dialog>
    </Col>
  );
};
