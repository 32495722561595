import { gql } from "@apollo/client";

export const fetchDistributionCenters = gql`
    query fetchDistributionCenters ($companyId: uuid!) {
        distributionCenters (where: {companyId: {_eq: $companyId}}) {
            id
            name
            description
            enabled
            state
            city
            address
            addressComplement
            lat
            lng
            contactName
            phone
            email
            sectorId
            distributionCenterSector {
                name
            }
        }
        distributionCenters_aggregate {
            aggregate {
                count(columns: id)
            }
        }
    }
`;

export const insertDistributionCenter = gql`
    mutation insertDistributionCenter($name: String!, $description: String!, $enabled: Boolean!, $companyId: uuid!, $state: String!, $city: String!, $address: String!, $addressComplement: String, $lat: String!, $lng: String!, $contactName: String!, $phone: String, , $email: String, $sectorId: uuid) {
        insert_distributionCenters(objects: {name: $name, description: $description, enabled: $enabled, companyId: $companyId, state: $state, city: $city, address: $address, addressComplement: $addressComplement, lat: $lat, lng: $lng, contactName: $contactName, phone: $phone, email: $email, sectorId: $sectorId}) {
            affected_rows
            returning {
                id
            }
        }
    }
`;

export const updateDistributionCenter = gql`
    mutation udateDistributionCenter($id: uuid!, $name: String!, $description: String!, $enabled: Boolean!, $state: String!, $city: String!, $address: String!, $addressComplement: String, $lat: String!, $lng: String!, $contactName: String!, $phone: String, , $email: String, $sectorId: uuid) {
        update_distributionCenters(_set: {name: $name, description: $description, enabled: $enabled, state: $state, city: $city, address: $address, addressComplement: $addressComplement, lat: $lat, lng: $lng, contactName: $contactName, phone: $phone, email: $email, sectorId: $sectorId}, where: {id: {_eq: $id}}) {
            affected_rows
        }
    }
`;

export const deleteDistributionCenter = gql`
    mutation deleteDistributionCenter($id: uuid!) {
        delete_distributionCenters(where: {id: {_eq: $id}}) {
            affected_rows
        }
    }
`;

export const fetchSectors  = gql`
    query fetchSectors ($companyId: uuid!) {
        sectors (where: {companyId: {_eq: $companyId}, enabled: {_eq: true}}) {
            id
            name
            sectorPolygons{
                lat
                lng
                }
        }
    }
`;