import CryptoJS from 'crypto-js';

const secretKey = process.env.REACT_APP_ENCRYPT_SECRET_KEY;

class CryptoService {

    encrypt(value) {

        return CryptoJS.AES.encrypt(value, secretKey).toString();
    };

    decrypt(value) {
        if (value !== undefined && value !== null) {
            const bytes = CryptoJS.AES.decrypt(value, secretKey);

            return bytes.toString(CryptoJS.enc.Utf8);
        }

        return '';
    };
}

export default new CryptoService();