import { ApolloLink, Observable } from '@apollo/client';

import localStorageService from '../localStorageService';

const authLink = new ApolloLink((operation, forward) =>
    new Observable(observer => {
        let handle;

        Promise.resolve(operation)
            .then(operation => {
                const accessToken = localStorageService.get('token');

                if (accessToken) {
                    operation.setContext({
                        headers: {
                            'Content-Type': 'application/json',
                            Authorization: `Bearer ${accessToken}`
                        }
                    });
                }
            })
            .then(() => {
                handle = forward(operation).subscribe({
                    next: observer.next.bind(observer),
                    error: observer.error.bind(observer),
                    complete: observer.complete.bind(observer),
                });
            })
            .catch(observer.error.bind(observer));

        return () => {
            if (handle) handle.unsubscribe();
        };
    })
);

export default authLink;
