import { gql } from '@apollo/client';

export const fetchVehicles = gql`
    subscription fetchVehicles ($companyId: uuid!) {
        vehicles (where: {companyId: {_eq: $companyId}}) {
            id
            licencePlate
            model
            weight
            height
            width
            depth
            tare
            vehicleBrandId
            vehicleTypeId
            transportCompanyId
            schedulingStartLocation
            vehicleDistributionCenterId
            cubicMeters
            addressExit
            vehicleBrand {
                name
            }
            vehicleType {
                name
            }
            transportCompany {
                name
            }
            userVehicles {
                userId
                user {
                    displayName
                }
                hasAssignedRoute
            }
            userVehicles_aggregate(limit: 1) {
                aggregate {
                    count(columns: vehicleId)
                }
            }
            vehicleDistributionCenter {
                name
            }
        }
    }
`;

export const insertVehicle = gql`
    mutation insertVehicle($licencePlate: String!, $model: String!, $weight: numeric!, $height: numeric!, $width: numeric!, $depth: numeric!, $tare: numeric!, $vehicleBrandId: uuid!, $vehicleTypeId: uuid!, $transportCompanyId: uuid!, $schedulingStartLocation: String!, $companyId: uuid!, $vehicleDistributionCenterId: uuid!, $cubicMeters: numeric, $addressExit: String) {
        insert_vehicles(objects: {licencePlate: $licencePlate, model: $model, weight: $weight, height: $height, width: $width, depth: $depth, tare: $tare, vehicleBrandId: $vehicleBrandId, vehicleTypeId: $vehicleTypeId, transportCompanyId: $transportCompanyId, schedulingStartLocation: $schedulingStartLocation, companyId: $companyId, vehicleDistributionCenterId: $vehicleDistributionCenterId, cubicMeters: $cubicMeters, addressExit: $addressExit}) {
            affected_rows
            returning {
                id
            }
        }
    }
`;

export const updateVehicle = gql`
    mutation updateVehicle($id: uuid!, $licencePlate: String!, $model: String!, $weight: numeric!, $height: numeric!, $width: numeric!, $depth: numeric!, $tare: numeric!, $vehicleBrandId: uuid!, $vehicleTypeId: uuid!, $transportCompanyId: uuid!, $schedulingStartLocation: String!, $vehicleDistributionCenterId: uuid!, $cubicMeters: numeric, $addressExit: String) {
        update_vehicles(_set: {licencePlate: $licencePlate, model: $model, weight: $weight, height: $height, width: $width, depth: $depth, tare: $tare, vehicleBrandId: $vehicleBrandId, vehicleTypeId: $vehicleTypeId, transportCompanyId: $transportCompanyId, schedulingStartLocation: $schedulingStartLocation, vehicleDistributionCenterId: $vehicleDistributionCenterId, cubicMeters: $cubicMeters, addressExit: $addressExit}, where: {id: {_eq: $id}}) {
            affected_rows
        }
    }
`;

export const deleteVehicle = gql`
    mutation deleteVehicle($id: uuid!) {
        delete_vehicles(where: {id: {_eq: $id}}) {
            affected_rows
        }
    }
`;

export const fetchVehicleBrands = gql`
    query fetchVehicleBrands ($companyId: uuid!) {
        vehicleBrands (where: {companyId: {_eq: $companyId}}) {
            id
            name
        }
    }
`;

export const fetchVehicleTypes = gql`
    query fetchVehicleTypes ($companyId: uuid!) {
        vehicleTypes (where: {companyId: {_eq: $companyId}}) {
            id
            name
        }
    }
`;

export const fetchTransportCompanies = gql`
    query fetchTransportCompanies ($companyId: uuid!) {
        transportCompanies(where: {companyId: {_eq: $companyId}, enabled: {_eq: true}}) {
            id
            name
        }
    }
`;

export const fetchDistributionCenters  = gql`
    query fetchDistributionCenters ($companyId: uuid!) {
        distributionCenters (where: {companyId: {_eq: $companyId}, enabled: {_eq: true}}) {
            id
            name
        }
    }
`;

export const fetchAvailableDrivers = gql`
    subscription fetchAvailableDrivers ($companyId: uuid!) {
        users(where: {_not: {userVehicle: {}}, _and: {role: {name: {_eq: "driver"}}, _and: {enabled: {_eq: true}, _and: {emailVerified: {_eq: true}, _and: {companyId: {_eq: $companyId}}}}}}) {
            id
            displayName
        }
    }
`;

export const insertUserVehicle = gql`
    mutation insertUserVehicle($userId: uuid!, $vehicleId: uuid!) {
        insert_userVehicle(objects: {userId: $userId, vehicleId: $vehicleId}) {
            affected_rows
            returning {
                id
            }
        }
    }
`;

export const updateUserVehicle = gql`
    mutation updateUserVehicle($id: uuid!, $userId: uuid!) {
        update_userVehicle(_set: {userId: $userId}, where: {id: {_eq: $id}}) {
            affected_rows
        }
    }
`;

export const removeUserVehicle = gql`
    mutation removeUserVehicle($vehicleId: uuid) {
        delete_userVehicle(where: {vehicleId: {_eq: $vehicleId}}) {
            affected_rows
        }
    }
`;

export const fetchUserVehicle = gql`
    query fetchUserVehicle($vehicleId: uuid!) {
        userVehicle(where: {vehicleId: {_eq: $vehicleId}}) {
            id
        }
    }
`;

