import React from 'react';
import { Col, Row } from 'antd';

export const VehicleBrandsIndicator = (props) => {
    return (
        <Row className='report-indicator-container'>
            <Col >
                {props.children}
            </Col>
            <Col >
                <Row><h1 className="report-indicator-value">{props.value}</h1></Row>
                <Row><p className="report-indicator-label">{props.label}</p></Row>
            </Col>
        </Row>
    );
}