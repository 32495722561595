import { ApolloProvider } from '@apollo/client';
import React, { useState, useEffect, useMemo, createContext, useContext } from 'react';

import localStorageService from '../services/localStorageService';
import apiClient from '../services/hasura/client';

const UserContext = createContext();

export const UserProvider = (props) => {
    const [user, setUser] = useState();
    const [isAuthenticated, setIsAuthenticated] = useState(false);

    useEffect(() => {
        const local = localStorage.getItem('userData');
        const session = sessionStorage.getItem('userData');

        if (local !== null && local !== '') {
            setIsAuthenticated(true);
            sessionStorage.setItem('userData', local);

            const usr = JSON.parse(local);

            if (Object.keys(usr).length > 0) {

                let initials = usr.displayName.split(' ');

                if (initials.length > 1) {
                    setUser({ ...usr, initials: initials[0][0] + initials[1][0] })
                }
            }
            
            fetch(process.env.REACT_APP_FUNCTION_ACCESS_TOKEN, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({
                    id: localStorageService.get('id'),
                    role: localStorageService.get('role')
                }),
            }).then(async (res) => {
                const token = await res.json();

                localStorageService.set('token', token.accessToken);
            });
        } else {
            if (session !== null && session !== '') {
                setIsAuthenticated(true);
                const usr = JSON.parse(session);

                if (Object.keys(usr).length > 0) {
                    let initials = usr.displayName.split(' ');

                    if (initials.length > 1) {
                        setUser({ ...usr, initials: initials[0][0] + initials[1][0] })
                    }
                }
            }
        }

    }, [isAuthenticated]);

    const value = useMemo(() => {
        return ({
            user,
            isAuthenticated
        });
    }, [user, isAuthenticated]);

    return (
        <UserContext.Provider value={value} {...props}>
            <ApolloProvider client={apiClient}>
                {props.children}
            </ApolloProvider>
        </UserContext.Provider>
    );
};

export const useUser = () => {
    const context = useContext(UserContext);

    if (!context) {
        throw new Error('useUser debe estar dentro del proveedor UserContext.');
    }

    return context;
}