import React, { useState } from 'react';
import { Table, Button, Drawer, Space, Form, Switch, Tooltip, Card, Descriptions, Input, Select, TimePicker } from 'antd';
import { useMutation } from '@apollo/client';
import { DeleteFilled, EditFilled } from '@ant-design/icons';
import Swal from 'sweetalert2';
import axios from "axios";

import { insertClientAddresses, updateClientAddresses, deleteClientAddresses, updatePrincipal, updatedistributionCenterPrincipal } from '../../queries/clientsQueries';
import {
    statesList, paymentMethodsList, priorityList
} from '../../../common/commonData';
import calculateGeoEnclosure from "../../../../../services/geo-enclosure";

export const ClientAddresses = (props) => {
    const { setShowDrawer, showDrawer, clientAddresses, setClientAddresses, clientName, clientId, distributionCenters, sectors } = props;

    const [insClientAddresses] = useMutation(insertClientAddresses, { fetchPolicy: 'no-cache' });
    const [updClientAddresses] = useMutation(updateClientAddresses, { fetchPolicy: 'no-cache' });
    const [delClientAddresses] = useMutation(deleteClientAddresses, { fetchPolicy: 'no-cache' });
    const [updPrincipal] = useMutation(updatePrincipal, { fetchPolicy: 'no-cache' });
    const [upddistributionCenterPrincipal] = useMutation(updatedistributionCenterPrincipal, { fetchPolicy: 'no-cache' });

    const [isDefininedDistributionCenter, setIsDefininedDistributionCenter] = useState(false);

    const [isEditing, setIsEditing] = useState(false);
    const [clickedRow, setClickedRow] = useState("");
    const [geoClouser, setGeoClouser] = useState("");
    const [selectedSector, setSelectedSector] = useState("");

    const [form] = Form.useForm();

    const handleSave = async (values) => {
        const { id, name, contactName, phoneNumber, cellPhoneNumber, state, city, address, addressComplement, mail, defaultDistributionCenterId, distributionCenter, note, defaultPaymentMethod, defaultPriority, sector, sectorId, country, lat, lng } = values;

        const distributionCenterPrincipal = values.distributionCenterPrincipal === null || values.distributionCenterPrincipal === undefined ?  false : values.distributionCenterPrincipal;
        const principal = values.principal === null || values.principal === undefined ? false : values.principal;
        const enabled = values.enabled === null || values.enabled === undefined ? false : values.enabled;
        if(principal){
            const setPrincipal = await updPrincipal({variables: {clientId}});
            if(setPrincipal.data.update_clientAddresses.affected_rows > 0)
            {
                for(let i = 0; i< clientAddresses.length; i++){
                    if(!isEditing)
                        clientAddresses[i].principal = false;
                    else if(clientAddresses[i].id !== id)
                        clientAddresses[i].principal = false;
                }        
            }
        }

        if(distributionCenterPrincipal){
            const setDistributionCenterPrincipal = await upddistributionCenterPrincipal({variables: {clientId, defaultDistributionCenterId},});

            if(setDistributionCenterPrincipal.data.update_clientAddresses.affected_rows > 0)
            {
                for(let i = 0; i< clientAddresses.length; i++){
                    if(!isEditing && clientAddresses[i].defaultDistributionCenterId === defaultDistributionCenterId)
                        clientAddresses[i].distributionCenterPrincipal = false;
                    else if(clientAddresses[i].id !== id && clientAddresses[i].defaultDistributionCenterId === defaultDistributionCenterId)
                        clientAddresses[i].distributionCenterPrincipal = false;
                }
            }
        }


        try {
            if(!isEditing) {
                const res = await insClientAddresses({
                    variables: {
                        name,
                        contactName,
                        phoneNumber,
                        cellPhoneNumber,
                        state,
                        city,
                        address,
                        addressComplement,
                        mail,
                        principal,
                        distributionCenter,
                        defaultDistributionCenterId,
                        distributionCenterPrincipal,
                        note,
                        defaultPaymentMethod,
                        defaultPriority,
                        sector,
                        sectorId,
                        country,
                        lat,
                        lng,
                        enabled,
                        clientId
                    },
                });
        
                if(res.data.insert_clientAddresses.affected_rows > 0) {
                    const { id } = res.data.insert_clientAddresses.returning[0];
    
                    setClientAddresses((current) => [...current, {
                        id,
                        name,
                        contactName,
                        phoneNumber,
                        cellPhoneNumber,
                        state,
                        city,
                        address,
                        addressComplement,
                        mail,
                        principal,
                        distributionCenter,
                        defaultDistributionCenterId,
                        distributionCenterPrincipal,
                        note,
                        defaultPaymentMethod,
                        defaultPriority,
                        sector,
                        sectorId,
                        country,
                        lat,
                        lng,
                        clientId
                    }]);
                }
            } else {
                const res = await updClientAddresses({
                    variables: {
                        id,
                        name,
                        contactName,
                        phoneNumber,
                        cellPhoneNumber,
                        state,
                        city,
                        address,
                        addressComplement,
                        mail,
                        principal,
                        distributionCenter,
                        defaultDistributionCenterId,
                        distributionCenterPrincipal,
                        note,
                        defaultPaymentMethod,
                        defaultPriority,
                        sector,
                        sectorId,
                        country,
                        lat,
                        lng,
                        enabled
                    }
                });

                if(res.data.update_clientAddresses.affected_rows > 0) {
                    const records = clientAddresses;
                    const indexToReplace = clientAddresses.findIndex((item) => item.id === id);

                    records.splice(indexToReplace, 1, {
                        id,
                        name,
                        contactName,
                        phoneNumber,
                        cellPhoneNumber,
                        state,
                        city,
                        address,
                        addressComplement,
                        mail,
                        principal,
                        distributionCenter,
                        defaultDistributionCenterId,
                        distributionCenterPrincipal,
                        note,
                        defaultPaymentMethod,
                        defaultPriority,
                        sector,
                        sectorId,
                        country,
                        lat,
                        lng,
                        enabled,
                        clientId
                    });
                    setClientAddresses([...records]);
                }
            }
        } catch (error) {
            
            if(error.message.includes('constraint')) {
                Swal.fire({
                    title: 'Error',
                    text: 'Dirección ya registrada, revise los datos',
                    icon: 'error',
                    confirmButtonText: 'Cerrar'
                });
            } else {
                Swal.fire({
                    title: 'Error',
                    text: error.message,
                    icon: 'error',
                    confirmButtonText: 'Cerrar'
                });
            }
        }

        setIsEditing(false);
        setClickedRow("");

        form.setFieldsValue({
            id: '',
            name: '',
            contactName: '',
            phoneNumber: '',
            cellPhoneNumber: '',
            state: '',
            city: '',
            address: '',
            addressComplement: '',
            mail: '',
            principal: false,
            distributionCenter: '',
            defaultDistributionCenterId: null,
            distributionCenterPrincipal: false,
            note: '',
            defaultPaymentMethod: '',
            defaultPriority: '',
            sector: '',
            sectorId: null,
            country: '',
            lat: '',
            lng: '',
            enabled: true,
        });
    };

    const handleDelete = (record) => {
        const { id } = record;

        Swal.fire({
            title: '¿Seguro desea eliminar?',
            text: "Este proceso es irreversible!",
            icon: 'question',
            showCancelButton: true,
            confirmButtonColor: 'var(--primaryColor)',
            cancelButtonColor: '#B6B6B6',
            confirmButtonText: 'Sí, eliminar',
            cancelButtonText: 'No, cancelar',
        }).then( async (result) => {
            if(result.isConfirmed) {
                try {
                    const res = await delClientAddresses({
                        variables: {
                            id
                        },
                        fetchPolicy: 'no-cache'
                    });
        
                    if(res.data.delete_clientAddresses.affected_rows > 0) {
                        setClientAddresses(clientAddresses.filter((item) => item.id !== id));

                        Swal.fire({
                            title: 'Registro eliminado',
                            icon: 'success',
                            showConfirmButton: false,
                            timer: 1000
                        });
                    }
                } catch (error) {
                    console.error(error)
                }
            }
        });
    };

    const handleEdit = (record) => {
        const { id, name, contactName, phoneNumber, cellPhoneNumber, state, city, address, addressComplement, mail, principal, defaultDistributionCenterId, distributionCenter, distributionCenterPrincipal, note, defaultPaymentMethod, defaultPriority, sector, sectorId, country, lat, lng, enabled } = record;
        
        setIsEditing(true);

        form.setFieldsValue({
            id,
            name,
            contactName,
            phoneNumber,
            cellPhoneNumber,
            state,
            city,
            address,
            addressComplement,
            mail,
            principal,
            distributionCenter,
            defaultDistributionCenterId,
            distributionCenterPrincipal,
            note,
            defaultPaymentMethod,
            defaultPriority,
            sector,
            sectorId,
            country,
            lat,
            lng,
            enabled
        });
    };

    const handleClear = () => {
        setIsEditing(false);
        setClickedRow("");

        form.setFieldsValue({
            id: '',
            name: '',
            contactName: '',
            phoneNumber: '',
            cellPhoneNumber: '',
            state: '',
            city: '',
            address: '',
            addressComplement: '',
            mail: '',
            principal: false,
            distributionCenter: '',
            defaultDistributionCenterId: false,
            distributionCenterPrincipal: false,
            note: '',
            defaultPaymentMethod: '',
            defaultPriority: '',
            sector: '',
            sectorId: null,
            country: '',
            lat: '',
            lng: '',
            enabled: true,
        });
    };

    const columns = [
        {
            key: 'name',
            dataIndex: 'name',
            title: 'Nombre'
        },
        {
            key: 'contactName',
            dataIndex: 'contactName',
            title: 'Contacto',
            align: 'center',
        },
        {
            key: 'phoneNumber',
            dataIndex: 'phoneNumber',
            title: 'Teléfono',
            align: 'center',
        },
        {
            key: 'cellPhoneNumber',
            dataIndex: 'cellPhoneNumber',
            title: 'Celular',
            align: 'center',
        },
        {
            key: 'country',
            dataIndex: 'country',
            title: 'País',
            align: 'center',
        },
        {
            key: 'state',
            dataIndex: 'state',
            title: 'Departamento',
            align: 'center',
        },
        {
            key: 'city',
            dataIndex: 'city',
            title: 'Ciudad',
            align: 'center',
        },
        {
            key: 'address',
            dataIndex: 'address',
            title: 'Dirección',
            align: 'center',
        },
        {
            key: 'addressComplement',
            dataIndex: 'addressComplement',
            title: 'Complemento de dirección',
            align: 'center',
        },
        {
            key: 'mail',
            dataIndex: 'mail',
            title: 'Correo',
            align: 'center',
        },
        {
            key: 'principal',
            dataIndex: 'principal',
            title: 'Dirección principal',
            align: 'center',
            render: (value) => <Switch checked={value} size='small' disabled />
        },
        {
            key: 'distributionCenter',
            dataIndex: 'distributionCenter',
            title: 'Centro de distribución',
            align: 'center',
        },
        {
            key: 'distributionCenterPrincipal',
            dataIndex: 'distributionCenterPrincipal',
            title: 'Centro de distribución principal',
            align: 'center',
            render: (value) => <Switch checked={value} size='small' disabled />
        },
        {
            key: 'defaultPaymentMethod',
            dataIndex: 'defaultPaymentMethod',
            title: 'Forma de pago',
            align: 'center',
        },
        {
            key: 'priority',
            dataIndex: 'priority',
            title: 'Prioridad',
            align: 'center',
        },
        {
            key: 'sector',
            dataIndex: 'sector',
            title: 'Zona',
            align: 'center',
        },
        {
            key: 'note',
            dataIndex: 'note',
            title: 'Comentarios',
            align: 'center',
        },
        {
            key: 'enabled',
            dataIndex: 'enabled',
            title: 'Habilitado',
            align: 'center',
            render: (value) => <Switch checked={value} size='small' disabled />
        },
        {
            key: 'actions',
            dataIndex: 'actions',
            title: 'Acciones',
            align: 'center',
            render: (value, record) => {
                return (
                    <Space>
                        <Tooltip title='Eliminar'>
                            <Button
                                type='primary'
                                shape='round'
                                icon={<DeleteFilled />}
                                onClick={() => {
                                    handleDelete(record);
                                }}
                            />
                        </Tooltip>
                        <Tooltip title='Modificar'>
                            <Button
                                type='primary'
                                shape='round'
                                icon={<EditFilled />}
                                onClick={() => {
                                    handleEdit(record);
                                }}
                            />
                        </Tooltip>
                    </Space>
                )
            }
        }
    ];

    const destinationAutoComplet = async (_address, _address1,_address2) => {
        const addressDepart = _address2;
        const addressCity = _address1;
        const addressA = _address;
        const addressComplement =`${addressDepart},${addressCity},${addressA}`;
        if (_address.length > 0) {
          // verificar si no viene la latitud, longitud,
          // y si viene la dirección, convertirla a lat, lng
          let newAddress = "";
          const address = addressComplement
            .replace(",", " ")
            .replace(/\s\s+/g, " ")
            .replace("#", "")
            .split(" ");
    
          if (address.length > 0) {
            address.forEach((item) => {
              newAddress += item + "+";
            });
            newAddress = newAddress.substring(0, newAddress.length - 1);
    
            const res = await axios.get(
              `https://maps.googleapis.com/maps/api/geocode/json?key=${process.env.REACT_APP_GOOGLE_MAPS_API_KEY}&components=country:CO&address=${newAddress}`
            );
    
            if (res.status === 200) {
              form.setFieldsValue({
                lat: res.data.results[0].geometry ? res.data.results[0].geometry.location.lat.toString() : '',
                lng: res.data.results[0].geometry ? res.data.results[0].geometry.location.lng.toString() : ''
              });
            }
            let sector = calculateGeoEnclosure({
                lat: res.data.results[0].geometry.location.lat,
                lng: res.data.results[0].geometry.location.lng
              }, sectors);
              setGeoClouser(sector);
          }
        }
      };

    return (
        <div
            style={{
                margin: '10px'
            }}
        >
            <Drawer
                title={`${clientName} - Direcciones`}
                width={window.innerWidth > 1200 ? 1100 : window.innerWidth - 100 }
                onClose={() => {
                    handleClear();
                    setShowDrawer(false);
                }}
                visible={showDrawer}
                bodyStyle={{ paddingBottom: 80 }}
                footer={
                    <div style={{ textAlign: 'right' }}>
                        <Button
                            type='ghost'
                            shape='round'
                            onClick={() => {
                                handleClear();
                                setShowDrawer(false);
                            }}
                        >
                            Cerrar
                        </Button>
                    </div>
                }
            >
                <Card
                    title={`Direcciones - ${isEditing ? 'Modificar' : 'Agregar'}`}
                    style={{ marginBottom: '10px', minHeight: '0%'}}
                    size='small'
                >
                    <Form
                        form={form}
                        name='configuration-clientAddresses'
                        onFinish={handleSave}
                    >
                        <Form.Item name='id' hidden>
                            <Input />
                        </Form.Item>
                        <Descriptions
                            style={{ marginBottom: '10px' }}
                            bordered 
                            size='small'
                            column={{ xxl: 2, xl: 2, lg: 2, md: 2, sm: 2, xs: 1 }}
                        >
                            <Descriptions.Item label='Nombre'>
                                <Form.Item 
                                    name='name'
                                    rules={[{ required: true, message: ''}]}
                                >
                                    <Input />
                                </Form.Item>
                            </Descriptions.Item>
                            <Descriptions.Item label='Contacto'>
                                <Form.Item 
                                    name='contactName'
                                    rules={[{ required: true, message: ''}]}
                                >
                                    <Input />
                                </Form.Item>
                            </Descriptions.Item>
                            <Descriptions.Item label='Teléfono'>
                                <Form.Item 
                                    name='phoneNumber'
                                    rules={[{ required: true, message: ''}]}
                                >
                                    <Input />
                                </Form.Item>
                            </Descriptions.Item>
                            <Descriptions.Item label='Celular'>
                                <Form.Item 
                                    name='cellPhoneNumber'
                                >
                                    <Input />
                                </Form.Item>
                            </Descriptions.Item>
                            <Descriptions.Item label='País'>
                                <Form.Item 
                                    name='country'
                                    rules={[{ required: true, message: ''}]}
                                >
                                    <Input />
                                </Form.Item>
                            </Descriptions.Item>
                            <Descriptions.Item label='Departamento'>
                                <Form.Item
                                        name='state'
                                        rules={[{ required: true, message: 'Seleccione el departamento' }]}
                                    >
                                        <Select
                                            placeholder='Seleccione...'
                                            allowClear
                                            style={{ width: '100%' }}
                                            onChange={(value, object) => {
                                                if (value !== undefined) {
                                                    destinationAutoComplet(value,form.getFieldValue("city"),form.getFieldValue("address"));
                                                    return form.setFieldsValue({ state : value })
                                                }
                                                return form.setFieldsValue({ state : null })
                                            }}
                                        >
                                            {
                                                statesList && statesList.map((item) => {
                                                    return <Select.Option value={item}>{item}</Select.Option>
                                                })
                                            }

                                        </Select>
                                </Form.Item>
                            </Descriptions.Item>
                            <Descriptions.Item label='Ciudad'>
                                <Form.Item 
                                    name='city'
                                    onChange={(value, object) =>{
                                        destinationAutoComplet(form.getFieldValue("state"),value.target.value,form.getFieldValue("address"));
                                    }}
                                    rules={[{ required: true, message: ''}]}
                                >
                                    <Input />
                                </Form.Item>
                            </Descriptions.Item>
                            <Descriptions.Item label='Dirección'>
                                <Form.Item 
                                    name='address'
                                    rules={[{ required: true, message: ''}]}
                                    onChange={(value, object) =>{
                                        destinationAutoComplet(form.getFieldValue("state"),form.getFieldValue("city"),value.target.value);
                                    }}
                                >
                                    <Input />
                                </Form.Item>
                            </Descriptions.Item>
                            <Descriptions.Item label="Latitud">
                                <Form.Item name='lat'>
                                    <Input />
                                </Form.Item>
                            </Descriptions.Item>
                            <Descriptions.Item label="Longitud">
                                <Form.Item name='lng'>
                                    <Input />
                                </Form.Item>
                            </Descriptions.Item>
                            <Descriptions.Item label='Complemento'>
                                <Form.Item 
                                    name='addressComplement'
                                >
                                    <Input />
                                </Form.Item>
                            </Descriptions.Item>
                            <Descriptions.Item label='Correo'>
                                <Form.Item 
                                    name='mail'
                                >
                                    <Input />
                                </Form.Item>
                            </Descriptions.Item>
                            <Descriptions.Item label='Dirección principal'>
                                <Form.Item name='principal' valuePropName='checked'>
                                    <Switch  />
                                </Form.Item>
                            </Descriptions.Item>
                            <Descriptions.Item label='Centro de distribución' >
                                <Form.Item
                                    labelAlign='left'
                                    hasFeedback
                                    name='defaultDistributionCenterId'
                                    
                                >
                                    <Select
                                        placeholder='Seleccione...'
                                        allowClear
                                        tyle={{ width: '100%' }}
                                        onChange={(value, object) => {
                                         
                                            if (value !== undefined) {
                                              
                                                setIsDefininedDistributionCenter(true);
                                                return form.setFieldsValue({ defaultDistributionCenterId : value, distributionCenter: object.children })
                                            }
                                            return form.setFieldsValue({ defaultDistributionCenterId : null, distributionCenter: "", distributionCenterPrincipal: false })
                                            
                                        }}
                                    >
                                        {
                                            distributionCenters.map((item) => {
                                                return <Select.Option value={item.id}>{item.name}</Select.Option>
                                            })
                                        }

                                    </Select>
                                </Form.Item>
                            </Descriptions.Item>
                            <Descriptions.Item label='Centro de distribución principal'>
                                <Form.Item name='distributionCenterPrincipal' valuePropName='checked'>
                                    <Switch disabled={!isDefininedDistributionCenter}/>
                                </Form.Item>
                            </Descriptions.Item>
                            <Descriptions.Item label='Zona' >
                                <Form.Item
                                    labelAlign='left'
                                    hasFeedback
                                    name='sectorId'
                                    
                                >
                                    <Select
                                        placeholder='Seleccione...'
                                        allowClear
                                        tyle={{ width: '100%' }}
                                        onChange={(value, object) => {
                                            setSelectedSector(sectors.find((item) => item.id === value).name);
                                            if (value !== undefined) {
                                
                                                return form.setFieldsValue({ sectorId : value, sector: object.children })
                                            }else{
                                                return form.setFieldsValue({ sectorId : null, sector: "" })
                                            }
                                        }}
                                    >
                                        {
                                            sectors.map((item) => {
                                                return <Select.Option value={item.id}>{item.name}</Select.Option>
                                            })
                                            
                                        }

                                    </Select>
                                </Form.Item>
                                {geoClouser?.name !== selectedSector && geoClouser?.id !== form.getFieldValue("sectorId") && geoClouser !== null && form.getFieldValue("lat") !== undefined && form.getFieldValue("lng") !== undefined ? (
                                    <Descriptions.Item>
                                    <Form.Item>
                                            <p style={{color:"red"}}>
                                            La ubicación se encuentra dentro de la zona {geoClouser?.name} pero esta no es la seleccionada
                                            </p>
                                    </Form.Item>
                                    </Descriptions.Item>
                                    ) : ("")

                                }
                            </Descriptions.Item>
                            <Descriptions.Item label='Forma de pago'>
                                <Form.Item
                                        name='defaultPaymentMethod'
                                    >
                                        <Select
                                            placeholder='Seleccione...'
                                            allowClear
                                            style={{ width: '100%' }}
                                            onChange={(value, object) => {
                                                if (value !== undefined) {
                                                    return form.setFieldsValue({ defaultPaymentMethod : value })
                                                }
                                                return form.setFieldsValue({ defaultPaymentMethod : null })
                                            }}
                                        >
                                            {
                                                paymentMethodsList && paymentMethodsList.map((item) => {
                                                    return <Select.Option value={item}>{item}</Select.Option>
                                                })
                                            }

                                        </Select>
                                </Form.Item>
                            </Descriptions.Item>
                            <Descriptions.Item label='Prioridad'>
                                <Form.Item
                                        name='defaultPriority'
                                    >
                                        <Select
                                            placeholder='Seleccione...'
                                            allowClear
                                            style={{ width: '100%' }}
                                            onChange={(value, object) => {
                                                if (value !== undefined) {
                                                    return form.setFieldsValue({ defaultPriority : value, priority: object.children })
                                                }
                                                return form.setFieldsValue({ defaultPriority : null, priority: '' })
                                            }}
                                        >
                                            {
                                                priorityList && priorityList.map((item) => {
                                                    return <Select.Option value={item.value}>{item.label}</Select.Option>
                                                })
                                            }

                                        </Select>
                                </Form.Item>
                            </Descriptions.Item>
                            <Descriptions.Item label='Habilitado'>
                                <Form.Item name='enabled' valuePropName='checked'>
                                    <Switch />
                                </Form.Item>
                            </Descriptions.Item>
                            <Descriptions.Item label="Comentarios">
                                <Form.Item name='note'>
                                    <Input />
                                </Form.Item>
                            </Descriptions.Item>
                        </Descriptions>
                        <Form.Item name='distributionCenter' hidden>
                            <Input />
                        </Form.Item>
                        <Form.Item name='sector' hidden>
                            <Input />
                        </Form.Item>
                        <Form.Item name='priority' hidden>
                            <Input />
                        </Form.Item>
                        <Form.Item>
                            <Space>
                                <Button 
                                    type='primary' 
                                    shape='round'
                                    htmlType='submit'
                                   
                                >
                                    {isEditing ? 'Modificar' : 'Agregar'}
                                </Button>
                                <Button
                                    type='ghost'
                                    shape='round'
                                    onClick={handleClear}
                                >
                                    Cancelar
                                </Button>
                            </Space>
                        </Form.Item>
                    </Form>
                </Card>
                <div>
                    <Table
                        columns={columns}
                        dataSource={clientAddresses}
                        size='small'
                        rowKey='id'
                        pagination={{ pageSize: 10 }}
                        scroll={{
                            x: 100
                        }}
                        onRow={(record) => {
                            return {
                                onClick: () => {
                                    setClickedRow(record.id);
                                    setIsDefininedDistributionCenter(record.defaultDistributionCenterId)
                                    handleEdit(record);
                                },
                            }
                        }}
                        rowClassName={(record) => ['mouse-row-enter', record.id === clickedRow ? 'table-row-color' : ""]}
                    />
                </div>
            </Drawer>
        </div>
    )
}
