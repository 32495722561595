import { gql } from "@apollo/client";

export const fetchClients = gql`
    query fetchClients ($companyId: uuid!) {
        clients (where: {companyId: {_eq: $companyId}}) {
            id
            nit
            name
            email
            enabled
            accountNumber
            documentType 
            currency 
            personType
            person
            typePerson
            clientAddresses {
                id
                name
                contactName
                phoneNumber
                cellPhoneNumber 
                state
                city
                address
                addressComplement
                mail
                principal
                defaultDistributionCenterId
                distributionCenterPrincipal
                note
                defaultPaymentMethod
                defaultPriority
                sectorId
                country
                lat
                lng
            }
        }
    }
`;

export const insertClient = gql`
    mutation insertClient($personType: String!, $person: String!, $documentType: String!, $nit: String!, $name: String!, $accountNumber: String, $currency: String, $email: String, $enabled: Boolean!, $companyId: uuid!, $typePerson: String!) {
        insert_clients(objects: {person: $person, personType: $personType, documentType: $documentType, nit: $nit, name: $name, accountNumber: $accountNumber, currency: $currency, email: $email, enabled: $enabled, companyId: $companyId, typePerson: $typePerson}) {
        affected_rows
            returning {
                id
            }
        }
    }
`;


export const updateClient = gql`
    mutation updateClient($id: uuid!, $person: String!, $personType: String!, $documentType: String!, $nit: String!, $name: String!, $accountNumber: String, $currency: String, $email: String, $enabled: Boolean!) {
        update_clients(_set: {person: $person, personType: $personType, documentType: $documentType, nit: $nit, name: $name, accountNumber: $accountNumber, currency: $currency, email: $email, enabled: $enabled}, where: {id: {_eq: $id}}) {
            affected_rows
        }
    }
`;

export const deleteClient = gql`
    mutation deleteClient($id: uuid!) {
        delete_clients(where: {id: {_eq: $id}}) {
            affected_rows
        }
        delete_clientAddresses(where: {clientId: {_eq: $id}}) {
            affected_rows
        }
    }  
`;

export const fetchClientAddresess = gql`
    query fetchClientAddresess($clientId: uuid!) {
        clientAddresses (where: {clientId: {_eq: $clientId}}) {
            id
            name
            contactName
            phoneNumber
            cellPhoneNumber
            state
            city
            address
            addressComplement
            mail
            principal
            defaultDistributionCenterId
            distributionCenterPrincipal
            note
            defaultPaymentMethod
            defaultPriority
            sectorId
            country
            lat
            lng
            enabled
            clientId
            clientAddressesDistributionCenter{
                name
            }
            clientAddressesSector{
                name
            }
        }
    }  
`;

export const insertClientAddresses = gql`
    mutation insertClientAddresses($name: String!, $contactName: String!, $phoneNumber: String!, $cellPhoneNumber: String, $state: String!, $city: String!, $address: String!, $addressComplement: String, $mail: String, $principal: Boolean, $defaultDistributionCenterId: uuid, $distributionCenterPrincipal: Boolean, $note: String, $defaultPaymentMethod: String, $defaultPriority: String, $sectorId: uuid, $country: String!, $lat: String, $lng: String, $enabled: Boolean, $clientId: uuid!) {
        insert_clientAddresses(objects: {name: $name, contactName: $contactName, phoneNumber: $phoneNumber, cellPhoneNumber: $cellPhoneNumber, state: $state, city: $city, address: $address, addressComplement: $addressComplement, mail: $mail, principal: $principal, defaultDistributionCenterId: $defaultDistributionCenterId, distributionCenterPrincipal: $distributionCenterPrincipal, note: $note, defaultPaymentMethod: $defaultPaymentMethod, defaultPriority: $defaultPriority, sectorId: $sectorId, country: $country, lat: $lat, lng: $lng, enabled: $enabled, clientId: $clientId}) {
            affected_rows
            returning {
                id
            }
        }
    }  
`;

export const updateClientAddresses = gql`
    mutation updateClientAddresses($id: uuid!, $name: String!, $contactName: String!, $phoneNumber: String!, $cellPhoneNumber: String, $state: String!, $city: String!, $address: String!, $addressComplement: String, $mail: String, $principal: Boolean, $defaultDistributionCenterId: uuid, $distributionCenterPrincipal: Boolean, $note: String, $defaultPaymentMethod: String, $defaultPriority: String, $sectorId: uuid, $country: String!, $lat: String, $lng: String, $enabled: Boolean!) {
        update_clientAddresses(_set: {name: $name, contactName: $contactName, phoneNumber: $phoneNumber, cellPhoneNumber: $cellPhoneNumber, state: $state, city: $city, address: $address, addressComplement: $addressComplement, mail: $mail, principal: $principal, defaultDistributionCenterId: $defaultDistributionCenterId, distributionCenterPrincipal: $distributionCenterPrincipal, note: $note, defaultPaymentMethod: $defaultPaymentMethod, defaultPriority: $defaultPriority, sectorId: $sectorId, country: $country, lat: $lat, lng: $lng,enabled: $enabled}, where: {id: {_eq: $id}}) {
            affected_rows
        }
    }  
`;

export const deleteClientAddresses = gql`
    mutation deleteClientAddresses($id: uuid!) {
        delete_clientAddresses(where: {id: {_eq: $id}}) {
            affected_rows
        }
    }
`;

export const fetchDistributionCenters  = gql`
    query fetchDistributionCenters ($companyId: uuid!) {
        distributionCenters (where: {companyId: {_eq: $companyId}, enabled: {_eq: true}}) {
            id
            name
        }
    }
`;

export const fetchSectors  = gql`
    query fetchSectors ($companyId: uuid!) {
        sectors (where: {companyId: {_eq: $companyId}, enabled: {_eq: true}}) {
            id
            name
            sectorPolygons{
                lat
                lng
                }
        }
    }
`;

export const updatePrincipal = gql`
    mutation updateClientAddresses($clientId: uuid!) {
        update_clientAddresses(_set: {principal: false}, where: {clientId: {_eq: $clientId}}) {
            affected_rows
        }
    }  
`;

export const updatedistributionCenterPrincipal = gql`
    mutation updateClientAddresses($clientId: uuid!, $defaultDistributionCenterId: uuid!) {
        update_clientAddresses(_set: {distributionCenterPrincipal: false}, where: {clientId: {_eq: $clientId}, _and: {defaultDistributionCenterId: {_eq: $defaultDistributionCenterId}}}) {
            affected_rows
        }
    }  
`;