import React from 'react';
import { useHistory } from 'react-router-dom';
import { Button, Divider, Row, Col, } from 'antd';
import styled from 'styled-components'
import { CloudUploadOutlined, FormOutlined, CloudDownloadOutlined } from '@ant-design/icons';
import Swal from 'sweetalert2';

const MainDiv = styled.div`
    line-height: 200px;
    text-align: center;
`;

export const MainUploadScreen = () => {
    const history = useHistory();

    return (
        <MainDiv className='children-inner-container-with-bg'>
            <Row>
                <Col xl={8} md={12} sd={12} xs={24} style={{marginTop: 20}}>
                <Button 
                    type="primary" 
                    shape="round" 
                    className='upload-button'
                    onClick={() => history.push('/upload-file')}
                    >
                    <CloudUploadOutlined style={{
                        fontSize: '100px',
                        marginTop: '0px',
                        marginBottom: '-20px',
                    }} />
                    <p
                    style={{
                        fontSize: '22px'
                    }}
                    >Cargar Excel</p>
                </Button>

                </Col>
                <Col xl={8} md={12} sd={12} xs={24} style={{marginTop: 20}}>
                <Button 
                    type="primary" 
                    shape="round" 
                    className='upload-button'
                    onClick={() => history.push('/manual-upload')}
                    >
                    <FormOutlined style={{
                        fontSize: '100px',
                        marginTop: '0px',
                        marginBottom: '-20px',
                    }} />
                    <p
                    style={{
                        fontSize: '22px'
                    }}
                    >Ingreso Manual</p>
                </Button>
                </Col>
                <Col xl={8} md={12} sd={12} xs={24} style={{marginTop: 20}}>
                <Button 
                    type="primary" 
                    shape="round" 
                    className='upload-button'
                    onClick={() => history.push('/TMSLoad')}
                    >
                    <CloudDownloadOutlined style={{
                        fontSize: '100px',
                        marginTop: '0px',
                        marginBottom: '-20px',
                    }} />
                    <p
                    style={{
                        fontSize: '22px'
                    }}
                    >Sincronizar Ordenes</p>
                </Button>
                </Col>
            </Row>
        </MainDiv>
    )
}
