import { gql } from "@apollo/client";

export const fetchSectors = gql`
    query fetchSectors ($companyId: uuid!){
        sectors(where: {companyId: {_eq: $companyId}},order_by: {name: asc}) {
            id
            name
            color
            enabled
            distributionCenterId
            sectorsDistributionCenter{
                name
            }
            sectorPolygons(order_by: {id: asc}) {
                id
                lat
                lng
            }
            ordersTable_aggregate {
                aggregate {
                    count(columns: sectorId)
                }
            }
        }
    }
`;

export const insertSector = gql`
    mutation insertSector($name: String!, $color: String!, $enabled: Boolean!, $companyId: uuid!) {
        insert_sectors(objects: {name: $name, color: $color, enabled: $enabled, companyId: $companyId}) {
            affected_rows
            returning {
                id
            }
        }
    }
`;

export const updateNameSector = gql`
    mutation updateNameSector($id: uuid!, $name: String!) {
        update_sectors(_set: {name: $name}, where: {id: {_eq: $id}}) {
            affected_rows
        }
    }
`;

export const updateColorSector = gql`
    mutation updateNameSector($id: uuid!, $color: String!) {
        update_sectors(_set: {color: $color}, where: {id: {_eq: $id}}) {
            affected_rows
        }
    }
`;

export const updateEnabledSector = gql`
    mutation updateEnabledSector($id: uuid!, $enabled: Boolean!) {
        update_sectors(_set: {enabled: $enabled}, where: {id: {_eq: $id}}) {
            affected_rows
        }
    }
`;

export const deleteSector = gql`
    mutation deleteSector($id: uuid!) {
        delete_sectors(where: {id: {_eq: $id}}) {
            affected_rows
        }
        
        delete_sectorsPolygons(where:{sectorId:{_eq: $id}}) {
            affected_rows
        }
    }
`;

export const insertSectorPolygon = gql`
    mutation insertSectorPolygon($lat: numeric!, $lng: numeric!, $sectorId: uuid!) {
        insert_sectorsPolygons(objects: {lat: $lat, lng: $lng, sectorId: $sectorId}) {
            affected_rows
            returning {
                id
            }
        }
    }
`;

export const updateSectorPolygon = gql`
    mutation updateSectorPolygon($id: bigint!, $lat: numeric!, $lng: numeric!) {
        update_sectorsPolygons(_set: {lat: $lat, lng: $lng}, where: {id: {_eq: $id}}) {
            affected_rows
        }
    }
`;

export const deletePolygon = gql`
    mutation deletePolygon($id: bigint!) {
        delete_sectorsPolygons(where: {id: {_eq: $id}}) {
            affected_rows
        }
    }
`;

export const fetchEnabledSectors = gql`
    query fetchEnabledSectors {
        sectors(order_by: {name: asc}, where: {enabled: {_eq: true}}) {
        id
        name
        color
        enabled
        sectorPolygons(order_by: {id: asc}) {
                id
                lat
                lng
            }
        }
    }
`;

export const fetchDistributionCenters  = gql`
    query fetchDistributionCenters ($companyId: uuid!) {
        distributionCenters (where: {companyId: {_eq: $companyId}, enabled: {_eq: true}}) {
            id
            name
        }
    }
`;