import React, { useState, useEffect } from 'react';
import { Result, Button, Form, Input, Spin } from 'antd';
import { useParams, useHistory } from 'react-router-dom';
import { useLazyQuery } from '@apollo/client';
import Swal from 'sweetalert2';
import axios from 'axios';

import '../styles/login.css';
import { fetchUserIsVerified } from '../../queries/userQuery';

export const ChangePasswordEmailScreen = () => {
    const [form] = Form.useForm();
    const history = useHistory();
    const { token } = useParams();

    const [getUser, dataUser] = useLazyQuery(fetchUserIsVerified, { fetchPolicy: 'no-cache' });

    const [tokenError, setTokenError] = useState();
    const [tokenDecoded, setTokenDecoded] = useState({});
    const [loading, setLoading] = useState(true);

    const [passwordChanged, setPasswordChanged] = useState(false);

    useEffect(() => {
        if (token !== undefined) {
            const verifyToken = async () => {
                const res = await fetch(process.env.REACT_APP_FUNCTION_VERIFY_TOKEN, {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify({
                        token
                    }),
                });

                const result = await res.json();

                if (res.status === 200) {
                    setTokenDecoded(result.statusText);
                    getUser({ variables: { id: result.statusText.id } });
                } else {
                    setTokenError(result.statusText);
                }

                setLoading(false);
            }

            verifyToken();
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [token]);

    const formItemLayout = {
        labelCol: {
            xs: { span: 24 },
            sm: { span: 12 },
            lg: { span: 10 }
        },
        wrapperCol: {
            xs: { span: 24 },
            sm: { span: 12 },
            lg: { span: 14 }
        },
    };

    const tailFormItemLayout = {
        wrapperCol: {
            xs: {
                span: 24,
                offset: 0,
            },
            sm: {
                span: 16,
                offset: 7,
            },
            lg: {
                span: 24,
                offset: 2,
            }
        },
    };

    const handleSubmit = (values) => {
        const { id, displayName, email } = tokenDecoded;
        const { password } = values;

        axios({
            method: 'post',
            url: process.env.REACT_APP_FUNCTION_RESTORE_PASSWORD_URL,
            data: {
                id,
                password,
                email
            }
        }).then((res) => {
            if (res.status === 200) {
                Swal.fire({
                    title: 'Contraseña cambiada',
                    text: `${displayName} has cambiado tu contraseña correctamente.`,
                    icon: 'success',
                    confirmButtonText: 'Cerrar',
                    willClose: () => {
                        setPasswordChanged(true);
                    }
                });
            } else {
                Swal.fire({
                    title: 'Error',
                    text: `${res.statusText}`,
                    icon: 'error',
                    confirmButtonText: 'Cerrar',
                });
            }
        }).catch((error) => {
            console.error(error)
            Swal.fire({
                title: 'Error',
                text: `${error.toString()}`,
                icon: 'error',
                confirmButtonText: 'Cerrar',
            });
        });
    }

    if (loading) {
        return (
            <center
                style={{
                    height: window.innerHeight,
                    display: "flex",
                    flexDirection: "row",
                    flexWrap: "wrap",
                    justifyContent: "center",
                    alignItems: "center",
                    textAlign: "center"
                }}
            >
                <Spin size='large' />
            </center>
        );
    }

    if (dataUser.loading) {
        return (
            <center
                style={{
                    height: window.innerHeight,
                    display: "flex",
                    flexDirection: "row",
                    flexWrap: "wrap",
                    justifyContent: "center",
                    alignItems: "center",
                    textAlign: "center"
                }}
            >
                <Spin size='large' />
            </center>
        )
    }

    if (tokenError !== undefined) {
        return (
            <div style={{
                height: '70vh',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
            }}>
                <Result
                    style={{
                        left: '50%',
                        top: '50%',

                    }}
                    status="500"
                    title={tokenError}
                    extra={[
                        <Button
                            key='result'
                            type="primary"
                            shape="round"
                            size="large"
                            onClick={() => history.replace('/')}
                        >
                            Aceptar
                        </Button>
                    ]}
                />
            </div>
        );
    }

    return (
        <div
            style={{
                height: '70vh',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
            }}
        >
            <Result
                icon={<img src="https://res.cloudinary.com/srf/image/upload/v1680285850/NAVITRANS_LOGOS_COLOR_boaoxt.png"  width="250" />}
                title={!passwordChanged ? `Bienvenido ${tokenDecoded.displayName}` : ''}
                subTitle={!passwordChanged ? "Proceso de recuperación de contraseña, por favor ingrese su nueva contraseña y confírmela.":''}
                extra={!passwordChanged ? [
                    <Form
                        key='form'
                        form={form}
                        name='verification'
                        onFinish={handleSubmit}
                        {...formItemLayout}
                    >
                        <Form.Item
                            label='Nueva contraseña'
                            name='password'
                            hasFeedback
                            rules={[
                                {
                                    required: true,
                                    message: 'Ingrese su nueva contraseña!',
                                },
                                ({ _ }) => ({
                                    validator(_, value) {
                                        if (value?.length < 6) {
                                            return Promise.reject('Mínimo 6 caracteres')
                                        }

                                        return Promise.resolve();
                                    },
                                }),
                            ]}
                        >
                            <Input.Password />
                        </Form.Item>
                        <Form.Item
                            label="Confirmar contraseña"
                            name="confirm"
                            dependencies={['password']}
                            hasFeedback
                            rules={[
                                {
                                    required: true,
                                    message: 'Confirme su contraseña',
                                },
                                ({ getFieldValue }) => ({
                                    validator(_, value) {
                                        if (!value || getFieldValue('password') === value) {
                                            return Promise.resolve();
                                        }
                                        if (value?.length < 6) {
                                            return Promise.reject('Mínimo 6 caracteres')
                                        }
                                        return Promise.reject('Las contraseñas ingresadas no coinciden!');
                                    },
                                }),
                            ]}
                        >
                            <Input.Password />
                        </Form.Item>
                        <Form.Item {...tailFormItemLayout}>
                            <Button type="primary" htmlType="submit" shape='round' size='large' style={{backgroundColor: '#D10505', borderColor: '#D10505'}}>
                                Aceptar
                            </Button>
                        </Form.Item>
                    </Form>
                ]: <h1>Puedes cerrar esta pagina, Ingresa a la aplicación con tu nueva contraseña</h1>}
            />
        </div>
    );


};
