export const statesList = [
    'Antioquia',
    'Atlántico',
    'Bogotá',
    'Bolivar',
    'Boyacá',
    'Caldas',
    'Caquetá',
    'Cauca',
    'Cesar',
    'Córdoba',
    'Cundinamarca',
    'Chocó',
    'Huila',
    'La Guajira',
    'Magdalena',
    'Meta',
    'Nariño',
    'Norte de Santander',
    'Quindío',
    'Risaralda',
    'Santander',
    'Sucre',
    'Tolima',
    'Valle del Cauca',
    'Arauca',
    'Casanare',
    'Putumayo',
    'San Andres',
    'Amazonas',
    'Guainía',
    'Guaviare',
    'Vaupés',
    'Vichada'
];

export const paymentMethodsList = [
    'Efectivo',
    'Crédito'
];

export const priorityList = [
    {
        value: 'a_high',
        label: 'Alto'
    },
    {
        value: 'b_medium',
        label: 'Medio'
    },
    {
        value: 'c_low',
        label: 'Bajo'
    }
];

export const personList = [
    {
        value: 'customer',
        label: 'Cliente'
    },
    {
        value: 'supplier',
        label: 'Proveedor'
    }
];

export const personTypeList = [
    {
        value: 'person',
        label: 'Persona natural'
    },
    {
        value: 'organization',
        label: 'Persona jurídica'
    }
];

export const documentTypeList = [
    'Registro civil de nacimiento',
    'Tarjeta de identidad',
    'Cédula de ciudadanía',
    'Tarjeta de extranjería',
    'Cédula de extranjería',
    'NIT',
    'Pasaporte',
    'Tipo de documento extranjero'
];

export const currencyList = [
    'Pesos colombianos',
    'Dólares estadounidenses'
];