import { gql } from "@apollo/client";

export const fetchUploadDataTableControllerOrdersTable = gql`
    query fetchUploadDataTableControllerOrdersTable {
        uploadDataTableController(where: {enabled: {_eq: true}, _and: {tableName: {_eq: "ordersTable"}}}) {
            id
            tableName
            child
            displayName
            uploadDataFields(where: {enabled: {_eq: true}}, order_by: {sort: asc}) {
                id
                fieldName
                label
                mandatory
                dataType
                pkTable
                pkTableSearchField
                pkReturnedField
                minValue
                maxValue
                isUnique
                columnWidth
            }
        }
    }
`;

export const fetchUploadDataTableControllerOrdersLine = gql`
    query fetchUploadDataTableControllerOrdersLine {
        uploadDataTableController(where: {enabled: {_eq: true}, _and: {tableName: {_eq: "ordersLine"}}}) {
            id
            tableName
            child
            displayName
            uploadDataFields(where: {enabled: {_eq: true}}, order_by: {sort: asc}) {
                id
                fieldName
                label
                mandatory
                dataType
                pkTable
                pkTableSearchField
                pkReturnedField
                minValue
                maxValue
                isUnique
                columnWidth
            }
        }
    }
`;
export const insertRoute = gql`
    mutation insertRoute($userId: uuid!, $batch: uuid!, $routeOrder: Int!, $orderTableId: uuid!, $createByUserId: uuid!, $orderInVehicleTempId: uuid) {
        insert_routes(objects: {userId: $userId, batch: $batch, routeOrder: $routeOrder, orderTableId: $orderTableId, createByUserId: $createByUserId, orderInVehicleTempId: $orderInVehicleTempId}) {
            affected_rows
            returning {
                id
            }
        }
    }
`;
export const deleteOrdersLineBelow = gql`
mutation deleteOrdersLineBelow($id: uuid!) {
    delete_ordersLine(where: {id: {_eq: $id}}) {
      affected_rows
    }
  }
  `; 
export const fetchOrders = gql`
query fetchOrders ($id: uuid) {
    ordersTable(where:{id:{ _eq: $id}, _and:{sector:{enabled: {_eq: true}}}}, order_by: {orderNumber: asc}) {
        id
        orderNumber
        address
        addressComplement
        custFullName
        custPhoneNumber
        paymentMethod
        totalOrderAmount
        custEmail
        priority
        custIdentificationNumber
        destination
        sectorId
        sector {
            name
            color
        }
        ordersLines{
            id
            productNumber
            productName
            orderedQuantity
        }
        inUse
        weight
        height
        width
        depth
        city
        state
        notes
        distributionCenterId
        expectedDeliveryStartTime
        expectedDeliveryEndTime
        customerSupplierId
        addressBookId
        consecutiveBill
        consecutiveRemission
        consecutiveTRM
        consecutiveSaleOrder
        consecutiveShipping
        consecutiveBurden
        type
        cubicMeters
        orderedQuantity
        packageId
        packageContent
        destinationDistributionCenterId
        paymentOrCashOnDeliveryRequired
        enableDeliveryWindow
        totalCubicMeters
        enablePackageDimensions
        linesDetail
    }
}
`;

export const OrdeNumberEdit = gql`
query OrdeNumberEdit {
    ordersTable(where: {sector: {enabled: {_eq: true}}}, order_by: {orderNumber: asc}) {
        id
        orderNumber
    }
}
`;

export const deleteOrdersLine = gql`
mutation deleteOrdersLine($deleteArray: [uuid!]!) {
    delete_ordersLine(where: {id: {_in: $deleteArray}}) {
      affected_rows
    }
  }
`;
export const updateOrdersTable = gql`
mutation updateOrdersTable($id: uuid!,$address: String!, $addressComplement: String, $custIdentificationNumber: String!, $custFullName: String!, $custPhoneNumber: String, $paymentMethod: String, $totalOrderAmount: Float, $destination: String!, $weight: numeric!, $orderNumber: String!, $sectorId: uuid!, $custEmail: String, $priority: String!) {
    update_ordersTable(_set: {address: $address, addressComplement: $addressComplement, custIdentificationNumber: $custIdentificationNumber, custFullName: $custFullName, custPhoneNumber: $custPhoneNumber, paymentMethod: $paymentMethod, totalOrderAmount: $totalOrderAmount, destination: $destination, weight: $weight, orderNumber: $orderNumber, sectorId: $sectorId, custEmail: $custEmail, priority: $priority}, where: {id: {_eq: $id}}) {
      returning {
        id
      }
      affected_rows
    }
  }
  `;
export const fetchSectors = gql`
    query fetchSectors {
        sectors(where:{enabled:{_eq: true}}) {
            id
        name
        sectorPolygons{
        lat
        lng
        }
        }
    }
`;
export const fetchPaymentMethods = gql`
query fetchPaymentMethods {
    paymentMethods {
        id
         name
      companyId
    }
}
`;

export const fetchSectorss  = gql`
    query fetchSectors ($companyId: uuid!) {
        sectors (where: {companyId: {_eq: $companyId}, enabled: {_eq: true}}) {
            id
            name
        }
    }
`;

export const fetchCustomers = gql`
    query fetchCustomers($companyId: uuid!) {
        clients(where:{enabled:{_eq: true}, companyId: {_eq: $companyId}}) {
            personType
            nit
            name
            id
            documentType
            currency
            person
        }
    }
`;

export const fetchWarehouses = gql`
    query fetchWarehouses($companyId: uuid!) {
        distributionCenters(where:{enabled:{_eq: true}, companyId: {_eq: $companyId}}) {
            name
            id
            state
            city
            address
            addressComplement
            lat
            lng
            contactName
            phone
            email
            sectorId
        }
    }
`;
export const fetchClientAddresess = gql`
    query fetchClientAddresess($clientId: uuid!) {
        clientAddresses (where: {clientId: {_eq: $clientId}}) {
            id
            name
            contactName
            phoneNumber
            cellPhoneNumber
            state
            city
            address
            addressComplement
            mail
            principal
            defaultDistributionCenterId
            distributionCenterPrincipal
            note
            defaultPaymentMethod
            defaultPriority
            sectorId
            country
            lat
            lng
            enabled
            clientId
            clientAddressesDistributionCenter{
                name
            }
            clientAddressesSector{
                name
            }
        }
    }  
`;
export const fetchAddressBook = gql`
    query fetchAddressBook($clientId: uuid!) {
        clientAddresses(where:{enabled:{_eq: true}, clientId: {_eq: $clientId}}) {
            id
            name
            contactName
            cellPhoneNumber
            state
            city
            address
            addressComplement
            lat
            lng
            principal
            defaultDistributionCenterId
            distributionCenterPrincipal
            note
            defaultPaymentMethod
            defaultPriority
            sectorId
            mail
        }
    }
`;


export const fetchPackages = gql`
    query fetchPackages($companyId: uuid!) {
        packages(where:{enabled:{_eq: true}, companyId: {_eq: $companyId}}) {
            id
            name
            height
            width
            depth
            safetyFactor
            defaultWeight
            maxWeight
            maxStackingWeight
            principal
            defaultItemName
            defaultItemNumber
            cubicMeters
        }
    }
`;





export const insertOrdersTable = gql`
    mutation insertOrdersTable($address: String!, $addressComplement: String, $custIdentificationNumber: String!, $custFullName: String!, $custPhoneNumber: String, $paymentMethod: String, $totalOrderAmount: Float, $destination: String!, $height: numeric, $width: numeric, $depth: numeric, $weight: numeric!, $orderNumber: String!, $sectorId: uuid!, $custEmail: String, $priority: String!, $city: String, $state: String, $notes: String, $distributionCenterId: uuid, $companyId: uuid, $expectedDeliveryStartTime: timestamptz, $expectedDeliveryEndTime: timestamptz, $customerSupplierId: uuid, $addressBookId: uuid, $consecutiveBill: String, $consecutiveRemission: String, $consecutiveTRM: String, $consecutiveSaleOrder: String, $consecutiveShipping: String, $consecutiveBurden: String, $type: String, $cubicMeters: numeric, $orderedQuantity: numeric, $packageId: uuid, $packageContent: String, $destinationDistributionCenterId: uuid, $linesDetail: Boolean, $paymentOrCashOnDeliveryRequired: Boolean, $enableDeliveryWindow: Boolean, $totalCubicMeters: numeric, $enablePackageDimensions: Boolean, $createByUserId: uuid, $typePerson: String) {
        insert_ordersTable(objects: {address: $address, addressComplement: $addressComplement, custIdentificationNumber: $custIdentificationNumber, custFullName: $custFullName, custPhoneNumber: $custPhoneNumber, paymentMethod: $paymentMethod, totalOrderAmount: $totalOrderAmount, destination: $destination, height: $height, width: $width, depth: $depth, weight: $weight, orderNumber: $orderNumber, sectorId: $sectorId, custEmail: $custEmail, priority: $priority, city: $city, state: $state, notes: $notes, distributionCenterId: $distributionCenterId, companyId: $companyId, expectedDeliveryStartTime: $expectedDeliveryStartTime, expectedDeliveryEndTime: $expectedDeliveryEndTime, customerSupplierId: $customerSupplierId, consecutiveBill: $consecutiveBill, consecutiveRemission: $consecutiveRemission, consecutiveTRM: $consecutiveTRM, consecutiveSaleOrder: $consecutiveSaleOrder, consecutiveShipping: $consecutiveShipping, consecutiveBurden: $consecutiveBurden, addressBookId: $addressBookId, type: $type, cubicMeters: $cubicMeters, orderedQuantity: $orderedQuantity, packageId: $packageId, packageContent: $packageContent, destinationDistributionCenterId: $destinationDistributionCenterId, linesDetail: $linesDetail, paymentOrCashOnDeliveryRequired: $paymentOrCashOnDeliveryRequired, enableDeliveryWindow: $enableDeliveryWindow, totalCubicMeters: $totalCubicMeters, enablePackageDimensions: $enablePackageDimensions, createByUserId : $createByUserId, typePerson : $typePerson}) {
            returning {
                id
            }
            affected_rows
        }
    }
`;

export const insertOrdersTableNoLines = gql`
    mutation insertOrdersTableNoLines($address: String, $addressComplement: String, $custIdentificationNumber: String, $custFullName: String, $custPhoneNumber: String, $paymentMethod: String, $totalOrderAmount: Float, $destination: String, $height: numeric, $width: numeric, $depth: numeric, $weight: numeric, $orderNumber: String, $sectorId: uuid, $custEmail: String, $priority: String, $city: String, $state: String, $notes: String, $distributionCenterId: uuid, $companyId: uuid, $expectedDeliveryStartTime: timestamptz, $expectedDeliveryEndTime: timestamptz, $customerSupplierId: uuid, $addressBookId: uuid, $consecutiveBill: String, $consecutiveRemission: String, $consecutiveTRM: String, $consecutiveSaleOrder: String, $consecutiveShipping: String, $consecutiveBurden: String, $type: String, $cubicMeters: numeric, $orderedQuantity: numeric, $packageId: uuid, $packageContent: String, $destinationDistributionCenterId: uuid, $linesDetail: Boolean, $paymentOrCashOnDeliveryRequired: Boolean, $enableDeliveryWindow: Boolean, $totalCubicMeters: numeric, $enablePackageDimensions: Boolean, $createByUserId: uuid, $typePerson: String) {
        insert_ordersTable(objects: {address: $address, addressComplement: $addressComplement, custIdentificationNumber: $custIdentificationNumber, custFullName: $custFullName, custPhoneNumber: $custPhoneNumber, paymentMethod: $paymentMethod, totalOrderAmount: $totalOrderAmount, destination: $destination, height: $height, width: $width, depth: $depth, weight: $weight, orderNumber: $orderNumber, sectorId: $sectorId, custEmail: $custEmail, priority: $priority, city: $city, state: $state, notes: $notes, distributionCenterId: $distributionCenterId, companyId: $companyId, expectedDeliveryStartTime: $expectedDeliveryStartTime, expectedDeliveryEndTime: $expectedDeliveryEndTime, customerSupplierId: $customerSupplierId, consecutiveBill: $consecutiveBill, consecutiveRemission: $consecutiveRemission, consecutiveTRM: $consecutiveTRM, consecutiveSaleOrder: $consecutiveSaleOrder, consecutiveShipping: $consecutiveShipping, consecutiveBurden: $consecutiveBurden, addressBookId: $addressBookId, type: $type, cubicMeters: $cubicMeters, orderedQuantity: $orderedQuantity, packageId: $packageId, packageContent: $packageContent, destinationDistributionCenterId: $destinationDistributionCenterId, linesDetail: $linesDetail, paymentOrCashOnDeliveryRequired: $paymentOrCashOnDeliveryRequired, enableDeliveryWindow: $enableDeliveryWindow, totalCubicMeters: $totalCubicMeters, enablePackageDimensions: $enablePackageDimensions, createByUserId : $createByUserId, typePerson : $typePerson}) {
            returning {
                id,
                orderNumber
            }
            affected_rows
        }
    }
`;

export const insertOrdersLine = gql`
    mutation insertOrdersLine($orderNumber: String!, $productNumber: String!, $productName: String!, $orderedQuantity: Int!, $initialQuantity: Int!) {
        insert_ordersLine(objects: {orderNumber: $orderNumber, productNumber: $productNumber, productName: $productName, orderedQuantity: $orderedQuantity, initialQuantity: $initialQuantity}) {
            returning {
                id
            }
            affected_rows
        }
    }
`;

export const fetchOrderByOrderNumber = gql`
    query fetchOrderByOrderNumber($orderNumber: String!) {
        ordersTable(where: {orderNumber: {_eq: $orderNumber}}) {
            id
        }
    }
`;

export const fetchOrdersLineByProductNumber = gql`
    query fetchOrdersLineByProductNumber($productNumber: String!) {
        ordersLine(where: {productNumber: {_eq: $productNumber}}) {
            id
        }
    }
`;
export const fetchUsers = gql`
query fetchUsers($id: uuid) {
    users(where:{id:{_eq: $id}}) {
        id
        distributionCenterId 
    }
}
`;
export const checkIfIdentificationNumberExists = gql`
  query checkIfIdentificationNumberExists($identificationNumber: String!) {
    users(where: { identificationNumber: { _eq: $identificationNumber } }) {
      id
    }
  }
`;
export const checkIfEmailExists = gql`
  query checkIfEmailExists($email: String!) {
    users(where: { email: { _eq: $email } }) {
      id
    }
  }
`;
export const fetchRoles = gql`
  query fetchRoles {
    roles {
      id
      name
      label
    }
  }
`;
export const fetchDistributionCenters = gql`
  query fetchDistributionCenters($companyId: uuid!) {
    distributionCenters(
      where: { companyId: { _eq: $companyId }, enabled: { _eq: true } }
    ) {
      id
      name
    }
  }
`;
export const fetchDistributionCenterss  = gql`
    query fetchDistributionCenters ($companyId: uuid!) {
        distributionCenters (where: {companyId: {_eq: $companyId}, enabled: {_eq: true}}) {
            id
            name
        }
    }
`;

export const fetchFields = gql`
    query fetchFields($tableName: String!) {
        uploadDataTableController(where: {tableName: {_eq: $tableName}}) {
            id
            tableName
            uploadDataFields {
                id
                fieldName
                mandatory
                dataType
                minValue
                maxValue
            }
        }
    }
`;
export const packages = gql`
query packages {
    packages(order_by: {id: asc}) {
      id
      name
    }
  }
  `;
  export const sectors = gql`
  query sectors {
    sectors(order_by: {id: asc}) {
      id
      name
    }
  }
  `;
  export const customersSuppliers = gql`
  query customersSuppliers {
    customersSuppliers(order_by: {id: asc}) {
      id
      name
    }
  }
  `;
  export const companies = gql`
  query companies {
    companies(order_by: {id: asc}) {
      id
      name
    }
  }
  `;
  export const distributionCenters = gql`
  query distributionCenters {
    distributionCenters(order_by: {id: asc}) {
      id
      name
    }
  }
  `;
  export const addressBook = gql`
  query addressBook {
    addressBook(order_by: {id: asc}) {
      id
      name
    }
  }
  `;
  export const fetchClients = gql`
    query fetchClients ($companyId: uuid!) {
        clients (where: {companyId: {_eq: $companyId}}) {
            id
            nit
            name
            email
            enabled
            accountNumber
            documentType 
            currency 
            personType
            person
            typePerson
            clientAddresses {
                id
                name
                contactName
                phoneNumber
                cellPhoneNumber 
                state
                city
                address
                addressComplement
                mail
                principal
                defaultDistributionCenterId
                distributionCenterPrincipal
                note
                defaultPaymentMethod
                defaultPriority
                sectorId
                country
                lat
                lng
            }
        }
    }
`;


export const insertClient = gql`
    mutation insertClient($personType: String!, $person: String!, $documentType: String!, $nit: String!, $name: String!, $accountNumber: String, $currency: String, $email: String, $enabled: Boolean!, $companyId: uuid!, $typePerson: String!) {
        insert_clients(objects: {person: $person, personType: $personType, documentType: $documentType, nit: $nit, name: $name, accountNumber: $accountNumber, currency: $currency, email: $email, enabled: $enabled, companyId: $companyId, typePerson: $typePerson}) {
        affected_rows
            returning { 
                id
                name
            }
        }
    }
`;

export const updateuploadDataFieldsController = gql`
mutation updateuploadDataFieldsController($id: uuid!, $mandatory : Boolean!, $minValue: Int!, $maxValue: Int!) {
    update_uploadDataFieldsController(_set: {mandatory: $mandatory,minValue: $minValue, maxValue: $maxValue}, where: {id: {_eq: $id}}) {
        affected_rows
    }
}
`;

export const searchClients = gql`
query searchClients {
    clients {
      id
      name
      person
      email
      documentType
    }
  }
`;

export const checkClientExists = gql`
query checkClientExists($nit: String!) {
    clients(where: {nit: {_eq: $nit}}) {
        id
        nit
        name
        typePerson
    }
}
`;

export const updateTypePerson = gql`
mutation updateTypePerson($nit: String!, $typePerson: String!, $email: String!) {
    update_clients(where: {nit: {_eq: $nit}}, _set: {typePerson: $typePerson, email: $email}) {
      returning {
        id
        nit
        typePerson

      }
    }
  }
  `;