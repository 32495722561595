import React, { useState, useEffect } from 'react';
import { Col, Row, Button, message, Upload } from 'antd';
import { ChromePicker } from 'react-color';
import { BgColorsOutlined, UndoOutlined, SaveOutlined, LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import { useMutation } from '@apollo/client';
import { 
    updateBrandingColor
} from '../../queries/brandingQueries';

import { ConfigurationMoreSettings } from '../../../ConfigurationMoreSettings';

import localStorageService from '../../../../../services/localStorageService';
const companyId = localStorageService.get('companyId');
const companyBrand = JSON.parse(localStorageService.get('companyDetails'));

const getBase64 = (img, callback) => {
    const reader = new FileReader();
    reader.addEventListener('load', () => callback(reader.result));
    reader.readAsDataURL(img);
};

const beforeUpload = (file) => {
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
    if (!isJpgOrPng) {
        message.error('Solo se permite la carga de archivos en formato JPG/PNG file!');
    }

    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
        message.error('La imagen debe ser de máximo 2MB!');
    }
    return isJpgOrPng && isLt2M;
};

export const BrandingScreen = () => {
    const [updBrandingColor] = useMutation(updateBrandingColor, { fetchPolicy: 'no-cache' });

    const [companyColor, setCompanyColor] = useState('#fff');
    const [newCompanyColor, setNewCompanyColor] = useState('#fff');
    const [displayColorPicker, setDisplayColorPicker] = useState( false);

    const [loading, setLoading] = useState(false);
    const [imageUrl, setImageUrl] = useState(companyBrand.bigBrand);

    useEffect(() => {
        if(companyBrand && companyBrand.primaryColor)
            setCompanyColor(companyBrand.primaryColor)
            setNewCompanyColor(companyBrand.primaryColor)
    }, []);

    const handleClick = () => {
        setDisplayColorPicker(true);
    };

    const handleClose = () => {
        setDisplayColorPicker(false);
    };

    const handleChange = (info) => {
        if (info.file.status === 'uploading') {
            setLoading(true);
            return;
        }
        
        if (info.file.status === 'done') {
          // Get this url from response in real world.
            getBase64(info.file.originFileObj, (url) => {
                setLoading(false);
                setImageUrl(url);
            });
        }
    };

    const uploadButton = (
        <div>
            {loading ? <LoadingOutlined /> : <PlusOutlined />}
            <div
                style={{
                    marginTop: 8,
                }}
            >
            Upload
            </div>
        </div>
    );

    const popover = {
        position: 'absolute',
        zIndex: '2',
    }
    
    const cover = {
        position: 'fixed',
        top: '0px',
        right: '0px',
        bottom: '0px',
        left: '0px',
    }

    const resetCompanyColor = () => {
        setNewCompanyColor(companyColor);
    }
    
    const saveCompanyColor = async () => {
        const res = await updBrandingColor({
            variables: {
                primaryColor: newCompanyColor,
                id: companyId
            }
        });

        if(res.data.update_companies.affected_rows > 0) {
            companyBrand.primaryColor = newCompanyColor;
            localStorageService.set('companyDetails', JSON.stringify(companyBrand))
            document.documentElement.style.setProperty('--primaryColor', companyBrand.primaryColor);
            setCompanyColor(newCompanyColor)
        }
    }

    return (
        <Col className='children-inner-container-with-bg'>
            <ConfigurationMoreSettings />
            <br /><br />
            <h3>Colores</h3>
            <Row gutter={[25, 25]}>
                <Col xs={24} sm={12} xl={12}>
                    <h4>Color actual</h4>
                    <div style={{minHeight: '100px', backgroundColor: companyColor}}>
                        
                    </div>
                </Col>
                <Col xs={24} sm={12} xl={12}>
                    <h4>Nuevo color</h4>
                    <div style={{minHeight: '100px', backgroundColor: newCompanyColor, display: 'flex', alignItems: 'center'}}>
                        <Button style={{position: 'relative', margin: '0 auto'}} onClick={ handleClick } shape="circle" icon={<BgColorsOutlined />} />
                        {displayColorPicker ? <div style={ popover }>
                            <div style={ cover } onClick={ handleClose }/>
                            <ChromePicker
                                onChangeComplete={(color) => {setNewCompanyColor(color.hex)}}
                                disableAlpha={true}
                                color={ newCompanyColor }
                            />
                        </div> : null }
                        
                        {newCompanyColor !== companyColor ? <Button style={{position: 'relative', margin: '0 auto'}} onClick={ resetCompanyColor } shape="circle" icon={<UndoOutlined />} /> : null}

                        {newCompanyColor !== companyColor ? <Button style={{position: 'relative', margin: '0 auto'}} onClick={ saveCompanyColor } shape="circle" icon={<SaveOutlined />} /> : null}
                    </div>
                </Col>
            </Row>

             <br /><br />
            <h3>Imágenes</h3>
            <Row gutter={[25, 25]}>
                <Col xs={24} sm={12} xl={12}>
                    <h4>Logo completo</h4>
                    <Upload
                        name="avatar"
                        listType="picture-card"
                        className="avatar-uploader"
                        showUploadList={false}
                        action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
                        beforeUpload={beforeUpload}
                        onChange={handleChange}
                        >
                        {imageUrl ? (
                            <img
                            src={imageUrl}
                            alt="avatar"
                            style={{
                                width: '100%',
                            }}
                            />
                        ) : (
                            uploadButton
                        )}
                    </Upload>
                </Col>
                <Col xs={24} sm={12} xl={12}>
                    <h4>Identificador</h4>
                    <div>
                        <img src={companyBrand.smallBrand} style={{maxWidth: '100px', height: 'auto'}} />
                    </div>
                </Col>
            </Row> 
        </Col>
    );
}
