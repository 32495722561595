import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useLazyQuery, gql, useApolloClient } from '@apollo/client';
import { Button, Steps, Upload, Table, Tag, Drawer, Divider, Typography, Progress, Row, Col } from 'antd';
import { UploadOutlined, CheckCircleTwoTone, CloseCircleTwoTone, CodepenCircleFilled } from '@ant-design/icons';
import Papaparse from 'papaparse';
import liluuid from 'lil-uuid';
import Swal from 'sweetalert2';
import axios from 'axios';
import loggerCooltrack from '../../../../services/logger-cooltrack';

import { isValidLatLng, getKeyByValue } from '../../../../helpers/utils';
import {
    fetchUploadDataTableControllerOrdersTable,
    fetchUploadDataTableControllerOrdersLine
} from '../../queries/uploadData';
import { When } from '../../../shared/When';
import {ExcelGenerator} from '../screens/ExcelGenerator';
import localStorageService from "../../../../services/localStorageService";
import moment from 'moment';
import 'moment/locale/es';
const companyId = localStorageService.get("companyId");

export const UploadDataScreen = () => {
    const apolloClient = useApolloClient();
    const history = useHistory();

    const [progressOrdersTablePercent, setProgressOrdersTablePercent] = useState(0);
    const [progressOrdersLinePercent, setProgressOrdersLinePercent] = useState(0);
    const [finishedSteps, setFinishedSteps] = useState(false);

    const [visibleDrawerLines, setVisibleDrawerLines] = useState(false);
    const [currentStep, setCurrentStep] = useState(0);
    const [orderToShow, setOrderToShow] = useState('');

    const [selectedFileOrdersTable, setSelectedFileOrdersTable] = useState([]);
    const [selectedFileOrdersLine, setSelectedFileOrdersLine] = useState([]);

    const [errosOnOrdersTable, setErrosOnOrdersTable] = useState(false);
    const [errosOnOrdersLine, setErrosOnOrdersLine] = useState(false);

    const [ordersTableColumns, setOrdersTableColumns] = useState([]);
    const [ordersTableData, setOrdersTableData] = useState([]);
    const [ordersLineColumns, setOrdersLineColumns] = useState([]);
    const [ordersLineData, setOrdersLineData] = useState([]);

    const [ordersTable, setOrdersTable] = useState([]);
    const [ordersLine, setOrdersLine] = useState([]);
    const [ordersTableandLine, setOrdersTableandLine] = useState('');
    const [addressSearchProgress, setAddressSearchProgress] = useState(0);
    const [sequenceOrderNumber, setSequenceOrderNumber] = useState("");

    const [getUloadDataTableControllerOrdersTable, dataUploadDataTableControllerOrdersTable] = useLazyQuery(fetchUploadDataTableControllerOrdersTable, { fetchPolicy: 'no-cache' });
    const [getUploadDataTableControllerOrdersLine, dataUploadDataTableControllerOrdersLine] = useLazyQuery(fetchUploadDataTableControllerOrdersLine, { fetchPolicy: 'no-cache' });

    useEffect(() => {
        getUloadDataTableControllerOrdersTable();
        getUploadDataTableControllerOrdersLine();

        // setOrdersTableColumns(JSON.parse(sessionStorage.getItem("ordersTableColumns")) ?? []);
        // setOrdersTableData(JSON.parse(sessionStorage.getItem("ordersTableData")) ?? []);
        // setOrdersLineColumns(JSON.parse(sessionStorage.getItem("ordersLineColumns")) ?? []);
        // setOrdersLineData(JSON.parse(sessionStorage.getItem("ordersLineData")) ?? []);
        // setErrosOnOrdersTable(sessionStorage.getItem("errosOnOrdersTable") ?? false);
        // setErrosOnOrdersLine(sessionStorage.getItem("errosOnOrdersLine") ?? false);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (!dataUploadDataTableControllerOrdersTable.loading && dataUploadDataTableControllerOrdersTable.data && dataUploadDataTableControllerOrdersTable.data.uploadDataTableController.length > 0) {
            setOrdersTable({
                id: dataUploadDataTableControllerOrdersTable.data.uploadDataTableController[0].id,
                tableName: dataUploadDataTableControllerOrdersTable.data.uploadDataTableController[0].tableName,
                uploadDataFields: dataUploadDataTableControllerOrdersTable.data.uploadDataTableController[0].uploadDataFields
            });
        }
    }, [dataUploadDataTableControllerOrdersTable.loading, dataUploadDataTableControllerOrdersTable.data]);

    useEffect(() => {
        if (!dataUploadDataTableControllerOrdersLine.loading && dataUploadDataTableControllerOrdersLine.data && dataUploadDataTableControllerOrdersLine.data.uploadDataTableController.length > 0) {
            setOrdersLine({
                id: dataUploadDataTableControllerOrdersLine.data.uploadDataTableController[0].id,
                tableName: dataUploadDataTableControllerOrdersLine.data.uploadDataTableController[0].tableName,
                uploadDataFields: dataUploadDataTableControllerOrdersLine.data.uploadDataTableController[0].uploadDataFields
            });
        }
    }, [dataUploadDataTableControllerOrdersLine.loading, dataUploadDataTableControllerOrdersLine.data]);

    const handlePrev = () => {
        setCurrentStep(currentStep - 1);
    };

    const handleNext = () => {
        setCurrentStep(currentStep + 1);
    };

    const sortByAlph = (a, b) => {
        if (a > b) return -1;

        if (a < b) return 1;

        return 0;
    }
    const autoOrdeNumber = async () => {
        const response = await axios.post(process.env.REACT_APP_FUNCTION_GET_SEQUENCE_CODE_URL, { companyId, sequenceTypeId: "orders" });
        return response.data.sequence;
    };
    const step01 = (
        <div>
            <Upload
                name='header'
                accept='.csv,.txt'
                multiple={false}
                onChange={(value) => {
                    let last = [];

                    if (value.fileList.length === 1) {
                        last = value.fileList[value.fileList.length];
                    } else if (value.fileList.length > 1) {
                        last = [value.fileList[value.fileList.length - 1]];
                    } else {
                        last = value.fileList;
                    }

                    setSelectedFileOrdersTable(last);
                }}
                onClick={() => {
                    setErrosOnOrdersTable(false);
                    setErrosOnOrdersLine(false);
                    setSelectedFileOrdersTable([]);
                    setSelectedFileOrdersLine([]);
                    setOrdersTableColumns([]);
                    setOrdersLineColumns([]);
                    setOrdersTableData([]);
                    setOrdersLineData([]);
                }}
                fileList={selectedFileOrdersTable}
                beforeUpload={file => {
                    const reader = new FileReader();

                    reader.onload = async (e) => {
                        const result = Papaparse.parse(e.target.result, {
                            delimiter: ';',
                            skipEmptyLines: true
                        });

                        if (result.errors.length === 0 && result.data.length > 1) {
                            const cols = result.data[0];
                            if (cols.length > 1) {
                                const regs = result.data.filter((_item, index) => index > 0);

                                let recordsArray = [];

                                setOrdersTableColumns([{
                                    key: 'lineNumber',
                                    title: 'Nro. línea',
                                    dataIndex: 'lineNumber',
                                    align: 'center',
                                    width: 300,
                                    sorter: (a, b) => a.lineNumber - b.lineNumber,
                                    sortDirections: ['descend', 'ascend'],
                                }]);

                                setOrdersTableData([]);

                                // generar columns basada en la definición
                                for (let fieldIndex = 0; fieldIndex < ordersTable.uploadDataFields.length; fieldIndex++) {
                                    const def = ordersTable.uploadDataFields[fieldIndex];
                                    let align = 'left';

                                    if (def !== undefined && def.dataType !== undefined) {
                                        align = def.dataType === 'Number' ? 'right' : 'left';

                                        setOrdersTableColumns((current) => [...current,
                                        {
                                            key: def.fieldName,
                                            title: def.label,
                                            dataIndex: def.fieldName,
                                            align,
                                            width: def.columnWidth,
                                            sorter: (a, b) => sortByAlph(a[def.fieldName], b[def.fieldName]),
                                            sortDirections: ['descend', 'ascend'],
                                            render: (value) => {
                                                if (liluuid.isUUID(value)) {
                                                    return <Tag color='green'>{value}</Tag>
                                                } else if (typeof value === 'string') {
                                                    if (value.toString().startsWith('Error')) {
                                                        return <Tag color='red'>{value}</Tag>
                                                    } else {
                                                        return <small>{value}</small>;
                                                    }
                                                }
                                            }
                                        }
                                        ]);
                                    }

                                    // generar y validar registros
                                    for (let recordIndex = 0; recordIndex < regs.length; recordIndex++) {
                                        const record = regs[recordIndex];
                                        const key = getKeyByValue(cols, def.fieldName);
                                        const newRec = { index: recordIndex, lineError: false };

                                        if (key !== undefined) {
                                            const textToValidate = new RegExp(/^[a-zA-Z\s]*$/);
                                            const numberToValidate = new RegExp('^[0-9]+$');
                                            const rec = recordsArray.find(e => e.index === recordIndex);
                                            const objValue = record[key];

                                            if ((def.dataType === 'Text' || def.dataType === 'OnlyText') && def.minValue !== null && objValue.length < def.minValue) {
                                                const value = `Error: El valor debe ser de al menos '${def.minValue}' caracteres (tiene ${objValue.length}).`;

                                                rec !== undefined ? rec[def.fieldName] = value : newRec[def.fieldName] = value;
                                                setErrosOnOrdersTable(true);
                                            } else if ((def.dataType === 'Text' || def.dataType === 'OnlyText') && def.maxValue !== null && objValue.length > def.maxValue) {
                                                const value = `Error: El valor no puede ser mayor a '${def.maxValue}' caracteres (tiene ${objValue.length}).`;

                                                rec !== undefined ? rec[def.fieldName] = value : newRec[def.fieldName] = value;
                                                setErrosOnOrdersTable(true);
                                            } else if (def.isUnique && objValue !== '' && def.mandatory) {
                                                const query = gql`
                                                    query getUnique {
                                                        ${ordersTable.tableName}(where:{${def.fieldName}: {_eq: "${objValue}"}}) {
                                                            id
                                                        }
                                                    }
                                                `;

                                                apolloClient.query({ query, fetchPolicy: 'network-only' })
                                                    .then(({ data }) => {
                                                        if (data[ordersTable.tableName].length > 0) {
                                                            const value = `Error: El número de orden '${objValue}' ya está registrado en el sistema.`;

                                                            rec !== undefined ? rec[def.fieldName] = value : newRec[def.fieldName] = value;
                                                            setErrosOnOrdersTable(true);
                                                        } else {
                                                            rec !== undefined ? rec[def.fieldName] = objValue : newRec[def.fieldName] = objValue
                                                        }
                                                    }).catch((error) => {
                                                        const value = `Error: ${error}`;

                                                        rec !== undefined ? rec[def.fieldName] = value : newRec[def.fieldName] = value;
                                                        setErrosOnOrdersTable(true);
                                                    });
                                            } else if (def.dataType === 'OnlyText' && !textToValidate.test(objValue)) {
                                                const value = `Error: El valor '${objValue}' no es correcto, deben ser sólo letras.`;

                                                rec !== undefined ? rec[def.fieldName] = value : newRec[def.fieldName] = value;
                                                setErrosOnOrdersTable(true);
                                            } else if (def.mandatory && def.dataType === 'OnlyNumbers' && !numberToValidate.test(objValue)) {
                                                const value = `Error: El valor '${objValue}' no es correcto. Solo números`;

                                                rec !== undefined ? rec[def.fieldName] = value : newRec[def.fieldName] = value;
                                                setErrosOnOrdersTable(true);
                                            } else if (def.mandatory && def.dataType === 'OnlyNumbers' && def.minValue !== null && objValue.length < def.minValue) {
                                                const value = `Error: El valor debe ser de al menos '${def.minValue}' caracteres (tiene ${objValue.length}).`;

                                                rec !== undefined ? rec[def.fieldName] = value : newRec[def.fieldName] = value;
                                                setErrosOnOrdersTable(true);
                                            } else if (def.mandatory && def.dataType === 'OnlyNumbers' && def.maxValue !== null && objValue.length > def.maxValue) {
                                                const value = `Error: El valor no puede ser mayor a '${def.maxValue}' caracteres (tiene ${objValue.length}).`;

                                                rec !== undefined ? rec[def.fieldName] = value : newRec[def.fieldName] = value;
                                                setErrosOnOrdersTable(true);
                                            } else if (objValue === '' && def.mandatory) {
                                                const value = 'Error: Obligatorio';

                                                rec !== undefined ? rec[def.fieldName] = value : newRec[def.fieldName] = value;
                                                setErrosOnOrdersTable(true);
                                            } else if (objValue === '' && !def.mandatory) {
                                                rec !== undefined ? rec[def.fieldName] = objValue : newRec[def.fieldName] = objValue
                                            } else if (def.pkTable !== null) {
                                                if (def.pkTableSearchField === null || def.pkTableSearchField === '') {
                                                    const value = `Error: La tabla de definición no contiene el campo pkTableSearchField. Imposible realizar la búsqueda para la relación.`;

                                                    rec !== undefined ? rec[def.fieldName] = value : newRec[def.fieldName] = value;
                                                    setErrosOnOrdersTable(true);
                                                } else {
                                                    const query = gql`
                                                        query getField {
                                                            ${def.pkTable}(where: {${def.pkTableSearchField}: {_ilike: "${objValue}"}}, limit: 1) {
                                                                ${def.pkReturnedField}
                                                            }
                                                        }
                                                    `;

                                                    apolloClient.query({ query, fetchPolicy: 'network-only' })
                                                        .then(({ data }) => {
                                                            if (data[def.pkTable].length === 0) {
                                                                const value = `Error: No existe el valor '${objValue}' en la tabla '${def.pkTable}'.`;

                                                                rec !== undefined ? rec[def.fieldName] = value : newRec[def.fieldName] = value;
                                                                setErrosOnOrdersTable(true);
                                                            } else {
                                                                rec !== undefined ? rec[def.fieldName] = data[def.pkTable][0][def.pkReturnedField] : newRec[def.fieldName] = data[def.pkTable][0][def.pkReturnedField];
                                                            }
                                                        }).catch((error) => {
                                                            const value = `Error: ${error}`;

                                                            rec !== undefined ? rec[def.fieldName] = value : newRec[def.fieldName] = value;
                                                            setErrosOnOrdersTable(true);
                                                        });
                                                }
                                            } else if (def.dataType === 'Number' && isNaN(objValue)) {
                                                const value = `Error: El valor '${objValue}' no es un número válido.`;
                                                rec !== undefined ? rec[def.fieldName] = value : newRec[def.fieldName] = value;
                                                setErrosOnOrdersTable(true);
                                            } else if (def.dataType === 'LatLng' && !isValidLatLng(objValue)) {
                                                const value = `Error: El valor '${objValue}' no es una latitud/longitud válida.`;

                                                rec !== undefined ? rec[def.fieldName] = value : newRec[def.fieldName] = value;
                                                setErrosOnOrdersTable(true);
                                            } else if (def.dataType === 'Number' && def.minValue !== null && objValue < def.minValue) {
                                                const value = `Error: El valor (${objValue}) no puede ser menor que '${def.minValue}'.`;

                                                rec !== undefined ? rec[def.fieldName] = value : newRec[def.fieldName] = value;
                                                setErrosOnOrdersTable(true);
                                            } else if (def.dataType === 'Number' && def.maxValue !== null && objValue > def.maxValue) {
                                                const value = `Error: El valor (${objValue}) no puede ser mayor que '${def.maxValue}'.`;

                                                rec !== undefined ? rec[def.fieldName] = value : newRec[def.fieldName] = value;
                                                setErrosOnOrdersTable(true);
                                            } else {
                                                rec !== undefined ? rec[def.fieldName] = objValue : newRec[def.fieldName] = objValue
                                            }

                                            if (rec === undefined) {
                                                recordsArray.push(newRec);
                                            }
                                        } else {
                                            const value = 'Error: Este campo debe estar en el archivo.';
                                            const rec = recordsArray.find(e => e.index === recordIndex);

                                            rec !== undefined ? rec[def.fieldName] = value : newRec[def.fieldName] = value;
                                            setErrosOnOrdersTable(true);
                                        }
                                    }
                                }

                                setOrdersTableColumns((current) => [...current, {
                                    key: 'detailsColumn',
                                    title: 'Líneas',
                                    dataIndex: 'detailsColumn',
                                    align: 'center',
                                    sorter: (a, b) => sortByAlph(a.lineError, b.lineError),
                                    sortDirections: ['descend', 'ascend'],
                                    render: (_value, record, _index) => {
                                        return <div>
                                            <Button
                                                type='primary'
                                                shape='round'
                                                icon={
                                                    record.lineError
                                                        ? <CloseCircleTwoTone twoToneColor='red' style={{ fontSize: '1.5em' }} />
                                                        : <CheckCircleTwoTone twoToneColor='#52c41a' style={{ fontSize: '1.5em' }} />
                                                }
                                                onClick={() => {
                                                    setOrderToShow(record.numberLine);
                                                    setVisibleDrawerLines(true);
                                                    setOrdersTableandLine(record.orderNumber)
                                                }}
                                            >
                                                Mostrar
                                            </Button>
                                        </div>
                                    }
                                }]);
                                setErrosOnOrdersTable(false);
                                let searchAddressCount = 0, searchAddressIdx = 0;

                                for (let seachAddress = 0; seachAddress < recordsArray.length; seachAddress++) {
                                    const element = recordsArray[seachAddress];

                                    if ((element['destination'] === 'Error: Obligatorio' || element['destination'] === '') && !element['address'].includes('Error')) {
                                        searchAddressCount += 1;
                                    }
                                }
                                for (let autoOrdenNumber = 0; autoOrdenNumber < recordsArray.length; autoOrdenNumber++) {
                                    const element = recordsArray[autoOrdenNumber];
                                    if (element['orderNumber'] === 'Error: Obligatorio' || element['orderNumber'] === '' || element['orderNumber'] === "Error: El valor debe ser de al menos '3' caracteres (tiene 0).") {
                                        recordsArray[autoOrdenNumber]['orderNumber'] = await autoOrdeNumber();
                                    }

                                }
                                for (let lineDetails = 0; lineDetails < recordsArray.length; lineDetails++) {
                                    const element = recordsArray[lineDetails];
                                    if (element['linesDetail'] === 'Si') {
                                        recordsArray[lineDetails]['linesDetail'] = "true";
                                    } else if (element['linesDetail'] === 'No') {
                                        recordsArray[lineDetails]['linesDetail'] = "false";
                                    } else if (element['linesDetail'] === '') {
                                        recordsArray[lineDetails]['linesDetail'] = "";
                                    }

                                }
                                for (let enableDeliveryWindow = 0; enableDeliveryWindow < recordsArray.length; enableDeliveryWindow++) {
                                    const element = recordsArray[enableDeliveryWindow];
                                    if (element['enableDeliveryWindow'] === 'Si') {
                                        recordsArray[enableDeliveryWindow]['enableDeliveryWindow'] = "true";
                                    } else if (element['enableDeliveryWindow'] === 'No') {
                                        recordsArray[enableDeliveryWindow]['enableDeliveryWindow'] = "false";
                                    } else if (element['enableDeliveryWindow'] === '') {
                                        recordsArray[enableDeliveryWindow]['enableDeliveryWindow'] = "";
                                    }

                                }
                                for (let enablePackageDimensions = 0; enablePackageDimensions < recordsArray.length; enablePackageDimensions++) {
                                    const element = recordsArray[enablePackageDimensions];
                                    if (element['enablePackageDimensions'] === 'Si') {
                                        recordsArray[enablePackageDimensions]['enablePackageDimensions'] = "true";
                                    } else if (element['enablePackageDimensions'] === 'No') {
                                        recordsArray[enablePackageDimensions]['enablePackageDimensions'] = "false";
                                    } else if (element['enablePackageDimensions'] === '') {
                                        recordsArray[enablePackageDimensions]['enablePackageDimensions'] = "";
                                    }

                                }
                                for (let paymentOrCashOnDeliveryRequired = 0; paymentOrCashOnDeliveryRequired < recordsArray.length; paymentOrCashOnDeliveryRequired++) {
                                    const element = recordsArray[paymentOrCashOnDeliveryRequired];
                                    if (element['paymentOrCashOnDeliveryRequired'] === 'Si') {
                                        recordsArray[paymentOrCashOnDeliveryRequired]['paymentOrCashOnDeliveryRequired'] = "true";
                                    } else if (element['paymentOrCashOnDeliveryRequired'] === 'No') {
                                        recordsArray[paymentOrCashOnDeliveryRequired]['paymentOrCashOnDeliveryRequired'] = "false";
                                    } else if (element['paymentOrCashOnDeliveryRequired'] === '') {
                                        recordsArray[paymentOrCashOnDeliveryRequired]['paymentOrCashOnDeliveryRequired'] = "";
                                    }

                                }
                                for (let distributionCenterId = 0; distributionCenterId < recordsArray.length; distributionCenterId++) {
                                    const element = recordsArray[distributionCenterId];
                                    if (element['distributionCenterId'] === '' || element['distributionCenterId'] === null) {
                                        recordsArray[distributionCenterId]['distributionCenterId'] = "00000000-0000-0000-0000-000000000000";
                                    }
                                }
                                for (let companyId = 0; companyId < recordsArray.length; companyId++) {
                                    const element = recordsArray[companyId];
                                    if (element['companyId'] === '' || element['companyId'] === null) {
                                        recordsArray[companyId]['companyId'] = "00000000-0000-0000-0000-000000000000";
                                    }
                                }
                                for (let customerSupplierId = 0; customerSupplierId < recordsArray.length; customerSupplierId++) {
                                    const element = recordsArray[customerSupplierId];
                                    if (element['customerSupplierId'] === '' || element['customerSupplierId'] === null) {
                                        recordsArray[customerSupplierId]['customerSupplierId'] = "00000000-0000-0000-0000-000000000000";
                                    }
                                }
                                for (let addressBookId = 0; addressBookId < recordsArray.length; addressBookId++) {
                                    const element = recordsArray[addressBookId];
                                    if (element['addressBookId'] === '' || element['addressBookId'] === null) {
                                        recordsArray[addressBookId]['addressBookId'] = "00000000-0000-0000-0000-000000000000";
                                    }
                                }
                                for (let expectedDeliveryStartTime = 0; expectedDeliveryStartTime < recordsArray.length; expectedDeliveryStartTime++) {
                                    const element = recordsArray[expectedDeliveryStartTime];
                                    if (element['expectedDeliveryStartTime'] === '' || element['expectedDeliveryStartTime'] === null) {
                                        recordsArray[expectedDeliveryStartTime]['expectedDeliveryStartTime'] = moment().format('MM/DD/YYYYTHH:mm');
                                    }
                                }
                                for (let expectedDeliveryEndTime = 0; expectedDeliveryEndTime < recordsArray.length; expectedDeliveryEndTime++) {
                                    const element = recordsArray[expectedDeliveryEndTime];
                                    if(element['expectedDeliveryEndTime'] === '' || element['expectedDeliveryEndTime'] === null){
                                        recordsArray[expectedDeliveryEndTime]['expectedDeliveryEndTime']= moment().format('MM/DD/YYYYTHH:mm');
                                    }
                                }
                                for (let type = 0; type < recordsArray.length; type++) {
                                    const element = recordsArray[type];
                                    if (element['type'] === 'Envio') {
                                        recordsArray[type]['type'] = "delivery";
                                    } else if (element['type'] === 'Recogida') {
                                        recordsArray[type]['type'] = "pickup";
                                    } else if (element['type'] === 'Servicio Personalizado') {
                                        recordsArray[type]['type'] = "customService";
                                    } else if (element['type'] === 'Traspaso de Bodegas') {
                                        recordsArray[type]['type'] = "warehousesTransfer";
                                    }

                                }
                                for (let priority = 0; priority < recordsArray.length; priority++) {
                                    const element = recordsArray[priority];
                                    if (element['priority'] === 'Alta') {
                                        recordsArray[priority]['priority'] = "a_high";
                                    } else if (element['priority'] === 'Media') {
                                        recordsArray[priority]['priority'] = "b_medium";
                                    } else if (element['priority'] === 'Baja') {
                                        recordsArray[priority]['priority'] = "c_low";
                                    }
                                }
                                for (let i = 0; i < recordsArray.length; i++) {
                                    const element = recordsArray[i];

                                    recordsArray[i]['lineNumber'] = i + 1;

                                    // verificar si no viene la latitud, longitud,
                                    // y si viene la dirección, convertirla a lat, lng
                                    if ((element['destination'] === 'Error: Obligatorio' || element['destination'] === '') && !element['address'].includes('Error')) {
                                        let newAddress = '';
                                        const address = element['address'].replace(',', ' ').replace(/\s\s+/g, ' ').replace('#', '').split(' ');
                                        const city = element['city'].replace(',', ' ').replace(/\s\s+/g, ' ').replace('#', '').split(' ');
                                        const state = element['state'].replace(',', ' ').replace(/\s\s+/g, ' ').replace('#', '').split(' ');
                                        const addressComplet = `${address},${city},${state}`;
                                        if (addressComplet.length > 0) {
                                            address.forEach(item => {
                                                newAddress += item + '+'
                                            });
                                            newAddress = newAddress.substring(0, newAddress.length - 1);

                                            const res = await axios.get(`https://maps.googleapis.com/maps/api/geocode/json?key=${process.env.REACT_APP_GOOGLE_MAPS_API_KEY}&components=country:CO&address=${newAddress}`);

                                            if (res.status === 200) {
                                                recordsArray[i]['destination'] = `${res.data.results[0].geometry.location.lat},${res.data.results[0].geometry.location.lng}`;

                                                setAddressSearchProgress(Math.round((100 / searchAddressCount) * (searchAddressIdx + 1)));
                                                searchAddressIdx += 1;
                                            }
                                        }
                                    }

                                    const fields = Object.keys(recordsArray[i]);

                                    for (let index = 0; index < fields.length; index++) {
                                        const value = recordsArray[i][fields[index]].toString();

                                        if (value.includes('Error')) setErrosOnOrdersTable(true);
                                    }

                                }

                                // chequear si hay duplicados (por orderNumber) en el archivo
                                const valueArr = recordsArray.map(function (item) { return item.orderNumber });

                                const toFindDuplicates = valueArr => valueArr.filter((item, index) => valueArr.indexOf(item) !== index)
                                const duplicateElements = toFindDuplicates(valueArr);
                                duplicateElements.map(item => {

                                    if (item !== undefined) {
                                        const elements = recordsArray.filter(reg => reg.orderNumber === item);

                                        elements.map(record => {
                                            const recordToUpdate = recordsArray.find(itm => itm.index === record.index);
                                            const indexToReplace = recordsArray.findIndex((i) => i.index === record.index);

                                            recordsArray.splice(indexToReplace, 1, {
                                                ...recordToUpdate,
                                                orderNumber: `Error: Orden '${item}' duplicada en el archivo`,
                                            });
                                        });

                                        setErrosOnOrdersTable(true);
                                    }
                                });

                                setOrdersTableData(recordsArray)
                                setAddressSearchProgress(0);
                            } else {
                                setOrdersTableColumns([]);
                                setOrdersTableData([]);
                                setSelectedFileOrdersTable([]);

                                Swal.fire({
                                    title: 'Error',
                                    text: 'Ocurrió un error general en la carga del archivo, es posible que no contenga datos y/o que la estructura no sea correcta. Verifique que el separador de listas sea punto y coma (;) y que el archivo contenga datos.',
                                    icon: 'error',
                                    confirmButtonText: 'Cerrar'
                                });
                            }
                        } else {
                            setOrdersTableColumns([]);
                            setOrdersTableData([]);
                            setSelectedFileOrdersTable([]);

                            Swal.fire({
                                title: 'Error',
                                text: 'Ocurrió un error general en la carga del archivo, es posible que no contenga datos y/o que la estructura no sea correcta. Verifique que el separador de listas sea punto y coma (;) y que el archivo contenga datos.',
                                icon: 'error',
                                confirmButtonText: 'Cerrar'
                            });
                        }
                    };

                    reader.readAsText(file);

                    // Prevent upload
                    return false;
                }}
            >

                <Button icon={<UploadOutlined />}>Clic para seleccionar archivo (encabezados)</Button>
                <When predicate={addressSearchProgress > 0}>
                    <Row>
                        <Col>Localizando direcciones, espere por favor: </Col>
                        <Col>
                            <Progress percent={addressSearchProgress} />
                        </Col>
                    </Row>

                </When>

            </Upload>
            <br />
            <Upload
                disabled={ordersTableData.length === 0}
                name='lines'
                accept='.csv,.txt'
                onChange={(value) => {
                    let last = [];

                    if (value.fileList.length === 1) {
                        last = value.fileList[value.fileList.length];
                    } else if (value.fileList.length > 1) {
                        last = [value.fileList[value.fileList.length - 1]];
                    } else {
                        last = value.fileList;
                    }

                    setSelectedFileOrdersLine(last);
                }}
                onClick={() => {
                    setErrosOnOrdersLine(false);
                }}
                fileList={selectedFileOrdersLine}
                beforeUpload={file => {
                    const reader = new FileReader();

                    reader.onload = async (e) => {
                        const result = Papaparse.parse(e.target.result, {
                            delimiter: ';',
                            skipEmptyLines: true
                        });

                        if (result.errors.length === 0) {
                            const cols = result.data[0];
                            const regs = result.data.filter((_item, index) => index > 0);
                            let recordsArray = [];

                            setOrdersLineColumns([{
                                key: 'lineNumber',
                                title: 'Nro. línea',
                                dataIndex: 'lineNumber',
                                align: 'center',
                                width: 100,
                                sorter: (a, b) => a.lineNumber - b.lineNumber,
                                sortDirections: ['descend', 'ascend'],
                            }]);
                            setOrdersLineData([]);

                            // generar columns basada en la definición
                            for (let fieldIndex = 0; fieldIndex < ordersLine.uploadDataFields.length; fieldIndex++) {
                                const def = ordersLine.uploadDataFields[fieldIndex];
                                let align = 'left';

                                if (def !== undefined && def.dataType !== undefined) {
                                    align = def.dataType === 'Number' ? 'right' : 'left';

                                    setOrdersLineColumns((current) => [...current,
                                    {
                                        key: def.fieldName,
                                        title: def.label,
                                        dataIndex: def.fieldName,
                                        align,
                                        width: def.columnWidth,
                                        sorter: (a, b) => sortByAlph(a[def.fieldName], b[def.fieldName]),
                                        sortDirections: ['descend', 'ascend'],
                                        render: (value) => {
                                            if (liluuid.isUUID(value)) {
                                                return <Tag color='green'>{value}</Tag>
                                            } else if (typeof value === 'string') {
                                                if (value.toString().startsWith('Error')) {
                                                    return <Tag color='red'>{value}</Tag>
                                                } else {
                                                    return <small>{value}</small>;
                                                }
                                            }
                                        }
                                    }
                                    ]);
                                }

                                // generar y validar registros
                                for (let recordIndex = 0; recordIndex < regs.length; recordIndex++) {
                                    const record = regs[recordIndex];
                                    const key = getKeyByValue(cols, def.fieldName);
                                    const newRec = { index: recordIndex };

                                    if (key !== undefined) {
                                        const rec = recordsArray.find(e => e.index === recordIndex);
                                        const objValue = record[key];

                                        if (objValue === '' && def.mandatory) {
                                            const value = 'Error: Obligatorio';

                                            rec !== undefined ? rec[def.fieldName] = value : newRec[def.fieldName] = value;
                                            setErrosOnOrdersLine(true);
                                        } else if (objValue === '' && !def.mandatory) {
                                            rec !== undefined ? rec[def.fieldName] = objValue : newRec[def.fieldName] = objValue
                                        } else if (def.pkTable !== null) {
                                            if (def.pkTableSearchField === null || def.pkTableSearchField === '') {
                                                const value = `Error: La tabla de definición no contiene el campo pkTableSearchField. Imposible realizar la búsqueda para la relación.`;

                                                rec !== undefined ? rec[def.fieldName] = value : newRec[def.fieldName] = value;
                                                setErrosOnOrdersLine(true);
                                            } else {
                                                const query = gql`
                                                    query getField {
                                                        ${def.pkTable}(where: {${def.pkTableSearchField}: {_eq: "${objValue}"}}, limit: 1) {
                                                            ${def.pkReturnedField}
                                                        }
                                                    }
                                                `;

                                                apolloClient.query({ query, fetchPolicy: 'network-only' })
                                                    .then(({ data }) => {
                                                        if (data[def.pkTable].length === 0) {
                                                            const value = `Error: No existe el valor '${objValue}' en la tabla '${def.pkTable}'.`;

                                                            rec !== undefined ? rec[def.fieldName] = value : newRec[def.fieldName] = value;
                                                            setErrosOnOrdersLine(true);
                                                        } else {
                                                            rec !== undefined ? rec[def.fieldName] = data[def.pkTable][0][def.pkReturnedField] : newRec[def.fieldName] = data[def.pkTable][0][def.pkReturnedField];
                                                        }
                                                    }).catch((error) => {
                                                        const value = `Error: ${error}`;

                                                        rec !== undefined ? rec[def.fieldName] = value : newRec[def.fieldName] = value;
                                                        setErrosOnOrdersLine(true);
                                                    });
                                            }
                                        } else if (def.dataType === 'Number' && isNaN(objValue)) {
                                            const value = `Error: El valor '${objValue}' no es un número válido.`;
                                            rec !== undefined ? rec[def.fieldName] = value : newRec[def.fieldName] = value;
                                            setErrosOnOrdersLine(true);
                                        } else if (def.dataType === 'LatLng' && !isValidLatLng(objValue)) {
                                            const value = `Error: El valor '${objValue}' no es una latitud/longitud válida.`;

                                            rec !== undefined ? rec[def.fieldName] = value : newRec[def.fieldName] = value;
                                            setErrosOnOrdersLine(true);
                                        } else if (def.dataType === 'Text' && def.minValue !== null && objValue.length < def.minValue) {
                                            const value = `Error: El valor debe ser de al menos '${def.minValue}' caracteres (tiene ${objValue.length}).`;

                                            rec !== undefined ? rec[def.fieldName] = value : newRec[def.fieldName] = value;
                                            setErrosOnOrdersLine(true);
                                        } else if (def.dataType === 'Text' && def.maxValue !== null && objValue.length > def.maxValue) {
                                            const value = `Error: El valor no puede ser mayor a '${def.maxValue}' caracteres (tiene ${objValue.length}).`;

                                            rec !== undefined ? rec[def.fieldName] = value : newRec[def.fieldName] = value;
                                            setErrosOnOrdersLine(true);
                                        } else if (def.dataType === 'Number' && def.minValue !== null && objValue < def.minValue) {
                                            const value = `Error: El valor (${objValue}) no puede ser menor que '${def.minValue}'.`;

                                            rec !== undefined ? rec[def.fieldName] = value : newRec[def.fieldName] = value;
                                            setErrosOnOrdersLine(true);
                                        } else if (def.dataType === 'Number' && def.maxValue !== null && objValue > def.maxValue) {
                                            const value = `Error: El valor (${objValue}) no puede ser mayor que '${def.maxValue}'.`;

                                            rec !== undefined ? rec[def.fieldName] = value : newRec[def.fieldName] = value;
                                            setErrosOnOrdersLine(true);
                                        } else {
                                            rec !== undefined ? rec[def.fieldName] = objValue : newRec[def.fieldName] = objValue
                                        }

                                        if (rec === undefined) {
                                            recordsArray.push(newRec);
                                        }
                                    } else {
                                        const value = 'Error: Este campo debe estar en el archivo.';
                                        const rec = recordsArray.find(e => e.index === recordIndex);

                                        rec !== undefined ? rec[def.fieldName] = value : newRec[def.fieldName] = value;
                                        setErrosOnOrdersLine(true);
                                    }
                                }
                            }

                            for (let i = 0; i < recordsArray.length; i++) {
                                recordsArray[i]['lineNumber'] = i + 1;
                                //add order number based on the order selected by line number
                                recordsArray[i]['orderNumber'] = ordersTableData?.find(e => e.numberLine === recordsArray[i]['numberLine'])?.orderNumber ?? "";
                            }

                            setOrdersLineData(recordsArray);

                            const tempOrdersTableData = ordersTableData;

                            // eslint-disable-next-line
                            tempOrdersTableData.map(reg => {
                                const lines = recordsArray.filter(e => e.numberLine === reg.numberLine);

                                if (lines.length > 0) {
                                    // eslint-disable-next-line
                                    Object.keys(lines).map((_e, i) => {
                                        const item = Object.values(lines)[i];

                                        // eslint-disable-next-line
                                        Object.keys(item).map((_a, b) => {
                                            const result = Object.values(item)[b]

                                            if (typeof result === 'string' && result.startsWith('Error:')) {
                                                reg.lineError = true;
                                            }
                                        })
                                    })
                                } else {
                                    reg.lineError = true;
                                }

                            });
                            setOrdersTableData(tempOrdersTableData);
                        } else {
                            setOrdersLineColumns([]);
                            setOrdersLineData([]);
                            setSelectedFileOrdersLine([]);

                            Swal.fire({
                                title: 'Error',
                                text: 'Ocurrió un error general la carga del archivo, es posible que no contenga datos y/o que la estructura no sea correcta. Verifique que el separador de listas sea punto y coma (;) y que el archivo contenga datos.',
                                icon: 'error',
                                confirmButtonText: 'Cerrar'
                            });
                        }
                    };

                    reader.readAsText(file);

                    // Prevent upload
                    return false;
                }}
            >
                <Button icon={<UploadOutlined />}>Clic para seleccionar archivo (líneas)</Button>
            </Upload>
            <Divider />
            <Button
                disabled={ordersTableColumns.length === 0 || addressSearchProgress > 0}
                type="primary"
                onClick={() => {
                    // sessionStorage.setItem("ordersTableColumns", JSON.stringify(ordersTableColumns));
                    // sessionStorage.setItem("ordersTableData", JSON.stringify(ordersTableData));
                    // sessionStorage.setItem("ordersLineData", JSON.stringify(ordersLineData));
                    // sessionStorage.setItem("ordersLineColumns", JSON.stringify(ordersLineColumns));
                    // sessionStorage.setItem("errosOnOrdersTable", errosOnOrdersTable);
                    // sessionStorage.setItem("errosOnOrdersLine", errosOnOrdersLine);
                    setCurrentStep(currentStep + 1);
               
                }}
                shape='round'
            >
                Siguiente
            </Button>
            <Button
                className='ExcelGeneratorCabecera'
                type="primary"
                onClick={() => {
                    const url = "https://navitranstorage.blob.core.windows.net/nav-cooltrack-attachments/CabeceraOrdenes.xlsx";
                    window.open(url, '_blank');
                }}
                shape='round'
            >
                Plantilla de Cabeceras
            </Button>
            <Button
                className='ExcelGeneratorLinea'
                type="primary"
                onClick={() => {
                    const url = "https://navitranstorage.blob.core.windows.net/nav-cooltrack-attachments/LineasOrdenes.xlsx";
                    window.open(url, '_blank');
                }}
                shape='round'
            >
                Plantilla de Lineas
            </Button>
        </div>
    );

    const step02 = (
        <>
            <div className='children-table-6-container'>
                <Table
                    className='children-table-6'
                    bordered
                    columns={ordersTableColumns}
                    dataSource={ordersTableData}
                    key='table01'
                    rowKey='key'
                    size='small'
                    scroll={{
                        x: 1
                    }}
                />
            </div>
            <Button
                key='boton1'
                type="primary"
                shape='round'
                onClick={() => {
                    setOrdersTableColumns([]);
                    setOrdersTableData([]);
                    setErrosOnOrdersTable(false);
                    setOrdersLineColumns([]);
                    setOrdersLineData([]);
                    setErrosOnOrdersLine(false);
                    setSelectedFileOrdersTable([]);
                    setSelectedFileOrdersLine([]);
                    handlePrev();
                }}
            >
                Anterior
            </Button>
            <Divider type='vertical' key='divider1' />
            <Button
                key='boton2'
                disabled={errosOnOrdersTable}
                type="primary"
                onClick={handleNext}
                shape='round'
            >
                Siguiente
            </Button>

        </>
    );

    const step03 = (
        <>
            <div className='children-table-6-container'>
                <Table
                    className='children-table-6'
                    bordered
                    columns={ordersTableColumns}
                    dataSource={ordersTableData}
                    key='table02'
                    rowKey='key'
                    size='small'
                    scroll={{
                        x: 1
                    }}
                    summary={() => {
                        return (
                            <>
                                <Table.Summary.Row>
                                    <Table.Summary.Cell colSpan={3} index={0}>Progreso (pedidos)</Table.Summary.Cell>
                                    <Table.Summary.Cell colSpan={2} index={1}>
                                        <Typography.Text>
                                            {
                                                <Progress percent={progressOrdersTablePercent} />
                                            }
                                        </Typography.Text>
                                    </Table.Summary.Cell>
                                </Table.Summary.Row>
                                <Table.Summary.Row>
                                    <Table.Summary.Cell colSpan={3} index={0}>Progreso (líneas)</Table.Summary.Cell>
                                    <Table.Summary.Cell colSpan={2} index={1}>
                                        <Typography.Text>
                                            {
                                                <Progress percent={progressOrdersLinePercent} />
                                            }
                                        </Typography.Text>
                                    </Table.Summary.Cell>
                                </Table.Summary.Row>
                            </>
                        )
                    }}
                />
            </div>
            <Button
                disabled={finishedSteps}
                type="primary"
                //onClick={handleNext}
                shape='round'
                onClick={() => {

                    let hasErrors = false;
                    setFinishedSteps(true);

                    for (let idx = 0; idx < ordersTableData.length; idx++) {
                        const item = ordersTableData[idx];
                        let fieldsValues = '';

                        // eslint-disable-next-line
                        Object.keys(item).map((key, index) => {
                            if (key !== 'index' && key !== 'lineError' && key !== 'lineNumber') {
                                let value = Object.values(item)[index];

                                if (typeof value === 'string') {
                                    fieldsValues = fieldsValues + `${key}: "${value === "" || value === null ? "0" : value}", `;

                                } else {
                                    fieldsValues = fieldsValues + `${key}: "${value}", `;
                                }
                            }
                        });
                        const query = gql`
                            mutation insertOrdersTable {
                                insert_ordersTable(objects: {${fieldsValues}}) {
                                    returning {
                                        id
                                      }
                                      affected_rows
                                }
                            }
                        `;

                        apolloClient.mutate({ mutation: query, fetchPolicy: 'no-cache' })
                            // eslint-disable-next-line
                            .then(({ data }) => {
                                setTimeout(async () => {
                                    if (data.insert_ordersTable.affected_rows > 0) {
                                        setProgressOrdersTablePercent(Math.round((100 / ordersTableData.length) * (idx + 1)));

                                        const linesOrders = ordersLineData.filter(l => l.numberLine === item.numberLine);
                                        const totalLines = linesOrders.length;

                                        for (let idx1 = 0; idx1 < totalLines; idx1++) {
                                            const line = linesOrders[idx1];
                                            let fieldsValuesLine = '';

                                            // eslint-disable-next-line
                                            Object.keys(line).map((key1, index1) => {
                                                if (key1 !== 'index' && key1 !== 'lineError' && key1 !== 'lineNumber') {
                                                    const value1 = Object.values(line)[index1];

                                                    if (typeof value1 === 'string') {
                                                        fieldsValuesLine = fieldsValuesLine + `${key1}: "${value1}", `
                                                    } else {
                                                        fieldsValuesLine = fieldsValuesLine + `${key1}: ${value1}, `
                                                    }
                                                }
                                            });
                                            const query1 = gql`
                                                mutation insertOrdersLine {
                                                    insert_ordersLine(objects: {${fieldsValuesLine}}) {
                                                        returning {
                                                            id
                                                        }
                                                        affected_rows
                                                    }
                                                }
                                            `;
                                            apolloClient.mutate({ mutation: query1, fetchPolicy: 'no-cache' })
                                                .then(({ data: lines }) => {
                                                    setTimeout(() => {
                                                        if (lines.insert_ordersLine.affected_rows > 0) {
                                                            setProgressOrdersLinePercent(Math.round((100 / totalLines) * (idx1 + 1)))

                                                        }
                                                    }, 200);
                                                    // eslint-disable-next-line
                                                }).catch((error1) => {
                                                    console.error(error1)
                                                    hasErrors = true;
                                                });
                                               

                                            if (idx === ordersTableData.length - 1 && idx1 === totalLines - 1) {
                                                if (!hasErrors) {
                                                    Swal.fire({
                                                        title: 'Proceso finalizado',
                                                        icon: 'success',
                                                        text: 'Proceso finalizado exitosamente',
                                                        confirmButtonText: 'Cerrar',
                                                        willClose: () => {
                                                            history.replace('/main-upload');
                                                        }
                                                    });
                                                 
                                                   
                                                }
                                            }
                                        };
                                        if (ordersTableData.length > 0) {
                                            Swal.fire({
                                                title: 'Proceso finalizado',
                                                icon: 'success',
                                                text: 'Proceso finalizado exitosamente',
                                                confirmButtonText: 'Cerrar',
                                                willClose: () => {
                                                    history.replace('/main-upload');
                                                }
                                            });
                                            const local = localStorage.getItem('userData');
                                            let usr={};
                                            
                                            if(!local || local.length === 0) {
                                                const session = sessionStorage.getItem('userData');
                                                usr = JSON.parse(session);
                                            }else{
                                                usr = JSON.parse(local);
                                            };
                                           
                                            loggerCooltrack({
                                                module: "UploadData",
                                                operation: "UploadFile",
                                                target: usr.companyId,
                                            });
                                       
                                        };
                                    }
                                }, 200);
                                // eslint-disable-next-line
                            }).catch((error) => {
                                console.error(error)
                                hasErrors = true;
                            });
                    }
                }}
            >
                Finalizar
            </Button>

        </>
    );

    const steps = [
        {
            key: '1',
            title: 'Seleccione archivos',
            description: `Seleccione los archivos csv o txt a procesar.`,
            content: step01
        },
        {
            key: '2',
            title: 'Validación',
            description: 'Ver los resultados de la validación de los archivos.',
            content: step02,
        },
        {
            key: '3',
            title: 'Subir datos',
            description: 'Listo para subir los datos al servidor.',
            content: step03,
        },
    ];

    return (
        <div className="children-inner-container-with-bg">
            <Steps
                className="children-steps"
                key='step001'
                current={currentStep}
                percent={(100 / steps.length) * (currentStep + 1)}
            >
                {steps.map((item) => {
                    return <Steps.Step
                        key={item.index}
                        title={item.title}
                        description={item.description}
                    />
                })}
            </Steps>

            <div key='allsteps'>
                {steps[currentStep].content}
            </div>

            <Drawer
                key="drawer"
                title={`Líneas de la orden ${ordersTableandLine}`}
                width={700}
                closable={true}
                onClose={() => {
                    setVisibleDrawerLines(false);
                }}
                visible={visibleDrawerLines}
                bodyStyle={{ paddingBottom: 80 }}
                footer={
                    <div style={{ textAlign: 'right' }}>
                        <Button
                            type='ghost'
                            shape='round'
                            onClick={() => {
                                setVisibleDrawerLines(false);
                            }}
                        >
                            Cerrar
                        </Button>
                    </div>
                }
            >
                <div className='children-table-6-container'>
                    <Table
                        className='children-table-6'
                        columns={ordersLineColumns}
                        dataSource={ordersLineData.filter(e => e.numberLine === orderToShow)}
                        key='table03'
                        rowKey='key'
                    />
                </div>
            </Drawer>
        </div>
    );
}
