import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Menu, Typography } from 'antd';

import {
    HeatMapOutlined,
    CloudUploadOutlined,
    ScheduleOutlined,
    WarningOutlined,
    SettingOutlined,
    LogoutOutlined,
    CalendarOutlined,
    DashboardOutlined,
    CodeSandboxOutlined

} from '@ant-design/icons';

import { useUser } from '../../../../context/UserContext';

import '../css/sidemenu.css';


const { Title } = Typography;


export const SideMenuScreen = (props) => {
    const location = useLocation();
    const { user } = useUser();

    const [roleName, setRoleName] = useState('');

    useEffect(() => {
        setRoleName(user.roleName);
    }, [user]);

    const logout = () => {
        localStorage.clear();
        sessionStorage.clear();
        window.location.reload();
    };

    return (
        <>
            <Menu
                theme='ligth'
                mode="inline"
                //defaultSelectedKeys={['/']}
                defaultSelectedKeys={[location.pathname]}
            >
                {roleName === 'admin' &&
                    <Menu.Item key='/' icon={<DashboardOutlined />} className="menuselected">
                        <Link to="/" onClick={()=> props.clearSearchTarget()}>Dashboard</Link>
                    </Menu.Item>
                }
                <Menu.Item key='/main-upload' icon={<CloudUploadOutlined />} className="menuselected">
                    <Link to='/main-upload' onClick={()=> props.clearSearchTarget()}>Cargue de datos</Link>
                </Menu.Item>
                <Menu.Item key='/scheduling' icon={<CalendarOutlined />} className="menuselected">
                    <Link to='/scheduling' onClick={()=> props.clearSearchTarget()}>Planificación</Link>
                </Menu.Item>
                <Menu.Item key='/home' icon={<HeatMapOutlined />} className="menuselected">
                    <Link to="/home" onClick={()=> props.clearSearchTarget()}>Seguimiento Rutas</Link>
                </Menu.Item>
                <Menu.Item key='/completed-routes' icon={<ScheduleOutlined />} className="menuselected">
                    <Link to='/completed-routes' onClick={()=> props.clearSearchTarget()}>Rutas finalizadas</Link>
                </Menu.Item>
                <Menu.Item key='/onSiteDeliveries' icon={<CodeSandboxOutlined />} className="menuselected">
                    <Link to='/onSiteDeliveries' onClick={()=> props.clearSearchTarget()}>Entrega en Sitio</Link>
                </Menu.Item>
                <Menu.Item key='/incidents' icon={<WarningOutlined />} className="menuselected">
                    <Link to='/incidents' onClick={()=> props.clearSearchTarget()}>Incidentes</Link>
                </Menu.Item>
                <Menu.Item key='/configuration' icon={<SettingOutlined />} className="menuselected">
                    <Link to='/configuration' onClick={()=> props.clearSearchTarget()}>Configuración</Link>
                </Menu.Item>
            </Menu>
        </>
    );
}
