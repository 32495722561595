import React, { forwardRef } from "react";
import Input from "antd/es/input";

import './input.css';

const CustomInput = (
  {
    value,
    onChange,
    disabled,
    handleFocus,
    handleBlur,
    autoFocus = false,
    placeholder = undefined,
    type = "text",
    allowClear = false,
    isPassword = false,
    prefix = null
  },
  ref
) => {
  const triggerChange = (changedValue) => {
    if (onChange) {
      onChange(changedValue);
    }
  };

  const handleChange = (e) => {
    triggerChange(e.target.value);
  };

  if(!isPassword) {
    return (
        <>
          <label htmlFor="" style={{ display: "none" }}>
            hidden label to mislead chrome autocomplete
          </label>
          
          <Input
            className="input"
            ref={ref}
            placeholder={placeholder}
            autoComplete="dont-use-autocomplete"
            disabled={disabled}
            onFocus={handleFocus}
            onBlur={handleBlur}
            onChange={handleChange}
            value={value}
            autoFocus={autoFocus}
            type={type}
            allowClear={allowClear}
            prefix={prefix}
          />
        </>
    );
  } else {
    return (
        <>
          <label htmlFor="" style={{ display: "none" }}>
            hidden label to mislead chrome autocomplete
          </label>
          
          <Input.Password
            className="input"
            ref={ref}
            placeholder={placeholder}
            autoComplete="dont-use-autocomplete"
            disabled={disabled}
            onFocus={handleFocus}
            onBlur={handleBlur}
            onChange={handleChange}
            value={value}
            autoFocus={autoFocus}
            type={type}
            allowClear={allowClear}
            prefix={prefix}
          />
        </>
    );
  }

  
};

export default forwardRef(CustomInput);