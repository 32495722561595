import React, { useState, useEffect } from "react";
import {
    Form, Select, Table, Switch, Descriptions, Input, Card, Button, Space, Skeleton, Tooltip, List, Col, Row, Modal
} from "antd";
import TextField from "@mui/material/TextField";
import bcrypt from "bcryptjs";

import Swal from "sweetalert2";
import { useLazyQuery, useMutation, useApolloClient } from "@apollo/client";
import {
    fetchClients,
    fetchDistributionCenterss,
    fetchSectorss,
    insertClient
} from "../../../queries/uploadData";
import axios from "axios";
import localStorageService from "../../../../../services/localStorageService";
import "./styles/HeaderUploadScreen.css";
import {personTypeList,personList,documentTypeList,currencyList,priorityList} from '../../../../configuration/common/commonData';

const companyId = localStorageService.get("companyId");

export const UserNew = ({ props }) => {
 const {
        showDrawer,
        setShowDrawer,
        getCustomers,
        setFormValue,
        setFormCustomer,
        setSelectedAddress,
        setSelectedInfo,
        formType,
        setCustomerSupplier
      } = props;
  const [form] = Form.useForm();
  const apolloClient = useApolloClient();
  const [getClients, dataClients] = useLazyQuery(fetchClients, { variables: {companyId}, fetchPolicy: 'no-cache' });
  const [getDistributionCenters, dataDistributionCenters] = useLazyQuery(fetchDistributionCenterss, { variables: {companyId}, fetchPolicy: 'no-cache' });
  const [getSectors, dataSectors] = useLazyQuery(fetchSectorss, { variables: {companyId}, fetchPolicy: 'no-cache' });
  const [insClient] = useMutation(insertClient, { variables: {companyId}, fetchPolicy: 'no-cache' });
  const [clients, setClients] = useState([]);


  useEffect(() => {
    getClients();
    getDistributionCenters();
    getSectors()
    // eslint-disable-next-line react-hooks/exhaustive-deps
}, []);
  useEffect(() => {
    setClients([]);

    if (!dataClients.loading && dataClients.data && dataClients.data.clients.length > 0) {
        dataClients.data.clients.map((item) => {
            const deleteable = true;//item.vehicles_aggregate.aggregate.count === 0;
            const { id, nit, name, email, enabled, accountNumber, documentType, currency, personType, person, clientAddresses } = item;

            const personTypeNameArr = personTypeList.filter(item => item.value === personType);
            const personTypeName = personTypeNameArr.length > 0 ? personTypeNameArr[0].label : '';

            const personNameArr = personList.filter(item => item.value === person);
            const personName = personNameArr.length > 0 ? personNameArr[0].label : '';

            return setClients((current) => [...current, {
                id,
                nit,
                name,
                email: email ?? "",
                enabled,
                deleteable,
                accountNumber,
                documentType,
                currency,
                personType,
                personTypeName,
                person,
                personName,
                clientAddresses
            }]);
        });
    }
}, [dataClients.loading, dataClients.data]);

  
  const formItemLayout = {
    labelCol: {
      lg: { span: 8 },
    },
    wrapperCol: {
      lg: { span: 15 },
    },
  };
  
  const handleCancel = () => {
    setShowDrawer(false);
};

  const handleClear = () => {
    handleCancel();

    form.setFieldsValue({
        id: '',
        nit: '',
        name: '',
        email: '',
        accountNumber: '',
        documentType: '',
        currency: '',
        personType: '',
        personTypeName: '',
        person: '',
        personName: '',
        enabled: true,
        deleteable: true,
        clientAddresses: []
    });
};
const handleSave = async () => {
    form
    .validateFields()
    .then(async(_) => {
        const values = form.getFieldsValue();
      const {
        id,
        nit,
        name,
        email,
        accountNumber,
        documentType,
        currency,
        personType,
        personTypeName,
        person,
        personName,
        enabled,
        deleteable,
      } = values;

    try {
            const res = await insClient({
                variables: {
                    nit,
                    name,
                    email,
                    accountNumber,
                    documentType,
                    currency,
                    personType,
                    person,
                    enabled
                },
            });
            if (res.data.insert_clients.affected_rows > 0) {
                const { id } = res.data.insert_clients.returning[0];
                Swal.fire({
                    title: "Cliente Creado",
                    text: "Se Creo Correctamente el Cliente en el Sistema!",
                    icon: "success",
                    confirmButtonText: "Aceptar",
                    willClose: () => {
                        setShowDrawer(false);
                    },
                  });
                setClients((current) => [...current, {
                    id,
                    nit,
                    name,
                    email,
                    accountNumber,
                    documentType,
                    currency,
                    personType,
                    personTypeName,
                    person,
                    personName,
                    enabled,
                    deleteable: true,
                    clientAddresses: []
                }]);
                getCustomers({
                    variables: {
                      companyId,
                    },
                  });
               
                setFormCustomer({
                    id,
                    name
                  });
                  
                  if(formType !== "delivery" || formType !== "pickup"){
                    setFormValue.setFieldsValue({
                        customerSupplierId: id,
                        customerSupplierName: `${name} (${documentType} ${nit})`,
                        custIdentificationNumber: nit,
                      }); 
                  
                  }
                  if(formType === "warehousesTransfer"){
                    setFormValue.setFieldsValue({
                        destinationDistributionCenterId: id,
                        destinationDistributionCenterName: `${name} (${documentType} ${nit})`,
                        custIdentificationNumber: nit,
                      }); 
           
                  }
                  
                  setSelectedAddress(true);
                  setSelectedInfo(true)
            }
    } catch (error) {
        console.error(error);
    }
    }); 
};
  return (
    <div className="children-inner-container-with-bg">
       <Modal className='children-modal' visible={showDrawer} onOk={handleCancel} onCancel={handleCancel} footer={[]} title={'Crear Cliente'} width={500}>
            <Form
            {...formItemLayout}
                    form={form}
                    name='configuration'
                    
                    initialValues={{
                        id: '',
                        nit: '',
                        name: '',
                        email: '',
                        address: '',
                        accountNumber: '',
                        documentType: '',
                        currency: '',
                        personType: '',
                        personTypeName: '',
                        person: '',
                        personName: '',
                        enabled: true
                    }}
                >
                    <Form.Item name='id' hidden>
                        <Input />
                    </Form.Item>
                    
                            <Form.Item
                            label='Tipo'
                            labelAlign='left'
                                name='person'
                                rules={[{ required: true, message: '' }]}
                            >
                                <Select 
                                    placeholder='Seleccione...'
                                    allowClear
                                    onChange={(value, object) => {
                                        if (value !== undefined) {
                                            return form.setFieldsValue({ person : value, personName: object.children })
                                        }
                                        return form.setFieldsValue({ person : null, personName: "" })
                                    }}
                                >
                                    {
                                        personList.map((item) => {
                                            return <Select.Option value={item.value}>{item.label}</Select.Option>
                                        })
                                    }
                                </Select>
                            </Form.Item>
                    
                            <Form.Item
                            label='Tipo de persona'
                            labelAlign='left'
                                name='personType'
                                rules={[{ required: true, message: '' }]}
                            >
                                <Select 
                                    placeholder='Seleccione...'
                                    allowClear
                                    onChange={(value, object) => {
                                        if (value !== undefined) {
                                            return form.setFieldsValue({ personType : value, personTypeName: object.children })
                                        }
                                        return form.setFieldsValue({ personType : null, personTypeName: "" })
                                    }}
                                >
                                    {
                                        personTypeList.map((item) => {
                                            return <Select.Option value={item.value}>{item.label}</Select.Option>
                                        })
                                    }
                                </Select>
                            </Form.Item>

                            <Form.Item
                            label='Nombre'
                            labelAlign='left'
                                name='name'
                                rules={[{ required: true, message: '' }]}
                            >
                                <Input />
                            </Form.Item>
      
                            <Form.Item
                            label='Tipo de documento'
                            labelAlign='left'
                                name='documentType'
                                rules={[{ required: true, message: '' }]}
                            >
                                <Select 
                                    placeholder='Seleccione...'
                                    allowClear
                                    onChange={(value, object) => {
                                        if (value !== undefined) {
                                            return form.setFieldsValue({ documentType : value })
                                        }
                                        return form.setFieldsValue({ documentType : null })
                                    }}
                                >
                                    {
                                        documentTypeList && documentTypeList.map((item) => {
                                            return <Select.Option value={item}>{item}</Select.Option>
                                        })
                                    }
                                </Select>
                            </Form.Item>
             
                            <Form.Item
                            label='Documento'
                            labelAlign='left'
                                name='nit'
                                rules={[{ required: true, message: '' }]}
                            >
                                <Input />
                            </Form.Item>

                            <Form.Item
                            label='Número de cuenta'
                            labelAlign='left'
                                name='accountNumber'
                            >
                                <Input />
                            </Form.Item>

                            <Form.Item
                            label='Moneda'
                            labelAlign='left'
                                name='currency'
                                rules={[{ required: true, message: '' }]}
                            >
                                <Select 
                                    placeholder='Seleccione...'
                                    allowClear
                                    onChange={(value, object) => {
                                        if (value !== undefined) {
                                            return form.setFieldsValue({ currency : value })
                                        }
                                        return form.setFieldsValue({ currency : null })
                                    }}
                                >
                                    {
                                        currencyList && currencyList.map((item) => {
                                            return <Select.Option value={item}>{item}</Select.Option>
                                        })
                                    }
                                </Select>
                            </Form.Item>
    
                            <Form.Item label='Correo electrónico'  labelAlign='left' name='email'>
                                <Input />
                            </Form.Item>
      
                            <Form.Item  labelAlign='left' label='Habilitado' name='enabled' valuePropName='checked'>
                                <Switch />
                            </Form.Item>

                    <Form.Item>
                        <Space>
                            <Button
                                type='primary'
                                shape='round'
                                htmlType='submit'
                                onClick={() => handleSave()}
                            >
                                Agregar
                            </Button>
                            <Button
                                type='ghost'
                                shape='round'
                                onClick={handleClear}
                            >
                                Cancelar
                            </Button>
                        </Space>
                    </Form.Item>
                    <Form.Item name='deleteable' hidden valuePropName='checked'>
                        <Switch />
                    </Form.Item>
                    <Form.Item name='clientAddresses' hidden>
                        <List />
                    </Form.Item>
                    <Form.Item name='personTypeName' hidden>
                        <Input />
                    </Form.Item>
                    <Form.Item name='personName' hidden>
                        <Input />
                    </Form.Item>
                </Form>
            </Modal>
    </div>
  );
};
