import './css/DashboardScreen.css';
import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import 'moment/locale/es';
import { Row, Col } from 'antd';
import { useLazyQuery, useMutation } from '@apollo/client';
import { Table, Switch, Tag } from 'antd';
import { BasicKPI } from './components/BasicKPI';
import { StandardKPI } from './components/StandardKPI';
import { BasicIndicator } from './components/BasicIndicator';
import {
    fetchHistoricalData
} from '../../queries/DashboardQueries';
import { FilterOutlined, UserOutlined, RadarChartOutlined } from '@ant-design/icons';
import KPI from '../../kpi/kpi';
import { useUser } from '../../../../context/UserContext';

export const DashboardScreen = (props) => {
    const [getHistoricalData, dataHistoricalData] = useLazyQuery(fetchHistoricalData, { fetchPolicy: 'no-cache' });
    const [incidentReport, setIncidentReport] = useState([]);
    const [ordersTable, setOrdersTable] = useState([]);
    const { user } = useUser();
    const [roleName, setRoleName] = useState('');
    const history = useHistory();

    useEffect(() => {
        setRoleName(user.roleName);
    }, [user]);

    const kpiConnector = new KPI(dataHistoricalData);
    const columns1 = [
        {
            title: 'Número de Orden',
            key: 'orderNumber',
            dataIndex: 'orderNumber'
        },
        {
            title: 'Nombre del Cliente',
            key: 'custFullName',
            dataIndex: 'custFullName'
        },
        {
            title: 'Dirección',
            key: 'address',
            dataIndex: 'address'
        },
        {
            title: 'Peso (Kg)',
            key: 'weight',
            dataIndex: 'weight'
        },
        {
            title: 'Estado',
            key: 'status',
            dataIndex: 'status',
            render: tag => (
                <>
                    <Tag color={tag !== "none" ? tag !== "undelivered" ? tag !== "partial_delivered" ? tag !== "delivered" ? '#ffba57' : '#9ccc65' : 'rgb(70, 128, 255)' : 'rgb(150, 150, 150)' : '#ffba57'} key={tag}>
                        {tag !== "none" ? tag !== "undelivered" ? tag !== "partial_delivered" ? tag !== "delivered" ? 'PENDIENTE' : 'ENTREGADO' : 'ENTREGADO PARCIAL' : 'NO ENTREGADO' : 'PENDIENTE'}
                    </Tag>
                </>
            ),
        },
        {
            title: 'Fecha y hora de creación',
            key: 'createdAt',
            dataIndex: 'createdAt'
        }
    ];

    const columns2 = [
        {
            title: 'Placa',
            key: 'vehicleLicencePlate',
            dataIndex: 'vehicleLicencePlate'
        },
        {
            title: 'Nombre',
            key: 'userDisplayName',
            dataIndex: 'userDisplayName'
        },
        {
            title: 'Incidente Reportado',
            key: 'incidentDescription',
            dataIndex: 'incidentDescription'
        },
        {
            title: 'Causa del Incidente',
            key: 'incidentCauseDescription',
            dataIndex: 'incidentCauseDescription'
        },
        {
            title: 'Gestión',
            key: 'enabled',
            dataIndex: 'enabled',
            render: tag => (
                <>
                    <Tag color={tag ? '#9ccc65' : '#ffba57'} key={tag}>
                        {tag ? 'GESTIONADO' : 'PENDIENTE'}
                    </Tag>
                </>
            ),
        },
        {
            title: 'Fecha y hora del incidente',
            key: 'incidentDatetime',
            dataIndex: 'incidentDatetime'
        }
    ];

    useEffect(() => {
        getHistoricalData({
            variables: {
                rangeDate1: localStorage.getItem("range-date-1"),
                rangeDate2: localStorage.getItem("range-date-2"),
            }
        });
    }, []);

    useEffect(() => {
        getHistoricalData({
            variables: {
                rangeDate1: localStorage.getItem("range-date-1"),
                rangeDate2: localStorage.getItem("range-date-2"),
            }
        });
    }, [props.flag]);


    useEffect(() => {
        setIncidentReport([]);
        setOrdersTable([]);

        if (!dataHistoricalData.loading && dataHistoricalData.data && dataHistoricalData.data.incidentReport.length > 0) {
            dataHistoricalData.data.incidentReport.map((item) => {
                const deleteable = true;
                const {
                    id,
                    vehicleId,
                    userId,
                    incidentId,
                    incidentCauseId,
                    observations,
                    incidentManagementId,
                    enabled,
                    incidentDatetime,
                    user,
                    vehicle,
                    incident,
                    incidentCause,
                    incidentManagement,
                    updated_at
                } = item;

                return setIncidentReport((current) => [...current, {
                    id,
                    vehicleId,
                    vehicleLicencePlate: vehicle.licencePlate,
                    userId,
                    userDisplayName: user.displayName,
                    incidentId,
                    incidentDescription: incident.description,
                    incidentCauseId,
                    incidentCauseDescription: (incidentCause) ? incidentCause.description : "",
                    observations,
                    incidentManagementId,
                    incidentManagementDescription: (incidentManagement) ? incidentManagement.description : "",
                    enabled,
                    deleteable,
                    incidentDatetime: moment(incidentDatetime).local().format('DD/MM/YYYY h:mm a'),
                    updated_at: moment(updated_at).local().format('DD/MM/YYYY h:mm a')
                }]);
            });
        }

        if (!dataHistoricalData.loading && dataHistoricalData.data && dataHistoricalData.data.ordersTable.length > 0) {
            dataHistoricalData.data.ordersTable.map((item) => {

                const {
                    orderNumber,
                    address,
                    custFullName,
                    custIdentificationNumber,
                    custPhoneNumber,
                    weight,
                    createdAt,
                    route
                } = item;

                return setOrdersTable((current) => [...current, {
                    orderNumber,
                    address,
                    custFullName,
                    custIdentificationNumber,
                    custPhoneNumber,
                    weight,
                    createdAt: moment(createdAt).local().format('DD/MM/YYYY h:mm a'),
                    status: route ? route.status : null
                }]);
            });
        }
    }, [dataHistoricalData.loading, dataHistoricalData.data]);
    
    if (roleName !== "" && roleName !== 'admin') {
        history.replace('/scheduling');
    }

    return (
        <div className='children-inner-container'>
            <Row gutter={[25, 25]}>
                <Col xs={24} sm={12} xl={6}>
                    <div className='test-component'>
                        <BasicKPI color={"rgb(70, 128, 255)"} fill={"rgba(70, 128, 255,0.5)"} data={kpiConnector.vehicleUtilizationLevel()} label={"Nivel de utilización de la flota"} unit={"%"} unitPosition={"right"} />
                    </div>
                </Col>
                <Col xs={24} sm={12} xl={6}>
                    <div className='test-component'>
                        <BasicKPI color={"rgb(102, 16, 242)"} fill={"rgba(102, 16, 242,0.5)"} data={kpiConnector.internalVehicleUse()} label={"Uso de vehículos internos"} unit={"%"} unitPosition={"right"} />
                    </div>
                </Col>
                <Col xs={24} sm={12} xl={6}>
                    <div className='test-component'>
                        <BasicKPI color={"rgb(52, 58, 64)"} fill={"rgba(52, 58, 64, 0.5)"} data={kpiConnector.vehicleUse()} label={"Uso de vehículos de la flota"} unit={"%"} unitPosition={"right"} />
                    </div>
                </Col>
                <Col xs={24} sm={12} xl={6}>
                    <div className='test-component'>
                        <BasicKPI color={"rgb(255, 82, 82)"} fill={"rgba(255, 82, 82,0.5)"} data={kpiConnector.averageLoadFactor()} label={"Factor de carga promedio"} unit={"%"} unitPosition={"right"} />
                    </div>
                </Col>
            </Row>
            <br />
            <Row gutter={[25, 25]}>
                <Col xs={24} sm={8} xl={8} >
                <Row gutter={[25, 25]}>
                    <Col span={24} >
                        <div className='test-component5'>
                            <StandardKPI
                                color={"rgb(70, 128, 255)"}
                                fill={"rgba(70, 128, 255, 0.5)"}
                                data={kpiConnector.estimatedDeliveryTimeVsRealDeliveryTime()}
                                value1={kpiConnector.estimatedDeliveryTime()}
                                value2={kpiConnector.realDeliveryTime()}
                                label={"Cumplimiento en la Entrega"}
                                label1={"Tiempo estimado de entrega"}
                                label2={"Tiempo real de entrega"}
                                unit={"%"}
                                unitPosition={"right"}
                                unit2={" Horas"}
                                unit2Position={"right"}
                                condition={true}
                            />
                        </div>
                    </Col>
                </Row>
                <br />
                <Row gutter={[25, 25]}>
                    <Col span={24} >
                        <div className='test-component5'>
                            <StandardKPI
                                color={"rgb(156, 204, 101)"}
                                fill={"rgba(156, 204, 101, 0.5)"}
                                data={kpiConnector.mobilizedTonsVsTotalVehicleCapacity()}
                                value1={kpiConnector.mobilizedTons()}
                                value2={kpiConnector.totalVehicleCapacity()}
                                label={"Capacidad Total Disponible"}
                                label1={"Kilogramos Mobilizados"}
                                label2={"Capacidad Total Flota"}
                                unit={" Kg"}
                                unitPosition={"right"}
                                unit2={" Kg"}
                                unit2Position={"right"}
                                condition={false} />
                        </div>
                    </Col>
                </Row>
                </Col>
                <Col xs={24} sm={16} xl={16} >
                    <div className='test-component2'>
                        <Row>
                            <h5 className="dashboard-subtitle">Últimos Pedidos</h5>
                        </Row>
                        <Row>
                            <Table
                                className="dashboard-table"
                                columns={columns1}
                                dataSource={ordersTable}
                                pagination={{ pageSize: 10 }}
                            />
                        </Row>
                    </div>
                </Col>
            </Row>
            <br />
            <Row gutter={[25, 25]}>
                <Col  xs={24} sm={8} xl={8} >
                    <div className='test-component3'>
                        <BasicIndicator bgColor={"#ffba57"} stroke={"white"} cumulative={kpiConnector.incidentReportCountSumary()} value={kpiConnector.incidentReportCountLast()} label={"Incidentes"} grew={kpiConnector.incidentReportCountGrew()} data={kpiConnector.incidentReportCount()}>
                            <FilterOutlined className='basic-indicator-icon' />
                        </BasicIndicator>
                    </div>
                </Col>
                <Col  xs={24} sm={8} xl={8} >
                    <div className='test-component3'>
                        <BasicIndicator bgColor={"var(--primaryColor)"} stroke={"white"} cumulative={kpiConnector.routesQuantitySumary()} value={kpiConnector.routesQuantityLast()} label={"Rutas"} grew={kpiConnector.routesQuantityGrew()} data={kpiConnector.routesQuantity()}>
                            <UserOutlined className='basic-indicator-icon' />
                        </BasicIndicator>
                    </div>
                </Col>
                <Col  xs={24} sm={8} xl={8} >
                    <div className='test-component3'>
                        <BasicIndicator bgColor={"#9ccc65"} stroke={"white"} cumulative={kpiConnector.totalOrdersDeliveredSumary()} value={kpiConnector.totalOrdersDeliveredLast()} label={"Pedidos"} grew={kpiConnector.totalOrdersDeliveredGrew()} data={kpiConnector.totalOrdersDelivered()}>
                            <RadarChartOutlined className='basic-indicator-icon' />
                        </BasicIndicator>
                    </div>
                </Col>
            </Row>
            <br />
            <Row gutter={[25, 25]}>
                <Col xs={24} sm={14} xl={16} >
                    <div className='test-component4'>
                        <Row>
                            <h5 className="dashboard-subtitle">Últimos Incidentes</h5>
                        </Row>
                        <Row>
                            <Table
                                className="dashboard-table2"
                                columns={columns2}
                                dataSource={incidentReport}
                                pagination={{ pageSize: 20 }}
                            />
                        </Row>
                    </div>
                </Col>
                <Col  xs={24} sm={10} xl={8} >
                    <Row gutter={[25, 25]}>
                        <Col span={24} >
                            <div className='test-component5'>
                                
                                <StandardKPI
                                    color={"rgb(255, 186, 87)"}
                                    fill={"rgba(255, 186, 87,0.5)"}
                                    data={kpiConnector.totalOrdersDispatchedVsTotalOrdersDelivered()}
                                    value1={kpiConnector.totalOrdersDeliveredComplete()}
                                    value2={kpiConnector.totalOrdersDispatched()}
                                    label={"Pedidos Entregados Completamente Vs Pedidos Despachados"}
                                    label1={"Pedidos Entregados Completamente"}
                                    label2={"Pedidos Despachados"}
                                    unit={"%"}
                                    unitPosition={"right"}
                                    unit2={""}
                                    unit2Position={"right"}
                                    condition={false} />
                            </div>
                        </Col>
                    </Row>
                    <br />
                    <Row gutter={[25, 25]}>
                        <Col span={24} >
                            <div className='test-component5'>
                                <StandardKPI
                                    color={"var(--primaryColor)"}
                                    fill={"#93b5ff"}
                                    data={kpiConnector.totalOrdersDispatchedVsTotalOrdersPartiallyDelivered()}
                                    value1={kpiConnector.totalOrdersPartiallyDelivered()}
                                    value2={kpiConnector.totalOrdersDispatched()}
                                    label={"Pedidos Entregados Parcialmente Vs Pedidos Despachados"}
                                    label1={"Pedidos Entregados Parcialmente"}
                                    label2={"Pedidos Despachados"}
                                    unit={"%"}
                                    unitPosition={"right"}
                                    unit2={""}
                                    unit2Position={"right"}
                                    condition={false} />
                            </div>
                        </Col>
                    </Row>
                    <br />
                    <Row gutter={[25, 25]}>
                        <Col span={24} >
                            <div className='test-component5'>
                                <StandardKPI
                                    color={"rgb(52, 58, 64)"}
                                    fill={"rgba(52, 58, 64, 0.5)"}
                                    data={kpiConnector.totalOrdersDispatchedVsTotalOrdersUndelivered()}
                                    value1={kpiConnector.totalOrdersUndelivered()}
                                    value2={kpiConnector.totalOrdersDispatched()}
                                    label={"Pedidos No Entregados Vs Pedidos Despachados"}
                                    label1={"Pedidos No Entregados"}
                                    label2={"Pedidos Despachados"}
                                    unit={"%"}
                                    unitPosition={"right"}
                                    unit2={""}
                                    unit2Position={"right"}
                                    condition={false} />
                            </div>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </div>
    );
}
