import React, { useState, useEffect } from 'react';
import { Form, Table, Switch, Input, Descriptions, Button, Select, Space, Skeleton, InputNumber, Row, Col, Popover, message, Modal } from 'antd';
import { DeleteFilled, EditFilled, SearchOutlined } from '@ant-design/icons';
import Swal from 'sweetalert2';
import { useLazyQuery, useSubscription, useMutation, useApolloClient, } from '@apollo/client';
import { CarOutlined, CheckOutlined, ClockCircleOutlined, EditOutlined } from '@ant-design/icons';
import { isValidLatLng } from '../../../../../helpers/utils';
import localStorageService from '../../../../../services/localStorageService';
import { VehiclesIndicator } from './VehiclesIndicator';
import { ConfigurationMoreSettings } from '../../../ConfigurationMoreSettings';
import axios from "axios";
import {
    fetchVehicles,
    insertVehicle,
    updateVehicle,
    deleteVehicle,
    fetchVehicleBrands,
    fetchDistributionCenters,
    fetchVehicleTypes,
    fetchTransportCompanies,
    fetchAvailableDrivers,
    insertUserVehicle,
    updateUserVehicle,
    fetchUserVehicle,
    removeUserVehicle
} from '../../queries/vehiclesQueries';

const companyId = localStorageService.get('companyId');

const role = localStorageService.get("role");

const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
};

const handleReset = (clearFilters) => {
    clearFilters();
};

const getColumnSearchPropsText = (title, dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
        <div style={{ padding: 8 }}>
            <Input
                placeholder={`Buscar ${title}`}
                value={selectedKeys[0]}
                onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                style={{ width: 188, marginBottom: 8, display: 'block' }}
            />
            <Space>
                <Button
                    type="primary"
                    onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                    icon={<SearchOutlined />}
                    size="small"
                    style={{ width: 90 }}
                >
                    Buscar
                </Button>
                <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
                    Restablecer
                </Button>
            </Space>
        </div>
    ),
    filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
    onFilter: (value, record) =>
        record[dataIndex]
            ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
            : '',
    onFilterDropdownVisibleChange: visible => {
        if (visible) {
            /*if(Object.keys(searchText).length > 0) {
                setTimeout(() => searchInput.select(), 100);
            }*/
        }
    },
});

export const VehiclesScreen = () => {
    const apolloClient = useApolloClient();
    const { data: dataVehicles, loading: loadingVehicles } = useSubscription(fetchVehicles, { variables: {companyId}, fetchPolicy: 'no-cache' });
    const [getVehicleBrands, dataVehicleBrands] = useLazyQuery(fetchVehicleBrands, { variables: {companyId}, fetchPolicy: 'no-cache' });
    const [getDistributionCenters, dataDistributionCenters] = useLazyQuery(fetchDistributionCenters, { variables: {companyId}, fetchPolicy: 'no-cache' });
    const [getVehicleTypes, dataVehicleTypes] = useLazyQuery(fetchVehicleTypes, { variables: {companyId}, fetchPolicy: 'no-cache' });
    const [getTransportCompanies, dataTransportCompanies] = useLazyQuery(fetchTransportCompanies, { variables: {companyId}, fetchPolicy: 'no-cache' });
    const { data: dataDrivers, loading: loadingDrivers } = useSubscription(fetchAvailableDrivers, { variables: {companyId}, fetchPolicy: 'no-cache' });
    const [insVehicle] = useMutation(insertVehicle, { variables: {companyId}, fetchPolicy: 'no-cache' });
    const [updVehicle] = useMutation(updateVehicle, { fetchPolicy: 'no-cache' });
    const [delVehicle] = useMutation(deleteVehicle, { fetchPolicy: 'no-cache' });
    const [insUserVehicle] = useMutation(insertUserVehicle, { fetchPolicy: 'no-cache' });
    const [updUserVehicle] = useMutation(updateUserVehicle, { fetchPolicy: 'no-cache' });
    const [delUserVehicle] = useMutation(removeUserVehicle, { fetchPolicy: 'no-cache' });

    const [vehicles, setVehicles] = useState([]);
    const [vehicleBrands, setVehicleBrands] = useState([]);
    const [distributionCenters, setDistributionCenters] = useState([]);
    const [vehicleTypes, setVehicleTypes] = useState([]);
    const [transportCompanies, setTransportCompanies] = useState([]);
    const [drivers, setDrivers] = useState([]);
    const [isEditing, setIsEditing] = useState(false);
    const [canSelectDriver, setCanSelectDriver] = useState(true);
    const [clickedRow, setClickedRow] = useState("");

    const [form] = Form.useForm();

    const [isModalVisible, setIsModalVisible] = useState(false);

    const showModal = () => {
        setIsModalVisible(true);
    };

    const handleOk = () => {
        setIsModalVisible(false);
    };

    const handleCancel = () => {
        setIsModalVisible(false);
    };

    useEffect(() => {
        getVehicleBrands();
        getVehicleTypes();
        getTransportCompanies();
        getDistributionCenters();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        setVehicleBrands([]);

        if (!dataVehicleBrands.loading && dataVehicleBrands.data && dataVehicleBrands.data.vehicleBrands.length > 0) {
            dataVehicleBrands.data.vehicleBrands.map((item) => {
                const { id, name } = item;

                return setVehicleBrands((current) => [...current, {
                    id,
                    name
                }]);
            });
        }
    }, [dataVehicleBrands.loading, dataVehicleBrands.data]);

    useEffect(() => {
        setDistributionCenters([]);

        if (!dataDistributionCenters.loading && dataDistributionCenters.data && dataDistributionCenters.data.distributionCenters.length > 0) {
            dataDistributionCenters.data.distributionCenters.map((item) => {
                const { id, name } = item;

                return setDistributionCenters((current) => [...current, {
                    id,
                    name
                }]);
            });
        }
    }, [dataDistributionCenters.loading, dataDistributionCenters.data]);

    useEffect(() => {
        setVehicleTypes([]);

        if (!dataVehicleTypes.loading && dataVehicleTypes.data && dataVehicleTypes.data.vehicleTypes.length > 0) {
            dataVehicleTypes.data.vehicleTypes.map((item) => {
                const { id, name } = item;

                return setVehicleTypes((current) => [...current, {
                    id,
                    name
                }]);
            });
        }
    }, [dataVehicleTypes.loading, dataVehicleTypes.data]);

    useEffect(() => {
        setTransportCompanies([]);

        if (!dataTransportCompanies.loading && dataTransportCompanies.data && dataTransportCompanies.data.transportCompanies.length > 0) {
            dataTransportCompanies.data.transportCompanies.map((item) => {
                const { id, name } = item;

                return setTransportCompanies((current) => [...current, {
                    id,
                    name
                }]);
            });
        }
    }, [dataTransportCompanies.loading, dataTransportCompanies.data]);

    useEffect(() => {
        setDrivers([]);

        if (!loadingDrivers && dataDrivers && dataDrivers.users.length > 0) {
            dataDrivers.users.map((item) => {
                const { id, displayName } = item;

                return setDrivers((current) => [...current, {
                    id,
                    displayName
                }]);
            });
        }
    }, [loadingDrivers, dataDrivers]);

    useEffect(() => {
        setVehicles([]);

        if (!loadingVehicles && dataVehicles && dataVehicles.vehicles.length > 0) {
            dataVehicles.vehicles.map((item) => {
                const deleteable = item.userVehicles_aggregate.aggregate.count === 0;
                const { id, licencePlate, model, weight, height, width, depth, tare, vehicleBrandId, vehicleTypeId, transportCompanyId, schedulingStartLocation, vehicleDistributionCenterId, cubicMeters, addressExit } = item;
                const vehicleBrandName = item.vehicleBrand?.name;
                const vehicleDistributionCenter = item.vehicleDistributionCenter?.name;
                const vehicleTypeName = item.vehicleType?.name;
                const transportCompanyName = item.transportCompany?.name;
                const userId = item.userVehicles.length > 0 ? item.userVehicles[0].userId : undefined;
                const hasAssignedRoute = item.userVehicles.length > 0 ? item.userVehicles[0].hasAssignedRoute : undefined;
                const driverName = item.userVehicles.length > 0 ? item.userVehicles[0].user?.displayName : undefined;

                return setVehicles((current) => [...current, {
                    id,
                    licencePlate,
                    model: model ?? "",
                    weight: weight ?? 0,
                    height: height ?? 0,
                    width: width ?? 0,
                    depth: depth ?? 0,
                    tare: tare ?? 0,
                    schedulingStartLocation: schedulingStartLocation ?? "",
                    vehicleBrandId,
                    vehicleTypeId,
                    transportCompanyId,
                    vehicleBrandName: vehicleBrandName ?? "",
                    vehicleTypeName: vehicleTypeName ?? "",
                    transportCompanyName: transportCompanyName ?? "",
                    vehicleDistributionCenterId,
                    cubicMeters,
                    addressExit: addressExit ?? "",
                    vehicleDistributionCenter: vehicleDistributionCenter ?? "",
                    userId,
                    driverName,
                    hasAssignedRoute,
                    deleteable
                }]);
            });
        }
    }, [loadingVehicles, dataVehicles]);

    const columns = [
        {
            title: 'Placa',
            key: 'licencePlate',
            dataIndex: 'licencePlate',
            sorter: (a, b) => a.licencePlate.localeCompare(b.licencePlate),
            ...getColumnSearchPropsText("Placa", "licencePlate")
        },
        {
            title: 'Conductor',
            key: 'driverName',
            dataIndex: 'driverName',
            sorter: (a, b) => a.driverName?.localeCompare(b.driverName),
            ...getColumnSearchPropsText("Conductor", "driverName")
        },
        {
            title: 'Modelo',
            key: 'model',
            dataIndex: 'model',
            sorter: (a, b) => a.model.localeCompare(b.model),
            ...getColumnSearchPropsText("Modelo", "model")
        },
        {
            title: 'Capacidad Kg',
            key: 'weight',
            dataIndex: 'weight',
            align: 'center',
            sorter: {
                compare: (a, b) => a.weight - b.weight,
            },
            //...getColumnSearchPropsText("Peso", "weight")
        },
        {
            title: 'Alto',
            key: 'height',
            dataIndex: 'height',
            align: 'center',
            sorter: {
                compare: (a, b) => a.height - b.height,
            },
            //...getColumnSearchPropsText("Alto", "height")
        },
        {
            title: 'Ancho Mtrs',
            key: 'width',
            dataIndex: 'width',
            align: 'center',
            sorter: {
                compare: (a, b) => a.width - b.width,
            },
            //...getColumnSearchPropsText("Ancho", "width")
        },
        {
            title: 'Profundidad Mtrs',
            key: 'depth',
            dataIndex: 'depth',
            align: 'center',
            sorter: {
                compare: (a, b) => a.depth - b.depth,
            },
        },
        {
            title: 'Metros cúbicos',
            key: 'cubicMeters',
            dataIndex: 'cubicMeters',
            align: 'center',
            sorter: {
                compare: (a, b) => a.cubicMeters - b.cubicMeters,
            },
        },
        {
            title: 'Tara',
            key: 'tare',
            dataIndex: 'tare',
            align: 'center',
            sorter: {
                compare: (a, b) => a.tare - b.tare,
            },
        },
        {
            title: 'Marca',
            key: 'vehicleBrandName',
            dataIndex: 'vehicleBrandName',
            sorter: (a, b) => a.vehicleBrandName.localeCompare(b.vehicleBrandName),
            ...getColumnSearchPropsText("Marca", "vehicleBrandName")
        },
        {
            title: 'Tipo',
            key: 'vehicleTypeName',
            dataIndex: 'vehicleTypeName',
            sorter: (a, b) => a.vehicleTypeName.localeCompare(b.vehicleTypeName),
            ...getColumnSearchPropsText("Tipo", "vehicleTypeName")
        },
        {
            title: 'Centro de distribución',
            key: 'vehicleDistributionCenter',
            dataIndex: 'vehicleDistributionCenter',
            sorter: (a, b) => a.vehicleDistributionCenter.localeCompare(b.vehicleDistributionCenter),
            ...getColumnSearchPropsText("Centro de distribución", "vehicleDistributionCenter")
        },
        {
            title: 'Transportista',
            key: 'transportCompanyName',
            dataIndex: 'transportCompanyName',
            sorter: (a, b) => a.transportCompanyName.localeCompare(b.transportCompanyName),
            ...getColumnSearchPropsText("Transportista", "transportCompanyName")
        },
        {
            title: 'Dirección de salida',
            key: 'addressExit',
            dataIndex: 'addressExit',
            ...getColumnSearchPropsText("Dirección", "addressExit")
        },
        {
            title: 'Ubicación de salida',
            key: 'schedulingStartLocation',
            dataIndex: 'schedulingStartLocation',
            ...getColumnSearchPropsText("Ubicación", "schedulingStartLocation")
        }
    ];

    const handleSave = async (values) => {
        const { id, licencePlate, model, weight, height, width, depth, tare, vehicleBrandId, vehicleTypeId, transportCompanyId, schedulingStartLocation, userId, vehicleDistributionCenterId, cubicMeters, addressExit} = values;

        try {
            if (!isEditing) {
                const res = await insVehicle({
                    variables: {
                        licencePlate,
                        model,
                        weight,
                        height,
                        width,
                        depth,
                        tare,
                        vehicleBrandId,
                        vehicleTypeId,
                        transportCompanyId,
                        schedulingStartLocation,
                        vehicleDistributionCenterId,
                        cubicMeters,
                        addressExit,
                    },
                });
                if (res.data.insert_vehicles.affected_rows > 0) {
                    const { id } = res.data.insert_vehicles.returning[0];

                    if (userId !== undefined) {
                        await insUserVehicle({
                            variables: { userId, vehicleId: id }
                        });
                    }
                    Swal.fire({
                        title: 'Vehiculo Creado',
                        icon: 'success',
                        showConfirmButton: false,
                        timer: 3000
                    })
                }
            } else {
                const res = await updVehicle({
                    variables: {
                        id,
                        licencePlate,
                        model,
                        weight,
                        height,
                        width,
                        depth,
                        tare,
                        vehicleBrandId,
                        vehicleTypeId,
                        transportCompanyId,
                        schedulingStartLocation,
                        vehicleDistributionCenterId,
                        cubicMeters,
                        addressExit
                    }
                });

                if (res.data.update_vehicles.affected_rows > 0) {

                    if (userId !== undefined && userId !== "") {
                        apolloClient.query({
                            query: fetchUserVehicle,
                            variables: { vehicleId: id }
                        }).then(async ({ data }) => {
                            if (data.userVehicle.length > 0) {
                                await updUserVehicle({
                                    variables: {
                                        id: data.userVehicle[0].id,
                                        userId
                                    }
                                });
                            } else {
                                await insUserVehicle({
                                    variables: { userId, vehicleId: id }
                                });
                            }
                        });
                    } else {
                        await delUserVehicle({
                            variables: {
                                vehicleId: id
                            }
                        })
                    }
                    Swal.fire({
                        title: 'Vehiculo Editado',
                        icon: 'success',
                        showConfirmButton: false,
                        timer: 3000
                    })
                    /*if (canSelectDriver) {
                        if (userId !== undefined) {
                            apolloClient.query({
                                query: fetchUserVehicle,
                                variables: { vehicleId: id }
                            }).then(async ({ data }) => {
                                if (data.userVehicle.length > 0) {
                                    await updUserVehicle({
                                        variables: {
                                            id: data.userVehicle[0].id,
                                            userId
                                        }
                                    });
                                } else {
                                    await insUserVehicle({
                                        variables: { userId, vehicleId: id }
                                    });
                                }
                            });
                        }
                    }*/
                }
            }
            form.setFieldsValue({
                id: '',
                licencePlate: undefined,
                model: '',
                weight: 0,
                height: 0,
                width: 0,
                depth: 0,
                tare: 0,
                vehicleBrandId: undefined,
                vehicleTypeId: undefined,
                transportCompanyId: undefined,
                schedulingStartLocation: '',
                vehicleBrandName: undefined,
                vehicleTypeName: undefined,
                transportCompanyName: undefined,
                userId: undefined,
                driverName: undefined,
                deleteable: true,
                vehicleDistributionCenterId : null,
                vehicleDistributionCenter: undefined,
                cubicMeters: 0,
                addressExit: undefined
            });

            setIsEditing(false);
            setCanSelectDriver(true);
            setClickedRow("");
        } catch (error) {
            if (error.toString().includes("duplicate")) {
                message.error('La placa que inteta ingresar ya se encuentra registrada, no se aceptan valores duplicados.', 5)
            } else {
                message.error(error.toString());
            }
            //console.error(error);
        }
    };

    const handleDelete = (record) => {
        const  id  = record;

        Swal.fire({
            title: '¿Seguro desea eliminar?',
            text: "Este proceso es irreversible!",
            icon: 'question',
            showCancelButton: true,
            confirmButtonColor: 'var(--primaryColor)',
            cancelButtonColor: '#B6B6B6',
            confirmButtonText: 'Sí, eliminar',
            cancelButtonText: 'No, cancelar',
        }).then(async (result) => {
            if (result.isConfirmed) {
                try {
                    const res = await delVehicle({
                        variables: {
                            id
                        },
                        fetchPolicy: 'no-cache'
                    });

                    if (res.data.delete_vehicles.affected_rows > 0) {
                        handleCancel();
                        Swal.fire({
                            title: 'Registro eliminado',
                            icon: 'success',
                            showConfirmButton: false,
                            timer: 1000
                        });
                        setIsEditing(false);
                        setCanSelectDriver(true);
                        setClickedRow("");
                        handleClear();
                    }
                } catch (error) {
                    console.error(error)
                }
            }
        });
    };

    const handleEdit = (record) => {
        const { id, licencePlate, model, weight, height, width, depth, tare, vehicleBrandId, vehicleTypeId, transportCompanyId, schedulingStartLocation, vehicleBrandName, vehicleTypeName, transportCompanyName, userId, driverName, deleteable, vehicleDistributionCenterId, vehicleDistributionCenter, cubicMeters, addressExit  } = record;

        setIsEditing(true);

        form.setFieldsValue({
            id,
            licencePlate,
            model,
            weight,
            height,
            width,
            depth,
            tare,
            vehicleBrandId,
            vehicleTypeId,
            transportCompanyId,
            schedulingStartLocation,
            vehicleBrandName,
            vehicleTypeName,
            transportCompanyName,
            userId,
            driverName,
            deleteable,
            vehicleDistributionCenter,
            vehicleDistributionCenterId,
            cubicMeters,
            addressExit
        });
    };

    const handleClear = () => {
        setIsEditing(false);
        setCanSelectDriver(true);
        setClickedRow("");
        handleCancel();

        form.setFieldsValue({
            id: '',
            licencePlate: undefined,
            model: '',
            weight: 0,
            height: 0,
            width: 0,
            depth: 0,
            tare: 0,
            vehicleBrandId: undefined,
            vehicleTypeId: undefined,
            transportCompanyId: undefined,
            schedulingStartLocation: '',
            vehicleBrandName: undefined,
            vehicleTypeName: undefined,
            transportCompanyName: undefined,
            userId: undefined,
            driverName: undefined,
            deleteable: true,
            vehicleDistributionCenterId : null,
            vehicleDistributionCenter : undefined,
            cubicMeters: 0,
            addresExit:''
        });
    };

    const formItemLayout = {
        labelCol: {
            lg: { span: 9 }
        },
        wrapperCol: {
            lg: { span: 14 }
        },
    };
    const destinarionAutoComplet = async (_address) => {
        if (_address.length > 0) {
          // verificar si no viene la latitud, longitud,
          // y si viene la dirección, convertirla a lat, lng
          let newAddress = "";
          const address = _address
            .replace(",", " ")
            .replace(/\s\s+/g, " ")
            .replace("#", "")
            .split(" ");
    
          if (address.length > 0) {
            address.forEach((item) => {
              newAddress += item + "+";
            });
            newAddress = newAddress.substring(0, newAddress.length - 1);
    
            const res = await axios.get(
              `https://maps.googleapis.com/maps/api/geocode/json?key=${process.env.REACT_APP_GOOGLE_MAPS_API_KEY}&components=country:CO&address=${newAddress}`
            );
    
            if (res.status === 200) {
              form.setFieldsValue({
                schedulingStartLocation: `${res.data?.results[0]?.geometry?.location?.lat},${res.data?.results[0]?.geometry?.location?.lng}`,
              });
            }
          }
        }
      };
    return (
        <Col className='children-inner-container-with-bg'>
            <ConfigurationMoreSettings />
            <Row className='children-inner-row'>
                <Col span={5} className='children-inner-col'>
                    <VehiclesIndicator value={vehicles.length} label={"Total Vehículos"}><CarOutlined style={{backgroundColor: 'var(--primaryColor)'}} className="report-indicator-icon"/></VehiclesIndicator>
                </Col>
                <Col span={5} className='children-inner-col'>
                    <VehiclesIndicator value={(vehicles.filter(item => item.userId)).length} label={"Vehículos Activos"}><CheckOutlined style={{backgroundColor: '#9ccc65'}} className="report-indicator-icon"/></VehiclesIndicator>
                </Col>
                <Col span={5} className='children-inner-col'>
                    <VehiclesIndicator value={(vehicles.filter(item => !item.userId)).length} label={"Vehículos Inactivos"}><ClockCircleOutlined style={{backgroundColor: '#ffba57'}} className="report-indicator-icon"/></VehiclesIndicator>
                </Col>
                <Col span={3} offset={3} className='children-inner-col'><Button type="primary" size={"large"} className='children-inner-button' icon={<EditOutlined />} onClick={showModal} >{clickedRow.length === 0 ? 'Crear' : 'Editar'}</Button></Col>
                <Col span={3} className='children-inner-col'><Button type="primary" size={"large"} className='children-inner-button' icon={<DeleteFilled />} onClick={()=> handleDelete(clickedRow)} disabled={role === "user" ? true : (vehicles.filter(item => item.id === clickedRow)[0] && vehicles.filter(item => item.id === clickedRow)[0].hasAssignedRoute ? vehicles.filter(item => item.id === clickedRow)[0].hasAssignedRoute : false)}>Eliminar</Button></Col>
            </Row>
            <Row>
            <Skeleton loading={loadingVehicles} active>
                    <Table
                        columns={columns}
                        dataSource={vehicles}
                        className='children-table'
                        size='small'
                        rowKey='id'
                        pagination={{ pageSize: 13 }}
                        onRow={(record) => {
                            return {
                                onClick: () => {
                                    if(clickedRow.length === 0 || clickedRow !== record.id){
                                        setClickedRow(record.id);
                                        handleEdit(record, setIsEditing, form);
                                    }else{
                                        handleClear(setIsEditing, form);
                                        setClickedRow("");
                                    }
                                },
                            }
                        }}
                        rowClassName={(record) => ['mouse-row-enter', record.id === clickedRow ? 'table-row-color' : ""]}
                    />
                </Skeleton>
            </Row>
            <Modal className='children-modal' visible={isModalVisible} onOk={handleCancel} onCancel={handleCancel} footer={[]} title={clickedRow.length === 0 ? 'Crear Vehículo' : 'Editar Vehículo'} width={500}>
            <Form
                    {...formItemLayout}
                    form={form}
                    name='configuration'
                    onFinish={(values) => {
                        handleSave(values);
                        setClickedRow("");
                    }}
                    initialValues={{
                        id: '',
                        licencePlate: undefined,
                        model: '',
                        weight: 0,
                        height: 0,
                        width: 0,
                        depth: 0,
                        tare: 0,
                        addressExit: undefined,
                        vehicleBrandId: undefined,
                        vehicleTypeId: undefined,
                        transportCompanyId: undefined,
                        schedulingStartLocation: '',
                        vehicleBrandName: undefined,
                        vehicleTypeName: undefined,
                        userId: undefined,
                        driverName: undefined,
                        transportCompanyName: undefined,
                        vehicleDistributionCenterId : null,
                        vehicleDistributionCenter: undefined,
                        cubicMeters: 0,
                    }}
                >
                    <Form.Item name='id' hidden>
                        <Input />
                    </Form.Item>
                   
                            <Form.Item
                                label='Placa'
                                labelAlign='left'
                                hasFeedback
                                name='licencePlate'
                                rules={[{ required: true, message: '' }]}
                            >
                                <Input />
                            </Form.Item>
                            <Form.Item
                                label='Modelo'
                                labelAlign='left'
                                hasFeedback
                                name='model'
                            >
                                <Input />
                            </Form.Item>
         
                            <Form.Item
                                label='Capacidad Kg'
                                labelAlign='left'
                                hasFeedback
                                name='weight'
                            >
                                <InputNumber min={0} />
                            </Form.Item>
            
                            <Form.Item
                            label='Alto Mtrs'
                                labelAlign='left'
                                hasFeedback
                                name='height'
                            >
                                <InputNumber min={0} />
                            </Form.Item>
           
                            <Form.Item
                            label='Ancho Mtrs'
                                labelAlign='left'
                                hasFeedback
                                name='width'
                            >
                                <InputNumber min={0} />
                            </Form.Item>
                            <Form.Item
                            label='Profundidad Mtrs'
                                labelAlign='left'
                                hasFeedback
                                name='depth'
                            >
                                <InputNumber min={0} />
                            </Form.Item>
                            <Form.Item
                            label='Metros Cúbicos'
                                labelAlign='left'
                                hasFeedback
                                name='cubicMeters'
                            >
                                <InputNumber min={0} />
                            </Form.Item>
                            <Form.Item
                            label='Tara'
                                labelAlign='left'
                                hasFeedback
                                name='tare'
                            >
                                <InputNumber min={0} />
                            </Form.Item>
                            <Form.Item
                            label='Marca'
                                labelAlign='left'
                                hasFeedback
                                name='vehicleBrandId'
                                rules={[{ required: true, message: '' }]}
                            >
                                <Select
                                    placeholder='Seleccione...'
                                    allowClear
                                    onChange={(value, object) => {
                                        if (value !== undefined) {
                                            return form.setFieldsValue({ vehicleBrandId: value, vehicleBrandName: object.children })
                                        }
                                    }}
                                >
                                    {
                                        vehicleBrands.map((item) => {
                                            return <Select.Option value={item.id}>{item.name}</Select.Option>
                                        })
                                    }

                                </Select>
                            </Form.Item>
                            <Form.Item
                            label='Tipo'
                                labelAlign='left'
                                hasFeedback
                                name='vehicleTypeId'
                                rules={[{ required: true, message: '' }]}
                            >
                                <Select
                                    placeholder='Seleccione...'
                                    allowClear
                                    onChange={(value, object) => {
                                        if (value !== undefined) {
                                            return form.setFieldsValue({ vehicleTypeId: value, vehicleTypeName: object.children })
                                        }
                                    }}
                                >
                                    {
                                        vehicleTypes.map((item) => {
                                            return <Select.Option value={item.id}>{item.name}</Select.Option>
                                        })
                                    }

                                </Select>
                            </Form.Item>
                            <Form.Item
                            label='Transportista'
                                labelAlign='left'
                                hasFeedback
                                name='transportCompanyId'
                                rules={[{ required: true, message: '' }]}
                            >
                                <Select
                                    placeholder='Seleccione...'
                                    allowClear
                                    onChange={(value, object) => {
                                        if (value !== undefined) {
                                            return form.setFieldsValue({ transportCompanyId: value, transportCompanyName: object.children })
                                        }
                                    }}
                                >
                                    {
                                        transportCompanies.map((item) => {
                                            return <Select.Option value={item.id}>{item.name}</Select.Option>
                                        })
                                    }

                                </Select>
                            </Form.Item>
                            <Form.Item
                            label='Direccion de Salida'
                                labelAlign='left'
                                hasFeedback
                                name='addressExit'
                                onChange={(value, object) =>
                                    destinarionAutoComplet(value.target.value)
                                  }
                                rules={[{ required: false, message: '' }]}
                            >
                                <Input />
                            </Form.Item>
                            <Form.Item
                            label='Ubicación'
                                labelAlign='left'
                                hasFeedback
                                name='schedulingStartLocation'
                                rules={[
                                    //{ required: true, message: 'Es requerido'},
                                    ({ _, __ }) => ({
                                        validator(_, value) {
                                            if (value !== '') {
                                                const res = isValidLatLng(value);

                                                if (!res) {
                                                    return Promise.reject('No es una latitud/longitud válida')
                                                } else {
                                                    return Promise.resolve();
                                                }
                                            }
                                            return Promise.resolve();
                                        }
                                    })
                                ]}
                            >
                                <Input />
                            </Form.Item>
                            <Form.Item
                            label='Conductor' 
                                labelAlign='left'
                                hasFeedback
                                name='driverName'
                                
                            >
                                <Select
                                    disabled={!canSelectDriver || (vehicles.filter(item => item.id === clickedRow)[0] && vehicles.filter(item => item.id === clickedRow)[0].hasAssignedRoute ? vehicles.filter(item => item.id === clickedRow)[0].hasAssignedRoute : false)}
                                    
                                    placeholder='Seleccione...'
                                    allowClear
                                    onChange={(value, object) => {
                                        if (value !== undefined) {
                                            return form.setFieldsValue({ userId: value, driverName: object.children })
                                        }
                                        return form.setFieldsValue({ userId: "", driverName: "" })
                                    }}
                                >
                                    {
                                        drivers.map((item) => {
                                            return <Select.Option value={item.id}>{item.displayName}</Select.Option>
                                        })
                                    }

                                </Select>
                            </Form.Item>
                            <Form.Item
                            label='Centro de distribución' 
                                labelAlign='left'
                                hasFeedback
                                name='vehicleDistributionCenterId'
                                
                            >
                                <Select
                                    placeholder='Seleccione...'
                                    allowClear
                                    tyle={{ width: '100%' }}
                                    onChange={(value, object) => {
                                        if (value !== undefined) {
                                            return form.setFieldsValue({ vehicleDistributionCenterId : value, vehicleDistributionCenter: object.children })
                                        }
                                        return form.setFieldsValue({ vehicleDistributionCenterId : null, vehicleDistributionCenter: "" })
                                    }}
                                >
                                    {
                                        distributionCenters.map((item) => {
                                            return <Select.Option value={item.id}>{item.name}</Select.Option>
                                        })
                                    }

                                </Select>
                            </Form.Item>
                

                    <Form.Item name="button1">
                        <Space>
                            <Button
                                type='primary'
                                shape='round'
                                htmlType='submit'
                                onClick={handleCancel}
                            >
                                {isEditing ? 'Modificar' : 'Agregar'}
                            </Button>
                            <Button
                                type='ghost'
                                shape='round'
                                onClick={handleClear}
                            >
                                Cancelar
                            </Button>
                        </Space>
                    </Form.Item>
                    <Form.Item name='deleteable' hidden valuePropName='checked'>
                        <Switch />
                    </Form.Item>
                    <Form.Item name='vehicleBrandName' hidden>
                        <Input />
                    </Form.Item>
                    <Form.Item name='vehicleDistributionCenter' hidden>
                        <Input />
                    </Form.Item>
                    <Form.Item name='vehicleTypeName' hidden>
                        <Input />
                    </Form.Item>
                    <Form.Item name='transportCompanyName' hidden>
                        <Input />
                    </Form.Item>
                    <Form.Item name='userId' hidden>
                        <Input />
                    </Form.Item>
                </Form>
            </Modal>
        </Col>
    )
}
