export default class KPI{

    constructor(getHistoricalData) {
        this.data = getHistoricalData.data;

        const baseArray = [
            {
                pv: 0
            },
            {
                pv: 0
            },
            { 
                pv: 0
            },
            { 
                pv: 0
            },
            { 
                pv: 0
            },
            { 
                pv: 0
            },
            { 
                pv: 0
            },
            { 
                pv: 0
            },
            { 
                pv: 0
            },
            { 
                pv: 0
            },
            {
                pv: 0
            },
            {
                pv: 0
            },
            { 
                pv: 0
            },
            { 
                pv: 0
            },
            { 
                pv: 0
            },
            { 
                pv: 0
            },
            { 
                pv: 0
            },
            { 
                pv: 0
            },
            { 
                pv: 0
            },
            { 
                pv: 0
            },
            {
                pv: 0
            },
            {
                pv: 0
            },
            { 
                pv: 0
            },
            { 
                pv: 0
            },
            { 
                pv: 0
            },
            { 
                pv: 0
            },
            { 
                pv: 0
            },
            { 
                pv: 0
            },
            { 
                pv: 0
            },
            { 
                pv: 0
            }
          ];
    }
    
    percentageDeliveriesOnTime(){
        if(this.data && this.data.historicalData && this.data.historicalData.length > 0){
            let arrayData = [];
            this.data.historicalData.forEach(element => {
                if(element.sumOfOrdersDeliveredOnTime && element.totalOrdersDelivered){
                    arrayData.push({ 
                        pv: Math.round(element.sumOfOrdersDeliveredOnTime / element.totalOrdersDelivered * 10000)/100
                    });
                }else{
                    arrayData.push({ 
                        pv: 0
                    });
                }
            });
            return arrayData;
        } else{
            return this.baseArray;
        } 
    }

    vehicleUtilizationLevel(){
        if(this.data && this.data.historicalData && this.data.historicalData.length > 0){
            let arrayData = [];
            this.data.historicalData.forEach(element => {
                if(element.actualCapacityUsed && element.totalLoadCapacity){
                    arrayData.push({ 
                        pv: Math.round(element.actualCapacityUsed / element.totalLoadCapacity * 10000)/100
                    });
                }else{
                    arrayData.push({ 
                        pv: 0
                    });
                }
            });
            return arrayData;
        } else{
            return this.baseArray;
        } 
    }

    plannedMileageVsActualMileage(){
        if(this.data && this.data.historicalData && this.data.historicalData.length > 0){
            let arrayData = [];
            this.data.historicalData.forEach(element => {
                if(element.executedMileage && element.plannedMileage){
                    arrayData.push({ 
                        pv: Math.round((element.executedMileage - element.plannedMileage) * 100)/100
                    });
                }else{
                    arrayData.push({ 
                        pv: 0
                    });
                }
            });
            return arrayData;
        } else{
            return this.baseArray;
        } 
    }
   
    mobilizedTonsVsTotalVehicleCapacity(){
        if(this.data && this.data.historicalData && this.data.historicalData.length > 0){
            let arrayData = [];
            this.data.historicalData.forEach(element => {
                if(element.mobilizedTons && element.plannedMileage){
                    arrayData.push({ 
                        pv: Math.round((element.totalVehicleCapacity - element.mobilizedTons) * 100)/100
                    });
                }else{
                    arrayData.push({ 
                        pv: 0
                    });
                }
            });
            return arrayData;
        } else{
            return this.baseArray;
        } 
    }

    plannedMileage(){
        if(this.data && this.data.historicalData && this.data.historicalData.length > 0 && this.data.historicalData[0].plannedMileage){
            return this.data.historicalData[this.data.historicalData.length - 1].plannedMileage;
        } else{
            return 0;
        } 
    }

    executedMileage(){
        if(this.data && this.data.historicalData && this.data.historicalData.length > 0 && this.data.historicalData[0].executedMileage){
            return this.data.historicalData[this.data.historicalData.length - 1].executedMileage;
        } else{
            return 0;
        }
    }
    
    mobilizedTons(){
        if(this.data && this.data.historicalData && this.data.historicalData.length > 0 && this.data.historicalData[0].mobilizedTons){
            return this.data.historicalData[this.data.historicalData.length - 1].mobilizedTons;
        } else{
            return 0;
        } 
    }

    totalVehicleCapacity(){
        if(this.data && this.data.historicalData && this.data.historicalData.length > 0 && this.data.historicalData[0].totalVehicleCapacity){
            return this.data.historicalData[this.data.historicalData.length - 1].totalVehicleCapacity;
        } else{
            return 0;
        }
    }

    routesQuantitySumary(){
        if(this.data && this.data.historicalData && this.data.historicalData.length > 0){
            let sumary = 0;
            this.data.historicalData.forEach(element => {
                sumary+= element.routesQuantity;
            });
            return sumary;
        } else{
            return 0;
        } 
    }

    routesQuantityLast(){
        if(this.data && this.data.historicalData && this.data.historicalData.length > 0 && this.data.historicalData[0].routesQuantity){
            return this.data.historicalData[this.data.historicalData.length - 1].routesQuantity;
        } else{
            return 0;
        }
    }

    routesQuantityGrew(){
        if(this.data && this.data.historicalData && this.data.historicalData.length > 1 && this.data.historicalData[0].routesQuantity){
            return this.data.historicalData[this.data.historicalData.length - 1].routesQuantity > this.data.historicalData[this.data.historicalData.length - 2].routesQuantity;
        } else{
            return null;
        }
    }

    routesQuantity(){
        if(this.data && this.data.historicalData && this.data.historicalData.length > 0){
            let arrayData = [];
            this.data.historicalData.forEach(element => {
                arrayData.push({ 
                    pv: element.routesQuantity
                });
            });
            return arrayData;
        } else{
            return this.baseArray;
        } 
    }

    loadsQuantitySumary(){
        if(this.data && this.data.historicalData && this.data.historicalData.length > 0){
            let sumary = 0;
            this.data.historicalData.forEach(element => {
                sumary+= element.loadsQuantity;
            });
            return sumary;
        } else{
            return 0;
        } 
    }

    loadsQuantityLast(){
        if(this.data && this.data.historicalData && this.data.historicalData.length > 0 && this.data.historicalData[0].loadsQuantity){
            return this.data.historicalData[this.data.historicalData.length - 1].loadsQuantity;
        } else{
            return 0;
        }
    }

    loadsQuantityGrew(){
        if(this.data && this.data.historicalData && this.data.historicalData.length > 1 && this.data.historicalData[0].loadsQuantity){
            return this.data.historicalData[this.data.historicalData.length - 1].loadsQuantity > this.data.historicalData[this.data.historicalData.length - 2].loadsQuantity;
        } else{
            return null;
        }
    }

    incidentReportCountSumary(){
        if(this.data && this.data.historicalData && this.data.historicalData.length > 0){
            let sumary = 0;
            this.data.historicalData.forEach(element => {
                sumary+= element.incidentReportCount;
            });
            return sumary;
        } else{
            return 0;
        } 
    }

    incidentReportCountLast(){
        if(this.data && this.data.historicalData && this.data.historicalData.length > 0 && this.data.historicalData[0].incidentReportCount){
            return this.data.historicalData[this.data.historicalData.length - 1].incidentReportCount;
        } else{
            return 0;
        }
    }

    incidentReportCountGrew(){
        if(this.data && this.data.historicalData && this.data.historicalData.length > 1 && this.data.historicalData[0].incidentReportCount){
            return this.data.historicalData[this.data.historicalData.length - 1].incidentReportCount > this.data.historicalData[this.data.historicalData.length - 2].incidentReportCount;
        } else{
            return null;
        }
    }

    incidentReportCount(){
        if(this.data && this.data.historicalData && this.data.historicalData.length > 0){
            let arrayData = [];
            this.data.historicalData.forEach(element => {
                arrayData.push({ 
                    pv: element.incidentReportCount
                });
            });
            return arrayData;
        } else{
            return this.baseArray;
        } 
    }

    loadsQuantity(){
        if(this.data && this.data.historicalData && this.data.historicalData.length > 0){
            let arrayData = [];
            this.data.historicalData.forEach(element => {
                arrayData.push({ 
                    pv: element.loadsQuantity
                });
            });
            return arrayData;
        } else{
            return this.baseArray;
        } 
    }

    totalOrdersDeliveredSumary(){
        if(this.data && this.data.historicalData && this.data.historicalData.length > 0){
            let sumary = 0;
            this.data.historicalData.forEach(element => {
                sumary+= element.totalOrdersDelivered;
            });
            return sumary;
        } else{
            return 0;
        } 
    }

    totalOrdersDeliveredLast(){
        if(this.data && this.data.historicalData && this.data.historicalData.length > 0 && this.data.historicalData[0].totalOrdersDelivered){
            return this.data.historicalData[this.data.historicalData.length - 1].totalOrdersDelivered;
        } else{
            return 0;
        }
    }

    totalOrdersDeliveredGrew(){
        if(this.data && this.data.historicalData && this.data.historicalData.length > 1 && this.data.historicalData[0].totalOrdersDelivered){
            return this.data.historicalData[this.data.historicalData.length - 1].totalOrdersDelivered > this.data.historicalData[this.data.historicalData.length - 2].totalOrdersDelivered;
        } else{
            return null;
        }
    }

    totalOrdersDelivered(){
        if(this.data && this.data.historicalData && this.data.historicalData.length > 0){
            let arrayData = [];
            this.data.historicalData.forEach(element => {
                arrayData.push({ 
                    pv: element.totalOrdersDelivered
                });
            });
            return arrayData;
        } else{
            return this.baseArray;
        } 
    }
    
    
    vehicleUse(){
        if(this.data && this.data.historicalData && this.data.historicalData.length > 0){
            let arrayData = [];
            this.data.historicalData.forEach(element => {
                if(element.vehicleHoursBusy && element.vehicleAvailableHours){
                    arrayData.push({ 
                        pv: Math.round(element.vehicleHoursBusy / element.vehicleAvailableHours * 10000)/100
                    });
                }else{
                    arrayData.push({ 
                        pv: 0
                    });
                }
            });
            return arrayData;
        } else{
            return this.baseArray;
        } 
    }

    internalVehicleUse(){
        if(this.data && this.data.historicalData && this.data.historicalData.length > 0){
            let arrayData = [];
            this.data.historicalData.forEach(element => {
                if(element.internalVehicleHoursBusy && element.internalVehicleAvailableHours){
                    arrayData.push({ 
                        pv: Math.round(element.internalVehicleHoursBusy / element.internalVehicleAvailableHours * 10000)/100
                    });
                }else{
                    arrayData.push({ 
                        pv: 0
                    });
                }
            });
            
            return arrayData;
        } else{
            return this.baseArray;
        } 
    }

    averageLoadFactor(){
        if(this.data && this.data.historicalData && this.data.historicalData.length > 0){
            let arrayData = [];
            this.data.historicalData.forEach(element => {
                if(element.mobilizedTons && element.totalVehicleCapacity && element.numberOfTrips){
                    arrayData.push({ 		
                        pv: Math.round((element.mobilizedTons / element.totalVehicleCapacity / element.numberOfTrips) * 10000)/100
                    });
                }else{
                    arrayData.push({ 		
                        pv: 0
                    }); 
                }
            });
            return arrayData;
        } else{
            return this.baseArray;
        } 
    }

    estimatedDeliveryTimeVsRealDeliveryTime(){
        if(this.data && this.data.historicalData && this.data.historicalData.length > 0){
            let arrayData = [];
            this.data.historicalData.forEach(element => {
                if(element.estimatedDeliveryTime && element.realDeliveryTime){
                    arrayData.push({ 
                        pv: Math.round((element.estimatedDeliveryTime/element.realDeliveryTime) * 10000)/100
                    });
                }else{
                    arrayData.push({ 
                        pv: 0
                    });
                }
            });
            return arrayData;
        } else{
            return this.baseArray;
        } 
    }

    estimatedDeliveryTime(){
        if(this.data && this.data.historicalData && this.data.historicalData.length > 0 && this.data.historicalData[0].estimatedDeliveryTime){
            return this.data.historicalData[this.data.historicalData.length - 1].estimatedDeliveryTime;
        } else{
            return 0;
        } 
    }

    realDeliveryTime(){
        if(this.data && this.data.historicalData && this.data.historicalData.length > 0 && this.data.historicalData[0].realDeliveryTime){
            return this.data.historicalData[this.data.historicalData.length - 1].realDeliveryTime;
        } else{
            return 0;
        }
    }

    totalOrdersDispatchedVsTotalOrdersDelivered(){
        if(this.data && this.data.historicalData && this.data.historicalData.length > 0){
            let arrayData = [];
            this.data.historicalData.forEach(element => {
                if(element.totalOrdersDelivered && element.totalOrdersDispatched){
                    arrayData.push({ 
                        pv:  Math.round((element.totalOrdersDelivered / element.totalOrdersDispatched) * 10000)/100
                    });
                }else{
                    arrayData.push({ 
                        pv:  0
                    });
                }
            });
            return arrayData;
        } else{
            return this.baseArray;
        } 
    }

    totalOrdersDispatchedVsTotalOrdersPartiallyDelivered(){
        if(this.data && this.data.historicalData && this.data.historicalData.length > 0){
            let arrayData = [];
            this.data.historicalData.forEach(element => {
                if(element.totalOrdersPartiallyDelivered && element.totalOrdersDispatched){
                    arrayData.push({ 
                        pv: Math.round((element.totalOrdersPartiallyDelivered / element.totalOrdersDispatched) * 10000)/100
                    });
                }else{
                    arrayData.push({ 
                        pv: 0
                    });
                }
            });
            return arrayData;
        } else{
            return this.baseArray;
        } 
    }

    totalOrdersDispatchedVsTotalOrdersUndelivered(){
        if(this.data && this.data.historicalData && this.data.historicalData.length > 0){
            let arrayData = [];
            this.data.historicalData.forEach(element => {
                if(element.totalOrdersUndelivered / element.totalOrdersDispatched){
                    arrayData.push({ 
                        pv: Math.round((element.totalOrdersUndelivered / element.totalOrdersDispatched) * 10000)/100
                    });
                }else{
                    arrayData.push({ 
                        pv: 0
                    });
                }
            });
            return arrayData;
        } else{
            return this.baseArray;
        } 
    }

    totalOrdersDeliveredComplete(){
        if(this.data && this.data.historicalData && this.data.historicalData.length > 0 && this.data.historicalData[0].totalOrdersDelivered){
            return this.data.historicalData[this.data.historicalData.length - 1].totalOrdersDelivered;
        } else{
            return 0;
        } 
    }

    totalOrdersDispatched(){
        if(this.data && this.data.historicalData && this.data.historicalData.length > 0 && this.data.historicalData[0].totalOrdersDispatched){
            return this.data.historicalData[this.data.historicalData.length - 1].totalOrdersDispatched;
        } else{
            return 0;
        }
    }

    totalOrdersPartiallyDelivered(){
        if(this.data && this.data.historicalData && this.data.historicalData.length > 0 && this.data.historicalData[0].totalOrdersPartiallyDelivered){
            return this.data.historicalData[this.data.historicalData.length - 1].totalOrdersPartiallyDelivered;
        } else{
            return 0;
        }
    }

    totalOrdersUndelivered(){
        if(this.data && this.data.historicalData && this.data.historicalData.length > 0 && this.data.historicalData[0].totalOrdersUndelivered){
            return this.data.historicalData[this.data.historicalData.length - 1].totalOrdersUndelivered;
        } else{
            return 0;
        }
    }
}