import React from 'react';
import { Route, Redirect } from "react-router-dom";

import { useUser } from '../context/UserContext';

export const PublicRoute = ({ component: Component, ...rest }) => {
    const { isAuthenticated } = useUser();
    
    return (
        <Route {...rest} 
            component={(props) => (
                (!isAuthenticated)
                    ? (<Component {...props} />)
                    : (<Redirect to="/" />)
            )}
        />
    )
}