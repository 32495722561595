import { gql } from "@apollo/client";

export const fetchPackages = gql`
    query fetchPackages ($companyId: uuid!){
        packages (where: {companyId: {_eq: $companyId}}) {
            id
            name
            height
            width
            depth
            safetyFactor
            defaultWeight
            maxWeight
            maxStackingWeight
            principal
            defaultItemName
            defaultItemNumber
            cubicMeters
        }
        packages_aggregate {
            aggregate {
                count(columns: id)
            }
        }
    }
`;

export const insertPackage = gql`
    mutation insertPackage($name: String!, $height: numeric, $width: numeric, $depth: numeric, $safetyFactor: numeric, $defaultWeight: numeric, $maxWeight: numeric, $maxStackingWeight: numeric, $principal: Boolean, $defaultItemName: String, $defaultItemNumber: String, $cubicMeters: numeric, $companyId: uuid!) {
        insert_packages(objects: {name: $name, height: $height, width: $width, depth: $depth, safetyFactor: $safetyFactor, defaultWeight: $defaultWeight, maxWeight: $maxWeight, maxStackingWeight: $maxStackingWeight, principal: $principal, defaultItemName: $defaultItemName, defaultItemNumber: $defaultItemNumber, cubicMeters: $cubicMeters, companyId: $companyId}) {
            affected_rows
            returning {
                id
            }
        }
    }  
`;

export const updatePackage = gql`
    mutation udatePackage($id: uuid!, $name: String!, $height: numeric, $width: numeric, $depth: numeric, $safetyFactor: numeric, $defaultWeight: numeric, $maxWeight: numeric, $maxStackingWeight: numeric, $principal: Boolean, $defaultItemName: String, $defaultItemNumber: String, $cubicMeters: numeric) {
        update_packages(_set: {name: $name, height: $height, width: $width, depth: $depth, safetyFactor: $safetyFactor, defaultWeight: $defaultWeight, maxWeight: $maxWeight, maxStackingWeight: $maxStackingWeight, principal: $principal, defaultItemName: $defaultItemName, defaultItemNumber: $defaultItemNumber, cubicMeters: $cubicMeters}, where: {id: {_eq: $id}}) {
            affected_rows
            returning {
                id
            }
        }
    }
`;

export const deletePackage = gql`
    mutation deletePackage($id: uuid!) {
        delete_packages(where: {id: {_eq: $id}}) {
            affected_rows
        }
    }
`;
