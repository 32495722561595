import React, { useState, useEffect } from 'react';
import { Result, Button, Form, Input, Spin } from 'antd';
import { useParams, useHistory } from 'react-router-dom';
import { useLazyQuery, useMutation } from '@apollo/client';
import bcrypt from 'bcryptjs';
import Swal from 'sweetalert2';

import '../styles/login.css';
import { changePassword, fetchUserIsVerified } from '../../queries/userQuery';

export const VerificationEmailScreen = () => {
    const [form] = Form.useForm();
    const history = useHistory();
    const { token } = useParams();

    const [getUser, dataUser] = useLazyQuery(fetchUserIsVerified, {fetchPolicy: 'no-cache' });
    const [updPassword] = useMutation(changePassword, { fetchPolicy: 'no-cache'});

    const [tokenError, setTokenError] = useState();
    const [tokenDecoded, setTokenDecoded] = useState({});
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        if(token !== undefined) {
            const verifyToken = async () => {
                const res = await fetch(process.env.REACT_APP_FUNCTION_VERIFY_TOKEN, {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify({
                        token
                    }),
                })

                const result = await res.json();

                if(res.status === 200) {
                    setTokenDecoded(result.statusText);
                    getUser({variables: {id: result.statusText.id}});
                } else {
                    setTokenError(result.statusText);
                }

                setLoading(false);
            }
            
            verifyToken();
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [token]);

    useEffect(() => {
        if(!dataUser.loading && dataUser.data && dataUser.data.users.length > 0) {
            if(dataUser.data.users[0].emailVerified) {
                setTokenError('Su cuenta ya ha sido verificada.')
            }
        }
    }, [dataUser.loading, dataUser.data]);

    const formItemLayout = {
        labelCol: {
            xs: { span: 24 },
            sm: { span: 24 },
            md: { span: 24 },
            lg: { span: 24},
            xl: { span: 24},
            xxl: { span: 24}
          },
          wrapperCol: {
            xs: { span: 24 },
            sm: { span: 24 },
            md: { span: 24 },
            lg: { span: 24},
            xl: { span: 24},
            xxl: { span: 24},
          },
        
    };


    const handleSubmit = (values) => {
        const { id, email, displayName } = tokenDecoded;
        const { password } = values;

        bcrypt.genSalt(5, (err, salt) => {
            if(err == null) {
                bcrypt.hash(email + password, salt, (error, hash) => {
                    if(error == null)  {
                        updPassword({
                            variables: {
                                id,
                                password: hash
                            }
                        }).then(res => {
                            if(res.data.update_users.affected_rows > 0) {
                                Swal.fire({
                                    title: 'Cuenta activada',
                                    text: `${displayName} has activado tu cuenta correctamente. Ahora puede ingresar a la aplicación.`,
                                    icon: 'success',
                                    confirmButtonText: 'Cerrar',
                                    timer: 3000,
                                    timerProgressBar: true,
                                    willClose: () => {
                                        history.replace('/login');
                                    }
                                });
                            }
                        }).catch(er => {
                            Swal.fire({
                                title: 'Error',
                                text: `Ocurrió un error al intentar procesar los datos. Muestre la siguiente información al administrador: ${er.toString()}`,
                                icon: 'error',
                                confirmButtonText: 'Cerrar'
                            });
                        });
                    }
                });
            }
        });
    }

    if(loading) {
        return (
            <center
                style={{
                    height: window.innerHeight,
                    display: "flex",
                    flexDirection: "row",
                    flexWrap: "wrap",
                    justifyContent: "center",
                    alignItems: "center",
                    textAlign: "center"
                }}
            >
                <Spin size='large' />
            </center>
        );
    }

    if(dataUser.loading) {
        return (
            <center
                style={{
                    height: window.innerHeight,
                    display: "flex",
                    flexDirection: "row",
                    flexWrap: "wrap",
                    justifyContent: "center",
                    alignItems: "center",
                    textAlign: "center"
                }}
            >
                <Spin size='large' />
            </center>
        )
    }

    if(tokenError !== undefined) {
        return (
            <div style={{
                height: '70vh',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
            }}>
                <Result
                    style={{
                        left: '50%',
                        top: '50%',
                        
                    }}
                    status="500"
                    title={tokenError}
                    extra={[
                        <Button 
                            key='result'
                            type="primary" 
                            shape="round" 
                            size="large"
                            onClick={() => history.replace('/')}
                        >
                            Aceptar
                        </Button>
                    ]}
                />
            </div>
        );
    }

    return (
        <div
            style={{
                height: '70vh',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
            }}
        >
            <Result
                status="info"
                title={`Bienvenido ${tokenDecoded.displayName}`}
                subTitle="Proceso de validación de cuenta. Por favor ingresa la información solicitada para crear tu nueva contraseña."
                extra={[
                    <Form
                        key='form'
                        form={form}
                        name='verification'
                        onFinish={handleSubmit}
                        {...formItemLayout}
                    >
                        <Form.Item
                            label='Contraseña'
                            name='password'
                            hasFeedback
                            rules={[
                                {
                                    required: true,
                                    message: 'Ingrese la contraseña',
                                },
                                ({ _ }) => ({
                                    validator(_, value) {
                                        if(value && value.length < 6) {
                                            return Promise.reject('Mínimo 6 caracteres')
                                        }

                                        return Promise.resolve();
                                    },
                                }),
                            ]}
                        >
                            <Input.Password />
                        </Form.Item>
                        <Form.Item
                            label="Confirmar contraseña"
                            name="confirm"
                            dependencies={['password']}
                            hasFeedback
                            rules={[
                            {
                                required: true,
                                message: 'Confirme su contraseña',
                            },
                            ({ getFieldValue }) => ({
                                validator(_, value) {
                                    if (!value || getFieldValue('password') === value) {
                                        return Promise.resolve();
                                    }
                                    if(value.length < 6) {
                                        return Promise.reject('Mínimo 6 caracteres')
                                    }
                                    return Promise.reject('Las contraseñas ingresadas no coinciden!');
                                },
                            }),
                            ]}
                        >
                            <Input.Password />
                        </Form.Item>
                        <Form.Item>
                            <Button type="primary" htmlType="submit" shape='round' size='large'>
                                Verificar
                            </Button>
                        </Form.Item>
                    </Form>
                ]}
            />
        </div>
    );

    
};
