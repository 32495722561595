import React, { useState, useEffect } from "react";
import TextField from '@mui/material/TextField';
import CircularProgress from '@mui/material/CircularProgress';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import Search from '@mui/icons-material/Search';
import { useTMSStore } from "../../../../../../../store/tms-store";
import "../../stylesTMS/styles-tms.css"

export const TMSSearchTextTransport = () => {
    const loading = useTMSStore((state) => state.loading);
    const setSearchTextTransport = useTMSStore((state) => state.setSearchTextTransport);
    const [searchFilterTransport, setSearchFilterTransport] = useState("NAVI");

    const textFieldEvent = (ev) => {
      if(ev.target.value.length === 0){ 
        setSearchTextTransport("")
      }; 
      setSearchFilterTransport(ev.target.value);
    };

    const textFieldOnKeyDown = (ev) => {
      if(ev.keyCode === 13){
        setSearchTextTransport(searchFilterTransport)
      }
    };


    return (
      <TextField onKeyDown={textFieldOnKeyDown} onChange={textFieldEvent} label="Buscar" className="custom-filter" size={"large"}  color= "error" value={searchFilterTransport} InputProps={{
        endAdornment: <InputAdornment position="end">
        <IconButton>
          {loading ? <CircularProgress size={10} color="error" /> :  <Search onClick={() => setSearchTextTransport(searchFilterTransport)} />}
        </IconButton>
      </InputAdornment>
      }}/>
  );
};
