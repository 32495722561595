import { gql } from '@apollo/client';

export const fetchWarehouses = gql`
    query fetchWarehouses($companyId: uuid!) {
        distributionCenters(where:{enabled:{_eq: true}, companyId: {_eq: $companyId}}) {
            name
            id
            state
            city
            address
            addressComplement
            lat
            lng
            contactName
            phone
            email
            sectorId
        }
    }
`;