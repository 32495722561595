import { Button, Input, Space } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import Swal from 'sweetalert2';

export const handleSave = async (values, insSequenceNumber, updSequenceNumber, sequencesNumbers, setSequencesNumbers, isEditing, setIsEditing, form, setIsModalVisible) => {
    const { id, name, from, to, currentNumber, prefix, enabled, deleteable, sequenceTypeId, sequenceType } = values;

    try {
        if(!isEditing) {
            const res = await insSequenceNumber({
                variables: {
                    name,
                    from,
                    to,
                    currentNumber,
                    prefix,
                    enabled,
                    sequenceType,
                    sequenceTypeId
                },
            });
    
            if(res.data.insert_sequenceNumbers.affected_rows > 0) {
                const { id } = res.data.insert_sequenceNumbers.returning[0];

                setSequencesNumbers((current) => [...current, {
                    id,
                    name,
                    from,
                    to,
                    currentNumber,
                    prefix,
                    enabled,
                    deleteable: true,
                    sequenceType,
                    sequenceTypeId
                }]);
                setIsModalVisible(false);
            }
        } else {
            const res = await updSequenceNumber({
                variables: {
                    id,
                    name,
                    from,
                    to,
                    currentNumber,
                    prefix,
                    enabled,
                    sequenceType,
                    sequenceTypeId
                }
            });

            if(res.data.update_sequenceNumbers.affected_rows > 0) {
                const records = sequencesNumbers;
                const indexToReplace = sequencesNumbers.findIndex((item) => item.id === id);

                records.splice(indexToReplace, 1, {
                    id,
                    name,
                    from,
                    to,
                    currentNumber,
                    prefix,
                    enabled,
                    deleteable,
                    sequenceType,
                    sequenceTypeId
                });
                setSequencesNumbers([...records]);
                setIsModalVisible(false);
            }
        }
    } catch (error) {
        console.error(error);
    }

    setIsEditing(false);

    form.setFieldsValue({
        id: '',
        name: '',
        from: '',
        to: '',
        currentNumber: '',
        prefix: '',
        enabled: true,
        deleteable: true,
        sequenceTypeId: null,
        sequenceType: ''
    });
};

export const handleDelete = (record, delSequenceNumber, sequencesNumbers, setSequencesNumbers, setClickedRow, form, setIsLoading) => {
    const id = record;

    Swal.fire({
        title: '¿Seguro desea eliminar?',
        text: "Este proceso es irreversible!",
        icon: 'question',
        showCancelButton: true,
        confirmButtonColor: 'var(--primaryColor)',
        cancelButtonColor: '#B6B6B6',
        confirmButtonText: 'Sí, eliminar',
        cancelButtonText: 'No, cancelar',
    }).then( async (result) => {
        if(result.isConfirmed) {
            try {
                const res = await delSequenceNumber({
                    variables: {
                        id
                    },
                    fetchPolicy: 'no-cache'
                });
    
                if(res.data.delete_sequenceNumbers.affected_rows > 0) {
                    setSequencesNumbers(sequencesNumbers.filter((item) => item.id !== id));
                    setClickedRow("");
                    Swal.fire({
                        title: 'Registro eliminado',
                        icon: 'success',
                        showConfirmButton: false,
                        timer: 1000
                    });
                    form.setFieldsValue({
                        id: '',
                        name: '',
                        from: '',
                        to: '',
                        currentNumber: '',
                        prefix: '',
                        enabled: true,
                        deleteable: true,
                        sequenceType: '',
                        sequenceTypeId: null
                    });
                    setIsLoading(false);
                }
            } catch (error) {
                console.error(error)
            }
        }
    });
};

export const handleEdit = (record, setIsEditing, form) => {
    const { id, name, from, to, currentNumber, prefix, enabled, deleteable, sequenceType, sequenceTypeId } = record;
    
    setIsEditing(true);

    form.setFieldsValue({
        id,
        name,
        from,
        to,
        currentNumber,
        prefix,
        enabled,
        deleteable,
        sequenceType,
        sequenceTypeId
    });
};

export const handleClear = (setIsEditing, form) => {
    setIsEditing(false);

    form.setFieldsValue({
        id: '',
        name: '',
        from: '',
        to: '',
        currentNumber: '',
        prefix: '',
        enabled: true,
        deleteable: true,
        sequenceType: '',
        sequenceTypeId: null
    });
};


const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
};

const handleReset = (clearFilters) => {
    clearFilters();
};

export const getColumnSearchPropsText = (title, dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
        <div style={{ padding: 8 }}>
            <Input
                placeholder={`Buscar ${title}`}
                value={selectedKeys[0]}
                onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                style={{ width: 188, marginBottom: 8, display: 'block' }}
            />
            <Space>
                <Button
                    type="primary"
                    onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                    icon={<SearchOutlined />}
                    size="small"
                    style={{ width: 90 }}
                >
                    Buscar
                </Button>
                <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
                    Restablecer
                </Button>
            </Space>
        </div>
    ),
    filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
    onFilter: (value, record) =>
        record[dataIndex]
            ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
            : '',
    onFilterDropdownVisibleChange: visible => {
        if (visible) {
            /*if(Object.keys(searchText).length > 0) {
                setTimeout(() => searchInput.select(), 100);
            }*/
        }
    },
});
