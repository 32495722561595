import axios from 'axios';

const insertLog = async (objects) => {
    const options = {
        method: 'POST',
        headers: {"x-hasura-admin-secret": process.env.REACT_APP_HASURA_ADMIN_SECRET},
        data: JSON.stringify({
            query: `
            mutation insert_transactions ($objects: [transactions_insert_input!]!)
            {
                insert_transactions(objects: $objects) 
                {
                    affected_rows
                }
            }`,
            variables: {
                objects
            }
        }),
        url: process.env.REACT_APP_HASURA_GRAPHQL_URL
    };

    const fetchResponse = await axios(options);
    return fetchResponse.data;
};


const loggerCooltrack = async ({module, operation, target}) => {
    const local = localStorage.getItem('userData');
    let usr={};
    
    if(!local || local.length === 0) {
        const session = sessionStorage.getItem('userData');
        usr = JSON.parse(session);
    }else{
        usr = JSON.parse(local);
    };
    

    const objects = [
        {
            userId: usr.email,
            module,
            operation,
            target
        }
    ];

    const response = await insertLog(objects);
    return response;
};

export default loggerCooltrack;