import { TokenRefreshLink } from "apollo-link-token-refresh";
import jwtDecode from 'jwt-decode';

import localStorageService from "../localStorageService";

export default new TokenRefreshLink({
    accessTokenField: 'accessToken',
    isTokenValidOrUndefined: () => {
        const token = localStorageService.get('token');

        if (!token) {
            return true;
        }

        try {
            const { exp } = jwtDecode(token);

            return Date.now() >= exp * 1000 ? false : true;
        } catch (err) {
            return false;
        }
    },
    fetchAccessToken: () => {
       
        return fetch(process.env.REACT_APP_FUNCTION_ACCESS_TOKEN, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                id: localStorageService.get('id'),
                role: localStorageService.get('role')
            }),
        });
    },
    handleFetch: (accessToken) => {
        localStorageService.set('token', accessToken);
    },
    handleError: (err) => {
        console.warn("Tu refresh token no es válido. Intenta iniciar sesión nuevamente");
        console.error(err.toString());
    }
});

