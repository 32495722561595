import React, { useState, useEffect } from 'react';
import moment from 'moment';
import 'moment/locale/es';
import { Form, Table, Switch, Descriptions, Input, Card, Button, Select, Space, Skeleton, Col, Row, Modal } from 'antd';
import { useLazyQuery, useMutation } from '@apollo/client';
import { CarOutlined, CheckOutlined, ClockCircleOutlined, EditOutlined } from '@ant-design/icons';
import { handleSave, handleEdit, handleClear, getColumnSearchProps, getColumnSearchPropsText } from './UseCases';
import {
    fetchIncidentReport,
    updateIncidentReport,
    fetchIncidentManagement
} from '../../queries/incidentReportQueries';
import { IncidentReportIndicator } from './IncidentReportIndicator';
import localStorageService from '../../../../services/localStorageService';
export const IncidentReportScreen = ({flag}) => {
    const [selectedWarehouse, setSelectedWarehouse] = useState(JSON.parse(localStorageService.get("selectedWarehouses"))?.warehouses);
    const [getIncidentReport, dataIncidentReport] = useLazyQuery(fetchIncidentReport, {variables: {distributionCenterId: selectedWarehouse?.length > 0 ? selectedWarehouse : ["00000000-0000-0000-0000-000000000000"]}, fetchPolicy: 'no-cache' });
    const [getIncidentManagement, dataIncidentManagement] = useLazyQuery(fetchIncidentManagement, { fetchPolicy: 'no-cache' });
    const [upIncidentReport] = useMutation(updateIncidentReport, { fetchPolicy: 'no-cache' });
    const [editRecord, setEditRecord] = useState({});
    const [incidentReport, setIncidentReport] = useState([]);
    const [, setIncidentManagementDescription] = useState('');
    const [clickedRow, setClickedRow] = useState("");

    const [form] = Form.useForm();

    const [isModalVisible, setIsModalVisible] = useState(false);
    useEffect(() => {
        const warehouses = JSON.parse(localStorageService.get("selectedWarehouses"))?.warehouses;
        setSelectedWarehouse(warehouses);
    }, [flag]);
    const showModal = () => {
        setIsModalVisible(true);
    };

    const handleOk = () => {
        setIsModalVisible(false);
    };

    const handleCancel = () => {
        setIsModalVisible(false);
    };

    useEffect(() => {
        getIncidentReport();
        getIncidentManagement();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        setIncidentReport([]);
       
        if (!dataIncidentReport.loading && dataIncidentReport.data && dataIncidentReport.data.incidentReport.length > 0) {
            
            dataIncidentReport.data.incidentReport.map((item) => {
                const deleteable = true;
                const {
                    id,
                    vehicleId,
                    userId,
                    incidentId,
                    incidentCauseId,
                    observations,
                    incidentManagementId,
                    enabled,
                    incidentDatetime,
                    user,
                    vehicle,
                    incident,
                    incidentCause,
                    incidentManagement,
                    updated_at
                    
                } = item;

                return setIncidentReport((current) => [...current, {
                    id,
                    vehicleId,
                    vehicleLicencePlate: vehicle?.licencePlate,
                    userId,
                    userDisplayName: user?.displayName,
                    incidentId,
                    incidentDescription: incident?.description,
                    incidentCauseId,
                    incidentCauseDescription: (incidentCause) ? incidentCause?.description : "",
                    observations,
                    incidentManagementId,
                    incidentManagementDescription: (incidentManagement) ? incidentManagement?.description : "",
                    enabled,
                    deleteable,
                    incidentDatetime: moment(incidentDatetime).local().format('DD/MM/YYYY h:mm a'),
                    updated_at: moment(updated_at).local().format('DD/MM/YYYY h:mm a')
                }]);
            });
        }
    }, [dataIncidentReport.loading, dataIncidentReport.data]);

    const columns = [
        {
            title: 'Placa',
            key: 'vehicleLicencePlate',
            dataIndex: 'vehicleLicencePlate',
            sorter: (a, b) => a.vehicleLicencePlate.localeCompare(b.vehicleLicencePlate),
            ...getColumnSearchPropsText("Placa", "vehicleLicencePlate")
        },
        {
            title: 'Nombre',
            key: 'userDisplayName',
            dataIndex: 'userDisplayName',
            sorter: (a, b) => a.userDisplayName.localeCompare(b.userDisplayName),
            ...getColumnSearchPropsText("Nombre", "userDisplayName")
        },
        {
            title: 'Incidente Reportado',
            key: 'incidentDescription',
            dataIndex: 'incidentDescription',
            sorter: (a, b) => a.incidentDescription.localeCompare(b.incidentDescription),
            ...getColumnSearchPropsText("Incidente", "incidentDescription")
        },
        {
            title: 'Causa del Incidente',
            key: 'incidentCauseDescription',
            dataIndex: 'incidentCauseDescription',
            sorter: (a, b) => a.incidentCauseDescription.localeCompare(b.incidentCauseDescription),
            ...getColumnSearchPropsText("Causa", "incidentCauseDescription")
        },
        {
            title: 'Observaciones',
            key: 'observations',
            dataIndex: 'observations',
            sorter: (a, b) => a.observations.localeCompare(b.observations),
            ...getColumnSearchPropsText("Observaciones", "observations")
        },
        {
            title: 'Gestión del incidente',
            key: 'incidentManagementDescription',
            dataIndex: 'incidentManagementDescription',
            sorter: (a, b) => a.incidentManagementDescription.localeCompare(b.incidentManagementDescription),
            ...getColumnSearchPropsText("Gestión", "incidentManagementDescription")
        },
        {
            title: 'Gestionado',
            key: 'enabled',
            dataIndex: 'enabled',
            align: 'center',
            sorter: {
                compare: (a, b) => a.enabled - b.enabled,
            },
            render: (value) => <Switch checked={value} disabled />
        },
        {
            title: 'Fecha y hora del accidente',
            key: 'incidentDatetime',
            dataIndex: 'incidentDatetime',
            sorter: (a, b) => a.incidentDatetime.localeCompare(b.incidentDatetime),
            ...getColumnSearchProps("incidentDatetime")
        },
        {
            title: 'Ultima actualización',
            key: 'updated_at',
            dataIndex: 'updated_at',
            sorter: (a, b) => a.updated_at.localeCompare(b.updated_at),
            ...getColumnSearchProps("updated_at")
        },
        /*{
            key: 'actions',
            title: 'Acciones',
            align: 'center',
            dataIndex: 'actions',
            render: (value, record) => {
                return (
                    <Space>
                        <Button
                            type='primary'
                            shape='round'
                            icon={<MenuOutlined />}
                            onClick={() => {
                                setEditRecord(record);
                                handleEdit(record, form);
                            }}
                        />
                    </Space>
                )
            },
        },*/
    ];
    const formItemLayout = {
        labelCol: {
            lg: { span: 9 }
        },
        wrapperCol: {
            lg: { span: 14 }
        },
    };
    return (
        <Col className='children-inner-container-with-bg'>
            <Row className='children-inner-row'>
                <Col span={6} className='children-inner-col'>
                    <IncidentReportIndicator value={incidentReport.length} label={"Total Incidentes"}><CarOutlined style={{backgroundColor: 'var(--primaryColor)'}} className="report-indicator-icon"/></IncidentReportIndicator>
                </Col>
                <Col span={6} className='children-inner-col'>
                    <IncidentReportIndicator value={(incidentReport.filter(item => item.enabled)).length} label={"Incidentes Gestionados"}><CheckOutlined style={{backgroundColor: '#9ccc65'}} className="report-indicator-icon"/></IncidentReportIndicator>
                </Col>
                <Col span={6} className='children-inner-col'>
                    <IncidentReportIndicator value={(incidentReport.filter(item => !item.enabled)).length} label={"Incidentes Sin Gestión"}><ClockCircleOutlined style={{backgroundColor: '#ffba57'}} className="report-indicator-icon"/></IncidentReportIndicator>
                </Col>
                <Col span={6} className='children-inner-col'><Button type="primary" size={"large"} className='children-inner-button' icon={<EditOutlined />} onClick={showModal} disabled={clickedRow.length === 0}>Gestionar Incidente</Button></Col>
            </Row>
            <Row>
                <Skeleton loading={dataIncidentReport.loading} active>
                    <Table
                        className='children-table'
                        columns={columns}
                        dataSource={incidentReport}
                        size='small'
                        rowKey='id'
                        pagination={{ pageSize: 13 }}
                        onRow={(record) => {
                            return {
                                onClick: () => {

                                    if(clickedRow.length === 0 || clickedRow !== record.id){
                                        setClickedRow(record.id);
                                        setEditRecord(record);
                                        handleEdit(record, form);
                                    }else{
                                        
                                        setEditRecord({});
                                        setClickedRow("");
                                    }
                                    
                                    
                                },
                            }
                        }}
                        rowClassName={(record) => ['mouse-row-enter', record.id === clickedRow ? 'table-row-color' : ""]}
                    />
                </Skeleton>
            </Row>
            <Modal className='children-modal' visible={isModalVisible} onOk={handleCancel} onCancel={handleCancel} footer={[]} title="Gestionar Incidente" width={500}>
                <Form
                {...formItemLayout}
                    form={form}
                    name='configuration'
                    onFinish={(values) => {
                        handleSave({ ...editRecord, ...values }, upIncidentReport, incidentReport, setIncidentReport, form)
                        setClickedRow("");
                    }}
                    initialValues={{
                        id: '',
                        incidentManagementId: undefined,
                        enabled: true,
                        deleteable: true
                    }}
                >
                    <Form.Item name='id' hidden>
                        <Input />
                    </Form.Item>
                    
                            <Form.Item
                            label='Gestión del incidente'
                            labelAlign='left'
                                hidden
                                name='incidentManagementDescription'
                            >

                            </Form.Item>
                            
                            <Form.Item
                            label='Gestión del incidente'
                                name='incidentManagementId'
                                labelAlign='left'
                                rules={[{ required: true, message: 'Por favor seleccione una opción' }]}
                            >
                                <Select
                                    placeholder='Seleccione...'
                                    allowClear
                                    onChange={(value, object) => {
                                        if (value !== undefined) {
                                            setIncidentManagementDescription(object.children);
                                            return form.setFieldsValue({ incidentManagementId: value, incidentManagementDescription: object.children })
                                        }
                                    }}
                                >
                                    {
                                        dataIncidentManagement && dataIncidentManagement.data && (dataIncidentManagement.data.incidentManagement.filter(item => item.incidentId === editRecord.incidentId)).map((item) => {
                                            return <Select.Option key={item.id} value={item.id}>{item.description}</Select.Option>
                                        })
                                    }

                                </Select>
                               
                            </Form.Item>
 
                            <Form.Item label='Gestionado' labelAlign='left' name='enabled' valuePropName='checked'>
                                <Switch />
                            </Form.Item>
                
                    <Form.Item>
                        <Space>
                        <Button
                                type='primary'
                                shape='round'
                                htmlType='submit'
                                onClick={handleCancel}
                                key="submit"
                            >
                                Guardar
                            </Button>
                            <Button
                                key="back"
                                type='ghost'
                                shape='round'
                                onClick={() => {
                                    handleClear(form);
                                    setClickedRow("");
                                    handleCancel();
                                }}
                            >
                                Cancelar
                            </Button>
                        </Space>
                    </Form.Item>
                    <Form.Item name='deleteable' hidden valuePropName='checked'>
                        <Switch />
                    </Form.Item>
                </Form>
            </Modal>
        </Col>
    );
}
