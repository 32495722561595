import cryptoService from './cryptoService';

class LocalStorageService {
    get(key) {
        const value = localStorage.getItem(key);
        
        return !!value ? cryptoService.decrypt(value) : null;
    };

    set(key, value) {
        localStorage.setItem(key, cryptoService.encrypt(value));
    };

    remove(key) {
        localStorage.removeItem(key);
    };
}

export default new LocalStorageService();