import { ApolloClient, from, HttpLink, split } from '@apollo/client';
import { getMainDefinition } from '@apollo/client/utilities';
import { WebSocketLink } from '@apollo/client/link/ws';

import errorLink from './errorLink';
import authLink from './authLink';
import cache from './cache';
import tokenRefreshLink from './tokenRefreshLink';
import localStorageService from '../localStorageService';

const { REACT_APP_HASURA_HOST_NAME } = process.env;

const getWebSocketsToken = async () => {
    const res = await fetch(process.env.REACT_APP_FUNCTION_WEB_SOCKETS_TOKEN, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
            id: localStorageService.get('id') ?? "",
            role: localStorageService.get('role') === null || localStorageService.get('role') === "" ? 'customer' : localStorageService.get('role'),
        }),
    });

    const data = await res.json();

    return data.websocketsToken;
};

const httpLink = new HttpLink({
    uri: `https://${REACT_APP_HASURA_HOST_NAME}/v1/graphql`,
    //credentials: 'include',
});

const wsLink = new WebSocketLink({
    uri: `wss://${REACT_APP_HASURA_HOST_NAME}/v1/graphql`,
    options: {
        reconnect: true,
        //lazy: true,
        connectionParams: async () => {
            const websocketsToken = await getWebSocketsToken();

            return {
                headers: {
                    "content-type": "application/json",
                    Authorization: `Bearer ${websocketsToken}`,
                    "x-hasura-role": localStorageService.get('role') === null || localStorageService.get('role') === "" ? 'customer' : localStorageService.get('role'),
                    "x-hasura-user-id": localStorageService.get('id') ?? "",
                }
            }
        },
    },
});

const splitLink = split(
    ({ query }) => {
        const definition = getMainDefinition(query);

        return (
            definition.kind === 'OperationDefinition' &&
            definition.operation === 'subscription'
        );
    },
    wsLink,
    httpLink,
);

const apiClient = new ApolloClient({
    link: from([tokenRefreshLink, errorLink, authLink, splitLink]),
    cache,
    //credentials: 'same-origin',
});

export default apiClient;