import { Button, Input, Space } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import Swal from 'sweetalert2';

export const handleSave = async (values, insDistributionCenter, updDistributionCenter, distributionCenters, setDistributionCenters, isEditing, setIsEditing, form) => {
    const { id, name, description, enabled, deleteable, state, city, address, addressComplement, lat, lng, contactName, phone, email, sectorId, sector } = values;

    try {
        if(!isEditing) {
            const res = await insDistributionCenter({
                variables: {
                    name,
                    description,
                    enabled,
                    state,
                    city,
                    address,
                    addressComplement,
                    lat,
                    lng,
                    contactName, 
                    phone,
                    email,
                    sectorId,
                    sector
                },
            });
    
            if(res.data.insert_distributionCenters.affected_rows > 0) {
                const { id } = res.data.insert_distributionCenters.returning[0];

                setDistributionCenters((current) => [...current, {
                    id,
                    name,
                    description,
                    enabled,
                    deleteable: true,
                    state,
                    city,
                    address,
                    addressComplement,
                    lat,
                    lng,
                    contactName, 
                    phone,
                    email,
                    sectorId,
                    sector
                }]);
            }
        } else {
            const res = await updDistributionCenter({
                variables: {
                    id,
                    name,
                    description,
                    enabled,
                    state,
                    city,
                    address,
                    addressComplement,
                    lat,
                    lng,
                    contactName, 
                    phone,
                    email,
                    sectorId,
                    sector
                }
            });

            if(res.data.update_distributionCenters.affected_rows > 0) {
                const records = distributionCenters;
                const indexToReplace = distributionCenters.findIndex((item) => item.id === id);

                records.splice(indexToReplace, 1, {
                    id,
                    name,
                    description,
                    enabled,
                    deleteable,
                    state,
                    city,
                    address,
                    addressComplement,
                    lat,
                    lng,
                    contactName, 
                    phone,
                    email,
                    sectorId,
                    sector
                });
                setDistributionCenters([...records]);
            }
        }
    } catch (error) {
        console.error(error);
    }

    setIsEditing(false);

    form.setFieldsValue({
        id: '',
        name: '',
        description: '',
        enabled: true,
        deleteable: true,
        state: '',
        city: '',
        address: '',
        addressComplement: '',
        lat: '',
        lng: '',
        contactName: '', 
        phone: '',
        email: '',
        sectorId: null,
        sector: ''
    });
};

export const handleDelete = (record, delIncident, distributionCenters, setDistributionCenters, setClickedRow) => {
    const id  = record;

    Swal.fire({
        title: '¿Seguro desea eliminar?',
        text: "Este proceso es irreversible!",
        icon: 'question',
        showCancelButton: true,
        confirmButtonColor: 'var(--primaryColor)',
        cancelButtonColor: '#B6B6B6',
        confirmButtonText: 'Sí, eliminar',
        cancelButtonText: 'No, cancelar',
    }).then( async (result) => {
        if(result.isConfirmed) {
            try {
                const res = await delIncident({
                    variables: {
                        id
                    },
                    fetchPolicy: 'no-cache'
                });
    
                if(res.data.delete_distributionCenters.affected_rows > 0) {
                    setDistributionCenters(distributionCenters.filter((item) => item.id !== id));
                    setClickedRow("");
                    Swal.fire({
                        title: 'Registro eliminado',
                        icon: 'success',
                        showConfirmButton: false,
                        timer: 1000
                    });
                }
            } catch (error) {
                console.error(error)
            }
        }
    });
};

export const handleEdit = (record, setIsEditing, form) => {
    const { id, name, description, enabled, deleteable, state, city, address, addressComplement, lat, lng, contactName, phone, email, sectorId, sector } = record;
    
    setIsEditing(true);

    form.setFieldsValue({
        id,
        name,
        description,
        enabled,
        deleteable,
        state,
        city,
        address,
        addressComplement,
        lat,
        lng,
        contactName, 
        phone,
        email,
        sector,
        sectorId
    });
};

export const handleClear = (setIsEditing, form) => {
    setIsEditing(false);

    form.setFieldsValue({
        id: '',
        name: '',
        description: '',
        enabled: true,
        deleteable: true,
        state: '',
        city: '',
        address: '',
        addressComplement: '',
        lat: '',
        lng: '',
        contactName: '', 
        phone: '',
        email: '',
        sector: '',
        sectorId: null
    });
};

const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
};

const handleReset = (clearFilters) => {
    clearFilters();
};

export const getColumnSearchPropsText = (title, dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
        <div style={{ padding: 8 }}>
            <Input
                placeholder={`Buscar ${title}`}
                value={selectedKeys[0]}
                onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                style={{ width: 188, marginBottom: 8, display: 'block' }}
            />
            <Space>
                <Button
                    type="primary"
                    onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                    icon={<SearchOutlined />}
                    size="small"
                    style={{ width: 90 }}
                >
                    Buscar
                </Button>
                <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
                    Restablecer
                </Button>
            </Space>
        </div>
    ),
    filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
    onFilter: (value, record) =>
        record[dataIndex]
            ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
            : '',
    onFilterDropdownVisibleChange: visible => {
        if (visible) {
            /*if(Object.keys(searchText).length > 0) {
                setTimeout(() => searchInput.select(), 100);
            }*/
        }
    },
});