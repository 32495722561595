import React from 'react';
import { Button, DatePicker, Input, Space } from 'antd';
import { ClearOutlined, SearchOutlined } from '@ant-design/icons';

export const handleSave = async (values, upIncidentReport, incidentReport, setIncidentReport, form) => {
    const { id, incidentManagementId, incidentManagementDescription, enabled, deleteable } = values;

    try {
        const res = await upIncidentReport({
            variables: {
                id,
                incidentManagementId,
                enabled
            }
        });

        if (res.data.update_incidentReport.affected_rows > 0) {
            const records = incidentReport;
            const indexToReplace = incidentReport.findIndex((item) => item.id === id);

            records.splice(indexToReplace, 1, {
                ...values,
                id,
                incidentManagementId,
                incidentManagementDescription,
                enabled,
                deleteable
            });
            setIncidentReport([...records]);
        }
    } catch (error) {
        console.error(error);
    }

    form.setFieldsValue({
        id: '',
        incidentManagementId: undefined,
        incidentManagementDescription: undefined,
        enabled: true,
        deleteable: true
    });
};

export const handleEdit = (record, form) => {
    const { id, incidentManagementId, incidentManagementDescription, enabled, deleteable } = record;

    form.setFieldsValue({
        id,
        incidentManagementId,
        incidentManagementDescription,
        enabled,
        deleteable
    });
};

export const handleClear = (form) => {
    form.setFieldsValue({
        id: '',
        incidentManagementId: undefined,
        enabled: true,
        deleteable: true
    });
};

const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
};

const handleReset = (clearFilters) => {
    clearFilters();
};

export const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters
    }) => (
        <div style={{ padding: 8 }}>
            <DatePicker
                placeholder='Fecha'
                style={{ marginRight: "10px" }}
                format="DD-MM-YYYY"
                onChange={e =>
                    setSelectedKeys(e !== null ? [e.format("DD/MM/YYYY")] : [])
                }
            />
            <br />
            <Button
                type="primary"
                onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                size="small"
                style={{ width: 95, marginRight: 8, marginTop: 5 }}
                icon={<SearchOutlined />}
            >
                Buscar
            </Button>
            <Button
                onClick={() => handleReset(clearFilters)}
                size="small"
                style={{ width: 30 }}
                icon={<ClearOutlined />}
            />
        </div>
    ),
    onFilter: (value, record) => record[dataIndex].includes(value),
    onFilterDropdownVisibleChange: (visible) => {
        if (visible) {
            //setTimeout(() => this.searchInput.select());
        }
    },

    /*record[dataIndex]
        .toString()
        .toLowerCase()
        .includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: visible => {
        if (visible) {
            //setTimeout(() => this.searchInput.select());
        }
    },*/
});

export const getColumnSearchPropsText = (title, dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
        <div style={{ padding: 8 }}>
            <Input
                placeholder={`Buscar ${title}`}
                value={selectedKeys[0]}
                onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                style={{ width: 188, marginBottom: 8, display: 'block' }}
            />
            <Space>
                <Button
                    type="primary"
                    onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                    icon={<SearchOutlined />}
                    size="small"
                    style={{ width: 90 }}
                >
                    Buscar
                </Button>
                <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
                    Restablecer
                </Button>
            </Space>
        </div>
    ),
    filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
    onFilter: (value, record) =>
        record[dataIndex]
            ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
            : '',
    onFilterDropdownVisibleChange: visible => {
        if (visible) {
            /*if(Object.keys(searchText).length > 0) {
                setTimeout(() => searchInput.select(), 100);
            }*/
        }
    },
});
