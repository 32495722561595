import { fromPromise } from '@apollo/client';
import { onError } from '@apollo/client/link/error';

import localStorageService from '../localStorageService';

const getNewAccessToken = async () => {
    const body = JSON.stringify({
        id: localStorageService.get('id') ?? '',
        role: localStorageService.get('role') ?? 'customer'
    });

    const newToken = await fetch(process.env.REACT_APP_FUNCTION_ACCESS_TOKEN, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body
    });

    const token = await newToken.json();

    return token.accessToken;
};

const errorLink = onError(({ graphQLErrors, networkError, operation, forward }) => {
   
    if (graphQLErrors || networkError) {
        return fromPromise(
            getNewAccessToken().catch((error) => {
                console.error(error);
                return;
            })
        )
            .filter((value) => Boolean(value))
            .flatMap((accessToken) => {
                const oldHeaders = operation.getContext().headers;

                operation.setContext({
                    headers: {
                        ...oldHeaders,
                        Authorization: `Bearer ${accessToken}`,
                        "x-hasura-role": localStorageService.get('role') ?? 'customer',
                        "x-hasura-user-id": localStorageService.get('id') ?? '',
                    }
                });

                return forward(operation);
            })
    }
});


export default errorLink;