import '../css/BasicIndicator.css';
import { Row, Col } from 'antd';
import { ArrowUpOutlined, ArrowDownOutlined, MinusOutlined } from '@ant-design/icons';
import { AreaChart, Area, ResponsiveContainer } from "recharts";

export const BasicIndicator = (props) => {
    
    return (
        <div className='basic-indicator-container' style={{backgroundColor: props.bgColor}}>
            <Row gutter={[25, 25]}>

                <Col className='basic-indicator-container1' span={20} >
                  <Row gutter={[25, 25]}>
                    <Col span={20} >
                      <h6 className='basic-indicator-label'>{props.label}</h6>  
                    </Col>
                  </Row>

                  <Row gutter={[25, 25]}>

                    <Col span={6}>
                      
                      <Row gutter={[25, 25]}>
                        <Col span={20} >
                          <h2 className='basic-indicator-cumulative'>{props.cumulative}</h2>  
                        </Col>
                      </Row>

                      <Row gutter={[25, 25]}>
                        <Col span={4}>
                            <p className='basic-indicator-value'>{props.value}</p>
                        </Col>
                        <Col span={3}>
                        {
                          props.grew !== null ?
                          props.grew ? 
                          <ArrowUpOutlined className='basic-indicator-icon2'/>
                          :
                          <ArrowDownOutlined className='basic-indicator-icon2'/> : <MinusOutlined className='basic-indicator-icon2'/>
                        }
                        </Col>    
                      </Row>

                    </Col>

                    <Col span={18}>
                      <ResponsiveContainer width="100%" height="87%">
                        <AreaChart
                          data={props.data}
                        >
                          <Area type="monotone" dataKey="pv" stroke={'rgba(255,255,255,0.75)'} fill={'rgba(255,255,255,0.5)'} strokeWidth={2}/>
                        </AreaChart>
                      </ResponsiveContainer>
                    </Col>

                  </Row>

                </Col>

                <Col className='basic-indicator-container2' span={4} >
                    {props.children}
                </Col>
            
            </Row>
        </div>
    );
}
