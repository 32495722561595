import React, { useState, useEffect, useCallback, useRef } from 'react';
import { Result, Button, Spin } from 'antd';
import { AimOutlined, EyeOutlined, EyeInvisibleOutlined } from '@ant-design/icons';
import {
    GoogleMap,
    useLoadScript,
    Marker,
    InfoWindow,
    Polyline
} from '@react-google-maps/api';

import { v4 as uuidv4 } from 'uuid';
import decodePolyline from 'decode-google-map-polyline';
import moment from 'moment';
import 'moment/locale/es';

import { basicTheme } from '../../../../map-themes/basicTheme';

import pin from "../../../../images/default_marker.png";
import finishPin from '../../../../images/finish_marker.png';
import currentLocationPin from '../../../../images/current-location.png';
import lastPositionTruck from '../../../../images/last-position-truck.png';
//import { getStrokeColor } from '../../../../helpers/utils';

const libraries = ["places"];

const mapContainerStyle = {
    width: '100%',
    height: '80vh'
};

const options = {
    styles: basicTheme,
    disableDefaultUI: true,
    zoomControl: false
};

const createRouteCoords = (points) => {
    let steps = decodePolyline(points);
    let coords = [];

    coords = [{ polylineId: uuidv4(), coords: steps }];

    return coords;
};

export const MapScreen = ({ selectedUserVehicle, toogleView, viewMode, children }) => {
    const [markers, setMarkers] = useState([]);
    const [polylines, setPolylines] = useState([]);
    const [selectedPin, setSelectedPin] = useState(null);
    const [center, setCenter] = useState({ lat: 4.570868, lng: -74.297333 });
    const [loadingLocation, setLoadingLocation] = useState(true);
    const [locationError, setLocationError] = useState('');
    const [tracking, setTracking] = useState(false);
    const [isSelectedVehicleMayorOne, setIsSelectedVehicleMayorOne] = useState(false);
    //const [strokeColors, setStrokeColors] = useState([]);

    const { isLoaded, loadError } = useLoadScript({
        googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
        libraries
    });

    useEffect(() => {
        let isMounted = true;

        if (isLoaded) {
            navigator.geolocation.getCurrentPosition(
                function (position) {
                    if (isMounted) {
                        setCenter({ lat: position.coords.latitude, lng: position.coords.longitude })
                        setMarkers((current) => [...current, {
                            lat: position.coords.latitude,
                            lng: position.coords.longitude,
                            markerId: 'currentLocation',
                            icon: currentLocationPin,
                            scaledSize: new window.google.maps.Size(30, 33),
                        }]);
                        setLoadingLocation(false);
                    }
                },
                function (error) {
                    if (error.code === 1) {
                        setLocationError('Ubicación denegada por el usuario.');
                    } else if (error.code === 2) {
                        setLocationError('Ubicación no disponible.');
                    } else if (error.code === 3) {
                        setLocationError('Tipo de espera agotado.');
                    }
                    setLoadingLocation(false);
                }
            );
        }

        return () => { isMounted = false };
    }, [isLoaded]);


    useEffect(() => {
        setMarkers([]);
        setPolylines([]);
        setIsSelectedVehicleMayorOne(false);
        let pathCoords = []
        let prevDestination = ''
        const strokeColors = [];

        if (selectedUserVehicle.length > 1) {
            selectedUserVehicle.map((item) => {
                return strokeColors.push(randonStrokeColorPolyline());
            });
        }

        if (selectedUserVehicle.length === 1) {
            const thePolylines = [];
            const { lastLocation } = selectedUserVehicle[0];
            let date = moment.utc(selectedUserVehicle[0].lastLocationUpdatedAt);
            let localTime = moment(date).local().format('DD/MM/YYYY h:mm a');

            if (lastLocation !== null) {
                if (tracking) {
                    setCenter({ lat: parseFloat(lastLocation.split(',')[0]), lng: parseFloat(lastLocation.split(',')[1]) })
                }

                setMarkers((current) => [...current, {
                    lat: parseFloat(lastLocation.split(',')[0]),
                    lng: parseFloat(lastLocation.split(',')[1]),
                    markerId: selectedUserVehicle[0].key,
                    address: `Vehículo: ${selectedUserVehicle[0].licencePlate}`,
                    addressComplement: `Conductor: ${selectedUserVehicle[0].displayName}`,
                    lastLocationDate: localTime,
                    icon: lastPositionTruck,
                    scaledSize: new window.google.maps.Size(35, 40),
                }]);
            }

            for (let index = 0; index < selectedUserVehicle[0].assignedRoutes.length; index++) {
                const item = selectedUserVehicle[0].assignedRoutes[index];

                setMarkers((current) => [...current, {
                    lat: parseFloat(item.orderTable.destination.split(',')[0]),
                    lng: parseFloat(item.orderTable.destination.split(',')[1]),
                    markerId: item.id,
                    address: item.orderTable.address,
                    addressComplement: item.orderTable.addressComplement,
                    icon: index !== selectedUserVehicle[0].assignedRoutes.length - 1 ? pin : finishPin,
                    scaledSize: index !== selectedUserVehicle[0].assignedRoutes.length - 1 ? new window.google.maps.Size(15, 38) : new window.google.maps.Size(24, 35),
                }]);

                if (Object.keys(item.routeBatch).length > 0 && item.routeBatch.polylines !== null) {
                    setPolylines((current) => [...current, createRouteCoords(item.routeBatch.polylines)]);
                    thePolylines.push(createRouteCoords(item.routeBatch.polylines));
                } else {
                    let pathFrom = {
                        lat: prevDestination !== '' ? parseFloat(prevDestination.split(',')[0]) : parseFloat(item.orderTable.destination.split(',')[0]),
                        lng: prevDestination !== '' ? parseFloat(prevDestination.split(',')[1]) : parseFloat(item.orderTable.destination.split(',')[1])
                    }

                    let pathTo = {
                        lat: parseFloat(item.orderTable.destination.split(',')[0]),
                        lng: parseFloat(item.orderTable.destination.split(',')[1])
                    }

                    let arr = [pathFrom, pathTo];
                    pathCoords = arr;
                    prevDestination = item.orderTable.destination;

                    thePolylines.push({
                        polylineId: item.id, coords: pathCoords
                    });
                }
            }
            setPolylines(thePolylines)

        } else if (selectedUserVehicle.length > 1) {
            setIsSelectedVehicleMayorOne(true);

            setTracking(false);
            const polys = [];
            selectedUserVehicle.map((item, idx) => {
                const { lastLocation } = item;
                let date = moment.utc(item.lastLocationUpdatedAt);
                let localTime = moment(date).local().format('DD/MM/YYYY h:mm a');
                prevDestination = '';
                const polyLineOptions = {
                    strokeColor: strokeColors[idx],
                    strokeOpacity: 0.75,
                    strokeWeight: 3,
                };

                if (lastLocation !== null) {
                    //setCenter({lat: parseFloat(lastLocation.split(',')[0]), lng: parseFloat(lastLocation.split(',')[1])})
                    setMarkers((current) => [...current, {
                        lat: parseFloat(lastLocation.split(',')[0]),
                        lng: parseFloat(lastLocation.split(',')[1]),
                        markerId: item.key,
                        address: `Vehículo: ${item.licencePlate}`,
                        addressComplement: `Conductor: ${item.displayName}`,
                        lastLocationDate: localTime,
                        icon: lastPositionTruck,
                        scaledSize: new window.google.maps.Size(35, 40),
                    }]);
                }

                for (let index = 0; index < item.assignedRoutes.length; index++) {
                    const assignedRoute = item.assignedRoutes[index];

                    setMarkers((current) => [...current, {
                        lat: parseFloat(assignedRoute.orderTable.destination.split(',')[0]),
                        lng: parseFloat(assignedRoute.orderTable.destination.split(',')[1]),
                        markerId: assignedRoute.id,
                        address: assignedRoute.orderTable.address,
                        addressComplement: assignedRoute.orderTable.addressComplement,
                        icon: index !== item.assignedRoutes.length - 1 ? pin : finishPin,
                        scaledSize: index !== item.assignedRoutes.length - 1 ? new window.google.maps.Size(15, 38) : new window.google.maps.Size(24, 35),
                    }]);

                    if (Object.keys(assignedRoute.routeBatch).length > 0 && assignedRoute.routeBatch.polylines !== null) {
                        polys.push([createRouteCoords(assignedRoute.routeBatch.polylines), { polyLineOptions }]);
                    } else {
                        let pathFrom = {
                            lat: prevDestination !== '' ? parseFloat(prevDestination.split(',')[0]) : parseFloat(assignedRoute.orderTable.destination.split(',')[0]),
                            lng: prevDestination !== '' ? parseFloat(prevDestination.split(',')[1]) : parseFloat(assignedRoute.orderTable.destination.split(',')[1])
                        }

                        let pathTo = {
                            lat: parseFloat(assignedRoute.orderTable.destination.split(',')[0]),
                            lng: parseFloat(assignedRoute.orderTable.destination.split(',')[1])
                        }

                        let arr = [pathFrom, pathTo];
                        pathCoords = arr;
                        prevDestination = assignedRoute.orderTable.destination;

                        polys.push({
                            polylineId: assignedRoute.id, coords: pathCoords, polyLineOptions: polyLineOptions
                        });
                    }
                }
                return setPolylines(polys);
            });
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedUserVehicle]);

    useEffect(() => {
        return () => {
            mapRef.current = null;
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const onLoadLocation = () => {
        setLoadingLocation(true);
        navigator.geolocation.getCurrentPosition(
            function (position) {
                const newMarkers = markers.filter((item) => item.markerId !== 'currentLocation');

                setCenter({ lat: position.coords.latitude, lng: position.coords.longitude })
                setMarkers([...newMarkers, {
                    lat: position.coords.latitude,
                    lng: position.coords.longitude,
                    markerId: 'currentLocation',
                    icon: currentLocationPin,
                    scaledSize: new window.google.maps.Size(30, 33)
                }]);
                setLoadingLocation(false);
            },
            function (error) {
                if (error.code === 1) {
                    setLocationError('Ubicación denegada por el usuario.');
                } else if (error.code === 2) {
                    setLocationError('Ubicación no disponible.');
                } else if (error.code === 3) {
                    setLocationError('Tipo de espera agotado.');
                }
                setLoadingLocation(false);
            }
        );
    };

    const mapRef = useRef();

    const onMapClick = useCallback((e) => {
        setSelectedPin(null);
    }, []);

    const onMapLoad = useCallback((map) => {
        mapRef.current = map;
    }, []);

    const randonStrokeColorPolyline = () => {
        let letters = '0123456789ABCDEF';
        let color = '#';
        for (let i = 0; i < 6; i++) {
            color += letters[Math.floor(Math.random() * 16)];
        }
        return color;
    };

    if (loadError || locationError !== '') {
        return (
            <section style={{
                height: '70vh',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
            }}>
                <Result
                    style={{
                        left: '50%',
                        top: '50%',

                    }}
                    status="warning"
                    title={locationError}
                    subTitle="Esto no impide seguir utilizando el sitio"
                    extra={[
                        <Button
                            type="primary"
                            shape="round"
                            size="large"
                            onClick={() => { setLocationError('') }}
                        >
                            {loadError ? 'Aceptar' : 'Cerrar y continuar'}
                        </Button>,
                    ]}
                />
            </section>
        );
    }

    if (!isLoaded) {
        return "Cargando mapas...";
    }

    return (
        <section className="mapa-google">
            <GoogleMap
                mapContainerStyle={mapContainerStyle}
                zoom={15}
                center={center}
                options={options}
                onClick={onMapClick}
                onLoad={onMapLoad}
                id="mapa-google-map"
            >
                <section>
                    <section style={{
                        float: 'right',
                        marginRight: '5px',
                        marginTop: '8px',
                        textAlign:'left'
                    }}>
                        <Button
                            type='primary'
                            shape='circle'
                            size='large'
                            loading={loadingLocation}
                            icon={<AimOutlined />}
                            onClick={() => { onLoadLocation() }}
                        />
                        <br />
                        <Button
                            type='primary'
                            shape='circle'
                            size='large'
                            disabled={selectedUserVehicle.length === 0 || selectedUserVehicle.length > 1}
                            style={{
                                marginTop: '5px'
                            }}
                            icon={!tracking ? <EyeOutlined /> : <EyeInvisibleOutlined />}
                            onClick={() => {
                                setTracking(!tracking);
                            }}
                        />

                        <br />
                        <Button
                            type='primary'
                            shape='circle'
                            size='large'
                            style={{
                                marginTop: '5px'
                            }}
                            onClick={() => {
                                toogleView();
                            }}
                        ><strong>{viewMode}</strong></Button>
                    </section>
                    {loadingLocation
                        ? <Spin
                            tip='Localizando...'
                            size="large"
                            style={{
                                position: 'absolute',
                                left: '50%',
                                top: '50%',
                                WebkitTransform: 'translate(-50%, -50%)',
                                transform: 'translate(-50%, -50%)'
                            }}
                        />
                        : <section></section>
                    }
                    {markers.length > 0
                        ? (markers.map((marker) => {
                            return (
                                <section key={marker.markerId}>
                                    <Marker
                                        //label='Elvis'
                                        key={marker.markerId}
                                        
                                        position={{ lat: marker.lat, lng: marker.lng }}
                                        icon={marker.icon !== null ? {
                                            url: marker.icon,
                                            scaledSize: marker.scaledSize,
                                            //origin: new window.google.maps.Point(0, 0),
                                            //anchor: new window.google.maps.Point(7, 42),
                                        } : null}
                                        onClick={(e) => {
                                            if (marker.markerId !== 'currentLocation') {
                                                setSelectedPin(marker);
                                            }
                                        }}
                                    />

                                </section>
                            )
                        }))
                        : null
                    }
                    

                    {polylines.length > 0
                        ? (polylines.map((polyline) => {
                            let coords = [];
                            let polylineId = '';
                            let polyLineOptions = {
                                strokeColor: '#000000',
                                strokeOpacity: 0.75,
                                strokeWeight: 3,
                            };

                            if (selectedUserVehicle.length > 1) {
                                if (isSelectedVehicleMayorOne) {
                                    coords = polyline.length > 1 ? polyline[0][0].coords : polyline.coords;
                                    polylineId = polyline.length > 1 ? polyline[0][0].polylineId : polyline.polylineId;
                                    polyLineOptions = polyline.length > 1 ? polyline[1].polyLineOptions : polyline.polyLineOptions;
                                } else {
                                    coords = polyline.length !== undefined ? polyline[0].coords : polyline.coords;
                                    polylineId = polyline.length !== undefined ? polyline[0].polylineId : polyline.polylineId;
                                }

                            } else {
                                coords = polyline.length !== undefined ? polyline[0].coords : polyline.coords;
                                polylineId = polyline.length !== undefined ? polyline[0].polylineId : polyline.polylineId;
                            }

                            return (
                                <Polyline
                                    className="polyline-google"
                                    key={polylineId}
                                    path={coords}
                                    options={polyLineOptions}
                                />
                            )
                        }))
                        : null
                    }

                    {children}
                    {/* <TransitLayer /> */}
                    {/* <TrafficLayer onLoad={(tl) => {
                   
                    }} /> */}
                </section>

                {selectedPin
                    ? <InfoWindow
                        position={{ lat: selectedPin.lat, lng: selectedPin.lng }}
                        onCloseClick={() => {
                            setSelectedPin(null);
                        }}
                    >
                        <section>
                            <h2>{selectedPin.address}</h2>
                            <p>{selectedPin.addressComplement}</p>
                            {selectedPin.lastLocationDate && <p>Ultima actualización: {selectedPin.lastLocationDate}</p>}
                        </section>
                    </InfoWindow>
                    : null
                }
                
            </GoogleMap>
            
        </section>
    );
};