import React, { useState, useEffect } from 'react'
import { Form, Select, Table, Switch, Descriptions, Input, Card, Button, Space, Skeleton, Tooltip, List, Col, Row, Modal } from 'antd';
import { DeleteFilled, EditFilled, FieldTimeOutlined, SearchOutlined } from '@ant-design/icons';
import Swal from 'sweetalert2';
import { IdcardOutlined, CheckOutlined, ClockCircleOutlined, EditOutlined } from '@ant-design/icons';
import { useLazyQuery, useMutation, useApolloClient } from '@apollo/client';
import localStorageService from '../../../../../services/localStorageService';
import { ConfigurationMoreSettings } from '../../../ConfigurationMoreSettings';

import {
    fetchClients,
    insertClient,
    updateClient,
    deleteClient,
    fetchClientAddresess,
    fetchDistributionCenters,
    fetchSectors
} from '../../queries/clientsQueries';

import {
    personTypeList,
    personList,
    documentTypeList,
    currencyList,
    priorityList
} from '../../../common/commonData';

import { ClientAddresses } from './ClientAddresses';

import '../styles/clients.css';

import { ClientsIndicator } from './ClientsIndicator';

const companyId = localStorageService.get('companyId');

const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
};

const handleReset = (clearFilters) => {
    clearFilters();
};

const getColumnSearchPropsText = (title, dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
        <div style={{ padding: 8 }}>
            <Input
                placeholder={`Buscar ${title}`}
                value={selectedKeys[0]}
                onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                style={{ width: 188, marginBottom: 8, display: 'block' }}
            />
            <Space>
                <Button
                    type="primary"
                    onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                    icon={<SearchOutlined />}
                    size="small"
                    style={{ width: 90 }}
                >
                    Buscar
                </Button>
                <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
                    Restablecer
                </Button>
            </Space>
        </div>
    ),
    filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
    onFilter: (value, record) =>
        record[dataIndex]
            ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
            : '',
    onFilterDropdownVisibleChange: visible => {
        if (visible) {
            /*if(Object.keys(searchText).length > 0) {
                setTimeout(() => searchInput.select(), 100);
            }*/
        }
    },
});


export const ClientsScreen = () => {
    const client = useApolloClient();

    const [getClients, dataClients] = useLazyQuery(fetchClients, { variables: {companyId}, fetchPolicy: 'no-cache' });
    const [insClient] = useMutation(insertClient, { variables: {companyId}, fetchPolicy: 'no-cache' });
    const [updClient] = useMutation(updateClient, { fetchPolicy: 'no-cache' });
    const [delClient] = useMutation(deleteClient, { fetchPolicy: 'no-cache' });
    const [getDistributionCenters, dataDistributionCenters] = useLazyQuery(fetchDistributionCenters, { variables: {companyId}, fetchPolicy: 'no-cache' });
    const [getSectors, dataSectors] = useLazyQuery(fetchSectors, { variables: {companyId}, fetchPolicy: 'no-cache' });

    const [clients, setClients] = useState([]);
    const [isEditing, setIsEditing] = useState(false);
    const [showDrawer, setShowDrawer] = useState(false);
    const [clientAddresses, setClientAddresses] = useState([]);
    const [clientName, setClientName] = useState('');
    const [clientId, setClientId] = useState('');
    const [clickedRow, setClickedRow] = useState("");
    const [distributionCenters, setDistributionCenters] = useState([]);
    const [sectors, setSectors] = useState([]);

    const [form] = Form.useForm();

    const [isModalVisible, setIsModalVisible] = useState(false);

    const showModal = () => {
        setIsModalVisible(true);
    };

    const handleOk = () => {
        setIsModalVisible(false);
    };

    const handleCancel = () => {
        setIsModalVisible(false);
    };

    useEffect(() => {
        getClients();
        getDistributionCenters();
        getSectors()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        setClients([]);

        if (!dataClients.loading && dataClients.data && dataClients.data.clients.length > 0) {
            dataClients.data.clients.map((item) => {
                const deleteable = true;//item.vehicles_aggregate.aggregate.count === 0;
                const { id, nit, name, email, enabled, accountNumber, documentType, currency, personType, person, typePerson, clientAddresses } = item;

                const personTypeNameArr = personTypeList.filter(item => item.value === personType);
                const personTypeName = personTypeNameArr.length > 0 ? personTypeNameArr[0].label : '';

                const personNameArr = personList.filter(item => item.value === person);
                const personName = personNameArr.length > 0 ? personNameArr[0].label : '';

                return setClients((current) => [...current, {
                    id,
                    nit,
                    name,
                    email: email ?? "",
                    enabled,
                    deleteable,
                    accountNumber,
                    documentType,
                    currency,
                    personType,
                    typePerson,
                    person,
                    personName,
                    clientAddresses
                }]);
            });
        }
    }, [dataClients.loading, dataClients.data]);

    useEffect(() => {
        setDistributionCenters([]);

        if (!dataDistributionCenters.loading && dataDistributionCenters.data && dataDistributionCenters.data.distributionCenters.length > 0) {
            dataDistributionCenters.data.distributionCenters.map((item) => {
                const { id, name } = item;

                return setDistributionCenters((current) => [...current, {
                    id,
                    name
                }]);
            });
        }
    }, [dataDistributionCenters.loading, dataDistributionCenters.data]);

    useEffect(() => {
        setSectors([]);

        if (!dataSectors.loading && dataSectors.data && dataSectors.data.sectors.length > 0) {
            dataSectors.data.sectors.map((item) => {
                const { id, name, sectorPolygons } = item;

                return setSectors((current) => [...current, {
                    id,
                    name,
                    sectorPolygons
                }]);
            });
        }
    }, [dataSectors.loading, dataSectors.data]);

    const columns = [
        {
            title: 'Tipo',
            key: 'personName',
            dataIndex: 'personName',
            sorter: (a, b) => a.personName.localeCompare(b.personName),
            ...getColumnSearchPropsText("Tipo", "personName")
        },
        {
            title: 'Tipo de persona',
            key: 'typePerson',
            dataIndex: 'typePerson',
            render: (typePerson) => typePerson || "N/D",
            sorter: (a, b) => a.typePerson.localeCompare(b.typePerson),
            ...getColumnSearchPropsText("Tipo de persona", "typePerson")
        },
        {
            title: 'Tipo de documento',
            key: 'documentType',
            dataIndex: 'documentType',
            sorter: (a, b) => a.documentType.localeCompare(b.documentType),
            ...getColumnSearchPropsText("Tipo de documento", "documentType")
        },
        {
            title: 'Documento',
            key: 'nit',
            dataIndex: 'nit',
            sorter: (a, b) => a.nit.localeCompare(b.nit),
            ...getColumnSearchPropsText("Documento", "nit")
        },
        {
            title: 'Nombre',
            key: 'name',
            dataIndex: 'name',
            sorter: (a, b) => a.name.localeCompare(b.name),
            ...getColumnSearchPropsText("Nombre", "name")
        },
        {
            title: 'Número de cuenta',
            key: 'accountNumber',
            dataIndex: 'accountNumber',
            sorter: (a, b) => a.accountNumber.localeCompare(b.accountNumber),
            ...getColumnSearchPropsText("Número de cuenta", "accountNumber")
        },
        {
            title: 'Moneda',
            key: 'currency',
            dataIndex: 'currency',
            sorter: (a, b) => a.currency.localeCompare(b.currency),
            ...getColumnSearchPropsText("Moneda", "currency")
        },
        {
            title: 'Correo electrónico',
            key: 'email',
            dataIndex: 'email',
            sorter: (a, b) => a.email.localeCompare(b.email),
            ...getColumnSearchPropsText("Email", "email")
        },
        {
            title: 'Habilitado',
            key: 'enabled',
            dataIndex: 'enabled',
            sorter: {
                compare: (a, b) => a.enabled - b.enabled,
            },
            align: 'center',
            render: (value) => <Switch checked={value} disabled size='small' />
        }
    ];

    const handleSave = async (values) => {
        const { id, nit, name, email, enabled, deleteable, accountNumber, documentType, currency, personType, personTypeName, person, personName, clientAddresses } = values;

        try {
            if (!isEditing) {
                const res = await insClient({
                    variables: {
                        nit,
                        name,
                        email,
                        accountNumber,
                        documentType,
                        currency,
                        personType,
                        person,
                        enabled,
                        typePerson: personTypeName  
                    },
                });
                
                if (res.data.insert_clients.affected_rows > 0) {
                    const { id } = res.data.insert_clients.returning[0];

                    setClients((current) => [...current, {
                        id,
                        nit,
                        name,
                        email,
                        accountNumber,
                        documentType,
                        currency,
                        personType,
                        personTypeName,
                        person,
                        personName,
                        enabled,
                        deleteable: true,
                        clientAddresses: []
                    }]);
                }
            } else {
                const res = await updClient({
                    variables: {
                        id,
                        nit,
                        name,
                        email,
                        accountNumber,
                        documentType,
                        currency,
                        personType,
                        person,
                        enabled
                    }
                });

                if (res.data.update_clients.affected_rows > 0) {
                    const records = clients;
                    const indexToReplace = clients.findIndex((item) => item.id === id);

                    records.splice(indexToReplace, 1, {
                        id,
                        nit,
                        name,
                        email,
                        accountNumber,
                        documentType,
                        currency,
                        personType,
                        personTypeName,
                        person,
                        personName,
                        enabled,
                        deleteable,
                        clientAddresses
                    });
                    setClients([...records]);
                }
            }
        } catch (error) {
            console.error(error);
        }

        setIsEditing(false);

        form.setFieldsValue({
            id: '',
            nit: '',
            name: '',
            email: '',
            accountNumber: '',
            documentType: '',
            currency: '',
            personType: '',
            personTypeName: '',
            person: '',
            personName: '',
            enabled: true,
            deleteable: true,
            clientAddresses: []
        });
    };

    const handleDelete = (record) => {
        const id = record;

        Swal.fire({
            title: '¿Seguro desea eliminar?',
            text: "Este proceso es irreversible!",
            icon: 'question',
            showCancelButton: true,
            confirmButtonColor: 'var(--primaryColor)',
            cancelButtonColor: '#B6B6B6',
            confirmButtonText: 'Sí, eliminar',
            cancelButtonText: 'No, cancelar',
        }).then(async (result) => {
            if (result.isConfirmed) {
                try {
                    const res = await delClient({
                        variables: {
                            id
                        },
                        fetchPolicy: 'no-cache'
                    });

                    if (res.data.delete_clients.affected_rows > 0) {
                        setClients(clients.filter((item) => item.id !== id));

                        Swal.fire({
                            title: 'Registro eliminado',
                            icon: 'success',
                            showConfirmButton: false,
                            timer: 1000
                        });
                        setIsEditing(false);
                        setClickedRow("");
                    }
                } catch (error) {
                    console.error(error)
                }
            }
        });
    };

    const handleEdit = (record) => {
        const { id, nit, name, email, enabled, accountNumber, documentType, currency, personType, personTypeName, person, personName, deleteable, clientAddresses } = record;

        setIsEditing(true);

        form.setFieldsValue({
            id,
            nit,
            name,
            email,
            accountNumber,
            documentType,
            currency,
            personType,
            personTypeName,
            person,
            personName,
            enabled,
            deleteable,
            clientAddresses
        });
    };

    const handleClear = () => {
        setIsEditing(false);
        setClickedRow("");
        handleCancel();

        form.setFieldsValue({
            id: '',
            nit: '',
            name: '',
            email: '',
            accountNumber: '',
            documentType: '',
            currency: '',
            personType: '',
            personTypeName: '',
            person: '',
            personName: '',
            enabled: true,
            deleteable: true,
            clientAddresses: []
        });
    };
    const formItemLayout = {
        labelCol: {
            lg: { span: 9 }
        },
        wrapperCol: {
            lg: { span: 14 }
        },
    };
    return (
        <Col className='children-inner-container-with-bg'>
            <ConfigurationMoreSettings />
            <Row className='children-inner-row'>
                <Col span={5} className='children-inner-col'>
                    <ClientsIndicator value={clients.length} label={"Total Clientes"}><IdcardOutlined style={{backgroundColor: 'var(--primaryColor)'}} className="report-indicator-icon"/></ClientsIndicator>
                </Col>
                <Col span={5} className='children-inner-col'>
                    <ClientsIndicator value={(clients.filter(item => item.enabled)).length} label={"Clientes Activos"}><CheckOutlined style={{backgroundColor: '#9ccc65'}} className="report-indicator-icon"/></ClientsIndicator>
                </Col>
                <Col span={5} className='children-inner-col'>
                    <ClientsIndicator value={(clients.filter(item => !item.enabled)).length} label={"Clientes Inactivos"}><ClockCircleOutlined style={{backgroundColor: '#ffba57'}} className="report-indicator-icon"/></ClientsIndicator>
                </Col>
                <Col span={3} className='children-inner-col'><Button type="primary" size={"large"} className='children-inner-button' icon={<EditOutlined />} onClick={showModal}>{clickedRow.length === 0 ? 'Crear' : 'Editar'}</Button></Col>
                <Col span={3} className='children-inner-col'><Button type="primary" size={"large"} className='children-inner-button' icon={<DeleteFilled />} onClick={()=> handleDelete(clickedRow)} disabled={clickedRow.length === 0  || (clients.length > 0 && clients.filter(item => item.id === clickedRow)[0] && !clients.filter(item => item.id === clickedRow)[0].deleteable)}>Eliminar</Button></Col>
                <Col span={3} className='children-inner-col'><Button type="primary" size={"large"} className='children-inner-button' icon={<FieldTimeOutlined />} onClick={() => {
                                    client.query({
                                        query: fetchClientAddresess,
                                        variables: {
                                            clientId: clickedRow
                                        },
                                        fetchPolicy: 'no-cache'
                                    }).then(({ data, loading }) => {
                                        setClientAddresses([]);

                                        if (!loading && data.clientAddresses.length > 0) {
                                            const records = [];
                                            data.clientAddresses.map((item) => {
                                                const { id, name, contactName, phoneNumber, cellPhoneNumber, state, city, address, addressComplement, mail, principal, defaultDistributionCenterId, distributionCenterPrincipal, note, defaultPaymentMethod, defaultPriority, sectorId, country, lat, lng, enabled, clientId } = item;

                                                const sector = item.clientAddressesSector ? item.clientAddressesSector.name : '';
                                                const distributionCenter = item.clientAddressesDistributionCenter ? item.clientAddressesDistributionCenter.name : '';

                                                const priorityArr = priorityList.filter(item => item.value === defaultPriority);
                                                const priority = priorityArr.length > 0 ? priorityArr[0].label : '';

                                                return records.push({
                                                    id,
                                                    name,
                                                    contactName,
                                                    phoneNumber,
                                                    cellPhoneNumber,
                                                    state,
                                                    city,
                                                    address,
                                                    addressComplement,
                                                    mail,
                                                    principal,
                                                    distributionCenter,
                                                    defaultDistributionCenterId,
                                                    distributionCenterPrincipal,
                                                    note,
                                                    defaultPaymentMethod,
                                                    defaultPriority,
                                                    priority,
                                                    sector,
                                                    sectorId,
                                                    country,
                                                    lat,
                                                    lng,
                                                    enabled,
                                                    clientId
                                                });
                                            });
                                            setClientAddresses(records);
                                        }
                                        setClientId(clickedRow);
                                        setClientName((clients.filter(item => item.id ===clickedRow))[0].name);
                                        setShowDrawer(true);
                                    }).catch((error) => {
                                        console.error(error);
                                    });
                                }} disabled={clickedRow.length === 0}>Direcciones</Button></Col>
            </Row>
            <Row>
            <Skeleton loading={dataClients.loading} active>
                    <Table
                        columns={columns}
                        dataSource={clients}
                        className='children-table'
                        size='small'
                        rowKey='id'
                        pagination={{ pageSize: 13 }}
                        onRow={(record) => {
                            return {
                                onClick: () => {
                                    if(clickedRow.length === 0 || clickedRow !== record.id){
                                        setClickedRow(record.id);
                                        handleEdit(record, setIsEditing, form);
                                    }else{
                                        handleClear(setIsEditing, form);
                                        setClickedRow("");
                                    }
                                },
                            }
                        }}
                        rowClassName={(record) => ['mouse-row-enter', record.id === clickedRow ? 'table-row-color' : ""]}
                    />
                </Skeleton>
            </Row>
            <Modal className='children-modal' visible={isModalVisible} onOk={handleCancel} onCancel={handleCancel} footer={[]} title={clickedRow.length === 0 ? 'Crear Cliente' : 'Editar Cliente'} width={500}>
            <Form
            {...formItemLayout}
                    form={form}
                    name='configuration'
                    onFinish={(values) => {
                        handleSave(values);
                        setClickedRow("");
                    }}
                    initialValues={{
                        id: '',
                        nit: '',
                        name: '',
                        email: '',
                        address: '',
                        accountNumber: '',
                        documentType: '',
                        currency: '',
                        personType: '',
                        personTypeName: '',
                        person: '',
                        personName: '',
                        enabled: true
                    }}
                >
                    <Form.Item name='id' hidden>
                        <Input />
                    </Form.Item>
                    
                            <Form.Item
                            label='Tipo'
                            labelAlign='left'
                                name='person'
                                rules={[{ required: true, message: '' }]}
                            >
                                <Select 
                                    placeholder='Seleccione...'
                                    allowClear
                                    onChange={(value, object) => {
                                        if (value !== undefined) {
                                            return form.setFieldsValue({ person : value, personName: object.children })
                                        }
                                        return form.setFieldsValue({ person : null, personName: "" })
                                    }}
                                >
                                    {
                                        personList.map((item) => {
                                            return <Select.Option value={item.value}>{item.label}</Select.Option>
                                        })
                                    }
                                </Select>
                            </Form.Item>
                    
                            <Form.Item
                            label='Tipo de persona'
                            labelAlign='left'
                                name='personType'
                                rules={[{ required: true, message: '' }]}
                            >
                                <Select 
                                    placeholder='Seleccione...'
                                    allowClear
                                    onChange={(value, object) => {
                                        if (value !== undefined) {
                                            return form.setFieldsValue({ personType : value, personTypeName: object.children })
                                        }
                                        return form.setFieldsValue({ personType : null, personTypeName: "" })
                                    }}
                                >
                                    {
                                        personTypeList.map((item) => {
                                            return <Select.Option value={item.value}>{item.label}</Select.Option>
                                        })
                                    }
                                </Select>
                            </Form.Item>

                            <Form.Item
                            label='Nombre'
                            labelAlign='left'
                                name='name'
                                rules={[{ required: true, message: '' }]}
                            >
                                <Input />
                            </Form.Item>
      
                            <Form.Item
                            label='Tipo de documento'
                            labelAlign='left'
                                name='documentType'
                                rules={[{ required: true, message: '' }]}
                            >
                                <Select 
                                    placeholder='Seleccione...'
                                    allowClear
                                    onChange={(value, object) => {
                                        if (value !== undefined) {
                                            return form.setFieldsValue({ documentType : value })
                                        }
                                        return form.setFieldsValue({ documentType : null })
                                    }}
                                >
                                    {
                                        documentTypeList && documentTypeList.map((item) => {
                                            return <Select.Option value={item}>{item}</Select.Option>
                                        })
                                    }
                                </Select>
                            </Form.Item>
             
                            <Form.Item
                            label='Documento'
                            labelAlign='left'
                                name='nit'
                                rules={[{ required: true, message: '' }]}
                            >
                                <Input />
                            </Form.Item>

                            <Form.Item
                            label='Número de cuenta'
                            labelAlign='left'
                                name='accountNumber'
                            >
                                <Input />
                            </Form.Item>

                            <Form.Item
                            label='Moneda'
                            labelAlign='left'
                                name='currency'
                                rules={[{ required: true, message: '' }]}
                            >
                                <Select 
                                    placeholder='Seleccione...'
                                    allowClear
                                    onChange={(value, object) => {
                                        if (value !== undefined) {
                                            return form.setFieldsValue({ currency : value })
                                        }
                                        return form.setFieldsValue({ currency : null })
                                    }}
                                >
                                    {
                                        currencyList && currencyList.map((item) => {
                                            return <Select.Option value={item}>{item}</Select.Option>
                                        })
                                    }
                                </Select>
                            </Form.Item>
    
                            <Form.Item label='Correo electrónico'  labelAlign='left' name='email'>
                                <Input />
                            </Form.Item>
      
                            <Form.Item  labelAlign='left' label='Habilitado' name='enabled' valuePropName='checked'>
                                <Switch />
                            </Form.Item>

                    <Form.Item>
                        <Space>
                            <Button
                                type='primary'
                                shape='round'
                                htmlType='submit'
                                onClick={handleCancel}
                            >
                                {isEditing ? 'Modificar' : 'Agregar'}
                            </Button>
                            <Button
                                type='ghost'
                                shape='round'
                                onClick={handleClear}
                            >
                                Cancelar
                            </Button>
                        </Space>
                    </Form.Item>
                    <Form.Item name='deleteable' hidden valuePropName='checked'>
                        <Switch />
                    </Form.Item>
                    <Form.Item name='clientAddresses' hidden>
                        <List />
                    </Form.Item>
                    <Form.Item name='personTypeName' hidden>
                        <Input />
                    </Form.Item>
                    <Form.Item name='personName' hidden>
                        <Input />
                    </Form.Item>
                </Form>
            </Modal>
            <ClientAddresses
                setShowDrawer={setShowDrawer}
                showDrawer={showDrawer}
                setClientAddresses={setClientAddresses}
                clientAddresses={clientAddresses}
                clientName={clientName}
                clientId={clientId}
                distributionCenters={distributionCenters}
                sectors={sectors}
            />
        </Col>
    );
}
