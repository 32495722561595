import React, { useState, useEffect } from 'react';
import { Form, Table, Switch, Select, Descriptions, Input, Card, Button, Space, Skeleton, Col, Row, Modal } from 'antd';
import { DeleteFilled, EditFilled } from '@ant-design/icons';
import { useLazyQuery, useMutation } from '@apollo/client';
import { InboxOutlined, CheckOutlined, ClockCircleOutlined, EditOutlined } from '@ant-design/icons';
import { handleSave, handleDelete, handleEdit, handleClear, getColumnSearchPropsText } from './UseCases';
import localStorageService from '../../../../../services/localStorageService';

import {
    fetchDistributionCenters,
    insertDistributionCenter,
    updateDistributionCenter,
    deleteDistributionCenter,
    fetchSectors
} from '../../queries/distributionCentersQueries';
import {
    statesList,
} from '../../../common/commonData';
import axios from "axios";
import { DistributionCentersScreenIndicator } from './DistributionCentersScreenIndicator';
import { ConfigurationMoreSettings } from '../../../ConfigurationMoreSettings';
import calculateGeoEnclosure from "../../../../../services/geo-enclosure";
const companyId = localStorageService.get('companyId');

export const DistributionCentersScreen = () => {
    const [getDistributionCenters, dataDistributionCenters] = useLazyQuery(fetchDistributionCenters, { fetchPolicy: 'no-cache' });
    const [insDistributionCenter] = useMutation(insertDistributionCenter, { variables: {companyId}, fetchPolicy: 'no-cache' });
    const [delDistributionCenter] = useMutation(deleteDistributionCenter, { fetchPolicy: 'no-cache' });
    const [updDistributionCenter] = useMutation(updateDistributionCenter, { fetchPolicy: 'no-cache' });
    const [getSectors, dataSectors] = useLazyQuery(fetchSectors, { fetchPolicy: 'no-cache' });
    const [clickedRow, setClickedRow] = useState("");

    const [distributionCenters, setDistributionCenters] = useState([]);
    const [isEditing, setIsEditing] = useState(false);

    const [form] = Form.useForm();
    const [sectors, setSectors] = useState([]);
    const [depart, setDepart] = useState('');
    const [city, setCity] = useState('');
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [geoClouser, setGeoClouser] = useState("");
    const [selectedSector, setSelectedSector] = useState("");

    const showModal = () => {
        setIsModalVisible(true);
    };

    const handleOk = () => {
        setIsModalVisible(false);
    };

    const handleCancel = () => {
        setIsModalVisible(false);
    };

    useEffect(() => {
        getDistributionCenters({variables: {companyId}});
        getSectors({variables: {companyId}});
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        setDistributionCenters([]);

        if (!dataDistributionCenters.loading && dataDistributionCenters.data && dataDistributionCenters.data.distributionCenters.length > 0) {
            dataDistributionCenters.data.distributionCenters.map((item) => {
                const deleteable = dataDistributionCenters.data.distributionCenters_aggregate.aggregate.count === 0;
                const { id, name, description, state, city, address, addressComplement, enabled, lat, lng, contactName, phone, email, sectorId } = item;

                const sector = item.distributionCenterSector ? item.distributionCenterSector.name : '';

                return setDistributionCenters((current) => [...current, {
                    id,
                    name,
                    description,
                    state,
                    city,
                    address,
                    addressComplement,
                    enabled,
                    deleteable,
                    lat,
                    lng,
                    contactName, 
                    phone,
                    email,
                    sectorId,
                    sector
                }]);
            });
        }
    }, [dataDistributionCenters.loading, dataDistributionCenters.data]);

    useEffect(() => {
        setSectors([]);

        if (!dataSectors.loading && dataSectors.data && dataSectors.data.sectors.length > 0) {
            dataSectors.data.sectors.map((item) => {
                const { id, name, sectorPolygons } = item;

                return setSectors((current) => [...current, {
                    id,
                    name,
                    sectorPolygons
                }]);
            });
        }
    }, [dataSectors.loading, dataSectors.data]);

    const columns = [
        {
            title: 'Nombre',
            key: 'name',
            dataIndex: 'name',
            sorter: (a, b) => a.name.localeCompare(b.name),
            ...getColumnSearchPropsText("Nombre", "name")
        },
        {
            title: 'Descripción',
            key: 'description',
            dataIndex: 'description',
            sorter: (a, b) => a.description.localeCompare(b.description),
            ...getColumnSearchPropsText("Descripción", "description")
        },
        {
            title: 'Nombre de contacto',
            key: 'contactName',
            dataIndex: 'contactName',
            sorter: (a, b) => a.contactName.localeCompare(b.contactName),
            ...getColumnSearchPropsText("Nombre de contacto", "contactName")
        },
        {
            title: 'Teléfono',
            key: 'phone',
            dataIndex: 'phone',
            sorter: (a, b) => a.phone.localeCompare(b.phone),
            ...getColumnSearchPropsText("Teléfono", "phone")
        },
        {
            title: 'Correo',
            key: 'email',
            dataIndex: 'email',
            sorter: (a, b) => a.email.localeCompare(b.email),
            ...getColumnSearchPropsText("Correo", "email")
        },
        {
            title: 'Zona',
            key: 'sector',
            dataIndex: 'sector',
            sorter: (a, b) => a.sector.localeCompare(b.sector),
            ...getColumnSearchPropsText("Zona", "sector")
        },
        {
            title: 'Departamento',
            key: 'state',
            dataIndex: 'state',
            sorter: (a, b) => a.state.localeCompare(b.state),
            ...getColumnSearchPropsText("Departamento", "state")
        },
        {
            title: 'Ciudad',
            key: 'city',
            dataIndex: 'city',
            sorter: (a, b) => a.city.localeCompare(b.city),
            ...getColumnSearchPropsText("Ciudad", "city")
        },
        {
            title: 'Dirección',
            key: 'address',
            dataIndex: 'address',
            sorter: (a, b) => a.address.localeCompare(b.address),
            ...getColumnSearchPropsText("Dirección", "address")
        },
        {
            title: 'Habilitado',
            key: 'enabled',
            dataIndex: 'enabled',
            align: 'center',
            sorter: {
                compare: (a, b) => a.enabled - b.enabled,
            },
            render: (value) => <Switch checked={value} disabled />
        },
    ];

    const destinationAutoComplet = async (_address, _address1,_address2) => {
        const addressDepart = _address2;
        const addressCity = _address1;
        const addressA = _address;
        const addressComplement =`${addressDepart},${addressCity},${addressA}`;
        if (addressComplement.length > 0) {
          // verificar si no viene la latitud, longitud,
          // y si viene la dirección, convertirla a lat, lng
          let newAddress = "";
          const address = addressComplement
            .replace(",", " ")
            .replace(/\s\s+/g, " ")
            .replace("#", "")
            .split(" ");
    
          if (address.length > 0) {
            address.forEach((item) => {
              newAddress += item + "+";
            });
            newAddress = newAddress.substring(0, newAddress.length - 1);
    
            const res = await axios.get(
              `https://maps.googleapis.com/maps/api/geocode/json?key=${process.env.REACT_APP_GOOGLE_MAPS_API_KEY}&components=country:CO&address=${newAddress}`
            );
    
            if (res.status === 200) {
              form.setFieldsValue({
                lat: res.data.results[0].geometry ? res.data.results[0].geometry.location.lat.toString() : '',
                lng: res.data.results[0].geometry ? res.data.results[0].geometry.location.lng.toString() : ''
              });
            }
            let sector = calculateGeoEnclosure({
                lat: res.data.results[0].geometry.location.lat,
                lng: res.data.results[0].geometry.location.lng
              }, sectors);
              setGeoClouser(sector);
          }
        }
      };
      const formItemLayout = {
        labelCol: {
            lg: { span: 9 }
        },
        wrapperCol: {
            lg: { span: 14 }
        },
    };
    return (
        <Col className='children-inner-container-with-bg'>
            <ConfigurationMoreSettings />
            <Row className='children-inner-row'>
                <Col span={5} className='children-inner-col'>
                    <DistributionCentersScreenIndicator value={distributionCenters.length} label={"Total Centros de distribución"}><InboxOutlined style={{backgroundColor: 'var(--primaryColor)'}} className="report-indicator-icon"/></DistributionCentersScreenIndicator>
                </Col>
                <Col span={5} className='children-inner-col'>
                    <DistributionCentersScreenIndicator value={(distributionCenters.filter(item => item.enabled)).length} label={"Centros de distribución Activos"}><CheckOutlined style={{backgroundColor: '#9ccc65'}} className="report-indicator-icon"/></DistributionCentersScreenIndicator>
                </Col>
                <Col span={5} className='children-inner-col'>
                    <DistributionCentersScreenIndicator value={(distributionCenters.filter(item => !item.enabled)).length} label={"Centros de distribución Inactivos"}><ClockCircleOutlined style={{backgroundColor: '#ffba57'}} className="report-indicator-icon"/></DistributionCentersScreenIndicator>
                </Col>
                <Col span={3} offset={3} className='children-inner-col'><Button type="primary" size={"large"} className='children-inner-button' icon={<EditOutlined />} onClick={showModal}>{clickedRow.length === 0 ? 'Crear' : 'Editar'}</Button></Col>
                <Col span={3}  className='children-inner-col'><Button type="primary" size={"large"} className='children-inner-button' icon={<DeleteFilled />} onClick={()=> handleDelete(clickedRow, delDistributionCenter, distributionCenters, setDistributionCenters, setClickedRow)} disabled={clickedRow.length === 0}>Eliminar</Button></Col>
            </Row>
            <Row>
                <Skeleton loading={dataDistributionCenters.loading} active>
                    <Table
                        className='children-table'
                        columns={columns}
                        dataSource={distributionCenters}
                        size='small'
                        rowKey='id'
                        pagination={{ pageSize: 13 }}
                        onRow={(record) => {
                            return {
                                onClick: () => {
                                    if(clickedRow.length === 0 || clickedRow !== record.id){
                                        setClickedRow(record.id);
                                        handleEdit(record, setIsEditing, form);
                                    }else{
                                        handleClear(setIsEditing, form);
                                        setClickedRow("");
                                    }
                                    
                                },
                            }
                        }}
                        rowClassName={(record) => ['mouse-row-enter', record.id === clickedRow ? 'table-row-color' : ""]}
                    />
                </Skeleton>
            </Row>
            <Modal className='children-modal' visible={isModalVisible} onOk={handleCancel} onCancel={handleCancel} footer={[]} title={clickedRow.length === 0 ? 'Crear centro de distribución' : 'Editar centro de distribución'} width={600}>
                <Form
                {...formItemLayout}
                    form={form}
                    name='configuration'
                    onFinish={(values) => {
                        handleSave(values, insDistributionCenter, updDistributionCenter, distributionCenters, setDistributionCenters, isEditing, setIsEditing, form);
                        setClickedRow("");
                    }}
                    initialValues={{
                        id: '',
                        name: '',
                        description: '',
                        enabled: true,
                        deleteable: true,
                        state: '',
                        city: '',
                        addrres: '',
                        addressComplement: '',
                        lat: '',
                        lng: '',
                        contactName: '', 
                        phone: '',
                        email: '',
                        sectorId: null,
                        sector: ''
                    }}
                >
                    <Form.Item name='id' hidden>
                        <Input />
                    </Form.Item>
                    
                            <Form.Item
                                label='Nombre'
                                labelAlign='left'
                                name='name'
                                rules={[{ required: true, message: 'Por favor ingrese el nombre' }]}
                            >
                                <Input />
                            </Form.Item>
                        
                            <Form.Item
                            label='Descripción'
                            labelAlign='left'
                                name='description'
                                rules={[{ required: true, message: 'Por favor ingrese una descripción' }]}
                            >
                                <Input />
                            </Form.Item>
 
                            <Form.Item
                            label='Nombre de contacto'
                            labelAlign='left'
                                name='contactName'
                                rules={[{ required: true, message: 'Por favor ingrese la persona de contacto' }]}
                            >
                                <Input />
                            </Form.Item>
           
                            <Form.Item
                            label='Teléfono'
                            labelAlign='left'
                                name='phone'
                            >
                                <Input />
                            </Form.Item>
          
                            <Form.Item
                            label='Correo'
                            labelAlign='left'
                                name='email'
                            >
                                <Input />
                            </Form.Item>
             
                            <Form.Item labelAlign='left' name='sector' hidden>
                                <Input />
                            </Form.Item>
               
                            <Form.Item
                            label='Departamento'
                            labelAlign='left'
                                    name='state'
                                    rules={[{ required: true, message: 'Seleccione el departamento' }]}
                                >
                                    <Select
                                        placeholder='Seleccione...'
                                        allowClear
                                        style={{ width: '100%' }}
                                        onChange={(value, object) => { 
                                            if (value !== undefined) {
                                                destinationAutoComplet(value,form.getFieldValue("city"),form.getFieldValue("address"));
                                                return form.setFieldsValue({ state : value })
                                            }
                                            return form.setFieldsValue({ state : null })
                                            
                                        }}
                                    >
                                        {
                                            statesList && statesList.map((item) => {
                                                return <Select.Option value={item}>{item}</Select.Option>
                                            })
                                        }

                                    </Select>
                            </Form.Item>
             
                            <Form.Item
                            label='Ciudad'
                            labelAlign='left'
                                name='city'
                                onChange={(value, object) =>{
                                    destinationAutoComplet(form.getFieldValue("state"),value.target.value,form.getFieldValue("address"));
                                }}
                                rules={[{ required: true, message: 'Por ingrese la ciudad' }]}
                            >
                                <Input />
                            </Form.Item>
            
                            <Form.Item
                            label='Dirección'
                            labelAlign='left'
                                name='address'
                                onChange={(value, object) =>{
                                    destinationAutoComplet(form.getFieldValue("state"),form.getFieldValue("city"),value.target.value);
                                }}
                                rules={[{ required: true, message: 'Por ingrese la dirección' }]}
                            >
                                <Input />
                            </Form.Item>
   
                            <Form.Item
                            label='Complemento de la dirección'
                            labelAlign='left'
                                name='addressComplement'
                            >
                                <Input />
                            </Form.Item>
               
                            <Form.Item labelAlign='left' label="Latitud" name='lat'>
                                <Input />
                            </Form.Item>
            
                            <Form.Item labelAlign='left' label="Longitud" name='lng'>
                                <Input />
                            </Form.Item>
                            <Form.Item
                            label='Zona'
                            labelAlign='left'
                                    name='sectorId'
                                >
                                    <Select
                                        placeholder='Seleccione...'
                                        allowClear
                                        style={{ width: '100%' }}
                                        onChange={(value, object) => {
                                            setSelectedSector(sectors.find((item) => item.id === value).name);
                                            if (value !== undefined) {
                                                return form.setFieldsValue({ sectorId : value, sector: object.children })
                                            }
                                            return form.setFieldsValue({ sectorId : null, sector: '' })
                                        }}
                                    >
                                        {
                                            sectors && sectors.map((item) => {
                                                return <Select.Option value={item.id}>{item.name}</Select.Option>
                                            })
                                        }

                                    </Select>
                            </Form.Item>
                            {geoClouser?.name !== selectedSector && geoClouser?.id !== form.getFieldValue("sectorId") && geoClouser !== null && form.getFieldValue("lat") !== undefined && form.getFieldValue("lng") !== undefined ? (
                                    <Descriptions.Item>
                                    <Form.Item>
                                            <p style={{color:"red", marginLeft:"200px", width:"350px"}}>
                                            La ubicación se encuentra dentro de la zona {geoClouser?.name} pero esta no es la seleccionada
                                            </p>
                                    </Form.Item>
                                    </Descriptions.Item>
                                    ) : ("")

                                }
                            <Form.Item labelAlign='left' label='Habilitado' name='enabled' valuePropName='checked'>
                                <Switch />
                            </Form.Item>
          
                    <Form.Item>
                        <Space>
                            <Button 
                                type='primary' 
                                shape='round'
                                htmlType='submit'
                                onClick={handleCancel}
                            >
                                {isEditing ? 'Modificar' : 'Agregar'}
                            </Button>
                            <Button
                                type='ghost'
                                shape='round'
                                onClick={() => {
                                    handleClear(setIsEditing, form);
                                    setClickedRow("");
                                    handleCancel();
                                }}
                            >
                                Cancelar
                            </Button>
                        </Space>
                    </Form.Item>
                    <Form.Item name='deleteable' hidden valuePropName='checked'>
                        <Switch />
                    </Form.Item>
                </Form>
            </Modal>
        </Col>
    );
}