import { gql } from "@apollo/client";

export const fetchTransportCompanies = gql`
    query fetchTransportCompanies ($companyId: uuid!) {
        transportCompanies (where: {companyId: {_eq: $companyId}}) {
            id
            nit
            name
            phoneNumber
            cellPhoneNumber
            email
            address
            contactName
            ground
            maritime
            air
            enabled
            timetables {
                id
                day
                startTime
                endTime
                enabled
            }
            vehicles_aggregate {
                aggregate {
                    count(columns: id)
                }
            }
        }
    }
`;

export const insertTransportCompany = gql`
    mutation insertTransportCompany($nit: String!, $name: String!, $phoneNumber: String, $cellPhoneNumber: String, $email: String, $address: String, $contactName: String, $ground: Boolean!, $maritime: Boolean!, $air: Boolean!, $enabled: Boolean!, $companyId: uuid!) {
        insert_transportCompanies(objects: {nit: $nit, name: $name, phoneNumber: $phoneNumber, cellPhoneNumber: $cellPhoneNumber, email: $email, address: $address, contactName: $contactName, ground: $ground, maritime: $maritime, air: $air, enabled: $enabled, companyId: $companyId}) {
        affected_rows
            returning {
                id
            }
        }
    }
`;

export const updateTransportCompany = gql`
    mutation updateTransportCompany($id: uuid!, $nit: String!, $name: String!, $phoneNumber: String, $cellPhoneNumber: String, $email: String, $address: String, $contactName: String, $ground: Boolean!, $maritime: Boolean!, $air: Boolean!, $enabled: Boolean!) {
        update_transportCompanies(_set: {nit: $nit, name: $name, phoneNumber: $phoneNumber, cellPhoneNumber: $cellPhoneNumber, email: $email, address: $address, contactName: $contactName, ground: $ground, maritime: $maritime, air: $air, enabled: $enabled}, where: {id: {_eq: $id}}) {
            affected_rows
        }
    }
`;

export const deleteTransportCompany = gql`
    mutation deleteTransportCompany($id: uuid!) {
        delete_transportCompanies(where: {id: {_eq: $id}}) {
            affected_rows
        }
        delete_timetables(where: {transportCompanyId: {_eq: $id}}) {
            affected_rows
        }
    }  
`;

export const fetchTimetables = gql`
    query fetchTimetables($transportCompanyId: uuid!) {
        timetables(where: {transportCompanyId: {_eq: $transportCompanyId}}) {
            id
            day
            startTime
            endTime
            enabled
            transportCompanyId
        }
    }  
`;

export const insertTimetables = gql`
    mutation insertTimetables($day: String!, $startTime: String!, $endTime: String!, $enabled: Boolean!, $transportCompanyId: uuid!) {
        insert_timetables(objects: {day: $day, startTime: $startTime, endTime: $endTime, enabled: $enabled, transportCompanyId: $transportCompanyId}) {
            affected_rows
            returning {
                id
            }
        }
    }  
`;

export const updateTimetables = gql`
    mutation updateTimetables($id: uuid!, $day: String!, $startTime: String!, $endTime: String!, $enabled: Boolean!) {
        update_timetables(_set: {day: $day, startTime: $startTime, endTime: $endTime, enabled: $enabled}, where: {id: {_eq: $id}}) {
            affected_rows
        }
    }  
`;

export const deleteTimetables = gql`
    mutation deleteTimetables($id: uuid!) {
        delete_timetables(where: {id: {_eq: $id}}) {
            affected_rows
        }
    }
`;