import { gql } from "@apollo/client";

export const fetchReasons = gql`
    query fetchReasons ($companyId: uuid!) {
        reasons (where: {companyId: {_eq: $companyId}}) {
            id
            description
            deliveryType
            enabled
            routes_aggregate {
                aggregate {
                    count(columns: reasonId)
                }
            }
        }
    }
`;

export const insertReason = gql`
    mutation insertReason($description: String!, $deliveryType: String!, $enabled: Boolean!, $companyId: uuid!) {
        insert_reasons(objects: {description: $description, deliveryType: $deliveryType, enabled: $enabled, companyId: $companyId}) {
            affected_rows
            returning {
                id
            }
        }
    }
`;

export const updateReason = gql`
    mutation udateReason($id: uuid!, $description: String!, $deliveryType: String!, $enabled: Boolean!) {
        update_reasons(_set: {description: $description, deliveryType: $deliveryType, enabled: $enabled}, where: {id: {_eq: $id}}) {
            affected_rows
        }
    }
`;

export const deleteReason = gql`
    mutation deleteReason($id: uuid!) {
        delete_reasons(where: {id: {_eq: $id}}) {
            affected_rows
        }
    }
`;
