
const isPointInsidePolygon = (point, polygon) => {
    let crossings = 0;
    const n = polygon?.length;
  
    for (let i = 0; i < n; i++) {
      const vertex1 = polygon[i];
      const vertex2 = polygon[(i + 1) % n];
  
      if (
        ((vertex1.lng > point.lng) !== (vertex2.lng > point.lng)) &&
        (point.lat < (vertex2.lat - vertex1.lat) * (point.lng - vertex1.lng) / (vertex2.lng - vertex1.lng) + vertex1.lat)
      ) {
        crossings++;
      }
    }
  
    return crossings % 2 === 1;
  };
  
  const calculatePolygonArea = (polygon) => {
    const n = polygon.length;
    let area = 0;
  
    for (let i = 0; i < n; i++) {
      const vertex1 = polygon[i];
      const vertex2 = polygon[(i + 1) % n];
      area += (vertex1.lat * vertex2.lng) - (vertex1.lng * vertex2.lat);
    }
  
    return Math.abs(area) / 2;
  };
  
  const calculateGeoEnclosure = (point, sectors) => {
    let minArea = Infinity;
    let enclosingSector = null;
  
    sectors.forEach((sector) => {
      if (isPointInsidePolygon(point, sector.sectorPolygons)) {
        const area = calculatePolygonArea(sector.sectorPolygons);
        if (area < minArea) {
          minArea = area;
          enclosingSector = sector;
        }
      }

    });

    return enclosingSector;
  };


export default calculateGeoEnclosure;
