import React, { useState } from 'react';
import { Result, Button, Form, Input, Spin, Divider } from 'antd';
import { useHistory } from 'react-router-dom';
import MailIcon from '@mui/icons-material/Mail';
import { useApolloClient } from '@apollo/client';
import axios from 'axios';
import Swal from 'sweetalert2';

import CustomInput from '../../../custom-components/input/CustomInput';
import { fetchUserByEmail } from '../../queries/userQuery';

export const ResetPassworScreen = () => {
    const apolloClient = useApolloClient();
    const [form] = Form.useForm();
    const history = useHistory();

    const [loading, setLoading] = useState(false);

    const formItemLayout = {
        labelCol: {
          xs: { span: 24 },
          sm: { span: 24 },
          md: { span: 24 },
          lg: { span: 24},
          xl: { span: 24},
          xxl: { span: 24}
        },
        wrapperCol: {
          xs: { span: 24 },
          sm: { span: 24 },
          md: { span: 24 },
          lg: { span: 24},
          xl: { span: 24},
          xxl: { span: 24},
        },
    };

    const sendMail = async (data) => {
        axios({
            method: 'post',
            url: process.env.REACT_APP_FUNCTION_RESTORE_PASSWORD_SEND_EMAIL_URL,
            data: {
                id: data.id,
                email: data.email,
                displayName: data.displayName,
                origin: window.location.origin
            }
        }).then((res) => {
            if(res.status === 200) {
                Swal.fire({
                    title: 'Correo electrónico enviado',
                    text: 'Se ha enviado al correo electrónico suministrado información para el cambio de contraseña, vaya a su bandeja de entrada y siga los pasos para restablecer su contraseña.',
                    icon: 'success',
                    confirmButtonText: 'Cerrar',
                    timer: 10000,
                    timerProgressBar: true,
                    willClose: () => {
                        history.replace('/login');
                    }
                });
            } else {
                Swal.fire({
                    title: 'Error',
                    text: `No fue posible enviar el correo electrónico para validarlo.`,
                    icon: 'error',
                    confirmButtonText: 'Cerrar',
                });
            }
            
        }).catch(error => {
            Swal.fire({
                title: 'Error',
                text: `No fue posible enviar el correo electrónico para validarlo. ${error.toString()}`,
                icon: 'error',
                confirmButtonText: 'Cerrar',
            });
        })
    };

    const handleSubmit = (values) => {
        const { email } = values;

        setLoading(true);

        apolloClient.query({
            query: fetchUserByEmail,
            variables: {
                email
            }
        }).then(async ({ data }) => {
            if(data.users.length > 0) {
                const { id, email, displayName } = data.users[0];
                const res = {
                    id, email, displayName
                };
                await sendMail(res);
            }
        }).catch(error => {

        });

        setLoading(false);
    }

    if(loading) {
        return (
            <center
                style={{
                    height: window.innerHeight,
                    display: "flex",
                    flexDirection: "row",
                    flexWrap: "wrap",
                    justifyContent: "center",
                    alignItems: "center",
                    textAlign: "center"
                }}
            >
                <Spin size='large' />
            </center>
        )
    }

    return (
        <div
            // style={{
            //     height: '70vh',
            //     display: 'flex',
            //     justifyContent: 'center',
            //     alignItems: 'center',
            // }}
            className='login-wrap'
        >
            <Result
                icon={<img src="https://res.cloudinary.com/srf/image/upload/v1680285850/NAVITRANS_LOGOS_COLOR_boaoxt.png"  width="250" />}
                title={`Restablecer contraseña`}
                className='login-form'
                extra={[
                    <Form
                        key='form'
                        form={form}
                        name='verification'
                        onFinish={handleSubmit}
                        {...formItemLayout}
                    >
                        <Form.Item
                            label='Correo electrónico'
                            name='email'
                            hasFeedback
                            rules={[
                                {
                                    type: 'email',
                                    message: 'Correo electrónico no válido',
                                },
                                {
                                    required: true,
                                    message: 'Ingrese correo electrónico',
                                },
                            ]}
                        >
                            <CustomInput
                                autoFocus={true}
                                placeholder="Correo electrónico"
                                prefix={<MailIcon />}
                            />
                        </Form.Item>
                        
                        <Form.Item>
                            <Button type="primary" htmlType="submit" shape='round' size='large' style={{backgroundColor: '#D10505', borderColor: '#D10505'}}>
                                Verificar
                            </Button>
                            <Divider type='vertical' />
                            <Button 
                                type="primary" 
                                style={{backgroundColor: '#D10505', borderColor: '#D10505'}}
                                shape='round' 
                                size='large'
                                onClick={() => history.push('/login')}
                            >
                                Regresar
                            </Button>
                        </Form.Item>
                    </Form>
                ]}
            />
        </div>
    );
};
